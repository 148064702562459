import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import { Paper, Tooltip } from "@material-ui/core";
import { EndPoints } from "../../utils/EndPoints";
import {
  color1,
  lightGrey,
  color4,
  color3,
  primary,
  color5,
} from "../../styles/colors";

import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import CreateDialog from "../../layoutComponents/CreateDialog";
import {
  CssTextField,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import NoData from "../../styles/Illustrations/NoData";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../../layoutComponents/ConfirmDialog";
import moment from "moment";
import { filterData, getSingleModule_widget } from "../../Regex";
import { accessText } from "../../routes/sidebar";
export class UsersAndPermissionsComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: "Pending",
        tooltip: `Click to approve pending ${EndPoints["UsersAndPermissions"]}`,
        data: [],
        widget: null,
        route: `/UsersAndPermissions/${
          EndPoints["UsersAndPermissions"].split("/")[0]
        }`,
        goBackRoute: "/UsersAndPermissions",
        colorArr: ["white", lightGrey],
        primaryField: "name",
        secondaryField: "email",
        unit: true,
        avatarField: "src",
        progressBar: true,
        dispatchPackagingArray: [],
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#0AFF00", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      listViewArray: [],
      loading: false,
      openRoles: false,
      openUsers: false,
      loader: false,
      allRoles: [],
      internalUsers: [],
      selectedRole: false,
      selctedTab: "All Modules",
      selctedStatus: "approved",
      MillerList: "",
      BrokerList: "",
      status: [
        { name: "approved" },
        { name: "pending" },
        { name: "CANCELLED" },
      ],
      openDialog: false,
      editList: false,
      changePassword: false,
      modulesArr: [
        { id: 0, name: "Purchase Orders" },
        { id: 1, name: "Ports" },
        { id: 2, name: "Sales Orders" },
        { id: 3, name: "Transporters" },
        { id: 4, name: "Brokers" },
        { id: 5, name: "Millers" },
        { id: 6, name: "Brands Management" },
        { id: 7, name: "Users & Permissions" },
        { id: 8, name: "Masters" },
        { id: 9, name: "Rates" },
        { id: 10, name: "Chats" },
        { id: 11, name: "Homes" },
      ],
      modulesArrUser: [
        { id: 0, name: "Purchase Orders" },
        { id: 1, name: "Ports" },
        { id: 2, name: "Sales Orders" },
        { id: 3, name: "Transporters" },
        { id: 4, name: "Brokers" },
        { id: 5, name: "Millers" },
        { id: 6, name: "Brands Management" },
        { id: 8, name: "Masters" },
        { id: 9, name: "Rates" },
        { id: 10, name: "Chats" },
        { id: 11, name: "Homes" },
      ],
      passLoader: false,
      passArr: [
        {
          name: "Old Password",
          type: "textField",
          api: "old_password",
        },
        {
          name: "New Password",
          type: "textField",
          api: "password",
          validate: (submittedData) => {
            if (
              submittedData["New Password"] ===
              submittedData["Confirm New Password"]
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          name: "Confirm New Password",
          type: "textField",
          api: "c_password",
          validate: (submittedData) => {
            if (
              submittedData["New Password"] ===
              submittedData["Confirm New Password"]
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
      ],
      submittedPass: {},
      errorMsg: {},
    };
  }
  getId = (name) => {
    switch (name) {
      case "Purchase Orders":
        return 0;
      case "Ports":
        return 1;
      case "Sales Orders":
        return 2;
      case "Transporters":
        return 3;
      case "Brokers":
        return 4;
      case "Millers":
        return 5;
      case "Brands Management":
        return 6;
      case "Users & Permissions":
        return 7;
      case "Masters":
        return 8;
      case "Rates":
        return 9;

      case "Chats":
        return 10;
      default:
    }
  };
  setInitialValues(next) {
    const { widget_permission } = getSingleModule_widget(
      next.user,
      accessText.settings,
      accessText.users
    );
    // const results =
    // next[EndPoints["UsersAndPermissions"]] &&
    // next[EndPoints["UsersAndPermissions"]].results &&
    // next[EndPoints["UsersAndPermissions"]].length > 0
    //   ? next[EndPoints["UsersAndPermissions"]].results
    //   : [];

    const UsersAndPermissions = next[EndPoints["UsersAndPermissions"]];

    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;

      tab.widget = widget_permission?.widget
        ? { ...widget_permission?.widget }
        : //{ ...widget_permission?.widget }
          null;
      tab.data =
        next &&
        UsersAndPermissions &&
        Array.isArray(UsersAndPermissions) &&
        UsersAndPermissions.length > 0
          ? UsersAndPermissions.map((s) => {
              return {
                ...s,
                name: `${s.first_name ? s.first_name : ""} ${
                  s.last_name ? s.last_name : ""
                }`,
                email: s.email,
              };
            }).sort((a, b) => {
              var textA = a.name.toUpperCase();
              var textB = b.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
          : [];
      return tab;
    });

    this.setState({
      loader: next.loader,
      tabs,
      pagination:
        tabs[0].data.length > 0
          ? {
              count: UsersAndPermissions.count,
              loadMore: () => {
                this.props.BrandsManagementsFunction(
                  "get",
                  EndPoints["UsersAndPermissions"],
                  null,
                  null,
                  null,
                  null,
                  null,
                  true,
                  tabs[0].data,
                  UsersAndPermissions.next
                );
              },
              next: UsersAndPermissions.next,
              currentLength: tabs[0].data.length,
            }
          : undefined,
      listViewArray:
        next[EndPoints["UsersAndPermissions"]] &&
        next[EndPoints["UsersAndPermissions"]] &&
        next[EndPoints["UsersAndPermissions"]].length > 0
          ? next[EndPoints["UsersAndPermissions"]]
              .map((s) => {
                return {
                  ...s,
                  name: `${s.first_name ? s.first_name : ""} ${
                    s.last_name ? s.last_name : ""
                  }`,
                  email: s.email,
                };
              })
              .sort((a, b) => {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
          : [],
      loading: next.loading,
    });
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    this.fetchRolesList();
    this.setInitialValues(this.props);
  }
  fetchWidget = (value) => {
    this.setState({ loading: true });
    this.props
      .BrandsManagementsFunction(
        "get",
        "users",
        value.id ?? value,
        "WidgetsList",
        null,
        null,
        "noeditparam"
      )
      .then((WidgetsList) => {
        if (WidgetsList && !WidgetsList.error) {
          this.setState({
            singlelistView: WidgetsList,
            loading: false,
          });
        } else {
          this.setState({
            singlelistView: undefined,
            loading: false,
          });
        }
      });
  };
  fetchRolesList = () => {
    this.setState({ loading: true });
    this.props
      .BrandsManagementsFunction(
        "get",
        "users-and-permissions/roles",
        null,
        "allRoles"
      )
      .then((allRoles) => {
        this.setState({
          allRoles: allRoles && allRoles.length > 0 ? allRoles : [],
          loading: false,
        });
      });
    this.props
      .BrandsManagementsFunction(
        "get",
        "masters/miller/list",
        null,
        "MillerList",
        null,
        { is_active: "True", profile_used: "False" }
      )
      .then((MillerList) => {
        this.setState({ MillerList, loading: false });
      });
    this.props
      .BrandsManagementsFunction(
        "get",
        "masters/broker/list",
        null,
        "BrokerList",
        null,
        { is_active: "True", profile_used: "False" }
      )
      .then((BrokerList) => {
        this.setState({ BrokerList, loading: false });
      });
    this.props
      .BrandsManagementsFunction(
        "get",
        "masters/clients/list",
        null,
        "ClientList",
        null,
        { is_active: "True", profile_used: "False" }
      )
      .then((ClientList) => {
        this.setState({ ClientList, loading: false });
      });
    // this.props
    // .BrandsManagementsFunction(
    //   "get",
    //   "users-and-permissions/modules/",
    //   null,
    //   "ModuleList",
    //   null,
    //   { is_active: "True" }
    // )
    // .then((ModuleList) => {
    //   this.setState({ ModuleList, loading: false });

    // });
  };
  setRoleUser = (role, userId) => {
    this.props
      .BrandsManagementsFunction(
        "post",
        "users-and-permissions/roles-to-users",
        null,
        "postdata",
        {
          role: role,
          assigned_to: userId,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
      });
  };
  // setModuleUser = (role, userId) => {
  //   this.props
  //     .BrandsManagementsFunction("post", "users-and-permissions/roles-to-users", null, "postdata", {
  //       role: role,
  //       assigned_to: userId,
  //     })
  //     .then((res) => {
  //       this.setState({ loading: false });
  //     });
  // };

  // fetchModulesList = () => {
  //   this.setState({ loading: true });
  //   this.props
  //     .BrandsManagementsFunction("get", "users-and-permissions/modules", null, "allModules")
  //     .then((allModules) => {
  //       this.setState({ allModules: allModules && allModules.length > 0 ? allModules : [], loading: false });
  //     });
  // };
  // fetchRoleToModuleList = (selectedRole) => {
  //   this.setState({ loading: true });
  //   this.props.BrandsManagementsFunction("get", "users-and-permissions/modules-to-roles").then((allRoleModules) => {
  //     this.setState({
  //       allRoleModules:
  //         allRoleModules && allRoleModules.length > 0
  //           ? selectedRole
  //             ? filterData(allRoleModules, "role", selectedRole.id)
  //               ? filterData(allRoleModules, "role", selectedRole.id)
  //               : []
  //             : allRoleModules
  //           : [],
  //       loading: false,
  //     });
  //   });
  // };

  // fetchRoleToUsersList = (selectedRole) => {
  //   this.setState({ loading: true });
  //   this.props.BrandsManagementsFunction("get", "users-and-permissions/roles-to-users").then((allRoleUsers) => {
  //     this.setState({
  //       allRoleUsers:
  //         allRoleUsers && allRoleUsers.length > 0
  //           ? selectedRole
  //             ? filterData(allRoleUsers, "role", selectedRole.id)
  //               ? filterData(allRoleUsers, "role", selectedRole.id)
  //               : []
  //             : allRoleUsers
  //           : [],
  //       loading: false,
  //     });
  //   });
  // };
  // fetchInternalUsersList = () => {
  //   this.setState({ loading: true });
  //   this.props
  //     .BrandsManagementsFunction("get", "users-and-permissions/internal-users", null, "internalUsers")
  //     .then((internalUsers) => {
  //       this.setState({
  //         internalUsers: internalUsers && internalUsers.length > 0 ? internalUsers : [],
  //         loading: false,
  //       });
  //     });
  // };

  listClickHandler = (value) => {
    // this.setState({
    //   singlelistView: value,
    // });
    this.fetchWidget(value);
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  render() {
    const {
      loading,
      WidgetsList,
      allRoles,
      tabs,
      listViewArray,
      openDialog,
      editList,
      modulesArr,
      modulesArrUser,
      singlelistView,
      changePassword,
      passLoader,
      passArr,
      submittedPass,
      errorMsg,
      MillerList,
      BrokerList,
      ClientList,
      selectedRole,
      role,
      ModuleList,
      loader,
    } = this.state;
    const { user } = this.props;

    // const { widget_permission } = getSingleModule_widget(
    //   user,
    //   accessText.settings,
    //   accessText.users
    // );
    console.log(WidgetsList, "WidgetsList");
    return (
      <Layout
        tabs={tabs}
        pagination={this.state.pagination}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: "Search Users",
          labelField: "name",
          // defaultValue: this.state.searchData ?? "",
          // apiCall: (data) => {

          //   this.setState({ searchData: data });
          //   this.props
          //     .BrandsManagementsFunction(
          //       "get",
          //       data
          //         ? `${EndPoints["UsersAndPermissions"]}/?search=${data}`
          //         : EndPoints["UsersAndPermissions"],
          //       undefined,
          //       undefined,
          //       undefined,
          //       undefined,
          //       undefined,
          //       undefined,
          //       undefined,
          //       undefined,
          //       true
          //     )
          //     .then((res) => {
          //       this.setInitialValues({
          //         ...this.props,
          //         [EndPoints["UsersAndPermissions"]]: res,
          //       });
          //     });
          // },
        }}
        loading={
          loading[EndPoints["UsersAndPermissions"]]
            ? loading[EndPoints["UsersAndPermissions"]] === true
              ? true
              : loader === true
              ? true
              : false
            : loader === true
            ? true
            : false
        }
        selectHandler={this.selectHandler}
        listClickHandler={this.listClickHandler}
        fabClickHandler={() => {
          this.setState({
            openDialog: true,
            editList: false,
            selectedRole: false,
          });
        }}
        serchClick={this.searchHandler}
        centerWidth="250px"
        openWidth="485px"
        closeWidth="307px">
        <Paper className="width-100 height-100 padding20 backgroundWhitef">
          <div className="d-flex width-100 height-100 d-flex-column">
            {openDialog === true ? (
              <>
                <CreateDialog
                  module={"User & Modules"}
                  noAddAnother={true}
                  closeOnSave={true}
                  selectedList={editList ? editList : false}
                  editList={editList ? editList : false}
                  borderRadius={false}
                  // textFieldStyle={{ width: "calc(50% - 20px)", margin: "10px" }}
                  heightNew="500px"
                  maxHeight="calc(100vh - 140px)"
                  width="100%"
                  handleClose={() => {
                    this.setState({ openDialog: false });
                  }}
                  fetchAgain={(response) => {
                    console.log(response, "response");
                    this.props
                      .BrandsManagementsFunction(
                        "get",
                        EndPoints["UsersAndPermissions"]
                      )
                      .then((res) => {
                        if (res) {
                          console.log(res.results, editList, "refilshs");
                          if (editList) {
                            this.fetchWidget(editList);
                          } else if (response && response.user_id) {
                            this.fetchWidget(response.user_id);
                          }
                        }
                      });
                  }}
                  addApi2={
                    editList
                      ? {
                          api: "users-and-permissions/permissions/bulk",
                          formatData: (data, res, s, second_api) => {
                            console.log(
                              data,
                              singlelistView,
                              user,
                              res,
                              "datadata re234t5s"
                            );
                            return data["Accordin"]
                              ? data["Accordin"].map((ss) => {
                                  return {
                                    ...ss,
                                    assigned_to: singlelistView.credential_id,
                                  };
                                })
                              : [];
                          },
                          method: "PUT",
                          // formatData3: (data, res, s, second_api) => {
                          //   const getProfile = () => {
                          //     switch (data["Role"]) {
                          //       case "Broker":
                          //         return data["BrokerId"];

                          //       case "Miller":
                          //         return data["MillerId"];

                          //       case "Client":
                          //         return data["ClientId"];
                          //     }
                          //   };
                          //   const getList = () => {
                          //     switch (data["Role"]) {
                          //       case "Broker":
                          //         return BrokerList && BrokerList.length > 0
                          //           ? BrokerList
                          //           : [];

                          //       case "Miller":
                          //         return MillerList && MillerList.length > 0
                          //           ? MillerList
                          //           : [];
                          //     }
                          //   };
                          //   const millerList = getList();

                          //   console.log(
                          //     millerList,
                          //     getProfile(),
                          //     "millerList",
                          //     filterData(
                          //       millerList,
                          //       "id",
                          //       Number(getProfile()),
                          //       "onlyOne"
                          //     )
                          //   );
                          //   return {
                          //     user: res.user_id,
                          //     id: getProfile(),
                          //     pincode: filterData(
                          //       millerList,
                          //       "id",
                          //       Number(getProfile()),
                          //       "onlyOne"
                          //     )
                          //       ? filterData(
                          //           millerList,
                          //           "id",
                          //           Number(getProfile()),
                          //           "onlyOne"
                          //         ).pincode
                          //       : "",
                          //   };
                          // },
                          // api3: (submitted, res) => {
                          //   const getProfile = () => {
                          //   console.log(submitted,"submittedsubmitted")
                          //     switch (submitted["Role"]) {
                          //       case "Broker":
                          //         return submitted["BrokerId"];

                          //       case "Miller":
                          //         return submitted["MillerId"];

                          //       case "Client":
                          //         return submitted["ClientId"];
                          //     }
                          //   };
                          //   switch (submitted["Role"]) {
                          //     case "Broker":
                          //       return `masters/broker/${getProfile()}`;

                          //     case "Miller":
                          //       return `masters/miller/${getProfile()}`;

                          //     // case "Client":
                          //     //   return `masters/client/${getProfile()}`;
                          //     default:
                          //       return null;
                          //   }
                          // },
                          // method3: "patch",
                        }
                      : {
                          method: "POST",
                          formatData: (data, res, s, second_api) => {
                            return {
                              user: {
                                id: res.user_id,
                                email: data.Email,
                              },
                              role: {
                                id: data.RoleId,
                                name: data.Role,
                              },
                              // "created_by": {
                              //     "id": user.id,
                              //     "credential_id":user.credential_id,
                              //     "name": user.username
                              // },
                              permissions: data["Accordin"] ?? [],
                            };
                          },
                          api: "users-and-permissions/permissions-create",
                          formatData3: (data, res, s, second_api) => {
                            const getProfile = () => {
                              switch (data["Role"]) {
                                case "Broker":
                                  return data["BrokerId"];

                                case "Miller":
                                  return data["MillerId"];

                                case "Client":
                                  return data["ClientId"];
                              }
                            };
                            const getList = () => {
                              switch (data["Role"]) {
                                case "Broker":
                                  return BrokerList && BrokerList.length > 0
                                    ? BrokerList
                                    : [];

                                case "Miller":
                                  return MillerList && MillerList.length > 0
                                    ? MillerList
                                    : [];
                              }
                            };
                            const millerList = getList();
                            // console.log(
                            //   millerList,
                            //   getProfile(),
                            //   "millerList",
                            //   filterData(
                            //     millerList,
                            //     "id",
                            //     Number(getProfile()),
                            //     "onlyOne"
                            //   )
                            // );

                            return {
                              user: res.user_id,
                              id: getProfile(),
                              pincode: filterData(
                                millerList,
                                "id",
                                Number(getProfile()),
                                "onlyOne"
                              )
                                ? filterData(
                                    millerList,
                                    "id",
                                    Number(getProfile()),
                                    "onlyOne"
                                  ).pincode
                                : "",
                            };
                          },
                          api3: (submitted, res) => {
                            const getProfile = () => {
                              switch (submitted["Role"]) {
                                case "Broker":
                                  return submitted["BrokerId"];

                                case "Miller":
                                  return submitted["MillerId"];

                                case "Client":
                                  return submitted["ClientId"];
                              }
                            };
                            switch (submitted["Role"]) {
                              case "Broker":
                                return `masters/broker/${getProfile()}`;

                              case "Miller":
                                return `masters/miller/${getProfile()}`;

                              default:
                                return null;
                            }
                          },
                          method3: "patch",
                        }
                  }
                  postApi="registration/user"
                  editApi="users"
                  textFieldWidth="calc(20% - 20px)"
                  borderRadius="20px"
                  textFieldStyle={{ width: "calc(25% - 20px)", margin: "10px" }}
                  heightNew="calc(100vh - 80px)"
                  maxHeight="calc(100vh - 80px)"
                  widthNew="1000px"
                  inputArray={[
                    // {
                    //   name: "Active",
                    //   type: "toggle",
                    //   defaultValue: {
                    //     value: editList ? editList.is_active : true,
                    //   },
                    //   api: "is_active",
                    // },
                    {
                      name: "Role",
                      type: "select",
                      dataType: "text",
                      array: allRoles,
                      value: "role_name",
                      disabled:
                        (selectedRole === "Broker" ||
                          selectedRole === "Miller" ||
                          selectedRole === "Client") &&
                        editList
                          ? true
                          : false,
                      api: "role",
                      sendNameNotId: true,
                      defaultValue: {
                        value:
                          editList && editList.role ? editList.role : "Admin",
                        id: filterData(
                          allRoles,
                          "role_name",
                          editList && editList.role ? editList.role : 1,
                          "onlyOne"
                        )
                          ? filterData(
                              allRoles,
                              "role_name",
                              editList && editList.role ? editList.role : 1,
                              "onlyOne"
                            )?.id
                          : 1,
                      },
                      onChange: async (submittedData) => {
                        if (
                          submittedData["Role"] === "Admin" &&
                          submittedData["Role"] === "Accountant" &&
                          submittedData["Role"] === "Manager"
                        ) {
                          submittedData["Module Permissions"] = modulesArr;
                          submittedData["Module PermissionsId"] =
                            modulesArr.map((s) => {
                              return s.id;
                            });
                          submittedData["First Name"] = "";
                          submittedData["Phone number"] = "";
                        } else {
                          submittedData["Module Permissions"] =
                            modulesArr.filter(
                              (s) => s.name !== "Users & Permissions"
                            );
                          submittedData["Module PermissionsId"] = modulesArr
                            .map((s) => {
                              return s.id;
                            })
                            .filter((s) => s !== 7);
                        }
                        this.setState({ selectedRole: submittedData["Role"] });

                        return submittedData;
                      },
                    },
                    selectedRole !== false && selectedRole === "Miller"
                      ? {
                          name: "Miller",
                          type: editList ? "text" : "select",
                          dataType: "text",
                          disabled: editList ? true : false,
                          not_required: true,
                          array:
                            MillerList && MillerList.length > 0
                              ? MillerList
                              : [],
                          value: "name",
                          api: "miller",

                          onChange: async (submittedData) => {
                            if (submittedData["Miller"]) {
                              const selected = filterData(
                                MillerList && MillerList.length > 0
                                  ? MillerList
                                  : [],
                                "id",
                                Number(submittedData["MillerId"]),
                                "onlyOne"
                              )
                                ? filterData(
                                    MillerList && MillerList.length > 0
                                      ? MillerList
                                      : [],
                                    "id",
                                    Number(submittedData["MillerId"]),
                                    "onlyOne"
                                  )
                                : {};
                              submittedData["First Name"] = selected
                                ? selected.name
                                : "";
                              submittedData["Phone number"] = selected
                                ? selected.phone
                                : "";
                            }
                            this.setState({
                              selectedMiller: submittedData["Miller"],
                            });

                            return submittedData;
                          },
                          defaultValue: {
                            value:
                              singlelistView && singlelistView.role_details
                                ? singlelistView.role_details.name //default_brokerage
                                : "",
                            id:
                              singlelistView && singlelistView.role_details
                                ? singlelistView.role_details.id
                                : "",
                          },
                        }
                      : false,
                    selectedRole !== false && selectedRole === "Broker"
                      ? {
                          name: "Broker",
                          type: editList ? "text" : "select",
                          dataType: "text",
                          not_required: true,
                          disabled: editList ? true : false,
                          array: BrokerList.length > 0 ? BrokerList : [],
                          value: "name",
                          api: "broker",
                          onChange: async (submittedData) => {
                            if (submittedData["Broker"]) {
                              const selected = filterData(
                                BrokerList && BrokerList.length > 0
                                  ? BrokerList
                                  : [],
                                "id",
                                Number(submittedData["BrokerId"]),
                                "onlyOne"
                              )
                                ? filterData(
                                    BrokerList && BrokerList.length > 0
                                      ? BrokerList
                                      : [],
                                    "id",
                                    Number(submittedData["BrokerId"]),
                                    "onlyOne"
                                  )
                                : {};

                              submittedData["First Name"] = selected
                                ? selected.name
                                : "";
                              submittedData["Phone number"] = selected
                                ? selected.phone
                                : "";
                            }
                            this.setState({
                              selectedBroker: submittedData["Broker"],
                            });

                            return submittedData;
                          },
                          defaultValue: {
                            value:
                              singlelistView && singlelistView.role_details
                                ? singlelistView.role_details.name //default_brokerage
                                : "",
                            id:
                              singlelistView && singlelistView.role_details
                                ? singlelistView.role_details.id
                                : "",
                          },
                        }
                      : false,
                    selectedRole !== false && selectedRole === "Client"
                      ? {
                          name: "Client",
                          type: editList ? "text" : "select",
                          dataType: "text",
                          disabled: editList ? true : false,
                          not_required: true,
                          array:
                            ClientList && ClientList.length > 0
                              ? ClientList
                              : [],
                          value: "client_name",
                          api: "clients",

                          defaultValue: {
                            value:
                              singlelistView && singlelistView.ClientList
                                ? singlelistView.ClientList.client_name //default_brokerage
                                : "",
                            id:
                              singlelistView && singlelistView.ClientList
                                ? singlelistView.ClientList.id
                                : "",
                          },
                          onChange: async (submittedData) => {
                            if (submittedData["Client"]) {
                              const selected = filterData(
                                ClientList && ClientList.length > 0
                                  ? ClientList
                                  : [],
                                "id",
                                Number(submittedData["ClientId"]),
                                "onlyOne"
                              )
                                ? filterData(
                                    ClientList && ClientList.length > 0
                                      ? ClientList
                                      : [],
                                    "id",
                                    Number(submittedData["ClientId"]),
                                    "onlyOne"
                                  )
                                : {};
                              submittedData["First Name"] = selected
                                ? selected.client_name
                                : "";
                              submittedData["Phone number"] = selected
                                ? selected.phone
                                : "";
                            }
                            this.setState({
                              selectedClient: submittedData["Client"],
                            });

                            return submittedData;
                          },
                        }
                      : false,
                    {
                      name: "First Name",
                      type: "textField",
                      dataType: "text",
                      disabled:
                        (selectedRole === "Broker" ||
                          selectedRole === "Miller" ||
                          selectedRole === "Client") &&
                        editList
                          ? true
                          : false,
                      not_required: true,
                      api: "first_name",
                      defaultValue: {
                        value:
                          editList && editList.first_name
                            ? editList.first_name
                            : "",
                      },
                    },
                    selectedRole !== false &&
                    (selectedRole === "Broker" ||
                      selectedRole === "Miller" ||
                      selectedRole === "Client")
                      ? false
                      : {
                          name: "Last Name",
                          type: "textField",
                          dataType: "text",
                          not_required: true,
                          api: "last_name",
                          defaultValue: {
                            value:
                              editList && editList.last_name
                                ? editList.last_name
                                : "",
                          },
                        },

                    {
                      name: "Phone number",
                      type: "textField",
                      disabled:
                        (selectedRole === "Broker" ||
                          selectedRole === "Miller" ||
                          selectedRole === "Client") &&
                        editList
                          ? true
                          : false,
                      dataType: "number",
                      onInput: "phone_number",
                      not_required: true,
                      only_number: true,
                      api: "phone",
                      defaultValue: {
                        value:
                          editList &&
                          editList.phone &&
                          editList.phone !== 0 &&
                          editList.phone !== "0"
                            ? editList.phone
                            : "",
                      },
                    },
                    {
                      name: "Date of Birth",
                      type: "Month",
                      dataType: "text",
                      api: "date_of_birth",
                      not_required: true,
                      defaultValue: {
                        value:
                          editList && editList.date_of_birth
                            ? editList.date_of_birth
                            : new Date(),
                      },
                    },
                    {
                      name: "Email",
                      type: "textField",
                      dataType: "text",
                      disabled:
                        (selectedRole === "Broker" ||
                          selectedRole === "Miller" ||
                          selectedRole === "Client") &&
                        editList
                          ? true
                          : false,
                      api: "email",
                      onInput: "email",
                      defaultValue: {
                        value: editList && editList.email,
                      },
                    },

                    editList === false && {
                      name: "Password",
                      type: "textField",
                      dataType: "password",
                      api: "password",
                      password: true,
                      validate: (submittedData) => {
                        if (
                          submittedData["Password"] ===
                          submittedData["Confirm Password"]
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                    editList === false && {
                      name: "Confirm Password",
                      type: "textField",
                      password: true,
                      dataType: "password",
                      api: "password",
                      validate: (submittedData) => {
                        if (
                          submittedData["Password"] ===
                          submittedData["Confirm Password"]
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                    {
                      name: "Status",
                      type: "select",
                      dataType: "text",
                      array: [
                        { name: "approved", id: "approved" },
                        { name: "pending", id: "pending" },
                        { name: "cancelled", id: "cancelled" },
                      ],
                      value: "name",
                      api: "status",
                      defaultValue: {
                        value:
                          editList && editList.status
                            ? editList.status
                            : "approved",
                      },
                    },

                    // {
                    //   name: "Module Permissions",
                    //   type: "multiInput",
                    //   placeholder: "Modules",
                    //   dataType: "text",
                    //   array: modulesArr,
                    //   value: "name",
                    //   api: "modules_access",
                    //   sendName: true,
                    //   defaultValue: {
                    //     value: editList
                    //       ? editList.modules_access &&
                    //         editList.modules_access.length > 0
                    //         ? editList.modules_access.map((s) => {
                    //             return modulesArr.filter(
                    //               (ss) => ss.name === s
                    //             )[0];
                    //           })
                    //         : []
                    //       : modulesArr,
                    //     id: editList
                    //       ? editList.modules_access &&
                    //         editList.modules_access.length > 0
                    //         ? editList.modules_access.map((s) => {
                    //         console.log(modulesArr,"modulesArr",s)
                    //             return modulesArr.filter(

                    //               (ss) =>

                    //               ss&& ss.name === s
                    //             )[0].id;

                    //           }

                    //           )
                    //         : []
                    //       : modulesArr.map((s) => {
                    //           return s.id;
                    //         }),
                    //   },
                    // },
                    {
                      name: "Accordin",
                      type: "accordin",
                      placeholder: "Modules",
                      dataType: "text",
                      array: modulesArr,
                      value: "name",
                      api: "modules_access",
                      sendName: true,
                      defaultValue: {
                        value: editList ? editList.access_details ?? [] : [],
                      },
                    },
                  ]}
                  postData={this.props.BrandsManagementsFunction}
                />
              </>
            ) : (
              ""
            )}

            {this.state.success || this.state.error ? (
              <SnackbarComponent
                error={this.state.error}
                autoHideDuration={this.state.error ? 3000 : 2000}
                success={this.state.success}
                snackbarClose={() => {
                  this.setState({
                    error: "",
                    success: "",
                  });
                }}
              />
            ) : (
              ""
            )}
            {singlelistView === null ? (
              <div className="d-flex width-100 height-100 justifyC alignC">
                <NoData />
              </div>
            ) : loading[EndPoints["UsersAndPermissions"]] === true ||
              loader === true ? (
              <div className="alignC justiyC d-flex width-100 height-100">
                <StyledCircularProgress color="rgb(241, 154, 48)" />
              </div>
            ) : (
              singlelistView && (
                <Paper
                  className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}>
                  {changePassword ? (
                    <ConfirmDialog
                      header={"Change Password"}
                      loader={passLoader}
                      onSubmit={(e) => {
                        const correctData = [];
                        passArr
                          .filter((sA) => sA.api)
                          .map((singleInput) => {
                            if (submittedPass[singleInput.name]) {
                              if (
                                !singleInput.validate ||
                                (singleInput.validate &&
                                  singleInput.validate(submittedPass) === true)
                              ) {
                                correctData.push(singleInput.name);
                              } else {
                                var errorMsgs = this.state.errorMsg;
                                errorMsgs[singleInput.name] =
                                  "Passwords do not match !";
                                return this.setState({ errorMsg: errorMsgs });
                              }
                            } else {
                              var errorMsgs = this.state.errorMsg;
                              errorMsgs[
                                singleInput.name
                              ] = `Please add ${singleInput.name} !`;
                              return this.setState({ errorMsg: errorMsgs });
                            }
                            return singleInput;
                          });
                        if (correctData.length === 3) {
                          this.setState({ passLoader: true });
                          this.props
                            .BrandsManagementsFunction(
                              "post",
                              "auth/change-password",
                              null,
                              "posted",
                              {
                                email: singlelistView.email,
                                old_password: submittedPass["Old Password"],
                                password: submittedPass["New Password"],
                              }
                            )
                            .then((res) => {
                              if (
                                res.error ||
                                (res.response &&
                                  res.response.data &&
                                  res.response.data.status === "error")
                              ) {
                                this.setState({
                                  error:
                                    typeof res.data === "string"
                                      ? res.data
                                      : res.data.length > 0
                                      ? res.data
                                          .map((s) => {
                                            return `${
                                              s.field ? `${s.field} :` : ""
                                            } ${
                                              typeof s.message === "string"
                                                ? s.message
                                                : s.message.length > 0 &&
                                                  s.message[0] &&
                                                  s.message[0]
                                            }`;
                                          })
                                          .join(" ,")
                                      : res.data.response
                                      ? res.data.response.data.msg.error
                                        ? res.data.response.data.msg.error
                                        : res.data.response.data.msg
                                      : "Error !",
                                  passLoader: false,
                                });
                              } else {
                                this.setState(
                                  { success: "Password Updated!" },
                                  () => {
                                    this.setState({
                                      passLoader: false,
                                      changePassword: false,
                                      submittedPass: {},
                                      errorMsg: {},
                                    });
                                  }
                                );
                              }
                            });
                        }
                      }}
                      height="473px"
                      width="450px"
                      maxWidth="450px"
                      action={"Update"}
                      setOpenDialog={() => {
                        this.setState({
                          changePassword: false,
                          submittedPass: {},
                          errorMsg: {},
                        });
                      }}
                      openDialog={changePassword !== false ? true : false}
                      content={() => {
                        return (
                          <>
                            {this.state.success || this.state.error ? (
                              <SnackbarComponent
                                autoHideDuration={
                                  this.state.error ? 3000 : 2000
                                }
                                error={this.state.error}
                                success={this.state.success}
                                snackbarClose={() => {
                                  this.setState({
                                    error: "",
                                    success: "",
                                  });
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {passLoader ? (
                              <StyledCircularProgress />
                            ) : (
                              <div className="width-100 height-100">
                                {passArr.map((single) => (
                                  <CssTextField
                                    required={true}
                                    onWheel={(event) => {
                                      event.preventDefault();
                                    }}
                                    inputProps={{
                                      autocomplete: "new-password",
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    style={{
                                      width: "calc(100% - 40px)",
                                      margin: "20px",
                                    }}
                                    id="outlined-basic"
                                    label={single.name}
                                    error={errorMsg[single.name] ? true : false}
                                    helperText={
                                      errorMsg[single.name]
                                        ? errorMsg[single.name]
                                        : false
                                    }
                                    variant="outlined"
                                    name={single.name}
                                    value={
                                      submittedPass[single.name]
                                        ? submittedPass[single.name]
                                        : ""
                                    }
                                    type="password"
                                    onChange={(event) => {
                                      const errorMsgs = this.state.errorMsg;
                                      errorMsgs[event.target.name] = false;
                                      const submittedData = submittedPass;
                                      submittedData[event.target.name] =
                                        event.target.value;

                                      this.setState({
                                        submittedPass: submittedData,
                                        errorMsg: errorMsgs,
                                      });
                                    }}
                                  />
                                ))}
                              </div>
                            )}
                          </>
                        );
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {/* {widget_permission?.widget?.has_update_permission && ( */}
                  <div className="width-100 d-flex justifyFE alignC">
                    <StyledButton
                      tooltip="Edit"
                      // name="Edit"
                      icon={<EditIcon />}
                      minWidth="20px"
                      padding="0"
                      onClick={() => {
                        this.setState({
                          openDialog: true,
                          editList: singlelistView,
                          selectedRole: singlelistView.role,
                        });
                      }}
                    />

                    <StyledButton
                      name="Change Password"
                      onClick={() => {
                        this.setState({
                          changePassword: true,
                          submittedPass: {},
                          errorMsg: {},
                        });
                      }}
                      margin="0 0 0 10px"
                      withBg={true}
                    />
                  </div>
                  {/* )} */}

                  <div
                    className="overflowYA scrollBluePrimary"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0 30px",
                      height: "calc(100% - 31.7px)",
                      textTransform: "capitalize",
                    }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}>
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}>
                        Name:{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                          textTransform: "capitalize",
                        }}>
                        {`${singlelistView.first_name} ${
                          singlelistView.last_name
                            ? singlelistView.last_name
                            : ""
                        }`}
                      </p>
                    </div>{" "}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}>
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}>
                        Date of Birth:
                      </p>{" "}
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                        }}>
                        {singlelistView.date_of_birth
                          ? moment(singlelistView.date_of_birth).format(
                              "ddd, MMM Do YYYY"
                            )
                          : ""}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}>
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}>
                        Phone Number:
                      </p>{" "}
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                        }}>
                        {singlelistView.phone &&
                        singlelistView.phone !== 0 &&
                        singlelistView.phone !== "0"
                          ? singlelistView.phone
                          : "-"}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}>
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}>
                        Email:{" "}
                      </p>{" "}
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                          textTransform: "lowercase",
                        }}>
                        {singlelistView.email}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}>
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}>
                        Status:{" "}
                      </p>{" "}
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                        }}>
                        {singlelistView.status}
                      </p>
                    </div>{" "}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}>
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}>
                        Role:{" "}
                      </p>{" "}
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                        }}>
                        {singlelistView.role}
                      </p>
                    </div>
                    {/* {singlelistView.role === "Miller" ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                        }}>
                        <p
                          style={{
                            fontSize: "18px",
                            margin: "10px 0 ",
                            minWidth: "200px",
                          }}>
                          Miller Name:{" "}
                        </p>

                        <p
                          style={{
                            fontSize: "18px",
                            margin: "10px 0 10px 10px",
                            color: "#808080",
                            textTransform: "capitalize",
                          }}>
                        

                          {singlelistView && singlelistView.role_details
                            ? singlelistView.role_details.name
                            : ""}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    {singlelistView.role === "Broker" ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                        }}>
                        <p
                          style={{
                            fontSize: "18px",
                            margin: "10px 0 ",
                            minWidth: "200px",
                          }}>
                          Broker Name:{" "}
                        </p>

                        <p
                          style={{
                            fontSize: "18px",
                            margin: "10px 0 10px 10px",
                            color: "#808080",
                            textTransform: "capitalize",
                          }}>
                  

                          {singlelistView && singlelistView.role_details
                            ? singlelistView.role_details.name
                            : ""}
                        </p>
                      </div>
                    ) : (
                      ""
                    )} */}
                    <div
                      style={{
                        width: "100%",
                        //display: "flex",
                      }}>
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                          fontWeight:"500"
                          //padding: "15px 0 0 0",
                        }}>
                        All Modules:{" "}
                      </p>{" "}
                      <div
                        className="flexWrap d-flex"
                        style={{
                          fontSize: "15px",
                        
                          color: "black",
                        }}>
                        {console.log(singlelistView, "singlelistView")}

                        {singlelistView.access_details &&
                        singlelistView.access_details.length > 0
                          ? singlelistView.access_details.map((s, i) => (
                              <>
                                {s.modules.map((module) => {
                                  const widgets_list = module.widgets.filter(
                                    (widget) =>
                                      widget.widget.has_create_permission ===
                                        true ||
                                      widget.widget.has_update_permission ===
                                        true ||
                                      widget.widget.has_read_permission === true
                                  );
                                  return widgets_list.length > 0 ? (
                                    <div
                                      key={module.module.module_name}
                                      style={{
                                        // width: "fit-content",
                                        fontWeight: "600",
                                        // background: "#F8B195",
                                        background:"rgb(0 0 0 / 1%)",
                                        boxShadow:"rgba(211, 211, 211, 0.56) 1px 2px 6px 1px",
                                        
                                 
                                        width: "180px", // Set the fixed width
                                        minWidth: "100px",
                                        borderRadius: "5px",
                                        color: "black",
                                        padding: "10px",
                                        margin:"5px 15px 10px 0px"
                                      }}
                                      className=" borderRadius7 minWidthFC">
                                      {module.module.module_name}

                                      <div>
                                        {widgets_list.map((widget,i) => (
                                          
                                          <div
                                            style={{
                                            
                                              width: "fit-content",
                                              fontWeight: "400",
                                              // background:
                                              // i === 0
                                              //   ? "#F8B195"
                                              //   : i === 1
                                              //   ? "F67280"
                                              //   : i === 2
                                              //   ? "#C06C84"
                                              //   : i === 3
                                              //   ? "#F8B195"
                                              //   : i === 4
                                              //   ? "#6C5B7B"
                                              //   : i === 5
                                              //   ? "#F8B195"
                                              //   : i === 6
                                              //   ? "F67280"
                                              //   : i === 7
                                              //   ? "#C06C84"
                                              //   : "white",
                                              maxWidth: "200px",
                                              borderRadius: "5px",
                                              color: "black",
                                              padding: "5px",
                                              // margin:"5px 15px 10px 0px"
                                            }}
                                            className=" borderRadius7 "
                                            key={widget.widget.id}>
                                            {widget.widget.widget_name}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  );
                                })}
                              </>
                            ))
                          : ""}
                      </div>
                    </div>
                  </div>
                </Paper>
              )
            )}
          </div>
        </Paper>
      </Layout>
    );
  }
}

export default UsersAndPermissionsComponent;
