import React, { useEffect } from "react";
import { connect } from "react-redux";
import ChatsComponent from "./ChatsComponent";
import { ChatsFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function ChatsContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    console.log(props.user, "props.user.credential_id");
    if (props.user.uid) {
      if (props.chat_room_id) {
      } else {
        props.ChatsFunction(
          "get",
          `${EndPoints["Chats"]}/?participants=${
            props.user && props.user.credential_id
              ? props.user.credential_id
              : ""
          }&is_active=True`,
          null,
          EndPoints["Chats"],
          null,
          null,
          null,
          null,
          null,
          null,
          "noSlash"
        );
      }
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <ChatsComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  Chats: state.modules.Chats,
  loading: state.modules.loading,
  [EndPoints["Chats"]]: state.modules[EndPoints["Chats"]],
});
const mapDispatchToProps = {
  ChatsFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatsContainer);
