import * as React from "react";
import Box from "@mui/material/Box";
import { StyledButton } from "../styles/StyledComponents";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";

const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiInputBase-root": {background:"rgb(0 0 0 / 1%)"},
    "&.PickerWithState-root-34 .MuiOutlinedInput-root .MuiOutlinedInput-input": {background:"none"},
    "&.MuiOutlinedInput-adornedEnd":{
background:"blue"
    },

    "&:hover": {
      border: "none",
    },
    // '& .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    //   padding: '0px 4px 7.5px 6px',
    // },
    // '&.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root': {
    //   padding: '5px',
    // },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: "0",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(9.5px, 9.5px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiInputBase-root.Mui-disabled": { color: "black" },
    "& .MuiFormControl-root": {},

    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input ": {
        caretColor: "transparent",
        background: "white",
        padding: "9.5px",
      },
      "& .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root": {
        padding: "5px",
      },
      "& .MuiInputLabel-outlined": {
        transform: "translate(9.5px, 9.5px) scale(1)",
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75) !important",
      },
    },
  },
})(KeyboardDatePicker);

export default function BasicDateRangePicker(props) {
  const [from, setFrom] = React.useState();
  const [to, setTo] = React.useState();
  const { value, labelStarte, labelEnd, selectedRange } = props;
  React.useEffect(() => {
    if (selectedRange && selectedRange.length > 0) {
      if (selectedRange[0] && selectedRange[0] !== null) {
        setFrom(selectedRange[0]);
      }
      if (selectedRange[1] && selectedRange[1] !== null) {
        setTo(selectedRange[1]);
      }
    }
  }, [selectedRange]);
  return (
    <React.Fragment>
      <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
        <CssKeyboardDatePicker
          label={"From"}
          variant="inline"
          maxDate={new Date()}
          disableFuture
          error={false}
          invalidLabel=""
          onKeyPress={(ev) => {
            ev.preventDefault();
          }}
          allowKeyboardControl={false}
          autoOk
          inputVariant="outlined"
          style={
            { width: "200px" } //disabled={tp}
          }
          format="do MMM, yyyy"
          maxDate={to}
          value={from ? from : null}
          InputAdornmentProps={{ position: "end" }}
          onChange={(newValue) => {
            // props.onChange([newValue,to])
            setFrom(newValue);
          }}
        />
      </MuiPickersUtilsProvider>
      <Box style={{ display: "flex", alignItems: "center" }} sx={{ mx: 2 }}>
        {" "}
        to{" "}
      </Box>
      <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
        <CssKeyboardDatePicker
          label={"To"}
          variant="inline"
          maxDate={new Date()}
          disableFuture
          minDate={from}
          error={false}
          invalidLabel=""
          onKeyPress={(ev) => {
            ev.preventDefault();
          }}
          allowKeyboardControl={false}
          autoOk
          inputVariant="outlined"
          style={
            { width: "200px" } //disabled={tp}
          }
          format="do MMM, yyyy"
          //  style={
          //   styles.withDivider50 //disabled={tp}
          // }
          value={to ? to : null}
          //views={['year', 'month']}
          InputAdornmentProps={{ position: "end" }}
          onChange={(newValue) => {
            // props.onChange([from,newValue])
            setTo(newValue);
          }}
        />
      </MuiPickersUtilsProvider>
      {(from || to) && (
        <StyledButton
          name="Get"
          onClick={() => {
            if (props.onChange) {
              props.onChange([from, to]);
            }
          }}
        />
      )}
      {props.onClear && (from || to) && (
        <StyledButton
          name="Clear"
          onClick={() => {
            if (props.onClear) {
              props.onClear([]);
              setTo();
              setFrom();
            }
          }}
        />
      )}
    </React.Fragment>
  );
}
