import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import { moduleURL } from "../../../http/config";
import { requests } from "../../../requests";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Backdrop } from "@material-ui/core";
import { StyledCircularProgress } from "../../../styles/StyledComponents";
import MenuItem from "@mui/material/MenuItem";
import { stateArray } from "../../../Defaults";
import { StopScroll } from "../../../Regex";
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "0 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
var companyId;
var out;
var surveyArr = [];

const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: "15px",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "8px 0",
  },
  textField: {
    width: "40%",

    // overflow: "hidden",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: "15px 0",
  },
  alignBtn: {
    display: "flex",
    justifyContent: "space-between",
  },
  formGroupHidden: {
    display: "hidden",
  },
};

const NewForm = ({ closePopup, getFn }) => {
  const [fieldValues, setFieldValues] = useState({
    nameInput: "",
    phoneInput: "",
    addressInput: "",
    gstinInput: "",
    contactPersonInput: "",
    cityInput: "",
    stateInput: "",
    pincodeInput: "",
    surveyorsInput: [""],
    statusInput: true,
  });

  const [isValid, setIsValid] = useState({
    nameInput: true,
    // phoneInput: true,
    addressInput: true,
    gstinInput: true,
    contactPersonInput: true,
    cityInput: true,
    stateInput: true,
    pincodeInput: true,
    surveyorsInput: [true],
  });
  const [error, setError] = useState("");
  const [pushNotier, setPushNotier] = useState(false);
  const [pushNoti, setPushNoti] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showSurveyor, setShowSurveyor] = useState(false);


  // const [showBtn, setShowBtn] = useState(true);

  const handleSurveyorInput = (e, index) => {
    const { value } = e.target;

    const list = [...fieldValues.surveyorsInput];
    list[index] = value ? value : "";
    setFieldValues((prev) => ({ ...prev, surveyorsInput: list }));
  };

  const handleDeleteSurveyor = (index) => {
    const list = [...fieldValues.surveyorsInput];
    const listAfterdel = list.filter((_, itemIndex) => itemIndex !== index);
    setFieldValues((prev) => ({ ...prev, surveyorsInput: listAfterdel }));
  };

  const addNewHandler = (e) => {
    e.preventDefault();

    if (fieldValues.nameInput.length === 0) {
      setIsValid((prev) => ({ ...prev, nameInput: false }));
    }
    if (fieldValues.cityInput.length === 0) {
      setIsValid((prev) => ({ ...prev, cityInput: false }));
    }
    if (fieldValues.gstinInput.length < 15) {
      setIsValid((prev) => ({ ...prev, gstinInput: false }));
    }
    if (fieldValues.addressInput.length === 0) {
      setIsValid((prev) => ({ ...prev, addressInput: false }));
    }
    if (fieldValues.stateInput.length === 0) {
      setIsValid((prev) => ({ ...prev, stateInput: false }));
    }
    // if (fieldValues.phoneInput.length < 10) {
    //   setIsValid((prev) => ({ ...prev, phoneInput: false }));
    // }
    if (fieldValues.contactPersonInput.length === 0) {
      setIsValid((prev) => ({ ...prev, contactPersonInput: false }));
    }
    if (fieldValues.pincodeInput.length < 6) {
      setIsValid((prev) => ({ ...prev, pincodeInput: false }));
    }

    if (
      fieldValues.nameInput.length > 0 &&
      fieldValues.cityInput.length > 0 &&
      fieldValues.gstinInput.length > 14 &&
      fieldValues.addressInput.length > 0 &&
      // fieldValues.phoneInput.length > 9 &&
      fieldValues.stateInput.length > 0 &&
      fieldValues.pincodeInput.length > 5 &&
      fieldValues.contactPersonInput.length > 0
      // && fieldValues.surveyorsInput.length > 0
    ) {
      // setShowSurveyor(true);

      const postURL = moduleURL("masters/survey-company/list");
      requests({
        method: "post",
        url: postURL,
        data: {
          name: fieldValues.nameInput,
          phone: fieldValues.phoneInput ? fieldValues.phoneInput : "",
          address: fieldValues.addressInput,
          gstin: fieldValues.gstinInput,
          contact_person: fieldValues.contactPersonInput,
          city: fieldValues.cityInput,
          state: fieldValues.stateInput,
          pincode: fieldValues.pincodeInput,
          is_active: fieldValues.statusInput,
          // surveyors: fieldValues.surveyorsInput,
          surveyors: [],
          // created_by: 0,
          // modified_by: 0,
        },
      }).then((response) => {
        setPushNotier(true);

        companyId = response.data.id;
        if (response.error) {
          
          // setError(response.data[0].message[0]);
          setError(
            typeof response.data === "string"
              ? response.data
              : response.data.length > 0
              ? response.data
                  .map((s) => {
                    return `${s.field ? `${s.field} :` : ""} ${
                      typeof s.message === "string"
                        ? s.message
                        : s.message.length > 0 && s.message[0] && s.message[0]
                    }`;
                  })
                  .join(" ,")
              : response.data.response
              ? response.data.response.data.msg.error
                ? response.data.response.data.msg.error
                : response.data.response.data.msg
              : "Error !"
          );
         
          // setPushNotier(true);
        } else {
          setPushNoti(true);
          setLoading(true);

          if (fieldValues.surveyorsInput.length === 0) {
            setIsValid((prev) => ({ ...prev, surveyorsInput: false }));
          }

          if (fieldValues.surveyorsInput.length > 0) {
            var array = fieldValues.surveyorsInput;
            array.map((user) => {
              out = { name: user, is_active: true, company: companyId };
              surveyArr.push(out);
            });
            const postURL = moduleURL("masters/surveyor/list");
            requests({
              method: "post",
              url: postURL,
              data: surveyArr,
            }).then((res) => {
              // setPushNoti(true);
              if (res.error) {
               
                // setError(res.data[0].message[0]);
                setError(
                  typeof res.data === "string"
                    ? res.data
                    : res.data.length > 0
                    ? res.data
                        .map((s) => {
                          return `${s.field ? `${s.field} :` : ""} ${
                            typeof s.message === "string"
                              ? s.message
                              : s.message.length > 0 &&
                                s.message[0] &&
                                s.message[0]
                          }`;
                        })
                        .join(" ,")
                    : res.data.response
                    ? res.data.response.data.msg.error
                      ? res.data.response.data.msg.error
                      : res.data.response.data.msg
                    : "Error !"
                );
               
                // setPushNotier(true);
              } else {
                getFn();
                // setError("");
                setPushNoti(true);
              }
            });
          }
          getFn();
          setError("");
          closePopup();
        }

        // setShowBtn(false);
        //  getFn();
      });
    }
  };

  const handleReset = () => {
    setFieldValues((prev) => ({ ...prev, nameInput: "" }));
    setFieldValues((prev) => ({ ...prev, addressInput: "" }));
    setFieldValues((prev) => ({ ...prev, cityInput: "" }));
    setFieldValues((prev) => ({ ...prev, gstinInput: "" }));
    setFieldValues((prev) => ({ ...prev, stateInput: "" }));
    setFieldValues((prev) => ({ ...prev, contactPersonInput: "" }));
    setFieldValues((prev) => ({ ...prev, pincodeInput: "" }));
    setFieldValues((prev) => ({ ...prev, countryInput: "" }));
    setFieldValues((prev) => ({ ...prev, phoneInput: "" }));
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={addNewHandler}>
        <div style={classes.formGroup}>
        <div
            style={{
              width: "40%",
             
              //// marginRight: "20px",
            }}>
        {labelText("Name")}
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
         //   label="Name *"
            value={fieldValues.nameInput}
            onKeyPress={(e) => {
              const re = /[a-z A-Z]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
           
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, nameInput: true }));
              }
              setFieldValues((prev) => ({ ...prev, nameInput: target.value }));

              if (
                isValid.nameInput === true &&
                // isValid.phoneInput === true &&
                isValid.addressInput === true &&
                isValid.gstinInput === true &&
                isValid.contactPersonInput === true &&
                isValid.cityInput === true &&
                isValid.stateInput === true &&
                isValid.pincodeInput === true
              ) {
                setShowSurveyor(true);
              }
            }}
            style={{width:"100%"}}
            error={!isValid.nameInput}
            helperText={isValid.nameInput ? "" : "Name should not be empty"}
          />
          </div>
          <div
            style={{
              width: "40%",
             
              //// marginRight: "20px",
            }}>
                     {labelText("Phone")}
          <TextField
            type="number"
            style={classes.textField}
            variant="outlined"
            onFocus={StopScroll}
            // label="Phone"
            inputProps={{
              maxLength: 10,
            }}
            style={{width:"100%"}}
            onKeyDown={(evt) => {
              evt.key === "." && evt.preventDefault();

              evt.key === "e" && evt.preventDefault();

              evt.key === "E" && evt.preventDefault();
              evt.key === "-" && evt.preventDefault();
              evt.keyCode === 38 && evt.preventDefault();
              evt.keyCode === 40 && evt.preventDefault();
            }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }}
            value={fieldValues.phoneInput}
            onChange={({ target }) => {
              if (fieldValues.phoneInput.length > 0) {
                // setIsValid((prev) => ({ ...prev, phoneInput: true }));
              }
              setFieldValues((prev) => ({ ...prev, phoneInput: target.value }));

              if (
                isValid.nameInput === true &&
                // isValid.phoneInput === true &&
                isValid.addressInput === true &&
                isValid.gstinInput === true &&
                isValid.contactPersonInput === true &&
                isValid.cityInput === true &&
                isValid.stateInput === true &&
                isValid.pincodeInput === true
              ) {
                setShowSurveyor(true);
              }
            }}
            // error={!isValid.phoneInput}
            // helperText={
            //   isValid.phoneInput ? "" : "Phone should be a 10 digit number"
            // }
          />
          </div>
        </div>
        <div style={classes.formGroup}>
        <div
            style={{
              width: "40%",
             
              //// marginRight: "20px",
            }}>
                     {labelText("Address")}
          <TextField
            type="text"
            multiline
            maxRows={3}
            style={classes.textField}
            variant="outlined"
            // label="Address *"
            style={{width:"100%"}}
            value={fieldValues.addressInput}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, addressInput: true }));
              }
              setFieldValues((prev) => ({
                ...prev,
                addressInput: target.value,
              }));
              if (
                isValid.nameInput === true &&
                // isValid.phoneInput === true &&
                isValid.addressInput === true &&
                isValid.gstinInput === true &&
                isValid.contactPersonInput === true &&
                isValid.cityInput === true &&
                isValid.stateInput === true &&
                isValid.pincodeInput === true
              ) {
                setShowSurveyor(true);
              }
            }}
            error={!isValid.addressInput}
            helperText={
              isValid.addressInput ? "" : "Address should not be empty"
            }
          />
          </div>
          <div
            style={{
              width: "40%",
             
              //// marginRight: "20px",
            }}>
                     {labelText("GSTIN")}
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
            // label="GSTIN *"
            style={{width:"100%"}}
            onKeyPress={(e) => {
              const re = /[0-9a-zA-Z]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
            value={fieldValues.gstinInput}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 15);
            }}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, gstinInput: true }));
              }
              setFieldValues((prev) => ({
                ...prev,
                gstinInput: target.value,
              }));
              if (
                isValid.nameInput === true &&
                // isValid.phoneInput === true &&
                isValid.addressInput === true &&
                isValid.gstinInput === true &&
                isValid.contactPersonInput === true &&
                isValid.cityInput === true &&
                isValid.stateInput === true &&
                isValid.pincodeInput === true
              ) {
                setShowSurveyor(true);
              }
            }}
            error={!isValid.gstinInput}
            helperText={
              isValid.gstinInput
                ? <span style={{color:"#3030dffa"}}>ex- 22AAAAA0000A1Z5</span>
                : "GSTIN should have 15 characters"
            }
          />
          </div>
        </div>
        <div style={classes.formGroup}>
        <div
            style={{
              width: "40%",
             
              //// marginRight: "20px",
            }}>
                     {labelText("Contact Person")}
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
            // label="Contact Person *"
            style={{width:"100%"}}
            value={fieldValues.contactPersonInput}
            onKeyPress={(e) => {
              const re = /[a-z A-Z]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, contactPersonInput: true }));
              }
              setFieldValues((prev) => ({
                ...prev,
                contactPersonInput: target.value,
              }));
              if (
                isValid.nameInput === true &&
                // isValid.phoneInput === true &&
                isValid.addressInput === true &&
                isValid.gstinInput === true &&
                isValid.contactPersonInput === true &&
                isValid.cityInput === true &&
                isValid.stateInput === true &&
                isValid.pincodeInput === true
              ) {
                setShowSurveyor(true);
              }
            }}
            error={!isValid.contactPersonInput}
            helperText={
              isValid.contactPersonInput ? "" : "Enter Contact Person"
            }
          />
          </div> <div
            style={{
              width: "40%",
             
              //// marginRight: "20px",
            }}>
                     {labelText("City")}
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
            // label="City *"
            value={fieldValues.cityInput}
            style={{width:"100%"}}
            onKeyPress={(e) => {
              const re = /[a-z A-Z]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, cityInput: true }));
              }
              setFieldValues((prev) => ({ ...prev, cityInput: target.value }));
              if (
                isValid.nameInput === true &&
                // isValid.phoneInput === true &&
                isValid.addressInput === true &&
                isValid.gstinInput === true &&
                isValid.contactPersonInput === true &&
                isValid.cityInput === true &&
                isValid.stateInput === true &&
                isValid.pincodeInput === true
              ) {
                setShowSurveyor(true);
              }
            }}
            error={!isValid.cityInput}
            helperText={isValid.cityInput ? "" : "City should not be empty"}
          />
          </div>
        </div>
        <div style={classes.formGroup}>
        <div
            style={{
              width: "40%",
             
              //// marginRight: "20px",
            }}>
                     {labelText("State")}
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
            select
            // label="State *"
            value={fieldValues.stateInput}
            style={{width:"100%"}}
            onKeyPress={(e) => {
              const re = /[a-z A-Z]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, stateInput: true }));
              }
              setFieldValues((prev) => ({ ...prev, stateInput: target.value }));
              if (
                isValid.nameInput === true &&
                // isValid.phoneInput === true &&
                isValid.addressInput === true &&
                isValid.gstinInput === true &&
                isValid.contactPersonInput === true &&
                isValid.cityInput === true &&
                isValid.stateInput === true &&
                isValid.pincodeInput === true
              ) {
                setShowSurveyor(true);
              }
            }}
            error={!isValid.stateInput}
            helperText={isValid.stateInput ? "" : "Please select State"}
          >
            {stateArray.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          </div> <div
            style={{
              width: "40%",
             
              //// marginRight: "20px",
            }}>
                     {labelText("Pincode")}
          <TextField
            type="number"
            style={classes.textField}
            variant="outlined"
            onFocus={StopScroll}
            // label="Pincode *"
            value={fieldValues.pincodeInput}
            style={{width:"100%"}}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 6);
            }}
            onKeyDown={(evt) => {
              evt.key === "." && evt.preventDefault();

              evt.key === "e" && evt.preventDefault();

              evt.key === "E" && evt.preventDefault();
              evt.key === "-" && evt.preventDefault();
              evt.keyCode === 38 && evt.preventDefault();
              evt.keyCode === 40 && evt.preventDefault();
            }}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, pincodeInput: true }));
              }
              setFieldValues((prev) => ({
                ...prev,
                pincodeInput: target.value,
              }));
              if (
                isValid.nameInput === true &&
                // isValid.phoneInput === true &&
                isValid.addressInput === true &&
                isValid.gstinInput === true &&
                isValid.contactPersonInput === true &&
                isValid.cityInput === true &&
                isValid.stateInput === true &&
                isValid.pincodeInput === true
              ) {
                setShowSurveyor(true);
              }
            }}
            error={!isValid.pincodeInput}
            helperText={
              isValid.pincodeInput ? "" : "Pincode should be a 6 digit number"
            }
          />
          </div>
        </div>
        {/* {showBtn && (
          <Button
            variant="contained"
            color="primary"
            style={{ width: "40%", margin: "10px 0" }}
            // onClick={() =>
            //   setFieldValues((prev) => ({
            //     ...prev,
            //     surveyorsInput: [...prev.surveyorsInput, ""],
            //   }))
            // }
            // onClick={() => setShowSurveyor(true)}
            onClick={handleAddCompany}
          >
            Add Survey Company
          </Button>
        )} */}
        <div
        // style={{ display: showSurveyor ? "flex" : "none" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "50%",
            }}
          >
            <p style={{fontSize:"1rem"}}>Add Multiple Surveyors:</p>
            {fieldValues.surveyorsInput.map((item, index) => (
              <div
                style={{
                  width: "200%",
                  dispaly: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <div
            style={{
              width: "40%",
             
              //// marginRight: "20px",
            }}>
                     {labelText("Surveyors",true)}
                <TextField
                  disabled={!showSurveyor}
                  type="text"
                  // style={{width:"100%"}}
                  // style={{ ...classes.textField, marginBottom: "15px" }}
                  variant="outlined"
                  value={item ? item : ""}
                  onKeyPress={(e) => {
                    const re = /[a-z A-Z]+/g;
                    if (!re.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    handleSurveyorInput(e, index);
                    if (fieldValues.surveyorsInput.length > 0) {
                      setIsValid((prev) => ({ ...prev, surveyorsInput: true }));
                    }
                  }}
                  error={!isValid.surveyorsInput}
                  helperText={isValid.surveyorsInput ? "" : "Enter Surveyor"}
                />
                </div>
                <DeleteIcon
                  color="error"
                  style={{
                    marginLeft: "10px",
                   marginTop:"10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    showSurveyor && handleDeleteSurveyor(index);
                  }}
                />
              </div>
            ))}
            <Button
              variant="outlined"
              color="primary"
              style={{
                // borderRadius: "25px",
                // marginLeft: "10px",
                width: "200px",
                // height: "30px",
                // marginBottom: "10px",
              }}
              onClick={() => {
                showSurveyor &&
                  setFieldValues((prev) => ({
                    ...prev,
                    surveyorsInput: [...prev.surveyorsInput, ""],
                  }));
              }}
            >
              Add Surveyor
            </Button>

            <div style={classes.alignBtn}>
              {/* <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(241 154 48)",
                  color: "white",
                  marginLeft: "20px",
                }}
                // color="success"

                // onClick={() =>
                //   setFieldValues((prev) => ({
                //     ...prev,
                //     surveyorsInput: [...prev.surveyorsInput, ""],
                //   }))
                // }
              >
                Save
              </Button> */}
            </div>
          </div>
          <div style={classes.formActions}>
            <FormControlLabel
              control={
                <Switch
                  disabled
                  style={{ color: "#B0B0B0" }}
                  defaultChecked
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValues((prev) => ({
                        ...prev,
                        statusInput: true,
                      }));
                    } else {
                      setFieldValues((prev) => ({
                        ...prev,
                        statusInput: false,
                      }));
                    }
                  }}
                />
              }
              label={fieldValues.statusInput ? "Active" : "Inactive"}
            />
            <div>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  marginRight: "20px",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={handleReset}
              >
                reset
              </Button>
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "rgb(241 154 48)",
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      </form>
      {loading ? (
        <Backdrop
          style={{ zIndex: 1, color: "#fff", position: "absolute" }}
          open={loading}
        >
          <StyledCircularProgress color="rgb(30, 53, 89)" />
        </Backdrop>
      ) : (
        ""
      )}
      {/* <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setPushNoti(false);
          setLoading(false);

          // closePopup();
        }}
      >
        <Alert severity="success" style={{ backgroundColor: "rgb(241 154 48)" }}>
          New Survey company added!
        </Alert>
         message="Changes have been saved!" 
        
      </Snackbar> */}
      <Snackbar
        open={pushNotier}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity={error ? "error" : "success"}
        autoHideDuration={error ? 5000 : 2000}
        onClose={() => {
          // setOpenPopup(false);
          setPushNotier(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}
      >
        <Alert
          severity={error ? "error" : "success"}
          style={{ backgroundColor: error ? "" : "rgb(241 154 48)" }}
        >
          {error ? error : "New Survey company added!"}
        </Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
    </div>
  );
};

export default NewForm;
