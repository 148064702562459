import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { LoginFunction, verifyOtp } from "../../redux";
import { primary } from "../../styles/colors";
import "../../styles/styles.css";
import { useNavigate } from "react-router-dom";
import {
  StyledCircularProgress,
  StyledSnackbar,
} from "../../styles/StyledComponents";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Backdrop } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {
  withStyles,
  // Button,
} from "@material-ui/core";
const theme = createTheme({
  palette: {
    primary: {
      light: primary,
      main: primary,
      dark: primary,
    },
    secondary: {
      light: primary,
      main: primary,
      dark: primary,
    },
  },
  status: {
    danger: "orange",
  },
});
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "0 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
export const CssTextField = withStyles({
  root: {
    "&.MuiFormControl-marginNormal": { marginTop: "0px" },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    boxShadow: "0px 0px 28px 0px rgba(0, 0, 0, 0.15)",
    borderRadius: "5px",
    background: "white",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
  },
}));

function LoginComponent(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [username, setusername] = useState(false);
  const [password, setPassword] = useState(false);
  // const [enterUser, setenterUser] = useState(true);
  const otp = "1234";
  const [error, setError] = useState(false);
  const [sendToDashboard, setSendToDashboard] = useState(false);
  const [visible, setVisible] = useState(false);
  const [errorusername, seterrorusername] = useState(false);

  const [errorPass, seterrorPass] = useState(false);

  const [loader, setLoader] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setVisible(!visible);
  };
  useEffect(() => {
    if (props.user && !sendToDashboard) {
      setSendToDashboard(true);
    }
  }, [props.user]);
  const submitData = () => {
    if (username && password) {
      setLoader(true);
      const data = { email: username, password: password };
      props.LoginFunction(data).then((response) => {
        if (response && response.error) {
          setLoader(false);
          setError(
            typeof response.data === "string"
              ? response.data
              : response.data.length > 0
              ? response.data
                  .map((s) => {
                    return `${s.field ? `${s.field} :` : ""} ${
                      typeof s.message === "string"
                        ? s.message
                        : s.message.length > 0 && s.message[0] && s.message[0]
                    }`;
                  })
                  .join(" ,")
              : response.data.response
              ? response.data.response.data.msg
              : "Error !"
          );
        } else {
          submitOtp();
          setError(false);
        }
      });
    } else {
      if (!username) {
        seterrorusername("please enter user name!");
      } else if (!password) {
        seterrorPass("please enter your password !");
      }
    }
  };
  const submitOtp = () => {
    if (otp) {
      const toSend = { email: username, otp: otp };

      props.verifyOtp(toSend, async () => {
        await navigate("/", { replace: true });
        await setLoader(false);
      });
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <div className="mainDivForLogin">
        {" "}
        {error && (
          <StyledSnackbar
            error={error ? error : false}
            message={error}
            handleCloseSnackbar={() => {
              setError(false);
            }}
          />
        )}
        {loader === true ? (
          <Backdrop style={{ zIndex: 1, color: "#fff" }} open={loader}>
            <StyledCircularProgress />
          </Backdrop>
        ) : (
          ""
        )}{" "}
        <img
          alt=""
          src="BackgroundBRI.jpg"
          style={{
            height: "100%",
            width: "100%",
            // margin: "-30px 0 -10px 0",
            overflow: "hidden",
          }}
        />{" "}
        <Container
          component="main"
          maxWidth="xs"
          style={{ position: "absolute" }}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              {/* <LockOutlinedIcon /> */}
              <img
                alt=""
                src="BriIcon.jpg"
                style={{
                  // height: "calc(100% + 40px)",
                  width: "100%",
                  // margin: "-30px 0 -10px 0",
                  overflow: "hidden",
                }}
              />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login to GrainByte
            </Typography>
            <form className={classes.form}>
              <div
                style={{
                  width: "100%",
                  margin: "10px 0px",
                }}>
                {labelText("Email")}
                <CssTextField
                  error={errorusername ? true : false}
                  helperText={errorusername ? errorusername : false}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      submitData();
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  // label="Email"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={username ? username : ""}
                  onChange={(event) => {
                    seterrorusername(false);
                    setusername(event.target.value);
                  }}
                />
              </div>{" "}
              <div
                style={{
                  width: "100%",
                  margin: "10px 0px",
                }}>
                {labelText("Password")}
                <CssTextField
                  value={password ? password : ""}
                  onChange={(event) => {
                    seterrorPass(false);
                    setPassword(event.target.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      submitData();
                      ev.preventDefault();
                    }
                  }}
                  style={{ background: "rgb(68 133 125 / 11%)" }}
                  error={errorPass ? true : false}
                  helperText={errorPass ? errorPass : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          edge="end">
                          {visible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  // label="Password"
                  type={visible ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                />
              </div>
              <Button
                onClick={(e) => {
                  submitData();
                }}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}>
                Sign In
              </Button>
            </form>
          </div>
        </Container>
      </div>
    </MuiThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication,
});
const mapDispatchToProps = {
  LoginFunction,
  verifyOtp,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
