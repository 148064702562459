import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RatesComponent from "./RatesComponent";
import { RatesFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";
function RatesContainer(props) {
  const [masters, setMasters] = useState({});
  const [loader, setLoader] = useState(true);

  const fetchAllData = () => {
   
    props
      .RatesFunction(
        "get",
        "masters/item-category/list",
        null,
        //state.singlelistView.id,
        "ItemCateoryList",
        null,
        { is_active: "True" }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          const newMasters = masters;
          newMasters.ItemCateoryList = ItemCateoryList;
          setMasters(newMasters);
        }
        props
          .RatesFunction(
            "get",
            "masters/item-type/list",
            null,
            //state.singlelistView.id,
            "ItemList",
            null,
            { is_active: "True" }
          )
          .then((ItemList) => {
            if (ItemList) {
              const newMasters = masters;
              newMasters.ItemList = ItemList;
              setMasters(newMasters);
            }
            
            
            setLoader(false);
             props
              .RatesFunction(
                "get",
                "masters/contract-type/list",
                null,
                //state.singlelistView.id,
                "ContracrTypeList",
                null,
                { is_active: "True" }
              )
              .then((ContracrTypeList) => {
           
                if (ContracrTypeList) {
                  const newMasters = masters;
                  newMasters.ContracrTypeList = ContracrTypeList;
                  setMasters(newMasters);
                }

                setLoader(false);
              });
      //      props
          // .RatesFunction(
          //   "get",
          //   "masters/contract-type/list",
          //   null,
          //   //state.singlelistView.id,
          //   "ContractTypeList",
          //   null,
          //   { is_active: "True" }
          // )
          // .then((ContractTypeList) => {
          //   if (ContractTypeList) {
          //     const newMasters = masters;
          //     newMasters.ContractTypeList = ContractTypeList;
          //     setMasters(newMasters);
          //   },

            // props
            //   .RatesFunction(
            //     "get",
            //     "dispatch/purchase-rate/list",
            //     null,
            //     //state.singlelistView.id,
            //     "PurchaseRate",
            //     null,
            //     { is_active: "True" }
            //   )
            //   .then((PurchaseRate) => {
           
            //     if (PurchaseRate) {
            //       const newMasters = masters;
            //       newMasters.PurchaseRate = PurchaseRate;
            //       setMasters(newMasters);
            //     }

            //     setLoader(false);
            //   });
          });
      });
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const { tab, id } = useParams();
  const params = { tab: tab, id: id };

  return (
    <RatesComponent
      {...props}
      masters={masters}
      params={params}
      loader={loader}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  loading: state.modules.loading,
  Rates: state.modules.Rates,
  [EndPoints["Rates"]]: state.modules[EndPoints["Rates"]],
});
const mapDispatchToProps = {
  RatesFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RatesContainer);
