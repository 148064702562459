// import React, { Component } from "react";
// import Layout from "../../layouts/Layout";
// import TabComponent from "../../layoutComponents/TabComponent";
// import { StyledCircularProgress } from "../../styles/StyledComponents";
// import TableComponent from "../../layoutComponents/TableComponent";
// import CreateDialog from "../../layoutComponents/CreateDialog";
// import NoData from "../../styles/Illustrations/NoData";
// import EditIcon from "@mui/icons-material/Edit";
// import moment from "moment";
// import Tooltip from "@material-ui/core/Tooltip";
// import { EndPoints } from "../../utils/EndPoints";
// import BasicDateRangePicker from "../../layoutComponents/BasicDateRangePicker";
// import { primary } from "../../styles/colors";
// import { CrossIcon } from "../../styles/Icons";
// import {
//   Paper,
//   Dialog,
//   Slide,
//   DialogActions,
//   IconButton,
//   DialogContent,
//   Button,
// } from "@material-ui/core";
// import ReactDOM from "react-dom";
// import { CssTextField, StyledButton } from "../../styles/StyledComponents";
// import { filterData, getSingleModule_widget ,StopScroll} from "../../Regex";
// import { accessText } from "../../routes/sidebar";
// import InvoiceCardUi from "../../layoutComponents/InvoiceCardUi";
// import ShowPackaging from "../../layoutComponents/ShowPackaging";
// import { stateArray } from "../../Defaults";
// import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
// const styles = {
//   heading: {
//     margin: 0,
//     color: "white",
//     width: "calc(100% - 32.81px)",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     textAlign: "left",
//     display: "flex",
//   },
//   paperStyle: {
//     borderRadius: "7px",
//     display: "flex",
//     width: "300px",
//     height: "38px",
//     fontSize: "0.5rem",
//     backgroundColor: "rgba(158, 158, 158, 0.18)",
//     color: "white",
//   },
// };
// export class BrokersComponent extends Component {
//   constructor(props) {
//     const templateTabs = [
//       {
//         label: EndPoints["Brokers"],
//         tooltip: `Click to approve pending ${EndPoints["Brokers"]}`,
//         data: [],
//         route: `/Brokers/${EndPoints["Brokers"].split("/")[0]}`,
//         widget: null,
//         goBackRoute: "/Brokers",
//         primaryField: "name",
//         secondaryField: "is_active",
//         primaryFieldThirdFallback: "total_net_weight_pending_trips",
//         avatarField: "src",
//         status: false,
//         decorators: {
//           conditionField: "status",
//           options: ["pending", "approved", "unidentified"],
//           colors: ["yellow", "#0AFF00", "#FF3D00"],
//         },
//       },
//     ];
//     super(props);
//     this.state = {
//       tabs: templateTabs,
//       summaryData: [],
//       Brokers: [],
//       singlelistView: null,
//       listViewArray: [],
//       loading: false,
//       openDialog: false,

//       selectedRange: [null, null],

//       selectedRange1: [null, null],
//       dispatchList: [],
//       dispatchListCopy: [],
//       invoiceListArr: [],
//       invoiceListArrCopy: [],
//       openStatusDialog: false,
//       broken_amount: "",
//       loader: false,
//       showInvoice: false,

//       downloadInvoicePdf: false,
//       selctedTab: "unpaid",
//       userRole:
//         this.props.user && this.props.user.roles
//           ? this.props.user.roles.toString().toLowerCase()
//           : "",
//     };
//   }
//   filterDataAccDate = (dispatchList) => {
//     const val = this.state.selectedRange;
//     const oldArray =
//       dispatchList && dispatchList.length > 0 ? dispatchList : [];

//     const dateFrom =
//       val && val[0]
//         ? moment(val[0]).format("MMM D YYYY")
//         : moment(new Date()).format("MMM D YYYY");

//     const dateTo =
//       val && val[1]
//         ? moment(val[1]).format("MMM D YYYY")
//         : moment(new Date()).format("MMM D YYYY");
//     const newArr = oldArray.filter((singleObj) => {
//       const createdAt = moment(singleObj.created_at).format("MMM D YYYY");

//       return (
//         createdAt === dateFrom ||
//         createdAt === dateTo ||
//         (new Date(createdAt) > new Date(dateFrom) &&
//           new Date(dateTo) > new Date(createdAt))
//       );
//     });
//     return newArr ? newArr : [];
//   };
//   UNSAFE_componentWillReceiveProps(next) {
//     this.setInitialValues(next);
//   }
//   UNSAFE_componentWillMount() {
//     this.fetchAllData();
//     const next = this.props;
//     if (next.params && next.params.tab) {
//       let tab = next.params.tab;
//       if (tab === EndPoints["Brokers"] && next.params.id) {
//         this.listClickHandler(this.state.tabs[0].data[next.params.id]);
//       } else if (tab === "NEW") {
//         this.setState({ singlelistView: null });
//       }
//     } else {
//       this.setState({ singlelistView: null });
//     }
//     this.setInitialValues(this.props);
//   }

//   listClickHandler = (value) => {
//     this.singlelistView && this.fetchDispatchList("UNPAID");
//     const { selctedTab } = this.state;
//     // this.fetchAllData();
//     this.setState(
//       {
//         singlelistView: value,
//       },

//       () => {
//         if (this.state.singlelistView && this.state.singlelistView.id) {
//           if (selctedTab === "unpaid") {
//             this.fetchDispatchList("UNPAID");
//           }
//           if (selctedTab === "pending") {
//             this.fetchDispatchList("PENDING");
//           }
//           if (selctedTab === "paid") {
//             this.fetchDispatchList("paid");
//           }
//         }
//       }
//     );
//   };

//   onEditUpdate = (res) => {
//     if (res) {
//       this.setState({ singlelistView: res });
//     }
//   };
//   setInitialValues(props) {
//     const { widget_permission } = getSingleModule_widget(
//       props.user,
//       accessText.vendors,
//       accessText.brokers
//     );
//     const tabs = this.state.tabs.map((e, i) => {
//       const tab = e;
//       // has_read_permission:true,has_create_permission:true
//       tab.widget = widget_permission?.widget
//         ? { ...widget_permission?.widget }
//         : null;
//       tab.data =
//         props[EndPoints["Brokers"]] && props[EndPoints["Brokers"]].length > 0
//           ? props[EndPoints["Brokers"]]
//               //  .sort((a, b) => a.name.localeCompare(b.name))
//               .sort((a, b) => {
//                 var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
//                 var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
//                 return new Date(dateA) - new Date(dateB);
//               })
//           : [];
//       return tab;
//     });

//     this.setState({
//       userRole: this.props.user.roles
//         ? this.props.user.roles.toString().toLowerCase()
//         : "admin",
//       listViewArray:
//         props[EndPoints["Brokers"]] && props[EndPoints["Brokers"]].length > 0
//           ? props[EndPoints["Brokers"]]
//           : [],
//       Brokers: props.Brokers,
//       tabs,
//       loading: props.loading,
//     });
//   }

//   fetchAllData = () => {
//     this.setState({ loader: true });
//     this.props
//       .BrokersFunction(
//         "get",
//         "masters/item-category/list",
//         null,
//         "ItemCateoryList",
//         null,
//         {
//           is_active: "True",
//         }
//       )
//       .then((ItemCateoryList) => {
//         if (ItemCateoryList) {
//           this.setState({ ItemCateoryList }, () => {
//             this.props
//               .BrokersFunction(
//                 "get",
//                 "masters/item-type/list",
//                 null,
//                 "ItemList",
//                 null,
//                 {
//                   is_active: "True",
//                 }
//               )
//               .then((ItemList) => {
//                 if (ItemList) {
//                   this.setState({ ItemList }, () => {
//                     // this.props
//                     //   .BrokersFunction(
//                     //     "get",
//                     //     "masters/brand/list",
//                     //     null,
//                     //     "BrandList",
//                     //     null,
//                     //     {
//                     //       is_active: "True",
//                     //     }
//                     //   )
//                     //   .then((BrandList) => {
//                     //     if (BrandList) {
//                     //       this.setState({
//                     //         loader: false,
//                     //         BrandList: BrandList.map((sBrand) => {
//                     //           if (
//                     //             this.state.ItemList!==undefined&&this.state.ItemList&&this.state.ItemList.length > 0 && this.state.ItemCateoryList!==undefined&&
//                     //             this.state.ItemCateoryList&&this.state.ItemCateoryList.length > 0
//                     //           ) {
//                     //             sBrand.item_category_name =
//                     //               this.state.ItemCateoryList.filter(
//                     //                 (singleItem) =>
//                     //                   singleItem.id === sBrand.item_category
//                     //               )
//                     //                 ? this.stateitem_category.ItemCateoryList.filter(
//                     //                     (singleItem) =>
//                     //                       singleItem.id === sBrand.item_category
//                     //                   )[0].name
//                     //                 : "";
//                     //             sBrand.item_type_name =
//                     //               this.state.ItemList.filter(
//                     //                 (singleItem) =>
//                     //                   singleItem.id === sBrand.item_type
//                     //               )
//                     //                 ? this.state.ItemList.filter(
//                     //                     (singleItem) =>
//                     //                       singleItem.id === sBrand.item_type
//                     //                   )[0].broken_percentage
//                     //                 : "";

//                     //             return sBrand;
//                     //           } else {
//                     //             return sBrand;
//                     //           }
//                     //         }),
//                     //       });
//                     //     } else {
//                           this.setState({ loader: false });
//                       //   }
//                       // });
//                   });
//                 } else {
//                   this.setState({ loader: false });
//                 }
//               });
//           });
//         } else {
//           this.setState({ loader: false });
//         }
//       });
//   };
//   filterDataAccDate1 = (invoiceList) => {
//     const { selectedRange1 } = this.state;
//     const oldArray = invoiceList && invoiceList.length > 0 ? invoiceList : [];

//     const dateFrom =
//       selectedRange1 && selectedRange1[0]
//         ? moment(selectedRange1[0]).format("MMM D YYYY")
//         : moment(new Date()).format("MMM D YYYY");

//     const dateTo =
//       selectedRange1 && selectedRange1[1]
//         ? moment(selectedRange1[1]).format("MMM D YYYY")
//         : moment(new Date()).format("MMM D YYYY");
//     const newArr = oldArray.filter((singleObj) => {
//       const createdAt = moment(singleObj.invoice_date).format("MMM D YYYY");

//       return (
//         createdAt === dateFrom ||
//         createdAt === dateTo ||
//         (new Date(createdAt) > new Date(dateFrom) &&
//           new Date(dateTo) > new Date(createdAt))
//       );
//     });
//     return newArr ? newArr : [];
//   };
//   fetchDispatchList = (type) => {
//     this.setState({ loader: true });
//     if (type === "paid") {
//       this.props
//         .BrokersFunction(
//           "get",
//           "dispatch/invoice/list",
//           null,
//           "invoice",
//           null,
//           {
//             broker_id: this.state.singlelistView.id,
//           }
//         )
//         .then((invoiceArr) => {
//           this.setState({
//             invoiceListArr:
//               invoiceArr && invoiceArr.length > 0
//                 ? this.state.selectedRange1 &&
//                   this.state.selectedRange1.length > 0 &&
//                   ((this.state.selectedRange1[0] &&
//                     this.state.selectedRange1[0] !== null) ||
//                     (this.state.selectedRange1[1] &&
//                       this.state.selectedRange1[1] !== null))
//                   ? this.filterDataAccDate1(invoiceArr)
//                   : invoiceArr
//                 : [],
//             invoiceListArrCopy: invoiceArr,
//             loader: false,
//           });
//         });
//     } else {
//       this.props
//         .BrokersFunction("get", "dispatch/list", null, "dispatchList", null, {
//           broker_id: this.state.singlelistView.id,
//           payment_status: type,
//         })
//         .then((dispatchList) => {

//           const newArrId = [];
//           const newArr = [];
//           if (dispatchList && dispatchList.length > 0) {
//             dispatchList.map((sR) => {
//               if (sR.id) {
//                 this.props
//                   .BrokersFunction(
//                     "get",
//                     "dispatch/dispatch-items/list",
//                     null,
//                     "dispatch-item",
//                     null,
//                     {
//                       dispatch_id: sR.id,
//                     }
//                   )
//                   .then((res2) => {
//                     if (res2) {
//                       sR.subList = res2;
//                       if (!newArrId.includes(sR.id)) {
//                         newArr.push(sR);
//                         newArrId.push(sR.id);
//                       }

//                       if (newArr.length === dispatchList.length) {

//                         newArr.map((sN) => {
//                           sN.broker = sN.purchase_order_details.broker;
//                           return sN;
//                         });

//                         this.setState({
//                           dispatchList: newArr,
//                           dispatchListCopy: newArr,
//                           // dispatchList: filterData(
//                           //   newArr,
//                           //   "broker",
//                           //   this.state.singlelistView.id
//                           // )
//                           //   ? filterData(
//                           //       filterData(
//                           //         newArr,
//                           //         "broker",
//                           //         this.state.singlelistView.id
//                           //       ),
//                           //       `${payment_field}`,
//                           //       type === "paid" ? true : false
//                           //     )
//                           //     ? this.state.selectedRange &&
//                           //       this.state.selectedRange.length > 0 &&
//                           //       ((this.state.selectedRange[0] &&
//                           //         this.state.selectedRange[0] !== null) ||
//                           //         (this.state.selectedRange[1] &&
//                           //           this.state.selectedRange[1] !== null))
//                           //       ? this.filterDataAccDate(
//                           //           filterData(
//                           //             filterData(
//                           //               newArr,
//                           //               "broker",
//                           //               this.state.singlelistView.id
//                           //             ),
//                           //             `${payment_field}`,
//                           //             type === "paid" ? true : false
//                           //           )
//                           //         )
//                           //       : filterData(
//                           //           filterData(
//                           //             newArr,
//                           //             "broker",
//                           //             this.state.singlelistView.id
//                           //           ),
//                           //           `${payment_field}`,
//                           //           type === "paid" ? true : false
//                           //         )
//                           //     : []
//                           //   : [],

//                           // dispatchListCopy: filterData(
//                           //   newArr,

//                           //   "broker",
//                           //   this.state.singlelistView.id
//                           // )
//                           //   ? filterData(
//                           //       filterData(
//                           //         newArr,
//                           //         "broker",
//                           //         this.state.singlelistView.id
//                           //       ),
//                           //       `${payment_field}`,
//                           //       type === "paid" ? true : false
//                           //     )
//                           //     ? filterData(
//                           //         filterData(
//                           //           newArr,
//                           //           "broker",
//                           //           this.state.singlelistView.id
//                           //         ),
//                           //         `${payment_field}`,
//                           //         type === "UNPAID" ? "UNPAID" : false
//                           //       )
//                           //     : []
//                           //   : [],

//                           loader: false,
//                         });

//                         return sR;
//                       }
//                     } else {

//                       dispatchList.map((sN) => {
//                         sN.broker = sN.purchase_order_details.broker;
//                         return sN;
//                       });
//                       this.setState({
//                         dispatchListCopy: dispatchList,

//                         // dispatchList: filterData(
//                         //   dispatchList,
//                         //   "broker",
//                         //   this.state.singlelistView.id
//                         // )
//                         //   ? filterData(
//                         //       filterData(
//                         //         dispatchList,
//                         //         "broker",
//                         //         this.state.singlelistView.id
//                         //       ),
//                         //       `${payment_field}`,
//                         //       type === "paid" ? true : false
//                         //     )
//                         //     ? this.state.selectedRange &&
//                         //       this.state.selectedRange.length > 0 &&
//                         //       ((this.state.selectedRange[0] &&
//                         //         this.state.selectedRange[0] !== null) ||
//                         //         (this.state.selectedRange[1] &&
//                         //           this.state.selectedRange[1] !== null))
//                         //       ? this.filterDataAccDate(
//                         //           filterData(
//                         //             filterData(
//                         //               newArr,
//                         //               "broker",
//                         //               this.state.singlelistView.id
//                         //             ),
//                         //             `${payment_field}`,
//                         //             type === "paid" ? true : false
//                         //           )
//                         //         )
//                         //       : filterData(
//                         //           filterData(
//                         //             dispatchList,
//                         //             "broker",
//                         //             this.state.singlelistView.id
//                         //           ),
//                         //           `${payment_field}`,
//                         //           type === "paid" ? true : false
//                         //         )
//                         //     : []
//                         //   : [],
//                         // dispatchListCopy: filterData(
//                         //   dispatchList,
//                         //   "broker",
//                         //   this.state.singlelistView.id
//                         // )
//                         //   ? filterData(
//                         //       filterData(
//                         //         dispatchList,
//                         //         "broker",
//                         //         this.state.singlelistView.id
//                         //       ),
//                         //       `${payment_field}`,
//                         //       type === "paid" ? true : false
//                         //     )
//                         //     ? filterData(
//                         //         filterData(
//                         //           dispatchList,
//                         //           "broker",
//                         //           this.state.singlelistView.id
//                         //         ),
//                         //         `${payment_field}`,
//                         //         type === "paid" ? true : false
//                         //       )
//                         //     : []
//                         //   : [],

//                         loader: false,
//                       });
//                     }
//                   });
//               }
//             });
//           } else {
//             this.setState({
//               dispatchList: [],
//               dispatchListCopy: [],
//               loader: false,
//             });
//           }
//         });
//     }
//   };
//   render() {
//     const {
//       tabs,
//       listViewArray,
//       singlelistView,
//       loading,
//       openDialog,
//       editList,
//       dispatchList,
//       selectedRange,
//       selectedRange1,
//       dispatchListCopy,
//       openStatusDialog,
//       loader,
//       showInvoice,
//       BrandList,
//       ItemCateoryList,
//       ItemList,
//       invoiceListArr,
//       broken_amount,
//       invoiceListArrCopy,
//       downloadInvoicePdf,
//       userRole,
//       selectedTab,

//     } = this.state;
//     const { user } = this.props;

//     const { widget_permission } = getSingleModule_widget(
//       user,
//       accessText.vendors,
//       accessText.brokers
//     );

//     var tabb = "";
//     console.log(userRole,"userRole")
//     const payment_field=`broker_payment_by_${userRole==="miller"?"miller":"bri"}`
//     const filterChild = (type) => {
//       return (
//         <BasicDateRangePicker
//           selectedRange={selectedRange}
//           onClear={() => {
//             this.setState({
//               dispatchList:
//                 dispatchListCopy.length > 0
//                   ? filterData(
//                       filterData(
//                         dispatchListCopy,
//                         "broker",
//                         this.state.singlelistView.id
//                       ),
//                       `${payment_field}`,
//                       false
//                     )
//                     ? filterData(
//                         filterData(
//                           dispatchListCopy,
//                           "broker",
//                           this.state.singlelistView.id
//                         ),
//                         `${payment_field}`,
//                         false
//                       )
//                     : []
//                   : [],
//               selectedRange: [null, null],
//             });
//           }}
//           onChange={(val) => {
//             const oldArray =
//               dispatchListCopy && dispatchListCopy.length > 0
//                 ? filterData(
//                     filterData(
//                       dispatchListCopy,
//                       "broker",
//                       this.state.singlelistView.id
//                     ),
//                     `${payment_field}`,
//                     false
//                   )
//                   ? filterData(
//                       filterData(
//                         dispatchListCopy,
//                         "broker",
//                         this.state.singlelistView.id
//                       ),
//                       `${payment_field}`,
//                       false
//                     )
//                   : []
//                 : [];

//             const dateFrom =
//               val && val[0]
//                 ? moment(val[0]).format("MMM D YYYY")
//                 : moment(new Date()).format("MMM D YYYY");

//             const dateTo =
//               val && val[1]
//                 ? moment(val[1]).format("MMM D YYYY")
//                 : moment(new Date()).format("MMM D YYYY");
//             const newArr = oldArray.filter((singleObj) => {
//               const createdAt = moment(singleObj.created_at).format(
//                 "MMM D YYYY"
//               );

//               return (
//                 createdAt === dateFrom ||
//                 createdAt === dateTo ||
//                 (new Date(createdAt) > new Date(dateFrom) &&
//                   new Date(dateTo) > new Date(createdAt))
//               );
//             });
//             this.setState({
//               dispatchList: newArr.length > 0 ? newArr : [],
//               selectedRange: val,
//             });
//           }}
//         />
//       );
//     };
//     const filterChild2 = () => {
//       return (
//         <BasicDateRangePicker
//           selectedRange={selectedRange1}
//           onClear={() => {
//             this.setState({
//               invoiceListArr:
//                 invoiceListArrCopy.length > 0 ? invoiceListArrCopy : [],
//               selectedRange1: [null, null],
//             });
//           }}
//           onChange={(val) => {
//             const oldArray =
//               invoiceListArrCopy && invoiceListArrCopy.length > 0
//                 ? invoiceListArrCopy
//                 : [];

//             const dateFrom =
//               val && val[0]
//                 ? moment(val[0]).format("MMM D YYYY")
//                 : moment(new Date()).format("MMM D YYYY");

//             const dateTo =
//               val && val[1]
//                 ? moment(val[1]).format("MMM D YYYY")
//                 : moment(new Date()).format("MMM D YYYY");
//             const newArr = oldArray.filter((singleObj) => {
//               const createdAt = moment(singleObj.invoice_date).format(
//                 "MMM D YYYY"
//               );

//               return (
//                 createdAt === dateFrom ||
//                 createdAt === dateTo ||
//                 (new Date(createdAt) > new Date(dateFrom) &&
//                   new Date(dateTo) > new Date(createdAt))
//               );
//             });
//             this.setState({
//               invoiceListArr: newArr.length > 0 ? newArr : [],
//               selectedRange1: val,
//             });
//           }}
//         />
//       );
//     };
//     var labelField = "name";
//     const setData = (singleDispatch) => {
//       const unit =
//         singleDispatch.purchase_order_details &&
//         singleDispatch.purchase_order_details.unit &&
//         singleDispatch.purchase_order_details.unit;
//       singleDispatch["PO#"] = singleDispatch.purchase_order_details
//         ? singleDispatch.purchase_order_details.po_number
//         : "-";
//       singleDispatch["Dispatch date"] = moment(
//         singleDispatch.dispatch_date
//       ).format("ddd, MMM Do YYYY");

//       singleDispatch["From"] = singleDispatch.dispatched_from_details
//         ? singleDispatch.dispatched_from_details.name
//         : "-";
//       singleDispatch["To"] = singleDispatch.dispatched_to_details
//         ? singleDispatch.dispatched_to_details.name
//         : "-";
//       singleDispatch["Bags qty"] =
//         singleDispatch.subList &&
//         singleDispatch.subList.length > 0 &&
//         singleDispatch.subList.reduce(
//           (previousValue, currentValue) =>
//             Number(previousValue) +
//             Number(
//               currentValue.dispatch_packaging &&
//                 currentValue.dispatch_packaging.length > 0
//                 ? currentValue.dispatch_packaging.reduce(
//                     (previousValue1, currentValue1) =>
//                       Number(previousValue1) +
//                       (currentValue1.total_bags_dispatched
//                         ? Number(currentValue1.total_bags_dispatched)
//                         : 0),
//                     0
//                   )
//                 : 0
//             ),
//           0
//         );

//       singleDispatch["Gross wt"] = singleDispatch.gross_weight
//         ? `${Number(
//             singleDispatch.gross_weight ? singleDispatch.gross_weight : 0
//           ).toFixed(3)} ${unit}`
//         : "-";
//       singleDispatch["Dispatch qty"] = singleDispatch.dispatch_weight
//         ? `${Number(
//             singleDispatch.dispatch_weight ? singleDispatch.dispatch_weight : 0
//           ).toFixed(3)} ${unit}`
//         : "-";
//       singleDispatch["Dispatch Status"] = singleDispatch.dispatch_status
//         ? singleDispatch.dispatch_status
//         : "-";
//       singleDispatch["totalamount"] = 0;
//       singleDispatch["subList"] = singleDispatch.subList
//         ? singleDispatch.subList.map((singleSub) => {
//             singleSub["Brand"] =singleSub.brand??""

//             singleSub["Item Category"] = filterData(
//               ItemCateoryList,
//               "id",
//               singleSub.item_category,
//               "onlyOne"
//             )
//               ? filterData(
//                   ItemCateoryList,
//                   "id",
//                   singleSub.item_category,
//                   "onlyOne"
//                 ).name
//               : "";
//             singleSub["Item Type"] = filterData(
//               ItemList,
//               "id",
//               singleSub.item_type,
//               "onlyOne"
//             )
//               ? `${Number(
//                   filterData(ItemList, "id", singleSub.item_type, "onlyOne")?
//                   filterData(ItemList, "id", singleSub.item_type, "onlyOne").broken_percentage:0
//                 ).toFixed(2)} %`
//               : "";
//             singleSub["Packaging"] =
//               singleSub.dispatch_packaging &&
//               singleSub.dispatch_packaging.length > 0
//                 ? `  ${
//                     singleSub.dispatch_packaging.map((sP) => {
//                       const singlePack = sP.packaging_details;

//                       return singlePack
//                         ? `${singlePack.size}${singlePack.unit}${" - "}${
//                             singlePack.bag_type
//                           }`
//                         : "";
//                     })[0]
//                   } Total (...s)`
//                 : "-";
//             singleSub["PackagingUI"] = (
//               <ShowPackaging
//                 noTotalbags={true}
//                 details={singleSub.dispatch_packaging.map((sP) => {
//                   const singlePack = sP.packaging_details;
//                   singlePack.used_bags = sP.total_bags_dispatched;

//                   return singlePack;
//                 })}
//               />
//             );
//             singleSub["PackagingUIPdf"] =
//               singleSub.dispatch_packaging &&
//               singleSub.dispatch_packaging.length > 0
//                 ? singleSub.dispatch_packaging
//                     .map((sP) => {
//                       const singlePack = sP.packaging_details;

//                       return singlePack
//                         ? `${singlePack.size}${singlePack.unit}${" - "}${
//                             singlePack.bag_type
//                           }`
//                         : "";
//                     })
//                     .join(", ")
//                 : "-";
//             return singleSub;
//           })
//         : [];
//       return singleDispatch;
//     };
//     return (
//       <Layout
//         loading={loading[EndPoints["Brokers"]]}
//         tabs={tabs}
//         noTab={true}
//         RaisedSearchBar={{
//           data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
//           hintText: `Search Brokers`,
//           labelField: labelField,
//         }}
//         fabClickHandler={(value) => {
//           localStorage.removeItem("fetchagain-bri")
//           this.setState({
//             openDialog: true,
//             loadingFun: false,
//             editList: false,
//             openDialogScreen: true,
//           });
//         }}
//         listClickHandler={this.listClickHandler}
//         serchClick={this.listClickHandler}
//         centerWidth="230px"
//         openWidth="465px"
//         closeWidth="287px"
//       >
//         {openDialog === true && userRole !== "broker" ? (
//           <CreateDialog
//             noAddAnother={true}
//             textFieldWidth="calc(50% - 20px)"
//             heightNew="500px"
//             maxHeight="calc(100vh - 40px)"
//             widthNew="900px"
//             onEditUpdate={(res) => {
//               this.onEditUpdate(res);
//             }}
//             // addFieldsToAdd={[{ name: "is_active", value: true }]}
//             module={"Brokers"}
//             selectedList={editList ? editList : false}
//             editList={editList ? editList : false}
//             handleClose={() => {
//               this.setState({
//                 openDialog: false,
//                 loadingFun: false,
//                 editList: true,
//               });
//             }}
//             postApi="masters/broker/list"
//             editApi="masters/broker"
//             inputArray={[
//               {
//                 name: "Active",
//                 type: "toggle",
//                 defaultValue: {
//                   value: editList ? editList.is_active : true,
//                 },
//                 api: "is_active",
//               },
//               {
//                 name: "name",
//                 type: "textField",
//                 dataType: "text",
//                 api: "name",
//                 defaultValue: {
//                   value: editList && editList.name,
//                 },
//               },
//               {
//                 name: "phone number",
//                 type: "textField",
//                 dataType: "number",
//                 onInput: "phone_number",
//                 not_required: true,
//                 only_number: true,
//                 api: "phone",
//                 defaultValue: {
//                   value: editList && editList.phone,
//                 },
//               },
//               {
//                 name: "address",
//                 type: "addressTextField",
//                 dataType: "text",
//                 api: "address",
//                 defaultValue: {
//                   value: editList && editList.address,
//                 },
//               },
//               {
//                 name: "GST IN",
//                 type: "textField",
//                 dataType: "text",
//                 not_required: true,
//                 onKeyPress: "alphaNum",
//                 // only_number: true,
//                 onInput: "gst_in",
//                 api: "gstin",
//                 defaultValue: {
//                   value: editList && editList.gstin,
//                 },
//                 helperText: "ex: 22AAAAA0000A1Z5",
//               },

//               {
//                 name: "contact person",
//                 type: "textField",
//                 dataType: "text",
//                 api: "contact_person",
//                 defaultValue: {
//                   value: editList && editList.contact_person,
//                 },
//               },

//               {
//                 name: "state",
//                 type: "select",
//                 array: stateArray,
//                 value: "name",
//                 api: "state",
//                 defaultValue: {
//                   value: editList && editList.state,
//                 },
//               },
//               {
//                 name: "city",
//                 type: "textField",
//                 dataType: "text",
//                 api: "city",
//                 onKeyPress: "textOnly",
//                 defaultValue: {
//                   value: editList && editList.city,
//                 },
//               },

//               {
//                 name: "pincode",
//                 type: "textField",
//                 onInput: "pincode",
//                 dataType: "number",
//                 api: "pincode",
//                 only_number: true,
//                 defaultValue: {
//                   value: editList && editList.pincode,
//                 },
//               },
//             ]}
//             postData={this.props.BrokersFunction}
//             fetchAgain={() => {
//               localStorage.setItem("fetchagain-bri","broker")
//               this.props.BrokersFunction("get", EndPoints["Brokers"]);
//             }}
//           />
//         ) : (
//           ""
//         )}

//         <Paper className="width-100 height-100 padding15 backgroundWhite">
//           {/* <button onClick={()=>{  localStorage.setItem("fetchagain-bri","broker")}}>test reload</button> */}
//           {singlelistView === null ? (
//             <div className="d-flex width-100 height-100 justifyC alignC">
//               <NoData />
//             </div>
//           ) : loading.Brokers === true ? (
//             <div className="alignC justiyC d-flex width-100 height-100">
//               <StyledCircularProgress color="inherit" />
//             </div>
//           ) : (
//             <TabComponent
//               onChange={(newVal) => {

//                 const selctedTab =
//                   newVal === 0
//                     ? "unpaid"
//                     : newVal === 1
//                     ? "pending"
//                     : newVal === 2
//                     ? "paid"
//                     : newVal === 3 && "brokerDetail";

//                 this.setState({ selctedTab }, () => {
//                   // localStorage.setItem("selectedTabSSIPL", selctedTab);
//                   if (newVal === 0) {
//                     this.fetchDispatchList("UNPAID");
//                     tabb = "UNPAID";
//                   }
//                   if (newVal === 1) {
//                     this.fetchDispatchList("PENDING");
//                     tabb = "PENDING";
//                   }
//                   if (newVal === 2) {
//                     this.fetchDispatchList("paid");
//                     tabb = "PAID";
//                   }
//                 });
//               }}
//               list={[
//                 {
//                   name: "Unpaid",
//                   component: (
//                     <TableComponent
//                       loader={loader ? loader : false}
//                       onCheckSubmit={async (selectedListArray) => {
//                         return this.setState({
//                           openStatusDialog: true,
//                           broken_amount: "",
//                           selectedListArray: selectedListArray,
//                         });
//                       }}
//                      bottomBar={userRole==="admin"||userRole==="accountant"|| userRole==="manager"?true:false}
//                       subMenu={true}
//                       filterChild={() => {
//                         return filterChild("unpaid");
//                       }}
//                       checkbox={true}
//                       noAddButton={true}
//                       module={"Dispatch"}
//                       {...this.state}
//             //           button={
//             //  true
//             //           }
//                       header={[
//                         "Sr.No.",
//                         "PO#",
//                         "Dispatch date",
//                         "From",
//                         "To",
//                         "Bags qty",
//                         "Gross wt",
//                         "Dispatch qty",
//                         "Dispatch Status",
//                       ]}
//                       subMenuheader={[
//                         "Sr.No.",
//                         "Brand",
//                         "Item Category",
//                         "Item Type",
//                         "Packaging",
//                       ]}
//                       actions={[]}
//                       {...this.props}
//                       tableList={
//                         dispatchList && dispatchList.length
//                           ? filterData(
//                               dispatchList,
//                               "broker",
//                               this.state.singlelistView.id
//                             ) &&
//                             filterData(
//                               filterData(
//                                 dispatchList,
//                                 "broker",
//                                 this.state.singlelistView.id
//                               ),
//                               `${payment_field}`,
//                               "UNPAID"
//                             ) &&
//                             filterData(
//                               filterData(
//                                 dispatchList,
//                                 "broker",
//                                 this.state.singlelistView.id
//                               ),
//                               `${payment_field}`,
//                               "UNPAID"
//                             )
//                               .sort((a, b) => {
//                                 var dateB = moment(b.dispatch_date).format(
//                                   "MMM D YYYY h:mm:ss A"
//                                 );
//                                 var dateA = moment(a.dispatch_date).format(
//                                   "MMM D YYYY h:mm:ss A"
//                                 );
//                                 return new Date(dateB) - new Date(dateA);
//                               })
//                               .map((singleDispatch) => {
//                                 return setData(singleDispatch);
//                               })
//                           : []
//                       }
//                       postData={this.props.BrokersFunction}
//                     />
//                   ),
//                 },
//                 {
//                   name: "Pending",
//                   component: (
//                     <TableComponent
//                       loader={loader ? loader : false}
//                       onCheckSubmit={async (selectedListArray) => {
//                         return this.setState({
//                           openStatusDialog: true,
//                           broken_amount: "",
//                           selectedListArray: selectedListArray,
//                         });
//                       }}
//                       bottomBar={userRole==="admin"?true:false}
//                       subMenu={true}
//                       filterChild={() => {
//                         return filterChild("pending");
//                       }}
//                       checkbox={true}
//                       noAddButton={true}
//                       module={"Dispatch"}
//                       {...this.state}
//                       header={[
//                         "Sr.No.",
//                         "PO#",
//                         "Dispatch date",
//                         "From",
//                         "To",
//                         "Bags qty",
//                         "Gross wt",
//                         "Dispatch qty",
//                         "Dispatch Status",
//                       ]}
//                       subMenuheader={[
//                         "Sr.No.",
//                         "Brand",
//                         "Item Category",
//                         "Item Type",
//                         "Packaging",
//                       ]}
//                       actions={[]}
//                       {...this.props}
//                       tableList={
//                         dispatchList && dispatchList.length
//                           ? filterData(
//                               dispatchList,
//                               "broker",
//                               this.state.singlelistView.id
//                             ) &&
//                             filterData(
//                               filterData(
//                                 dispatchList,
//                                 "broker",
//                                 this.state.singlelistView.id
//                               ),
//                               `${payment_field}`,
//                               "PENDING"
//                             ) &&
//                             filterData(
//                               filterData(
//                                 dispatchList,
//                                 "broker",
//                                 this.state.singlelistView.id
//                               ),
//                               `${payment_field}`,
//                               "PENDING"
//                             )
//                               .sort((a, b) => {
//                                 var dateB = moment(b.dispatch_date).format(
//                                   "MMM D YYYY h:mm:ss A"
//                                 );
//                                 var dateA = moment(a.dispatch_date).format(
//                                   "MMM D YYYY h:mm:ss A"
//                                 );
//                                 return new Date(dateB) - new Date(dateA);
//                               })
//                               .map((singleDispatch) => {
//                                 return setData(singleDispatch);
//                               })
//                           : []
//                       }
//                       postData={this.props.BrokersFunction}
//                     />
//                   ),
//                 },

//                 {
//                   name: "Paid",
//                   component: (
//                     <InvoiceCardUi
//                       singlelistView={singlelistView}
//                       module="broker"
//                       setData={setData}
//                       filterChild={() => {
//                         return filterChild2();
//                       }}
//                       listArrCopy={
//                         invoiceListArrCopy && invoiceListArrCopy.length
//                           ? invoiceListArrCopy
//                           : []
//                       }
//                       header={[
//                         "Sr.No.",
//                         "PO#",
//                         "Dispatch date",
//                         "From",
//                         "To",
//                         "Bags qty",
//                         "Gross wt",
//                         "Dispatch qty",
//                         "Dispatch Status",
//                       ]}
//                       pdfHeader={[
//                         "Sr.No.",
//                         "PO#",
//                         "Dispatch date",
//                         "From",
//                         "To",
//                         "Bags qty",
//                         "Gross wt",
//                         "Dispatch qty",
//                       ]}
//                       loader={loader ? loader : false}
//                       fetchFunction={this.props.BrokersFunction}
//                       listArr={
//                         invoiceListArr && invoiceListArr.length
//                           ? invoiceListArr
//                           : []
//                       }
//                     />
//                   ),
//                 },
//                 {
//                   name: "Broker Details",
//                   component: (
//                     <Paper
//                       className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
//                       style={{
//                         width: "100%",
//                         display: "flex",
//                         flexDirection: "column",
//                         height: "100%",
//                       }}
//                     >
//                       <div className="width-100 d-flex justifyFE">
//                       {widget_permission?.widget?.has_update_permission && (
//                         <Tooltip title="Edit">
//                           <EditIcon
//                             onClick={() => {
//                               this.setState({
//                                 openDialog: true,
//                                 editList: singlelistView,
//                               });
//                             }}
//                             style={{ cursor: "pointer" }}
//                           />
//                         </Tooltip>
//                       )}
//                       </div>

//                       <div
//                         className="overflowYA scrollBluePrimary"
//                         style={{
//                           width: "100%",
//                           display: "flex",
//                           flexDirection: "column",
//                           padding: "0 50px",
//                           height: "calc(100% - 31.7px)",
//                         }}
//                       >
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                           }}
//                         >
//                           <p
//                             style={{
//                               fontSize: "18px",
//                               margin: "10px 0 ",
//                               minWidth: "200px",
//                             }}
//                           >
//                             Name:{" "}
//                           </p>
//                           <p
//                             style={{
//                               fontSize: "18px",
//                               margin: "10px 0 10px 10px",
//                               color: "#808080",
//                               wordBreak: "break-all",
//                             }}
//                           >
//                             {singlelistView ? singlelistView.name : ""}
//                           </p>
//                         </div>
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                           }}
//                         >
//                           <p
//                             style={{
//                               fontSize: "18px",
//                               margin: "10px 0 ",
//                               minWidth: "200px",
//                             }}
//                           >
//                             Phone Number:
//                           </p>{" "}
//                           <p
//                             style={{
//                               fontSize: "18px",
//                               margin: "10px 0 10px 10px",
//                               color: "#808080",
//                             }}
//                           >
//                             {singlelistView.phone}
//                           </p>
//                         </div>
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                           }}
//                         >
//                           <p
//                             style={{
//                               fontSize: "18px",
//                               margin: "10px 0 ",
//                               minWidth: "200px",
//                             }}
//                           >
//                             Address:{" "}
//                           </p>{" "}
//                           <p
//                             style={{
//                               fontSize: "18px",

//                               margin: "10px 0 10px 10px",
//                               color: "#808080",
//                               wordBreak: "break-all",
//                             }}
//                           >
//                             {singlelistView.address}
//                           </p>
//                         </div>
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                           }}
//                         >
//                           <p
//                             style={{
//                               fontSize: "18px",
//                               margin: "10px 0 ",
//                               minWidth: "200px",
//                             }}
//                           >
//                             GST IN:{" "}
//                           </p>{" "}
//                           <p
//                             style={{
//                               fontSize: "18px",

//                               margin: "10px 0 10px 10px",
//                               color: "#808080",
//                             }}
//                           >
//                             {singlelistView.gstin}
//                           </p>
//                         </div>
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                           }}
//                         >
//                           <p
//                             style={{
//                               fontSize: "18px",
//                               margin: "10px 0 ",
//                               minWidth: "200px",
//                             }}
//                           >
//                             Contact Person:{" "}
//                           </p>{" "}
//                           <p
//                             style={{
//                               fontSize: "18px",

//                               margin: "10px 0 10px 10px",
//                               color: "#808080",
//                               wordBreak: "break-all",
//                             }}
//                           >
//                             {singlelistView.contact_person}
//                           </p>
//                         </div>
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                           }}
//                         >
//                           <p
//                             style={{
//                               fontSize: "18px",
//                               margin: "10px 0 ",
//                               minWidth: "200px",
//                             }}
//                           >
//                             City:{" "}
//                           </p>{" "}
//                           <p
//                             style={{
//                               fontSize: "18px",

//                               margin: "10px 0 10px 10px",
//                               color: "#808080",
//                               wordBreak: "break-all",
//                             }}
//                           >
//                             {singlelistView.city}
//                           </p>
//                         </div>
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                           }}
//                         >
//                           <p
//                             style={{
//                               fontSize: "18px",
//                               margin: "10px 0 ",
//                               minWidth: "200px",
//                             }}
//                           >
//                             State:{" "}
//                           </p>{" "}
//                           <p
//                             style={{
//                               fontSize: "18px",

//                               margin: "10px 0 10px 10px",
//                               color: "#808080",
//                             }}
//                           >
//                             {singlelistView.state}
//                           </p>
//                         </div>
//                         <div
//                           style={{
//                             width: "100%",
//                             display: "flex",
//                           }}
//                         >
//                           <p
//                             style={{
//                               fontSize: "18px",
//                               margin: "10px 0 ",
//                               minWidth: "200px",
//                             }}
//                           >
//                             Pincode:{" "}
//                           </p>{" "}
//                           <p
//                             style={{
//                               fontSize: "18px",

//                               margin: "10px 0 10px 10px",
//                               color: "#808080",
//                             }}
//                           >
//                             {singlelistView.pincode}
//                           </p>
//                         </div>
//                       </div>
//                     </Paper>
//                   ),
//                 },
//               ]}
//             />
//           )}
//           {openStatusDialog === true ? (
//             <Dialog
//               ref={(e) => {
//                 try {
//                   const target =
//                     ReactDOM.findDOMNode(e).children[2].children[0];
//                   target.style.borderRadius = "0px";
//                   target.style.maxHeight = "calc(100% - 62px)";
//                   target.style.minHeight = showInvoice
//                     ? "calc(100% - 63px)"
//                     : "300px";

//                   target.style.height = showInvoice
//                     ? "calc(100% - 63px)"
//                     : "300px";
//                   target.style.minWidth = showInvoice
//                     ? "calc(100% - 150px)"
//                     : "40%";
//                   target.style.maxWidth = showInvoice
//                     ? "calc(100% - 150px)"
//                     : "calc(100% - 150px)";
//                   target.style.margin = "32px 75px";
//                 } catch (e) {}
//               }}
//               open={true}
//               onClose={() => {
//                 this.setState({
//                   openStatusDialog: false,
//                   broken_amount: "",
//                   editDialog: false,
//                   added: false,
//                   selectedListArray: false,
//                   showInvoice: false,
//                 });
//               }}
//               TransitionComponent={Transition}
//             >
//               <DialogActions
//                 className="d-flex justifySB"
//                 style={{
//                   background: primary,
//                   color: "white",
//                   padding: "10px 24px",
//                 }}
//                 onClick={() => {
//                   this.setState({
//                     openStatusDialog: false,
//                     broken_amount: "",
//                     editDialog: false,
//                     added: false,
//                     selectedListArray: false,
//                     showInvoice: false,
//                   });
//                 }}
//               >
//                 {" "}
//                 <h3 style={styles.heading}>
//                   {showInvoice !== false
//                     ? "Invoice"
//                     : this.state.selectedListArray[0] &&
//                       this.state.selectedListArray[0][payment_field] ===
//                         "PENDING"
//                     ? "Approve Payment"
//                     : "Mark as Paid"}
//                 </h3>
//                 <Tooltip title="close" arrow placement="top">
//                   <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
//                     <CrossIcon strokeWidth="2" />
//                   </IconButton>
//                 </Tooltip>
//               </DialogActions>{" "}
//               <DialogContent
//                 className="dashedDividerBottom d-flex d-flex-column justifyC alignC"
//                 style={{ height: "100%" }}
//               >
//                 <div style={{ height: "0", width: "0", overflow: "hidden" }}>
//                   {downloadInvoicePdf === true ? (
//                     <InvoiceCardUi
//                       onlyShow={false}
//                       setLoader={() => {
//                         this.setState({ loader: false });
//                       }}
//                       onDownload={() => {
//                         this.setState({
//                           openStatusDialog: false,
//                           editDialog: false,
//                           added: false,
//                           selectedListArray: false,
//                           showInvoice: false,
//                           loader: false,
//                           downloadInvoicePdf: false,
//                           broken_amount: "",
//                         });
//                       }}
//                       onlyDownload={showInvoice}
//                       singlelistView={singlelistView}
//                       module="broker"
//                       setData={setData}
//                       filterChild={() => {
//                         return filterChild2();
//                       }}
//                       listArrCopy={
//                         invoiceListArrCopy && invoiceListArrCopy.length
//                           ? invoiceListArrCopy
//                           : []
//                       }
//                       header={[
//                         "Sr.No.",
//                         "PO#",
//                         "Dispatch date",
//                         "From",
//                         "To",
//                         "Bags qty",
//                         "Gross wt",
//                         "Dispatch qty",
//                         "Dispatch Status",
//                       ]}
//                       pdfHeader={[
//                         "Sr.No.",
//                         "PO#",
//                         "Dispatch date",
//                         "From",
//                         "To",
//                         "Bags qty",
//                         "Gross wt",
//                         "Dispatch qty",
//                       ]}
//                       loader={loader ? loader : false}
//                       fetchFunction={this.props.BrokersFunction}
//                       listArr={
//                         invoiceListArr && invoiceListArr.length
//                           ? invoiceListArr
//                           : []
//                       }
//                     />
//                   ) : (
//                     ""
//                   )}
//                 </div>

//                 {loader === true ? (
//                   <StyledCircularProgress />
//                 ) : showInvoice !== false ? (
//                   <InvoiceCardUi
//                     onlyShow={true}
//                     setLoader={() => {
//                       this.setState({ loader: false });
//                     }}
//                     onDownload={() => {
//                       this.setState({
//                         openStatusDialog: false,
//                         broken_amount: "",
//                         editDialog: false,
//                         added: false,
//                         selectedListArray: false,
//                         showInvoice: false,
//                         loader: false,
//                         broken_amount: "",
//                         downloadInvoicePdf: false,
//                       });
//                     }}
//                     onlyDownload={showInvoice}
//                     singlelistView={singlelistView}
//                     module="broker"
//                     setData={setData}
//                     filterChild={() => {
//                       return filterChild2();
//                     }}
//                     listArrCopy={
//                       invoiceListArrCopy && invoiceListArrCopy.length
//                         ? invoiceListArrCopy
//                         : []
//                     }
//                     header={[
//                       "Sr.No.",
//                       "PO#",
//                       "Dispatch date",
//                       "From",
//                       "To",
//                       "Bags qty",
//                       "Gross wt",
//                       "Dispatch qty",
//                       "Dispatch Status",
//                     ]}
//                     pdfHeader={[
//                       "Sr.No.",
//                       "PO#",
//                       "Dispatch date",
//                       "From",
//                       "To",
//                       "Bags qty",
//                       "Gross wt",
//                       "Dispatch qty",
//                     ]}
//                     loader={loader ? loader : false}
//                     fetchFunction={this.props.BrokersFunction}
//                     listArr={
//                       invoiceListArr && invoiceListArr.length
//                         ? invoiceListArr
//                         : []
//                     }
//                   />
//                 ) : (
//                   <>
//                     <div
//                       style={{
//                         fontSize: "0.9rem",
//                         justifyContent: "center",
//                         marginTop: "20px",
//                         fontFamily: "Poppins",
//                       }}
//                     >
//                       Are you sure you want to mark these dispatches as paid ?
//                     </div>
//                     {this.state.selectedListArray[0] &&
//                     this.state.selectedListArray[0][payment_field] ===
//                       "PENDING" ? (
//                       "Are you sure you want to approve and  mark these dispatches as paid ?"
//                     ) : (
//                       <></>

//                       // <CssTextField
//                       //   required={false}
//                       //   type="number"
//                       //   onFocus={StopScroll}
//                       //   autoComplete="off"
//                       //   style={{ margin: "40px 0" }}
//                       //   id="outlined-basic"
//                       //   label="Brokerage Amount"
//                       //   variant="outlined"
//                       //   name="broken_amount"
//                       //   value={broken_amount ? broken_amount : ""}
//                       //   onChange={(e) => {
//                       //     this.setState({ broken_amount: e.target.value });
//                       //   }}
//                       // />
//                     )}
//                   </>
//                 )}
//               </DialogContent>
//               <div
//                 style={{
//                   justifyContent: "end",
//                   display: "flex",
//                   padding: "10px",
//                 }}
//               >
//                 {showInvoice ? (
//                   <>
//                     <StyledButton
//                       name="Download pdf"
//                       width="150px"
//                       padding="2px 5px"
//                       onClick={() => {
//                         this.setState({
//                           downloadInvoicePdf: true,
//                           loader: true,
//                         });
//                       }}
//                     />
//                     {/* <StyledButton withBg={true} name="Download CSV" width="150px" padding="2px 5px" /> */}
//                   </>
//                 ) : (
//                   <>
//                     <Button
//                       onClick={() => {
//                         this.setState({
//                           openStatusDialog: false,
//                           editDialog: false,
//                           added: false,
//                           selectedListArray: false,
//                           broken_amount: "",
//                         });

//                       }}
//                       style={{
//                         color: "rgb(241 154 48)",
//                         margin: "0 10px 0 0",
//                         padding: "2px 5px",
//                       }}
//                     >
//                       No
//                     </Button>
//                     <Button
//                       onClick={() => {
//                         if (
//                           this.state.selectedListArray &&
//                           this.state.selectedListArray.length > 0
//                         ) {
//                           this.setState({ loader: true });
//                           const data = this.state.selectedListArray;
//                           const newInvoice = {
//                             invoice_date: moment(new Date()).format(
//                               "YYYY-MM-DD"
//                             ),
//                             // invoice_number: `${data[0].id.toString()}${data.length.toString()}`,
//                             invoice_for: "BROKER",
//                             amount: this.state.broken_amount
//                               ? this.state.broken_amount
//                               : 0,
//                             is_active: true,
//                             broker: singlelistView.id,
//                           };
//                           const apiData = [
//                             { name: "id" },
//                             { name: "dispatch_date" },
//                             { name: "total_bags" },
//                             { name: "dispatch_weight" },
//                             { name: "miller_bill_weight" },
//                             { name: "miller_gst_charge" },
//                             { name: "miller_bill_amount" },
//                             { name: "miller_tcs" },
//                             { name: "miller_advance" },
//                             { name: "miller_total_amount" },
//                             { name: "bilty_number" },
//                             { name: "bilty_date" },
//                             { name: "truck_number" },
//                             { name: "miller_tds" },
//                             { name: "gross_weight" },
//                             { name: "net_weight" },
//                             { name: "tare_weight" },
//                             { name: "freight_rate" },
//                             { name: "total_freight" },
//                             { name: "transporter_advance" },
//                             { name: "transporter_balance" },
//                             { name: "last_survey" },
//                             { name: "miller_payment" },
//                             { name: "transporter_payment" },
//                             { name: `${payment_field}` },
//                             { name: "dispatch_status" },
//                             { name: "miller_bill_number" },
//                             { name: "is_active" },
//                             { name: "unit" },
//                             { name: "dispatched_from" },
//                             { name: "dispatched_to" },
//                             { name: "purchase_order" },
//                             { name: "sales_order" },
//                             { name: "transporter" },
//                             { name: "surveyor" },
//                           ];

//                           if (
//                             this.state.selectedListArray[0][payment_field] ===
//                             "PENDING"
//                           ) {

//                             this.props
//                               .BrokersFunction(
//                                 "post",
//                                 "dispatch/invoice/list",
//                                 null,
//                                 "invoiceUpdate",
//                                 newInvoice
//                               )
//                               .then((invoiceId) => {
//                                 if (invoiceId && invoiceId.id) {
//                                   const newArr = [];
//                                   data.map((sData) => {
//                                     const newObj = {};
//                                     // const pay = "";
//                                     apiData.map((s) => {
//                                       // pay =
//                                       //   s.name === `${payment_field}`
//                                       //     ? s.value
//                                       //     : "";

//                                       return (newObj[s.name] = s.value
//                                         ? sData[s.value]
//                                         : sData[s.name]
//                                         ? sData[s.name]
//                                         : s.name === "transporter"
//                                         ? ""
//                                         : s.name === "transporter_payment" ||
//                                           s.name === `${payment_field}`
//                                         ? false
//                                         : null);
//                                     });
//                                     newObj.invoice = invoiceId.id;
//                                     newObj.invoice_number = 0;
//                                     newObj[payment_field] = "PAID";
//                                     newObj.broker_payment_by_bri= "PAID";

//                                     newObj.broker_payment_by_miller= "PAID";
//                                     newObj.invoice_date = moment(
//                                       new Date()
//                                     ).format("YYYY-MM-DD");
//                                     return newArr.push(newObj);
//                                   });

//                                   this.props
//                                     .BrokersFunction(
//                                       "put",
//                                       "dispatch/bulk-update",
//                                       null,
//                                       "dispatchListBulk",
//                                       newArr
//                                     )
//                                     .then((dispatchListBulk) => {

//                                       this.fetchDispatchList("PENDING");
//                                       this.setState({
//                                         loader: false,
//                                         showInvoice: invoiceId,
//                                       });
//                                     });
//                                 }
//                               });
//                           } else if (
//                             this.state.selectedListArray[0][payment_field] ===
//                             "UNPAID"
//                           ) {
//                             if (userRole === "admin") {
//                               this.props
//                                 .BrokersFunction(
//                                   "post",
//                                   "dispatch/invoice/list",
//                                   null,
//                                   "invoiceUpdate",
//                                   newInvoice
//                                 )
//                                 .then((invoiceId) => {
//                                   if (invoiceId && invoiceId.id) {
//                                     const newArr = [];
//                                     data.map((sData) => {
//                                       const newObj = {};
//                                       const pay = "";
//                                       apiData.map((s) => {
//                                         // pay =
//                                         //   s.name === `${payment_field}`
//                                         //     ? s.value
//                                         //     : "";

//                                         return (newObj[s.name] = s.value
//                                           ? sData[s.value]
//                                           : sData[s.name]
//                                           ? sData[s.name]
//                                           : s.name === "transporter"
//                                           ? ""
//                                           : s.name === "transporter_payment" ||
//                                             s.name === `${payment_field}`
//                                           ? false
//                                           : null);
//                                       });
//                                       newObj.invoice = invoiceId.id;
//                                       newObj.invoice_number = 0;
//                                       newObj[payment_field] = "PAID";
//                                        newObj.broker_payment_by_bri= "PAID";

//                                       newObj.broker_payment_by_miller= "PAID";

//                                       newObj.invoice_date = moment(
//                                         new Date()
//                                       ).format("YYYY-MM-DD");
//                                       return newArr.push(newObj);
//                                     });

//                                     this.props
//                                       .BrokersFunction(
//                                         "put",
//                                         "dispatch/bulk-update",
//                                         null,
//                                         "dispatchListBulk",
//                                         newArr
//                                       )
//                                       .then((dispatchListBulk) => {
//                                         this.fetchDispatchList("UNPAID");
//                                         this.setState({
//                                           loader: false,
//                                           showInvoice: invoiceId,
//                                         });
//                                       });
//                                   }
//                                 });
//                             } else {
//                               // this.props
//                               //   .BrokersFunction("post", "dispatch/invoice/list", null, "invoiceUpdate", newInvoice)
//                               //   .then((invoiceId) => {
//                               //     if (invoiceId && invoiceId.id) {
//                               //       const newArr = [];
//                               //       data.map((sData) => {
//                               //         const newObj = {};
//                               //         const pay = "";
//                               //         apiData.map((s) => {
//                               //           // pay =
//                               //           //   s.name === `${payment_field}`
//                               //           //     ? s.value
//                               //           //     : "";

//                               //           return (newObj[s.name] = s.value
//                               //             ? sData[s.value]
//                               //             : sData[s.name]
//                               //             ? sData[s.name]
//                               //             : s.name === "transporter"
//                               //             ? ""
//                               //             : s.name === "transporter_payment" || s.name === `${payment_field}`
//                               //             ? false
//                               //             : null);
//                               //         });
//                               //         newObj.invoice = invoiceId.id;
//                               //         newObj.invoice_number = 0;
//                               //         newObj[payment_field] = "PENDING";

//                               //         newObj.invoice_date = moment(new Date()).format("YYYY-MM-DD");
//                               //         return newArr.push(newObj);
//                               //       });

//                               //       this.props
//                               //         .BrokersFunction("put", "dispatch/bulk-update", null, "dispatchListBulk", newArr)
//                               //         .then((dispatchListBulk) => {
//                               //           this.fetchDispatchList("UNPAID");
//                               //           this.setState({
//                               //             loader: false,
//                               //             showInvoice: invoiceId,
//                               //           });
//                               //         });
//                               //     }
//                               //   });
//                               const newArr = [];
//                               data.map((sData) => {
//                                 const newObj = {};
//                                 const pay = "";
//                                 apiData.map((s) => {
//                                   // pay =
//                                   //   s.name === `${payment_field}`
//                                   //     ? s.value
//                                   //     : "";

//                                   return (newObj[s.name] = s.value
//                                     ? sData[s.value]
//                                     : sData[s.name]
//                                     ? sData[s.name]
//                                     : s.name === "transporter"
//                                     ? ""
//                                     : s.name === "transporter_payment" ||
//                                       s.name === `${payment_field}`
//                                     ? false
//                                     : null);
//                                 });
//                                 //  newObj.invoice = invoiceId.id;
//                                 //  newObj.invoice_number = 0;
//                                 newObj[payment_field] = "PENDING";
//                                 newObj.broker_payment_by_bri= "PENDING";

//                                 newObj.broker_payment_by_miller= "PENDING";
//                                 //  newObj.invoice_date = moment(
//                                 //    new Date()
//                                 //  ).format("YYYY-MM-DD");
//                                 return newArr.push(newObj);
//                               });

//                               this.props
//                                 .BrokersFunction(
//                                   "put",
//                                   "dispatch/bulk-update",
//                                   null,
//                                   "dispatchListBulk",
//                                   newArr
//                                 )
//                                 .then((dispatchListBulk) => {
//                                   this.fetchDispatchList("UNPAID");
//                                   this.setState({
//                                     loader: false,
//                                     //  showInvoice: invoiceId,
//                                     openStatusDialog: false,
//                                   });
//                                 });
//                             }
//                           }
//                         }
//                       }}
//                       style={{
//                         background: "rgb(241 154 48)",
//                         color: "white",
//                         margin: "0 0 0 10px",
//                         padding: "2px 5px",
//                         padding: "2px 5px",
//                       }}
//                     >
//                       Yes
//                     </Button>
//                   </>
//                 )}
//               </div>
//             </Dialog>
//           ) : (
//             ""
//           )}
//         </Paper>
//       </Layout>
//     );
//   }
// }

// export default BrokersComponent;

import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TabComponent from "../../layoutComponents/TabComponent";
import {
  CssDialog,
  CssTextField,
  CssTextField95,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import TableComponent from "../../layoutComponents/TableComponent";
import CreateDialog from "../../layoutComponents/CreateDialog";
import NoData from "../../styles/Illustrations/NoData";
import { ClickAwayListener, List, ListItem, MenuItem, Paper, Popover, Tooltip } from "@material-ui/core";
import { EndPoints } from "../../utils/EndPoints";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import InvoiceCardUi from "../../layoutComponents/InvoiceCardUi";
import BasicDateRangePicker from "../../layoutComponents/BasicDateRangePicker";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import { stateArray } from "../../Defaults";
import { filterData, getSingleModule_widget } from "../../Regex";
import { accessText } from "../../routes/sidebar";
import { primary, white } from "../../styles/colors";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { BottomArrow, TopArrow } from "../../styles/Icons";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import RadioButton from "../../layoutComponents/RadioButton";
import { MoreHorizOutlined } from "@material-ui/icons";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiInputBase-root": { background: "rgb(0 0 0 / 1%)" },
    "&.PickerWithState-root-34 .MuiOutlinedInput-root .MuiOutlinedInput-input":
      { background: "none" },
    // "& .PickerWithState-root-31 .MuiOutlinedInput-root .MuiOutlinedInput-input":{padding:"14.5px 8px"},
    "&.MuiOutlinedInput-adornedEnd": {
      background: "blue",
    },

    "&:hover": {
      border: "none",
    },
    // '& .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    //   padding: '0px 4px 7.5px 6px',
    // },
    // '&.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root': {
    //   padding: '5px',
    // },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: "0",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(9.5px, 9.5px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiInputBase-root.Mui-disabled": { color: "black" },
    "& .MuiFormControl-root": {},

    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input ": {
        caretColor: "transparent",
        background: "white",
        padding: "14.5px 8px",
      },
      "& .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root": {
        padding: "5px",
      },
      "& .MuiInputLabel-outlined": {
        transform: "translate(9.5px, 9.5px) scale(1)",
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75) !important",
      },
    },
  },
})(KeyboardDatePicker);
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "10px 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
export class BrokersComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Brokers"],
        tooltip: `Click to approve pending ${EndPoints["Brokers"]}`,
        data: [],
        route: `/Brokers/${EndPoints["Brokers"].split("/")[0]}`,
        goBackRoute: "/Brokers",
        widget: null,
        primaryField: "name",
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",
        status: false,
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#0AFF00", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.setStateOfPopup.bind(this);
    this.state = {
      tabs: templateTabs,
      Brokers: [],
      singlelistView: null,
      listViewArray: [],
      loading: false,
      isPopup: false,
      isNew: false,
      openDialog: false,
      searchedData: new Date(),
      dispatchList: [],
      dispatchListCopy: [],
      loader: false,
      invoiceListArr: [],
      selctedTYpeOfSO: "BRI",
      invoiceListArrCopy: [],
      openStatusDialog: false,
      selectedListArray: [],
      showInvoice: false,
      calenderOpen: false,
      selectedRange: [null, null],
      selectedContractType: "",
      selectedContractTypeId: "",
      downloadInvoicePdf: false,
      selctedTab: "miller_account",

      anchorEl:null,
      popper:{open:false},
      StatusList: [
        { status: "UNPAID" },
        { status: "PENDING" },
        { status: "PAID" },
      ],
      PaymentAmount: "",
      Status: "",
      errorMsg: {
        PaymentAmount: false,
        Status: false,
      },
      userRole:
        this.props.user && this.props.user.roles
          ? this.props.user.roles.toString().toLowerCase()
          : "",
    };
  }

  setStateOfPopup = (value) => {
    this.setState({ isPopup: value, isNew: false });
  };

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }

  filterDataAccDate = (dispatchList) => {
    const oldArray =
      dispatchList && dispatchList.length > 0
        ? dispatchList.map((s) => {
            s.contract_type_id = s.purchase_order_details.contract_type_details
              ? s.purchase_order_details.contract_type_details.contract_type
              : "";
            return s;
          })
        : [];
    const newArr =
      this.state.selectedContractType === "All"
        ? oldArray
        : filterData(
            oldArray,
            "contract_type_id",
            this.state.selectedContractType
          )
        ? filterData(
            oldArray,
            "contract_type_id",
            this.state.selectedContractType
          )
        : [];

    return newArr ? newArr : [];
  };
  filterDataAccDate1 = (invoiceList) => {
    const { selectedRange } = this.state;
    const oldArray = invoiceList && invoiceList.length > 0 ? invoiceList : [];

    const dateFrom =
      selectedRange && selectedRange[0]
        ? moment(selectedRange[0]).format("MMM D YYYY")
        : moment(new Date()).format("MMM D YYYY");

    const dateTo =
      selectedRange && selectedRange[1]
        ? moment(selectedRange[1]).format("MMM D YYYY")
        : moment(new Date()).format("MMM D YYYY");
    const newArr = oldArray.filter((singleObj) => {
      const createdAt = moment(singleObj.invoice_date).format("MMM D YYYY");

      return (
        createdAt === dateFrom ||
        createdAt === dateTo ||
        (new Date(createdAt) > new Date(dateFrom) &&
          new Date(dateTo) > new Date(createdAt))
      );
    });
    return newArr ? newArr : [];
  };
  UNSAFE_componentWillMount() {
    this.fetchAllData();
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Brokers"] && next.params.id) {
        this.listClickHandler(this.state.tabs[0].data[next.params.id]);
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }
  listClickHandler = (value) => {
    this.singlelistView && this.fetchDispatchList("miller_account");
    const { selctedTab } = this.state;
    this.setState(
      {
        singlelistView: value,
      },

      () => {
        if (this.state.singlelistView && this.state.singlelistView.id) {
          if (selctedTab === "miller_account") {
            this.fetchDispatchList("miller_account");
          }
        }
      }
    );
  };
  fetchAllData = () => {
    this.setState({ loader: true });
    this.props
      .BrokersFunction(
        "get",
        "masters/item-category/list",
        null,
        "ItemCateoryList",
        null,
        {
          is_active: "True",
        }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          this.setState({ ItemCateoryList }, () => {
            this.props
              .BrokersFunction(
                "get",
                "masters/item-type/list",
                null,
                "ItemList",
                null,
                {
                  is_active: "True",
                }
              )
              .then((ItemList) => {
                if (ItemList) {
                  this.setState({ ItemList }, () => {
                    this.props
                      .BrokersFunction(
                        "get",
                        "masters/brand/list",
                        null,
                        "BrandList",
                        null,
                        {
                          is_active: "True",
                        }
                      )
                      .then((BrandList) => {
                        if (BrandList) {
                          this.setState({
                            loader: false,
                            BrandList: BrandList.map((sBrand) => {
                              if (
                                this.state.ItemList &&
                                this.state.ItemList.length > 0 &&
                                this.state.ItemCateoryList &&
                                this.state.ItemCateoryList.length > 0
                              ) {
                                sBrand.item_category_name =
                                  this.state.ItemCateoryList.filter(
                                    (singleItem) =>
                                      singleItem.id === sBrand.item_category
                                  )
                                    ? this.state.ItemCateoryList.filter(
                                        (singleItem) =>
                                          singleItem.id === sBrand.item_category
                                      )[0].name
                                    : "";
                                sBrand.item_type_name =
                                  this.state.ItemList.filter(
                                    (singleItem) =>
                                      singleItem.id === sBrand.item_type
                                  )
                                    ? this.state.ItemList.filter(
                                        (singleItem) =>
                                          singleItem.id === sBrand.item_type
                                      )
                                      ? this.state.ItemList.filter(
                                          (singleItem) =>
                                            singleItem.id === sBrand.item_type
                                        )[0]
                                        ? this.state.ItemList.filter(
                                            (singleItem) =>
                                              singleItem.id === sBrand.item_type
                                          )[0].broken_percentage
                                        : ""
                                      : ""
                                    : "";

                                return sBrand;
                              } else {
                                return sBrand;
                              }
                            }),
                          });
                        } else {
                          this.setState({ loader: false });
                        }
                      });
                  });
                } else {
                  this.setState({ loader: false });
                }
              });
          });
        } else {
          this.setState({ loader: false });
        }
      });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  fetchDispatchList = (type) => {
    this.setState({ loader: true });
    if (type === "miller_account") {
      this.props
        .BrokersFunction(
          "get",
          "arrival/miller-accounts/list",
          null,
          "dispatchList",
          null,
          {
            broker_id: this.state.singlelistView.id,
            // payment_status: type,
          }
        )
        .then((dispatchList) => {
          const newArrId = [];
          const newArr = [];
          if (dispatchList && dispatchList.length > 0) {
            dispatchList.map((sR) => {
              if (sR.id) {
                // this.props
                //   .BrokersFunction(
                //     "get",
                //     "arrival/partial-payment/list",
                //     null,
                //     "partial-payment",
                //     null,
                //     {
                //       miller_id: sR.id,
                //     }
                //   )
                //   .then((res2) => {
                sR.subList =
                  sR.partial_payments && sR.partial_payments.length > 0
                    ? sR.partial_payments
                    : [];
                if (!newArrId.includes(sR.id)) {
                  newArr.push(sR);
                  newArrId.push(sR.id);
                }
                console.log(newArr, dispatchList, "newArrnewArr");
                if (newArr.length === dispatchList.length) {
                  //  newArr.map((sN) => {
                  //     if(sN){
                  //     sN.miller = sN.purchase_order_details.miller;}
                  //     return sN;
                  //   });
                  console.log(newArr, "newArrnewArr");

                  this.setState({
                    dispatchList: newArr,
                    dispatchListCopy: newArr,
                    // dispatchList: filterData(
                    //   newArr,
                    //   "miller",
                    //   this.state.singlelistView.id
                    // )
                    //   ? filterData(
                    //       filterData(
                    //         newArr,
                    //         "miller",
                    //         this.state.singlelistView.id
                    //       ),
                    //       "miller_payment",
                    //       type === "paid" ? true : false
                    //     )
                    //     ? this.state.selectedContractTypeId
                    //       ? this.filterDataAccDate(
                    //           filterData(
                    //             filterData(
                    //               newArr,
                    //               "miller",
                    //               this.state.singlelistView.id
                    //             ),
                    //             "miller_payment",
                    //             type === "paid" ? true : false
                    //           )
                    //         )
                    //       : filterData(
                    //           filterData(
                    //             newArr,
                    //             "miller",
                    //             this.state.singlelistView.id
                    //           ),
                    //           "miller_payment",
                    //           type === "paid" ? true : false
                    //         )
                    //     : []
                    //   : [],
                    // dispatchListCopy: filterData(
                    //   newArr,
                    //   "miller",
                    //   this.state.singlelistView.id
                    // )
                    //   ? filterData(
                    //       filterData(
                    //         newArr,
                    //         "miller",
                    //         this.state.singlelistView.id
                    //       ),
                    //       "miller_payment",
                    //       type === "paid" ? true : false
                    //     )
                    //     ? filterData(
                    //         filterData(
                    //           newArr,
                    //           "miller",
                    //           this.state.singlelistView.id
                    //         ),
                    //         "miller_payment",
                    //         type === "paid" ? true : false
                    //       )
                    //     : []
                    //   : [],
                    loader: false,
                  });

                  return sR;
                }
                // });
              }
            });
          } else {
            this.setState({
              dispatchList: [],
              dispatchListCopy: [],
              loader: false,
            });
          }
        });
    }
  };

  onEditUpdate = (res) => {
    if (res) {
      this.setState({ singlelistView: res });
    }
  };

  setInitialValues(props) {
    const { widget_permission } = getSingleModule_widget(
      props.user,
      accessText.vendors,
      accessText.brokers
    );
    console.log(widget_permission, "kkkkkkkkkkkkk");
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      // has_read_permission:true,has_create_permission:true
      tab.widget = widget_permission?.widget
        ? { ...widget_permission?.widget }
        : null;
      tab.data =
        props[EndPoints["Brokers"]] && props[EndPoints["Brokers"]].length > 0
          ? props[EndPoints["Brokers"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [];
      return tab;
    });
    this.setState({
      userRole: this.props.user.roles
        ? this.props.user.roles.toString().toLowerCase()
        : "admin",
      listViewArray:
        props[EndPoints["Brokers"]] && props[EndPoints["Brokers"]].length > 0
          ? props[EndPoints["Brokers"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [],
      tabs,
      contractType: props.contractType,
      loading: props.loading,
    });
  }
  render() {
    const {
      tabs,
      listViewArray,
      singlelistView,
      loading,
      dispatchList,
      invoiceListArr,
      invoiceListArrCopy,
      editList,
      loader,
      openDialog,
      ItemCateoryList,
      ItemList,
      BrandList,
      dispatchListCopy,
      contractType,
      openStatusDialog,
      showInvoice,
      selectedContractType,
      selectedRange,
      selectedContractTypeId,
      downloadInvoicePdf,
      userRole,
      calenderOpen,
      searchedData,
      StatusList,
      PaymentAmount,
      Status,
      selctedTYpeOfSO,
    } = this.state;
    const { user } = this.props;

    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.vendors,
      accessText.brokers
    );
    // const filterChild = () => {
    //   return (
    //     <CssTextField95
    //       required={true}
    //       autoComplete="off"
    //       value={
    //         selectedContractType ? selectedContractType : "" //
    //       }
    //       onChange={(e) => {
    //         this.setState(
    //           {
    //             selectedContractTypeId: e.currentTarget.id,
    //             selectedContractType: e.target.value,
    //           },
    //           () => {
    //             const oldArray =
    //               dispatchListCopy && dispatchListCopy.length > 0
    //                 ? dispatchListCopy.map((s) => {
    //                     s.contract_type_id = s.purchase_order_details
    //                       .contract_type_details
    //                       ? s.purchase_order_details.contract_type_details
    //                           .contract_type
    //                       : "";
    //                     return s;
    //                   })
    //                 : [];
    //             const newArr =
    //               this.state.selectedContractType === "All"
    //                 ? oldArray
    //                 : filterData(
    //                     oldArray,
    //                     "contract_type_id",
    //                     this.state.selectedContractType
    //                   )
    //                 ? filterData(
    //                     oldArray,
    //                     "contract_type_id",
    //                     this.state.selectedContractType
    //                   )
    //                 : [];

    //             this.setState({
    //               dispatchList: newArr.length > 0 ? newArr : [],
    //             });
    //           }
    //         );
    //       }}
    //       style={
    //         { width: "200px", margin: "0px" } //disabled={tp}
    //       }
    //       id="outlined-select-currency-native"
    //       select
    //       label="Contract Type"
    //       variant="outlined"
    //       InputLabelProps={{}}>
    //       {" "}
    //       <MenuItem key={"All"} value={"All"} id={"All"}>
    //         All
    //       </MenuItem>
    //       {contractType &&
    //         contractType.length > 0 &&
    //         contractType.map((option) => (
    //           <MenuItem
    //             key={option.contract_type}
    //             value={option.contract_type}
    //             id={option.id}>
    //             {option.contract_type}
    //           </MenuItem>
    //         ))}
    //     </CssTextField95>
    //   );
    // };
    const filterChild2 = () => {
      return (
        <BasicDateRangePicker
          selectedRange={selectedRange}
          onClear={() => {
            this.setState({
              invoiceListArr:
                invoiceListArrCopy.length > 0 ? invoiceListArrCopy : [],
              selectedRange: [null, null],
            });
          }}
          onChange={(val) => {
            const oldArray =
              invoiceListArrCopy && invoiceListArrCopy.length > 0
                ? invoiceListArrCopy
                : [];

            const dateFrom =
              val && val[0]
                ? moment(val[0]).format("MMM D YYYY")
                : moment(new Date()).format("MMM D YYYY");

            const dateTo =
              val && val[1]
                ? moment(val[1]).format("MMM D YYYY")
                : moment(new Date()).format("MMM D YYYY");
            const newArr = oldArray.filter((singleObj) => {
              const createdAt = moment(singleObj.invoice_date).format(
                "MMM D YYYY"
              );

              return (
                createdAt === dateFrom ||
                createdAt === dateTo ||
                (new Date(createdAt) > new Date(dateFrom) &&
                  new Date(dateTo) > new Date(createdAt))
              );
            });
            this.setState({
              invoiceListArr: newArr.length > 0 ? newArr : [],
              selectedRange: val,
            });
          }}
        />
      );
    };
    var labelField;

    labelField = "name";
    const setData = (singleDispatch) => {
      console.log(singleDispatch, "singleDispatch");
      const unit =
        singleDispatch.purchase_order_details &&
        singleDispatch.purchase_order_details.unit &&
        singleDispatch.purchase_order_details.unit;
      singleDispatch["Dispatch date"] = moment(
        singleDispatch.dispatch_date
      ).format("ddd, MMM Do YYYY");
      // singleDispatch["User Type"] = userRole ? userRole : "-";
      // singleDispatch["Invoice Number"] = singleDispatch.invoice_number
      //   ? singleDispatch.invoice_number
      //   : "-";

      // singleDispatch["Invoice Weight"] = singleDispatch.invoice_weight
      //   ? `${Number(singleDispatch.invoice_weight).toFixed(2)}`
      //   : "-";
      singleDispatch["Miller Advance"] =
        userRole === "admin" || userRole === "miller"
          ? singleDispatch.miller_advance
            ? `Rs ${Number(singleDispatch.miller_advance).toFixed(2)}`
            : "-"
          : "";

      singleDispatch["Miller Bill Amount"] = singleDispatch.miller_bill_amount
        ? `Rs ${Number(singleDispatch.miller_bill_amount).toFixed(2)}`
        : "-";
      singleDispatch["Miller Bill Weight"] = singleDispatch.miller_bill_weight
        ? `Rs ${Number(singleDispatch.miller_bill_weight).toFixed(2)}`
        : "-";
      singleDispatch["Cash Discount"] = singleDispatch.cash_discount
        ? `Rs ${singleDispatch.cash_discount}`
        : "-";
      singleDispatch["Miller Discount Expiry Date"] = moment(
        singleDispatch.miller_discount_expiry_date
      ).format("ddd, MMM Do YYYY");

      singleDispatch["Miller Discounted Amount"] =
        userRole === "admin" || userRole === "miller"
          ? singleDispatch.miller_discounted_amount
            ? `Rs ${singleDispatch.miller_discounted_amount}`
            : "-"
          : "";
      singleDispatch["Miller Gst Charge"] =
        userRole === "admin" || userRole === "miller"
          ? singleDispatch.miller_gst_charge
            ? `${Number(singleDispatch.miller_gst_charge).toFixed(2)} %`
            : "-"
          : "";

      singleDispatch["Miller TDS"] =
        userRole === "admin" || userRole === "miller"
          ? singleDispatch.miller_tds
            ? `${Number(singleDispatch.miller_tds).toFixed(2)}`
            : "-"
          : "";
      singleDispatch["Miller Total Amount"] =
        userRole === "admin" || userRole === "miller"
          ? singleDispatch.miller_total_amount
            ? `Rs ${Number(singleDispatch.miller_total_amount).toFixed(2)}`
            : "-"
          : "";

      singleDispatch["Bill Rate"] = singleDispatch.bill_rate
        ? `Rs ${Number(singleDispatch.bill_rate).toFixed(2)}`
        : "-";
      singleDispatch["Brokerage Amount For Bri"] =
        userRole === "admin" || userRole === "broker"
          ? singleDispatch.brokerage_amount_for_bri
            ? `Rs ${Number(singleDispatch.brokerage_amount_for_bri).toFixed(2)}`
            : "-"
          : "";

      singleDispatch["Brokerage Amount For Miller"] =
        singleDispatch.brokerage_amount_for_miller
          ? `Rs ${Number(singleDispatch.brokerage_amount_for_miller).toFixed(
              2
            )}`
          : "-";
      singleDispatch["Brokerage Rate For Bri"] =
        userRole === "admin" || userRole === "broker"
          ? singleDispatch.brokerage_rate_for_bri
            ? `Rs ${Number(singleDispatch.brokerage_rate_for_bri).toFixed(2)}`
            : "-"
          : "";

      singleDispatch["Brokerage Rate For Miller"] =
        singleDispatch.brokerage_rate_for_miller
          ? `Rs ${Number(singleDispatch.brokerage_rate_for_miller).toFixed(2)}`
          : "-";
      singleDispatch["Unloading Bags Number"] =
        userRole === "admin" || userRole === "broker"
          ? singleDispatch.unloading_bags_number
            ? singleDispatch.unloading_bags_number
            : "-"
          : "";

      singleDispatch["Final Rate"] =
        userRole === "admin" || userRole === "miller"
          ? singleDispatch.final_rate
            ? `Rs ${Number(singleDispatch.final_rate).toFixed(2)}`
            : "-"
          : "";
      singleDispatch["Final Weight"] =
        userRole === "admin" || userRole === "miller"
          ? singleDispatch.final_weight
            ? `Rs ${Number(singleDispatch.final_weight).toFixed(2)}`
            : "-"
          : "";

      // singleDispatch["Bill Amount"] = singleDispatch.miller_bill_amount
      //   ? `Rs ${Number(singleDispatch.miller_bill_amount).toFixed(2)}`
      //   : "-";

      // singleDispatch["Bill Number"] =
      //   singleDispatch.miller_bill_number &&
      //   singleDispatch.miller_bill_number.length > 0
      //     ? singleDispatch.miller_bill_number
      //     : "-";
      // singleDispatch["Bags qty"] =
      //   singleDispatch.subList &&
      //   singleDispatch.subList.length > 0 &&
      //   singleDispatch.subList.reduce(
      //     (previousValue, currentValue) =>
      //       Number(previousValue) +
      //       Number(
      //         currentValue.dispatch_packaging &&
      //           currentValue.dispatch_packaging.length > 0
      //           ? currentValue.dispatch_packaging.reduce(
      //               (previousValue1, currentValue1) =>
      //                 Number(previousValue1) +
      //                 (currentValue1.total_bags_dispatched
      //                   ? Number(currentValue1.total_bags_dispatched)
      //                   : 0),
      //               0
      //             )
      //           : 0
      //       ),
      //     0
      //   );
      // singleDispatch["Tcs"] =
      //   singleDispatch.miller_tcs && singleDispatch.miller_tcs.length > 0
      //     ? `${Number(singleDispatch.miller_tcs).toFixed(2)} %`
      //     : "-";
      // singleDispatch["Dispatch qty"] = singleDispatch.dispatch_weight
      //   ? `${Number(
      //       singleDispatch.dispatch_weight ? singleDispatch.dispatch_weight : 0
      //     ).toFixed(3)} ${unit}`
      //   : "-";
      // singleDispatch["Advance"] = singleDispatch.miller_advance
      //   ? `Rs ${Number(singleDispatch.miller_advance).toFixed(2)}`
      //   : "-";
      // singleDispatch["Total Amount"] =
      //   singleDispatch.miller_total_amount &&
      //   singleDispatch.miller_total_amount.length > 0
      //     ? `Rs ${Number(singleDispatch.miller_total_amount).toFixed(2)}`
      //     : "-";
      // singleDispatch["totalamount"] =
      //   singleDispatch.miller_total_amount &&
      //   singleDispatch.miller_total_amount.length > 0
      //     ? Number(singleDispatch.miller_total_amount).toFixed(2)
      //     : "-";
      // singleDispatch["Dispatch Status"] = singleDispatch.dispatch_status
      //   ? singleDispatch.dispatch_status
      //   : "-";
      // singleDispatch["Status"] = singleDispatch.dispatch_status
      //   ? singleDispatch.dispatch_status.charAt(0).toUpperCase() +
      //     singleDispatch.dispatch_status.slice(1)
      //   : "-";
      console.log(userRole, "userRole");

      singleDispatch["Action"] = "vvv";
      singleDispatch["ActionUI"] =
        userRole === "admin" ||
        userRole === "accountant" ||
        userRole === "manager" ? (
          <div>
            <StyledButton
              name={"Payment"}
              background={primary}
              color={white}
              onClick={() => {
                this.setState({
                  openStatusDialog: true,
                  loader: false,
                  selectedDispatch: singleDispatch,
                });
              }}
            />
          </div>
        ) : (
          ""
        );
      singleDispatch["subList"] = singleDispatch.subList
        ? singleDispatch.subList.map((singleSub) => {
            singleSub["Date"] = moment(singleSub.payment_datetime).format(
              "ddd, MMM Do YYYY"
            );
            singleSub["Payment Amount"] = singleSub.payment_amount ? `${Number(singleSub.payment_amount).toFixed(2)}`:"";
            singleSub["Payment Status"] = singleSub.payment_status;
            singleSub["Recorded By"] = singleSub.recorded_by;
            singleSub["Verified By"] = singleSub.verified_by;
            singleSub["Action"] = "rfrfgrgfrtgtr5g";
            singleSub["ActionUI"] = (
              // <div>
              //   <StyledButton
              //     name={"Download PDF"}
              //     background={white}
              //     padding={"2px 6px"}
              //     fontSize={"0.8rem"}
              //     border={"1px solid rgb(241, 154, 48)"}
              //     boxShadow={"none"}
              //     // color={white}
              //     onClick={() => {
              //       this.setState({
              //         downloadInvoicePdf: true,
              //         selectedDispatch: singleDispatch,
              //         selectedPartialPayment: singleSub,
              //       });
              //     }}
              //   />
              // </div>
              <div>
                {/* <StyledButton
                  name={"Download PDF"}
                  background={white}
                  padding={"2px 6px"}
                  fontSize={"0.8rem"}
                  border={"1px solid rgb(241, 154, 48)"}
                  boxShadow={"none"}
                  // color={white}
                  onClick={() => {
                    this.setState({
                      downloadInvoicePdf: true,
                      selectedDispatch: singleDispatch,
                      selectedPartialPayment: singleSub,
                    });
                  }}
                /> */}
                 <div
              onClick={(e) => {
                e.stopPropagation();
                this.setState({anchorEl:e.currentTarget,popper:{open:true,data:singleSub,parent:singleDispatch}})
              }}
              className="d-flex alignC justifyC"
              style={{
                margin: "12px 10px",
                width: "50px",
              }}>
              <MoreHorizOutlined />
            </div>
              </div>
            );

            //filterData(
            //   BrandList,
            //   "id",
            //   singleSub.brand,
            //   "onlyOne"
            // )
            //   ? filterData(BrandList, "id", singleSub.brand, "onlyOne")
            //       .brand_name
            //   : "";

            // singleSub["Item Category"] = filterData(
            //   ItemCateoryList,
            //   "id",
            //   singleSub.item_category,
            //   "onlyOne"
            // )
            //   ? filterData(
            //       ItemCateoryList,
            //       "id",
            //       singleSub.item_category,
            //       "onlyOne"
            //     ).name
            //   : "";
            // singleSub["Item Type"] = filterData(
            //   ItemList,
            //   "id",
            //   singleSub.item_type,
            //   "onlyOne"
            // )
            //   ? `${Number(
            //       filterData(ItemList, "id", singleSub.item_type, "onlyOne")
            //         ? filterData(ItemList, "id", singleSub.item_type, "onlyOne")
            //             .broken_percentage
            //         : 0
            //     ).toFixed(2)} %`
            //   : "";
            // singleSub["Packaging"] =
            //   singleSub.dispatch_packaging &&
            //   singleSub.dispatch_packaging.length > 0
            //     ? `  ${
            //         singleSub.dispatch_packaging.map((sP) => {
            //           const singlePack = sP.packaging_details;

            //           return singlePack
            //             ? `${singlePack.size}${singlePack.unit}${" - "}${
            //                 singlePack.bag_type
            //               }`
            //             : "";
            //         })[0]
            //       } Total (...s)`
            //     : "-";
            // singleSub["PackagingUI"] = (
            //   <ShowPackaging
            //     noTotalbags={true}
            //     details={singleSub.dispatch_packaging.map((sP) => {
            //       const singlePack = sP.packaging_details;
            //       singlePack.used_bags = sP.total_bags_dispatched;

            //       return singlePack;
            //     })}
            //   />
            // );
            // singleSub["PackagingUIPdf"] =
            //   singleSub.dispatch_packaging &&
            //   singleSub.dispatch_packaging.length > 0
            //     ? singleSub.dispatch_packaging
            //         .map((sP) => {
            //           const singlePack = sP.packaging_details;

            //           return singlePack
            //             ? `${singlePack.size}${singlePack.unit}${" - "}${
            //                 singlePack.bag_type
            //               }`
            //             : "";
            //         })
            //         .join(", ")
            //     : "-";
            return singleSub;
          })
        : [];
      return singleDispatch;
    };
    const pdfFormat = (data) => {
      return {
        // "Payment Datetime":"efer",
        // "Invoice Number":"efer",
        "Payment Status": data.payment_status,
        "Whose Payment": data.whose_payment ?? "",
        "Final Amount": data.final_amount,
        "Payment Amount": data.payment_amount,
        "Unpaid Amount": data.unpaid_amount,
        "Recorded By": data.recorded_by,
        "Verified By": data.verified_by,
        ...data,
      };
    };
    return (
      <Layout
        loading={loading[EndPoints["Brokers"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Brokers`,
          labelField: labelField,
        }}
        listClickHandler={this.listClickHandler}
        fabClickHandler={(value) => {
          localStorage.removeItem("fetchagain-bri");
          this.setState({
            openDialog: true,
            loadingFun: false,
            editList: false,
          });
        }}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px">
        {openDialog === true ? (
          <CreateDialog
            textFieldWidth="calc(50% - 20px)"
            heightNew="500px"
            maxHeight="calc(100vh - 40px)"
            widthNew="900px"
            noAddAnother={true}
            onEditUpdate={(res) => {
              this.onEditUpdate(res);
            }}
            // addFieldsToAdd={[{ name: "is_active", value: true }]}
            module={"Brokers"}
            selectedList={editList ? editList : false}
            editList={editList ? editList : false}
            handleClose={() => {
              this.setState({
                openDialog: false,
                loadingFun: false,
                editList: true,
              });
            }}
            postApi="masters/broker/list"
            editApi="masters/broker"
            inputArray={[
              {
                name: "Active",
                type: "toggle",
                defaultValue: {
                  value: editList ? editList.is_active : true,
                },
                api: "is_active",
              },
              {
                name: "name",
                type: "textField",
                dataType: "text",
                api: "name",
                defaultValue: {
                  value: editList && editList.name,
                },
              },
              {
                name: "phone number",
                type: "textField",
                dataType: "number",
                onInput: "phone_number",
                only_number: true,
                not_required: true,
                api: "phone",
                defaultValue: {
                  value: editList && editList.phone,
                },
              },
              {
                name: "address",
                type: "addressTextField",
                dataType: "text",
                api: "address",
                defaultValue: {
                  value: editList && editList.address,
                },
              },
              {
                name: "GST IN",
                not_required: true,
                type: "textField",
                dataType: "text",
                onKeyPress: "alphaNum",
                onInput: "gst_in",
                api: "gstin",
                defaultValue: {
                  value: editList && editList.gstin,
                },
                helperText: "ex: 22AAAAA0000A1Z5",
              },
              {
                name: "contact person",
                type: "textField",
                dataType: "text",
                api: "contact_person",
                defaultValue: {
                  value: editList && editList.contact_person,
                },
              },

              {
                name: "state",
                type: "select",
                array: stateArray,
                value: "name",
                api: "state",
                defaultValue: {
                  value: editList && editList.state,
                },
              },
              {
                name: "city",
                type: "textField",
                dataType: "text",
                api: "city",
                onKeyPress: "textOnly",
                defaultValue: {
                  value: editList && editList.city,
                },
              },

              {
                name: "pincode",
                type: "textField",
                onInput: "pincode",
                dataType: "number",
                api: "pincode",
                only_number: true,
                defaultValue: {
                  value: editList && editList.pincode,
                },
              },
            ]}
            postData={this.props.BrokersFunction}
            fetchAgain={() => {
              this.props.BrokersFunction("get", EndPoints["Brokers"]);
            }}
          />
        ) : (
          ""
        )}


<Popover
          open={this.state.popper.open}
          anchorEl={this.state.anchorEl}
          style={{ marginTop: "-2.5%" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}>
          <ClickAwayListener
            onClickAway={() => {
              this.setState({popper:{open:false,anchorEl:null}})
            }}>
            <List> 
                <ListItem
                   
                    button
                    onClick={() => {
                      this.setState({
                        openStatusDialog: true,
                        loader: false,
                       Status: userRole === "admin" ? "PAID" : "PENDING",
                        selectedDispatch: this.state.popper.parent,
                        Status: this.state.popper.data.payment_status,
                        searchedData:this.state.popper.data.payment_datetime,
                        PaymentAmount:this.state.popper.data.payment_amount,
                        selectedPartialPayment: this.state.popper.data,
                      });
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "0 0 0 0",
                      padding: "10px",
                    }}>
                    <p
                      className="d-flex alignC textC"
                      style={{
                        margin: "0",
                        fontSize: "0.9rem",
                        height: "24px",
                      }}>
                    Edit
                    </p>
                  </ListItem>
                  {this.state.popper&&this.state.popper.data&&this.state.popper.data.payment_status!=="PENDING"&&  <ListItem
                    button
                    onClick={() => {
                      this.setState({
                        downloadInvoicePdf: true,
                        selectedDispatch: this.state.popper.parent,
                        selectedPartialPayment: this.state.popper.data,
                      });
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "0 0 0 0",
                      padding: "10px",
                    }}>
                    <p
                      className="d-flex alignC textC"
                      style={{
                        margin: "0",
                        fontSize: "0.9rem",
                        height: "24px",
                      }}>
                     Download Pdf
                    </p>
                  </ListItem>
  }
            </List>
          </ClickAwayListener>
        </Popover>
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {openStatusDialog === true && (
            <CssDialog
              height={showInvoice ? "calc(100% - 63px)" : "420px"}
              width={showInvoice ? "calc(100% - 150px)" : "420px"}
              noReset={true}
              header={
                // showInvoice !== false
                //   ? "Invoice"
                //   : this.state.selectedListArray[0] &&
                //     this.state.selectedListArray[0].transporter_payment ===
                //       "PENDING"
                //   ? "Approve Payment"
                // :
                "Broker Payment"
              }
              onClose={() => {
                this.setState({
                  openStatusDialog: false,
                  editDialog: false,
                  added: false,
                  selectedListArray: false,
                  showInvoice: false,
                });
              }}
              children={() => {
                return (
                  <div className="d-flex justifyC alignC width-100 height-100 fontSize1 ">

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="d-flex justifyFE width-100">
                          <RadioButton
                            array={["Miller", "BRI"]}
                            selected={selctedTYpeOfSO ? selctedTYpeOfSO : "BRI"}
                            onChange={(type) => {
                              this.setState({ selctedTYpeOfSO: type });
                            }}
                          />
                        </div>
                        <MuiPickersUtilsProvider
                          libInstance={moment()}
                          utils={DateFnsUtils}>
                          {labelText("Date")}
                          <CssKeyboardDatePicker
                            variant="inline"
                            onOpen={() => {
                              this.setState({ calenderOpen: true });
                            }}
                            onClose={() => {
                              this.setState({ calenderOpen: false });
                            }}
                            maxDate={new Date()}
                            disableFuture
                            error={false}
                            invalidLabel=""
                            onKeyPress={(ev) => {
                              ev.preventDefault();
                            }}
                            onKeyDown={(evt) => {
                              evt.keyCode === 8 && evt.preventDefault();
                            }}
                            allowKeyboardControl={false}
                            keyboardIcon={
                              calenderOpen ? <TopArrow /> : <BottomArrow />
                            }
                            autoOk
                            inputVariant="outlined"
                            style={
                              { width: "100%" } //disabled={tp}
                            }
                            format="do MMM, yyyy"
                            value={searchedData ? searchedData : new Date()}
                            //views={['year', 'month']}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={(newValue) => {
                              this.setState({ searchedData: newValue });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {labelText("Payment Amount")}
                        <CssTextField
                          required={false}
                          type="number"
                          autoComplete="off"
                          style={
                            { width: "100%" } //disabled={tp}
                          }
                          id="outlined-basic"
                          // label="Brokerage Amount"
                          variant="outlined"
                          name="Rate"
                          value={
                            this.state.PaymentAmount
                              ? this.state.PaymentAmount
                              : ""
                          }
                          onChange={(e) => {
                            this.setState({ PaymentAmount: e.target.value });
                          }}
                        />

                        {labelText("Status")}
                        <CssTextField
                          id="broker"
                          required={true}
                          autoComplete="off"
                          value={Status}
                          onChange={userRole === "admin" &&this.state.selectedPartialPayment?(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Status = false;
                            this.setState({
                              Status: e.target.value,
                            });
                          }:()=>{}
                        }
                          disabled={userRole === "admin" &&this.state.selectedPartialPayment?(this.state.selectedPartialPayment.recorded_by.includes(`${user.first_name} ${user.last_name}`))?true:false:true}
                         
                          
                          style={{
                            width: "100%",
                          }}
                          select
                          //     label="Broker"
                          variant="outlined"
                          InputLabelProps={{}}>
                          {StatusList &&
                            StatusList &&
                            StatusList.map((option) => (
                              <MenuItem
                                key={option.status.toString()}
                                value={option.status}
                                id={option.id}>
                                {option.status}
                              </MenuItem>
                            ))}
                        </CssTextField>
                      </div>
                    
                  </div>
                );
              }}
              bottomBarContent={() => {
                return showInvoice ? (
                  <>
                    <StyledButton
                      name="Download pdf"
                      width="150px"
                      padding="2px 5px"
                      onClick={() => {
                        this.setState({
                          downloadInvoicePdf: true,
                          loader: true,
                        });
                      }}
                    />
                  </>
                ) : (
                  <>
                    <StyledButton
                      padding="2px 5px"
                      onClick={() => {
                        this.setState({
                          openStatusDialog: false,
                          editDialog: false,
                          added: false,
                          selectedListArray: false,
                        });
                      }}
                      name="Cancel"
                    />
                    <StyledButton
                      onClick={() => {
                        if (this.state.PaymentAmount) {
                          this.setState({ loader: true });
                          //  this.state.PaymentAmount
                          // this.state.searchedData
                          const partialpayment = {
                            payment_datetime: moment(
                              this.state.searchedData
                            ).format("YYYY-MM-DD"),
                            payment_amount: this.state.PaymentAmount,
                            payment_status:
                              userRole === "admin" ? "PAID" : "PENDING",
                            whose_payment: "BROKER",
                            // "brokerage_of": "string",
                            [`brokerage_of`]:
                              selctedTYpeOfSO === "Miller" ? "MILLER" : "ADMIN",
                            miller_account: this.state.selectedDispatch.id,
                          };
                          console.log(
                            this.state.selectedDispatch,
                            partialpayment,
                            "selectedDispatch"
                          );
                          this.props
                            .BrokersFunction(
                              "post",
                              "arrival/partial-payment/list",
                              null,
                              "invoiceUpdate",
                              partialpayment
                            )
                            .then((invoiceId) => {
                              if (invoiceId) {
                                this.fetchDispatchList("miller_account");
                                this.setState({
                                  loader: false,
                                  showInvoice: invoiceId,
                                });
                              }
                            });
                        }
                      }}
                      name="Submit"
                      withBg={true}
                      padding="2px 5px"
                    />
                  </>
                );
              }}
              loadingDialog={loader}
            />
          )}
          {downloadInvoicePdf === true ? (
            <InvoiceCardUi
              onlyShow={false}
              setLoader={() => {
                this.setState({ loader: false });
              }}
              onDownload={() => {
                this.setState({
                  openStatusDialog: false,
                  editDialog: false,
                  added: false,
                  selectedListArray: false,
                  showInvoice: false,
                  loader: false,
                  selectedDispatch: false,
                  selectedPartialPayment: false,
                  downloadInvoicePdf: false,
                  broken_amount: "",
                });
              }}
              onlyDownload={true}
              singlelistView={singlelistView}
              module="miller"
              pdfHeader={[
                // "Sr.No.",

                // "Payment Datetime",
                // "Invoice Number",
                "Payment Status",
                "Whose Payment",
                "Final Amount",
                "Payment Amount",
                "Unpaid Amount",
                "Recorded By",
                "Verified By",
              ]}
              header={[
                // "Sr.No.",
                // "Payment Datetime",
                // "Invoice Number",
                "Payment Status",
                "Whose Payment",
                "Final Amount",
                "Payment Amount",
                "Unpaid Amount",
                "Recorded By",
                "Verified By",
              ]}
              setData={pdfFormat}
              loader={loader ? loader : false}
              partial_payment={{
                ...this.state.selectedPartialPayment,
                account_detail: this.state.selectedDispatch,
              }}
            />
          ) : (
            ""
          )}
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Brokers === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <TabComponent
              onChange={(newVal) => {
                const selctedTab =
                  newVal === 0
                    ? "miller_account"
                    : newVal === 3 && "Miller Details";
                this.setState({ selctedTab }, () => {
                  if (newVal === 0) {
                    this.fetchDispatchList("miller_account");
                  }
                });
              }}
              list={[
                {
                  name: "Broker Accounts",
                  component: (
                    <TableComponent
                      loader={loader ? loader : false}
                      subMenu={true}
                      // filterChild={() => {
                      //   return filterChild();
                      // }}
                      noAddButton={true}
                      module={"Dispatch"}
                      {...this.state}
                      header={[
                        "Sr.No.",
                        userRole === "admin"| userRole === "manager"||userRole === "accountant" ? "Action" : false,
                        "Dispatch date",
                        // "User Type",
                        // "Invoice Number",
                        // "Invoice Weight",

                        userRole === "admin" || userRole === "miller"
                          ? "Miller Advance"
                          : false,

                        userRole === "admin" || userRole === "miller"
                          ? "Miller Bill Amount"
                          : false,

                        userRole === "admin" || userRole === "miller"
                          ? "Miller Bill Weight"
                          : false,
                        "Cash Discount",
                        userRole === "admin" || userRole === "miller"
                          ? "Miller Discount Expiry Date"
                          : false,

                        userRole === "admin" || userRole === "miller"
                          ? "Miller Discounted Amount"
                          : false,

                        userRole === "admin" || userRole === "miller"
                          ? "Miller Gst Charge"
                          : false,

                        userRole === "admin" || userRole === "miller"
                          ? "Miller TDS"
                          : false,
                        userRole === "admin" ? "Miller Total Amount" : false,

                        "Bill Rate",
                        userRole === "admin" || userRole === "broker"
                          ? "Brokerage Amount For Bri"
                          : false,

                        "Brokerage Amount For Miller",

                        //  "Brokerage Amount From Bri",

                        // "Brokerage Amount From Miller",

                        userRole === "admin" || userRole === "broker"
                          ? "Brokerage Rate For Bri"
                          : false,

                        "Brokerage Rate For Miller",

                        userRole === "admin" || userRole === "miller"
                          ? "Unloading Bags Number"
                          : false,
                        userRole === "admin" || userRole === "miller"
                          ? "Final Rate"
                          : false,

                        userRole === "admin" || userRole === "miller"
                          ? "Final Weight"
                          : false,
                      ].filter((s) => s !== false)}
                      subMenuheader={[
                        "Sr.No.",
                        "Date",
                        "Miller Amount",
                        "Payment Status",
                        "Recorded By",
                        "Verified By",
                        "Action",
                      ]}
                      actions={[]}
                      {...this.props}
                      tableList={
                        dispatchList && dispatchList.length
                          ? dispatchList
                              .sort((a, b) => {
                                var dateB = moment(b.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                var dateA = moment(a.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                return new Date(dateB) - new Date(dateA);
                              })
                              .map((singleDispatch) => {
                                return setData(singleDispatch);
                              })
                          : []
                      }
                    />
                  ),
                },
                {
                  name: "Broker Details",
                  component: (
                    <Paper
                      className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}>
                      <div className="width-100 d-flex justifyFE">
                        {widget_permission?.widget?.has_update_permission && (
                          <Tooltip title="Edit">
                            <EditIcon
                              onClick={() => {
                                this.setState({
                                  openDialog: true,
                                  editList: singlelistView,
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </Tooltip>
                        )}
                      </div>

                      <div
                        className="overflowYA scrollBluePrimary"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          padding: "0 50px",
                          height: "calc(100% - 31.7px)",
                          textTransform: "capitalize",
                        }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            Name:{" "}
                          </p>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              textTransform: "capitalize",
                              wordBreak: "break-all",
                            }}>
                            {singlelistView.name}
                          </p>
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            Address:
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}>
                            {singlelistView.address}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            City:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}>
                            {singlelistView.city}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            State:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}>
                            {singlelistView.state}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            GSTIN:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}>
                            {singlelistView.gstin}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            Phone:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}>
                            {singlelistView.phone}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            Contact Person:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}>
                            {singlelistView.contact_person}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            Pincode:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}>
                            {singlelistView.pincode}
                          </p>
                        </div>
                      </div>
                    </Paper>
                  ),
                },
              ]}
            />
          )}
        </Paper>
      </Layout>
    );
  }
}

export default BrokersComponent;
