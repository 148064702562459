import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import { moduleURL } from "../../../http/config";
import { requests } from "../../../requests";
import FormControlLabel from "@mui/material/FormControlLabel";

const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: "15px",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  textField: {
    width: "40%",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
};

const NewForm = ({ getFn, closePopup }) => {
  const [fieldValues, setFieldValues] = useState({
    contractTypeInput: "",
    statusInput: true,
  });

  const [isValid, setIsValid] = useState({
    contractTypeInput: true,
  });

  const [pushNoti, setPushNoti] = useState(false);

  const addNewHandler = (e) => {
    e.preventDefault();
    if (fieldValues.contractTypeInput.length === 0) {
      setIsValid((prev) => ({ ...prev, contractTypeInput: false }));
    }
    if (fieldValues.contractTypeInput.length > 0) {
      const postURL = moduleURL("masters/contract-type/list");
      requests({
        method: "post",
        url: postURL,
        data: {
          contract_type: fieldValues.contractTypeInput,
          is_active: fieldValues.statusInput,
          // created_by: 0,
          // modified_by: 0,
        },
      }).then((response) => {
        setPushNoti(true);
        getFn();
      });
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={addNewHandler}>
        <div style={classes.formGroup}>
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
            label="Contract Type"
            value={fieldValues.contractTypeInput}
            onChange={({ target }) => {
              if (fieldValues.contractTypeInput.length > 0) {
                setIsValid((prev) => ({ ...prev, contractTypeInput: true }));
              }
              setFieldValues((prev) => ({
                ...prev,
                contractTypeInput: target.value,
              }));
            }}
            error={!isValid.contractTypeInput}
            helperText={isValid.contractTypeInput ? "" : "Enter Contract Type"}
          />
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "18px" }}>Active</p>
            <Switch
              disabled
              defaultChecked
              onChange={(e) => {
                if (e.target.checked) {
                  setFieldValues((prev) => ({
                    ...prev,
                    statusInput: true,
                  }));
                } else {
                  setFieldValues((prev) => ({
                    ...prev,
                    statusInput: false,
                  }));
                }
              }}
            />
          </div> */}
        </div>
        <div style={classes.formActions}>
          <FormControlLabel
            control={
              <Switch
                disabled
                defaultChecked
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: true,
                    }));
                  } else {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: false,
                    }));
                  }
                }}
              />
            }
            label={fieldValues.statusInput ? "Active" : "Inactive"}
          />
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "rgb(241 154 48)", color: "white" }}>
            Add
          </Button>
        </div>
      </form>
      <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setPushNoti(false);
          // setOpenPopup(false);
          closePopup();
        }}>
        <Alert severity="success">New Contract Added!</Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
    </div>
  );
};

export default NewForm;
