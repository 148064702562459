import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SalesOrdersComponent from "./SalesOrdersComponent";
import { SalesOrdersFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";
import { useLocalStorage } from "../PurchaseOrders/useLocalStorage";
function SalesOrdersContainer(props) {
  const [masters, setMasters] = useState({});

  const [loader, setLoader] = useState(true);

  const [reload, setreload] = useState();
  // const fetchAgain=fetchAgain?localStorage.getItem("fetchagain-bri"):undefined
  const fetchAgain = useLocalStorage("fetchagain-bri");
  useEffect(() => {
    fetchUsers();
    fetchAllData();
  }, []);
  useEffect(() => {
    const handleChange = () => {
      if (fetchAgain !== undefined) {
        if(fetchAgain==="shipping-term"){
        fetchShippingTerm();
        }if(fetchAgain==="inco-term"){
          // client
          fetchIncoTerm()
       
        }else if(fetchAgain==="client")
          fetchClients()
          else if(fetchAgain==="so-payment-term")
          fetchPaymentTerm()
        
     
      }
    };

    window.addEventListener("storage", handleChange);

    return () => {
      window.removeEventListener("storage", handleChange);
    };
  }, []);
  console.log(fetchAgain, reload, "fetchfetchAgainAgainreload 0");
  const fetchClients=()=>{
    props
    .SalesOrdersFunction(
      "get",
      "masters/clients/list",
      null,
      "ClientList",
      null,
      { is_active: "True" }
    )
    .then((ClientList) => {
      if (ClientList) {
      
      const newMasters = masters;
      newMasters.ClientList = ClientList;
      setMasters((p)=>{return{...masters,...newMasters}});
    }
    });
   }
  useEffect(() => {
    console.log(fetchAgain, reload, "fetchfetchAgainAgainreload");
    if (fetchAgain !== undefined || reload === true) {
      console.log(fetchAgain, reload, "fetchfetchAgainAgainreload 1");

      fetchAllData();
      setreload(false);
      localStorage.removeItem("fetchagain-bri");
    }
  }, [fetchAgain, reload]);

  // useEffect(() => {
  //   fetchUsers();
  //   fetchAllData();
  // }, []);
  const fetchShippingTerm = () => {
    props
      .SalesOrdersFunction(
        "get",
        "masters/shipping-term/list",
        null,
        //state.singlelistView.id,
        "ShippingTerms",
        null,
        { is_active: "True" }
      )
      .then((ShippingTerms) => {   setreload(false);
        localStorage.removeItem("fetchagain-bri");
        if (ShippingTerms) {console.log(fetchAgain, reload, "fetchfetchAgainAgainreload 1");

      
          const newMasters = masters;
          newMasters.ShippingTerms = ShippingTerms;
          setMasters((p)=>{return{...masters,ShippingTerms:ShippingTerms}});
        }
      });
  };

  const fetchIncoTerm = () => {

        props
      .SalesOrdersFunction(
        "get",
        "masters/inco-term/list",
        null,
        //state.singlelistView.id,
        "Incoterms",
        null,
        { is_active: "True" }
      )
      .then((Incoterms) => {
        setreload(false);
        localStorage.removeItem("fetchagain-bri");
        if (Incoterms) {
          const newMasters = masters;
          newMasters.Incoterms = Incoterms;
          setMasters(newMasters);
        }
      });
  
  };


  const fetchPaymentTerm = () => {

  
  props
  .SalesOrdersFunction(
    "get",
    "masters/so-payment-term/list",
    null,
    //state.singlelistView.id,
    "SoPaymentList",
    null,
    { is_active: "True" }
  )
  .then((SoPaymentList) => {
    setreload(false);
    localStorage.removeItem("fetchagain-bri");
    if (SoPaymentList) {
      const newMasters = masters;
      newMasters.SoPaymentList = SoPaymentList;
      setMasters(newMasters);
    }
  });

};
 
  const fetchAllData = () => {
    // setLoader(true);
    //
    console.log(fetchAgain, reload, "fetchfetchAgainAgainreload 1");
    props
      .SalesOrdersFunction(
        "get",
        "masters/brand/list",
        null,
        //state.singlelistView.id,
        "BrandList",
        null,
        { is_active: "True" ,is_group:"False"}
      )
      .then((BrandList) => {
        if (BrandList) {
          const newMasters = masters;
          newMasters.BrandList = BrandList;
          setMasters(newMasters);
        }
      });

    props
      .SalesOrdersFunction(
        "get",
        "masters/hsn/list",
        null,
        //state.singlelistView.id,
        "HSNlist",
        null,
        { is_active: "True" }
      )
      .then((HSNlist) => {
        if (HSNlist) {
          const newMasters = masters;
          newMasters.HSNlist = HSNlist;
          setMasters(newMasters);
        }
      });
   
   fetchClients()
   fetchIncoTerm()
   fetchPaymentTerm()
    props
      .SalesOrdersFunction(
        "get",
        "masters/item-category/list",
        null,
        //state.singlelistView.id,
        "ItemCateoryList",
        null,
        { is_active: "True" }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          const newMasters = masters;
          newMasters.ItemCateoryList = ItemCateoryList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/item-type/list",
        null,
        //state.singlelistView.id,
        "ItemList",
        null,
        { is_active: "True" }
      )
      .then((ItemList) => {
        if (ItemList) {
          const newMasters = masters;
          newMasters.ItemList = ItemList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/port/list",
        null,
        //state.singlelistView.id,
        "PortsList",
        null
      )
      .then((PortsList) => {
        if (PortsList) {
          const newMasters = masters;
          newMasters.PortsList = PortsList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/notify/list",
        null,
        //state.singlelistView.id,
        "notifyList",
        null
      )
      .then((notifyList) => {
        if (notifyList) {
          const newMasters = masters;
          newMasters.notifyList = notifyList;
          setMasters(newMasters);
        }
      });

    props
      .SalesOrdersFunction(
        "get",
        "dispatch/sales-order/list",
        null,
        //state.singlelistView.id,
        "SalesOrderList"
      )
      .then((SalesOrderList) => {
        if (SalesOrderList) {
          const newMasters = masters;
          newMasters.SalesOrderList = SalesOrderList;
          setMasters(newMasters);
        }
      });
    // props
    //   .SalesOrdersFunction(
    //     "get",
    //     "masters/inco-term/list",
    //     null,
    //     //state.singlelistView.id,
    //     "Incoterms",
    //     null,
    //     { is_active: "True" }
    //   )
    //   .then((Incoterms) => {
    //     if (Incoterms) {
    //       const newMasters = masters;
    //       newMasters.Incoterms = Incoterms;
    //       setMasters(newMasters);
    //     }
    //   });

    props
      .SalesOrdersFunction(
        "get",
        "masters/currency/list",
        null,
        //state.singlelistView.id,
        "CurrencyList",
        null,
        { is_active: "True" }
      )
      .then((CurrencyList) => {
        if (CurrencyList) {
          const newMasters = masters;
          newMasters.CurrencyList = CurrencyList;
          setMasters(newMasters);
        }
      });
    fetchShippingTerm();
    // props
    //   .SalesOrdersFunction(
    //     "get",
    //     "masters/so-payment-term/list",
    //     null,
    //     //state.singlelistView.id,
    //     "SoPaymentList",
    //     null,
    //     { is_active: "True" }
    //   )
    //   .then((SoPaymentList) => {
    //     if (SoPaymentList) {
    //       const newMasters = masters;
    //       newMasters.SoPaymentList = SoPaymentList;
    //       setMasters(newMasters);
    //     }
    //   });
    props
      .SalesOrdersFunction(
        "get",
        "dispatch/so-packaging/list",
        null,
        //state.singlelistView.id,
        "soPackahging",
        null,
        {
          is_active: "True",
        }
      )
      .then((soPackahging) => {
        if (soPackahging) {
          const newMasters = masters;
          newMasters.soPackahging = soPackahging;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/so-policy/list",
        null,
        //state.singlelistView.id,
        "soPolicyList",
        null,
        {
          is_active: "True",
        }
      )
      .then((soPolicyList) => {
        if (soPolicyList) {
          const newMasters = masters;
          newMasters.soPolicyList = soPolicyList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/payment-terms/list",
        null,
        //state.singlelistView.id,
        "PaymentTermList",
        null,
        { is_active: "True" }
      )
      .then((PaymentTermList) => {
        if (PaymentTermList) {
          const newMasters = masters;
          newMasters.PaymentTermList = PaymentTermList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/benificiary/list",
        null,
        "benificiarysList",
        null,
        { is_active: "True" }
      )
      .then((benificiarysList) => {
        if (benificiarysList) {
          const newMasters = masters;
          newMasters.benificiarysList = benificiarysList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction("get", EndPoints["Shippers"], null, "shippersList")
      .then((shippersList) => {
        if (shippersList) {
          const newMasters = masters;
          newMasters.shippersList = shippersList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/packaging/list",
        null,
        "PackagingList",
        null,
        { is_active: "True" }
      )
      .then((PackagingList) => {
        if (PackagingList) {
          const newMasters = masters;
          newMasters.PackagingList = PackagingList;
          setMasters(newMasters);
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
  };

  const fetchUsers = () => {
    if (props.user.uid) {
      // props.SalesOrdersFunction("get", EndPoints["SalesOrders"]);
    }
  };
  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return (
    <SalesOrdersComponent
      {...props}
      params={params}
      masters={masters}
      loader={loader}
      setreload={setreload}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  SalesOrders: state.modules.SalesOrders,
  loading: state.modules.loading,
  [EndPoints["SalesOrders"]]: state.modules[EndPoints["SalesOrders"]],
  SODispatchedList: state.modules.SODispatchedList,
  SO_Items_List: state.modules.SO_Items_List,
});
const mapDispatchToProps = {
  SalesOrdersFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesOrdersContainer);
