import React, { Component } from "react";
import { green, primary } from "../styles/colors";
import { Download, InfoIcon, ThreeDots } from "../styles/Icons";
import { CardDetail, CssDialog, StyledCircularProgress } from "../styles/StyledComponents";
import { Backdrop, List, Paper, Popover } from "@material-ui/core";

import ClickAwayListener from "@mui/base/ClickAwayListener";
import moment from "moment";
import TableComponent from "./TableComponent";
import InvoicePdfUi from "./InvoicePdfUi";
import SnackbarComponent from "./SnackbarComponent";
export class InvoiceCardUi extends Component {
  constructor(props) {
    super(props);
    this.state = {partial_payment:{} ,account_detail:{}, 
      loader: false,
      open: false,
      anchorEl: null,
      singleInvoiceSelected: false,
      openInvoice: false,
      loadingDialog: false,
      downloadPdf: false,
      fetched: false,
      onlyShow: false,
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props);
  }
  setInitialValues = (props) => {
    const { partial_payment , loader, onlyDownload, onlyShow } = props;
  
   
    
    this.setState(
      {
        partial_payment , 
        loader,
        onlyShow: onlyShow ? onlyShow : false,
        downloadPdf: !onlyShow 
      },
      () => {
        if (onlyDownload && this.state.fetched === false) {
          this.setState(
            {
              singleInvoiceSelected: onlyDownload,
              openInvoice: true,
            },
          );
        } else {
        }
      }
    );
  };
  render() {
    const { partial_payment ,  loader,success,error, openInvoice, singleInvoiceSelected, downloadPdf, loadingDialog } = this.state;
    const { onlyDownload } = this.props;

  console.log(downloadPdf,"onlyShowPfdownloadPdf")
    return (
      <div className={`width-100  d-flex d-flex-column height-100`}>
        {error || success ? (
        <SnackbarComponent
          error={error}
          autoHideDuration={error ? 5000 : 2000}
          success={success}
          snackbarClose={() => {
            this.setState({
              error: "",
              success: "",
            });
          }}
        />
      ) : (
        ""
      )}
        {onlyDownload
          ? ""
          : loadingDialog === true && (
              <Backdrop style={{ zIndex: 1, color: "#fff", position: "absolute" }} open={loadingDialog}>
                <StyledCircularProgress color="inherit" />
              </Backdrop>
            )}

        {downloadPdf === true && loadingDialog === false && (
          <InvoicePdfUi
          partial_payment={partial_payment}
            tableList={
             [{...this.props.setData(partial_payment)}]
            }
            {...this.state}
            {...this.props}
            subMenuheader={[]}
          //    subMenuheader={[ 
          //     // "Sr.No.",
          //   "Date",
          //   "Miller Amount",
          //   "Payment Status",
          // ]}
            onClose={() => {
              if (onlyDownload && this.props.onDownload) {
                this.props.onDownload();
              }
              this.setState(
                {
                  downloadPdf: false,
                  singleInvoiceSelected: false,
                  openInvoice: false,
                  open: false,
                  anchorEl: null,
                  success:"Invoice Downloaded"
                },
                () => {}
              );
            }}
          />
        )}

        {onlyDownload
          ? ""
          : openInvoice &&
            !downloadPdf && (
              <CssDialog
                noReset={true}
                header={`Invoice No. ${singleInvoiceSelected && singleInvoiceSelected.invoice_number} Total ${
                  openInvoice && openInvoice.length > 1 ? "Dispatches" : "Dispatch"
                } ( ${openInvoice && openInvoice.length})`}
                onClose={() => {
                  this.setState({ openInvoice: false });
                }}
                snackbarClose={() => {
                  this.setState({ openInvoice: false });
                }}
                children={() => {
                  return (
                    <div className="d-flex d-flex-column justifyC alignC width-100 height-100 fontSize1 ">
                      {singleInvoiceSelected && singleInvoiceSelected.amount && module === "broker" ? (
                        <Paper
                          className=" backgroundWhite "
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 15px",
                          }}
                        >
                          {" "}
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 ",
                                minWidth: "150px",
                              }}
                            >
                              Brokerage Amount :{" "}
                            </p>
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                              }}
                            >
                              {singleInvoiceSelected.amount}
                            </p>
                          </div>
                        </Paper>
                      ) : (
                        ""
                      )}
                      <TableComponent
                        actions={[]}
                        subMenu={true}
                        noAddButton={true}
                        module={"Dispatch"}
                        filterChild={false}
                        {...this.state}
                        header={
                          this.props.header
                            ? this.props.header
                            : [
                                "Sr.No.",
                                "PO#",
                                "Dispatch date",
                                "From",
                                "To",
                                "Bags qty",
                                "Gross wt",
                                "Dispatch Quantity",
                                "Status",
                              ]
                        }
                        subMenuheader={[ "Sr.No.",
                        "Date",
                        "Miller Amount",
                        "Payment Status",]}
                        {...this.props}
                        tableList={
                          openInvoice && openInvoice.length
                            ? openInvoice
                                // .sort((a, b) => {
                                //   var dateA = moment(b.createdAt).format("MMM D YYYY h:mm:ss A");
                                //   var dateB = moment(a.createdAt).format("MMM D YYYY h:mm:ss A");
                                //   return new Date(dateA) - new Date(dateB);
                                // })
                                .map((singleDispatch) => {
                                  if (this.props.setData) {
                                    return this.props.setData(singleDispatch);
                                  } else {
                                    return singleDispatch;
                                  }
                                })
                            : []
                        }
                        filterChild={false}
                      />
                    </div>
                  );
                }}
                loadingDialog={loadingDialog}
                noActions={true}
              />
            )}
       
      </div>
    );
  }
}

export default InvoiceCardUi;
