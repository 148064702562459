import moment from "moment";
import React, { Component } from "react";
import * as XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { StyledButton, StyledSnackbar } from "../../../styles/StyledComponents";
import "../../../styles/styles.css";
import { Download } from "@mui/icons-material";
export class ExcelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allList: [],
      allListCopy: [],
      loadingDialog: false,
      fileExtension: ".xlsx",
      fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",

      array: [],
      tripHeaderAll: [
        "SR.NO.",
        "GRN",
        "TRUCK NO.",
        "SELLER NAME",
        "BROKER NAME",
        "FINAL OIL %",
        "Party'S AVG. OIL %",
        "Plant'S AVG. OIL %",
        "STATUS",
        "REMARK",
      ],
      tripHeader: [
        "SR.NO.",
        "GRN",
        "TRUCK NO.",
        "SELLER NAME",
        "BROKER NAME",
        "FINAL OIL %",
        "Party'S AVG. OIL %",
        "Plant'S AVG. OIL %",
        "STATUS",
        "REMARK",
      ],
      tripHeaderBran: ["SR.NO.", "Code No.", "Oil %", "REMARK"],
      tripHeaderMaster: [
        "SR.NO.",
        "GRN",
        "Party Name",
        "Broker",
        "Vehicle No.",
        "Quality",
        "Bags",
        "Plant Code",
        "Party Code",
        // "G.R Oil",
        "Final Oil",
        "FFA",
      ],
      sampleHeader: ["", "SR.NO.", "OIL", "FFA", "REMARK", "TESTED AT", "", "sample"],
      excelrows: ["company name", "blank", "blank", "trip header"],
      mergeCR: [
        // { s: { c: 0, r: 0 }, e: { c: 9, r: 1 } }, //for company name
        // { s: { c: 0, r: 2 }, e: { c: 9, r: 2 } }, //blank
      ],
      wsrows: [],
      excelOptions: ["Complete", "Bran Analysis", "Master"],
      selectedExcel: "Complete",
    };
  }
  longest = (key, array) => {
    return Math.max(
      ...array.slice(1, array.length).map((it) => {
        if (it[key] && it[key]) {
          return it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  exportToCSV = (apiData, wscols) => {
    const { fileType, fileExtension, wsrows, fileName, selectedExcel, selectedDate } = this.state;
    const { header } = this.props;
    const ws = XLSX.utils.json_to_sheet(apiData, {
      skipHeader: true,
      origin: "A1",
    });

    ws["!merges"] = this.state.mergeCR;

    ws["!cols"] = wscols;
    // wscols.map((data, i) => {
    //   ws[`${(i + 10).toString(36).toUpperCase()}1`].s = {
    //     font: {
    //       name: "Calibri",
    //       sz: selectedDate === "Complete" ? 30 : 16,
    //       bold: true,
    //       color: {
    //         rgb: "FFFFFF",
    //       },
    //     },
    //     fill: {
    //       fgColor: { rgb: "15133c" },
    //     },
    //     border: {
    //       bottom: {
    //         style: "thin",
    //         color: { rgb: "000000" },
    //       },
    //     },
    //     alignment: {
    //       horizontal: "center",
    //       wrapText: true,
    //       vertical: "center",
    //     },
    //   };
    //   return data;
    // });
    // apiData.slice(1, apiData.length).map((singleData, i) => {
    //   return wscols.map((data, i2) => {
    //     return (ws[`${(i2 + 10).toString(36).toUpperCase()}${i + 2}`].s = {
    //       font: {
    //         name: "Calibri",
    //         // sz:
    //         //   singleData[tripHeader[0]] === "SR.NO." || singleData[tripHeader[1]] === "SR.NO."
    //         //     ? 9
    //         //     : singleData[tripHeader[2]] === "subHead" && 8,
    //         bold: singleData[tripHeader[0]] === "SR.NO." || (singleData[tripHeader[1]] === "SR.NO." && true),
    //         color: {
    //           rgb:
    //             singleData[tripHeader[0]] === "SR.NO." || singleData[tripHeader[1]] === "SR.NO."
    //               ? "FFFFFF"
    //               : // singleData[tripHeader[0]] === "SR.NO." || singleData[tripHeader[1]] === "SR.NO." ? "FFFFFF" :
    //                 "000000",
    //         },
    //       },
    //       fill: {
    //         fgColor:
    //           singleData[tripHeader[7]] === "sample" && (i2 === 0 || i2 > 5)
    //             ? { rgb: "FFFFFF" }
    //             : singleData[tripHeader[2]] === "subHead" && (i2 === 0 || i2 > 5)
    //             ? { rgb: "FFFFFF" }
    //             : singleData[tripHeader[0]] === "SR.NO." || singleData[tripHeader[1]] === "SR.NO."
    //             ? { rgb: "15133c" }
    //             : singleData[tripHeader[1]] !== "blank"
    //             ? { rgb: "e7e7ee" }
    //             : { rgb: "FFFFFF" },
    //       },
    //       border: {
    //         bottom: {
    //           style: "thin",
    //           color: { rgb: "000000" },
    //         },
    //         top: {
    //           style: "thin",
    //           color: { rgb: "000000" },
    //         },
    //         right: {
    //           style: "thin",
    //           color: { rgb: "000000" },
    //         },
    //         left: {
    //           style: "thin",
    //           color: { rgb: "000000" },
    //         },
    //       },

    //       alignment: {
    //         horizontal: singleData[tripHeader[2]] === "subHead" ? "" : "center",
    //         wrapText: true,
    //         vertical: "center",
    //       },
    //     });
    //   });
    // });

    ws["!rows"] = wsrows;
    const name = "OCEAN FRT & OTHER - 2021 - 2022";
    const wb = {
      Sheets: { [name]: ws },
      SheetNames: [name],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ openExcelDialog: false, loadingDialog: false, success: "Xlsx file downloaded successfully!" });
  };
  setInitialValues = (next) => {
    this.setState(
      {
        array: next.array,
        fileName: `OCEAN FRT & OTHER - ${moment(
          next.selectedCustomInvoice && next.selectedCustomInvoice.invoice_date
            ? next.selectedCustomInvoice.invoice_date
            : new Date()
        ).format("ddd, MMM Do YYYY")}`,
      },
      () => {}
    );
  };
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props);
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }

  render() {
    const { success, error, array } = this.state;
    const { header } = this.props;
    return (
      <div className="d-flex justifyFE width-100">
        {error || success ? (
          <StyledSnackbar
            error={error}
            success={success}
            handleCloseSnackbar={() => {
              this.setState({ success: false, error: false });
            }}
          />
        ) : (
          ""
        )}

        <StyledButton
          tooltip="Download Excel"
          name={<Download />}
          maxHeight="35.74px"
          margin="10px"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ loadingDialog: true });

            const wscols = [];
            if (array && array.length > 0) {
              if (array[0] && !Array.isArray(array[0])) {
                const widthList = {};
                const array = header ? header : Object.keys(array[0]);
                array.map((single, i) => {
                  return (widthList[header[i]] = `${Math.floor(
                    2 *
                      (single && single.toString().length > this.longest(single, array)
                        ? single.toString().length
                        : this.longest(single, array)
                        ? this.longest(single, array)
                        : 1)
                  )}`);
                });
                header.map((sH, i) => {
                  wscols.push({ wch: widthList[sH] });
                  return sH;
                });
              }
            }
            this.exportToCSV(array, wscols);
          }}
          width={"43px"}
          minWidth={"43px"}
          withBg={true}
          background="rgb(241 154 48)"
        />
      </div>
    );
  }
}

export default ExcelComponent;
