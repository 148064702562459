import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TabComponent from "../../layoutComponents/TabComponent";
import {
  CssDialog,
  CssTextField,
  CssTextField95,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import TableComponent from "../../layoutComponents/TableComponent";
import CreateDialog from "../../layoutComponents/CreateDialog";
import NoData from "../../styles/Illustrations/NoData";
// import { MenuItem, Paper, Tooltip } from "@material-ui/core";
import { ClickAwayListener, List, ListItem, MenuItem, Paper, Popover, Tooltip } from "@material-ui/core";
import { EndPoints } from "../../utils/EndPoints";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import InvoiceCardUi from "../../layoutComponents/InvoiceCardUi";
import BasicDateRangePicker from "../../layoutComponents/BasicDateRangePicker";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import { stateArray } from "../../Defaults";
import { filterData, getSingleModule_widget } from "../../Regex";
import { accessText } from "../../routes/sidebar";
import { primary, white } from "../../styles/colors";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { BottomArrow, TopArrow } from "../../styles/Icons";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { MoreHorizOutlined } from "@material-ui/icons";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiInputBase-root": { background: "rgb(0 0 0 / 1%)" },
    "&.PickerWithState-root-34 .MuiOutlinedInput-root .MuiOutlinedInput-input":
      { background: "none" },
    // "& .PickerWithState-root-31 .MuiOutlinedInput-root .MuiOutlinedInput-input":{padding:"14.5px 8px"},
    "&.MuiOutlinedInput-adornedEnd": {
      background: "blue",
    },

    "&:hover": {
      border: "none",
    },
    // '& .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    //   padding: '0px 4px 7.5px 6px',
    // },
    // '&.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root': {
    //   padding: '5px',
    // },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: "0",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(9.5px, 9.5px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiInputBase-root.Mui-disabled": { color: "black" },
    "& .MuiFormControl-root": {},

    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input ": {
        caretColor: "transparent",
        background: "white",
        padding: "14.5px 8px",
      },
      "& .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root": {
        padding: "5px",
      },
      "& .MuiInputLabel-outlined": {
        transform: "translate(9.5px, 9.5px) scale(1)",
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75) !important",
      },
    },
  },
})(KeyboardDatePicker);
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "10px 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
export class MillersComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Millers"],
        tooltip: `Click to approve pending ${EndPoints["Millers"]}`,
        data: [],
        route: `/Millers/${EndPoints["Millers"].split("/")[0]}`,
        goBackRoute: "/Millers",
        widget: null,
        primaryField: "name",
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",
        status: false,
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#0AFF00", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.setStateOfPopup.bind(this);
    this.state = {
      tabs: templateTabs,
      Millers: [],
      singlelistView: null,
      listViewArray: [],
      loading: false,
      isPopup: false,
      isNew: false,
      openDialog: false,
      searchedData: new Date(),
      dispatchList: [],
      dispatchListCopy: [],
      loader: false,
      invoiceListArr: [],


      anchorEl:null,
      popper:{open:false},
      invoiceListArrCopy: [],
      openStatusDialog: false,
      selectedListArray: [],
      showInvoice: false,
      calenderOpen: false,
      selectedRange: [null, null],
      selectedContractType: "",
      selectedContractTypeId: "",
      downloadInvoicePdf: false,
      selctedTab: "miller_account",
      StatusList: [
        { status: "UNPAID" },
        { status: "PENDING" },
        { status: "PAID" },
      ],
      PaymentAmount: "",
      Status: "",
      errorMsg: {
        PaymentAmount: false,
        Status: false,
      },
      userRole:
        this.props.user && this.props.user.roles
          ? this.props.user.roles.toString().toLowerCase()
          : "",
    };
  }

  setStateOfPopup = (value) => {
    this.setState({ isPopup: value, isNew: false });
  };

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }

  filterDataAccDate = (dispatchList) => {
    const oldArray =
      dispatchList && dispatchList.length > 0
        ? dispatchList.map((s) => {
            s.contract_type_id = s.purchase_order_details.contract_type_details
              ? s.purchase_order_details.contract_type_details.contract_type
              : "";
            return s;
          })
        : [];
    const newArr =
      this.state.selectedContractType === "All"
        ? oldArray
        : filterData(
            oldArray,
            "contract_type_id",
            this.state.selectedContractType
          )
        ? filterData(
            oldArray,
            "contract_type_id",
            this.state.selectedContractType
          )
        : [];

    return newArr ? newArr : [];
  };
  filterDataAccDate1 = (invoiceList) => {
    const { selectedRange } = this.state;
    const oldArray = invoiceList && invoiceList.length > 0 ? invoiceList : [];

    const dateFrom =
      selectedRange && selectedRange[0]
        ? moment(selectedRange[0]).format("MMM D YYYY")
        : moment(new Date()).format("MMM D YYYY");

    const dateTo =
      selectedRange && selectedRange[1]
        ? moment(selectedRange[1]).format("MMM D YYYY")
        : moment(new Date()).format("MMM D YYYY");
    const newArr = oldArray.filter((singleObj) => {
      const createdAt = moment(singleObj.invoice_date).format("MMM D YYYY");

      return (
        createdAt === dateFrom ||
        createdAt === dateTo ||
        (new Date(createdAt) > new Date(dateFrom) &&
          new Date(dateTo) > new Date(createdAt))
      );
    });
    return newArr ? newArr : [];
  };
  UNSAFE_componentWillMount() {
    this.fetchAllData();
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Millers"] && next.params.id) {
        this.listClickHandler(this.state.tabs[0].data[next.params.id]);
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }
  listClickHandler = (value) => {
    this.singlelistView && this.fetchDispatchList("miller_account");
    const { selctedTab } = this.state;
    this.setState(
      {
        singlelistView: value,
      },

      () => {
        if (this.state.singlelistView && this.state.singlelistView.id) {
          if (selctedTab === "miller_account") {
            this.fetchDispatchList("miller_account");
          }
        }
      }
    );
  };
  fetchAllData = () => {
    this.setState({ loader: true });
    this.props
      .MillersFunction(
        "get",
        "masters/item-category/list",
        null,
        "ItemCateoryList",
        null,
        {
          is_active: "True",
        }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          this.setState({ ItemCateoryList }, () => {
            this.props
              .MillersFunction(
                "get",
                "masters/item-type/list",
                null,
                "ItemList",
                null,
                {
                  is_active: "True",
                }
              )
              .then((ItemList) => {
                if (ItemList) {
                  this.setState({ ItemList }, () => {
                    this.props
                      .MillersFunction(
                        "get",
                        "masters/brand/list",
                        null,
                        "BrandList",
                        null,
                        {
                          is_active: "True",
                        }
                      )
                      .then((BrandList) => {
                        if (BrandList) {
                          this.setState({
                            loader: false,
                            BrandList: BrandList.map((sBrand) => {
                              if (
                                this.state.ItemList &&
                                this.state.ItemList.length > 0 &&
                                this.state.ItemCateoryList &&
                                this.state.ItemCateoryList.length > 0
                              ) {
                                sBrand.item_category_name =
                                  this.state.ItemCateoryList.filter(
                                    (singleItem) =>
                                      singleItem.id === sBrand.item_category
                                  )
                                    ? this.state.ItemCateoryList.filter(
                                        (singleItem) =>
                                          singleItem.id === sBrand.item_category
                                      )[0].name
                                    : "";
                                sBrand.item_type_name =
                                  this.state.ItemList.filter(
                                    (singleItem) =>
                                      singleItem.id === sBrand.item_type
                                  )
                                    ? this.state.ItemList.filter(
                                        (singleItem) =>
                                          singleItem.id === sBrand.item_type
                                      )
                                      ? this.state.ItemList.filter(
                                          (singleItem) =>
                                            singleItem.id === sBrand.item_type
                                        )[0]
                                        ? this.state.ItemList.filter(
                                            (singleItem) =>
                                              singleItem.id === sBrand.item_type
                                          )[0].broken_percentage
                                        : ""
                                      : ""
                                    : "";

                                return sBrand;
                              } else {
                                return sBrand;
                              }
                            }),
                          });
                        } else {
                          this.setState({ loader: false });
                        }
                      });
                  });
                } else {
                  this.setState({ loader: false });
                }
              });
          });
        } else {
          this.setState({ loader: false });
        }
      });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  fetchDispatchList = (type) => {
    this.setState({ loader: true });
    if (type === "miller_account") {
      this.props
        .MillersFunction(
          "get",
          "arrival/miller-accounts/list",
          null,
          "dispatchList",
          null,
          {
            miller_id: this.state.singlelistView.id,
            // payment_status: type,
          }
        )
        .then((dispatchList) => {
          const newArrId = [];
          const newArr = [];
          if (dispatchList && dispatchList.length > 0) {
            dispatchList.map((sR) => {
              if (sR.id) {
                // this.props
                //   .MillersFunction(
                //     "get",
                //     "arrival/partial-payment/list",
                //     null,
                //     "partial-payment",
                //     null,
                //     {
                //       miller_id: sR.id,
                //     }
                //   )
                //   .then((res2) => {
                sR.subList =
                  sR.partial_payments && sR.partial_payments.length > 0
                    ? sR.partial_payments
                    : [];
                if (!newArrId.includes(sR.id)) {
                  newArr.push(sR);
                  newArrId.push(sR.id);
                }
                console.log(newArr, dispatchList, "newArrnewArr");
                if (newArr.length === dispatchList.length) {
                  //  newArr.map((sN) => {
                  //     if(sN){
                  //     sN.miller = sN.purchase_order_details.miller;}
                  //     return sN;
                  //   });
                  console.log(newArr, "newArrnewArr");

                  this.setState({
                    dispatchList: newArr,
                    dispatchListCopy: newArr,
                    // dispatchList: filterData(
                    //   newArr,
                    //   "miller",
                    //   this.state.singlelistView.id
                    // )
                    //   ? filterData(
                    //       filterData(
                    //         newArr,
                    //         "miller",
                    //         this.state.singlelistView.id
                    //       ),
                    //       "miller_payment",
                    //       type === "paid" ? true : false
                    //     )
                    //     ? this.state.selectedContractTypeId
                    //       ? this.filterDataAccDate(
                    //           filterData(
                    //             filterData(
                    //               newArr,
                    //               "miller",
                    //               this.state.singlelistView.id
                    //             ),
                    //             "miller_payment",
                    //             type === "paid" ? true : false
                    //           )
                    //         )
                    //       : filterData(
                    //           filterData(
                    //             newArr,
                    //             "miller",
                    //             this.state.singlelistView.id
                    //           ),
                    //           "miller_payment",
                    //           type === "paid" ? true : false
                    //         )
                    //     : []
                    //   : [],
                    // dispatchListCopy: filterData(
                    //   newArr,
                    //   "miller",
                    //   this.state.singlelistView.id
                    // )
                    //   ? filterData(
                    //       filterData(
                    //         newArr,
                    //         "miller",
                    //         this.state.singlelistView.id
                    //       ),
                    //       "miller_payment",
                    //       type === "paid" ? true : false
                    //     )
                    //     ? filterData(
                    //         filterData(
                    //           newArr,
                    //           "miller",
                    //           this.state.singlelistView.id
                    //         ),
                    //         "miller_payment",
                    //         type === "paid" ? true : false
                    //       )
                    //     : []
                    //   : [],
                    loader: false,
                  });

                  return sR;
                }
                // });
              }
            });
          } else {
            this.setState({
              dispatchList: [],
              dispatchListCopy: [],
              loader: false,
            });
          }
        });
    }
  };

  onEditUpdate = (res) => {
    if (res) {
      this.setState({ singlelistView: res });
    }
  };

  setInitialValues(props) {
    const { widget_permission } = getSingleModule_widget(
      props.user,
      accessText.vendors,
      accessText.millers
    );
    console.log(widget_permission, "kkkkkkkkkkkkk");
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      // has_read_permission:true,has_create_permission:true
      tab.widget = widget_permission?.widget
        ? { ...widget_permission?.widget }
        : null;
      tab.data =
        props[EndPoints["Millers"]] && props[EndPoints["Millers"]].length > 0
          ? props[EndPoints["Millers"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [];
      return tab;
    });
    this.setState({
      userRole: this.props.user.roles
        ? this.props.user.roles.toString().toLowerCase()
        : "admin",
      listViewArray:
        props[EndPoints["Millers"]] && props[EndPoints["Millers"]].length > 0
          ? props[EndPoints["Millers"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [],
      tabs,
      contractType: props.contractType,
      loading: props.loading,
    });
  }
  render() {
    const {
      tabs,
      listViewArray,
      singlelistView,
      loading,
      dispatchList,
      invoiceListArr,
      invoiceListArrCopy,
      editList,
      loader,
      openDialog,
      ItemCateoryList,
      ItemList,
      BrandList,
      dispatchListCopy,
      contractType,
      openStatusDialog,
      showInvoice,
      selectedContractType,
      selectedRange,
      selectedContractTypeId,
      downloadInvoicePdf,
      userRole,
      calenderOpen,
      searchedData,
      StatusList,
      PaymentAmount,
      Status,
    } = this.state;
    const { user } = this.props;

    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.vendors,
      accessText.millers
    );
    // const filterChild = () => {
    //   return (
    //     <CssTextField95
    //       required={true}
    //       autoComplete="off"
    //       value={
    //         selectedContractType ? selectedContractType : "" //
    //       }
    //       onChange={(e) => {
    //         this.setState(
    //           {
    //             selectedContractTypeId: e.currentTarget.id,
    //             selectedContractType: e.target.value,
    //           },
    //           () => {
    //             const oldArray =
    //               dispatchListCopy && dispatchListCopy.length > 0
    //                 ? dispatchListCopy.map((s) => {
    //                     s.contract_type_id = s.purchase_order_details
    //                       .contract_type_details
    //                       ? s.purchase_order_details.contract_type_details
    //                           .contract_type
    //                       : "";
    //                     return s;
    //                   })
    //                 : [];
    //             const newArr =
    //               this.state.selectedContractType === "All"
    //                 ? oldArray
    //                 : filterData(
    //                     oldArray,
    //                     "contract_type_id",
    //                     this.state.selectedContractType
    //                   )
    //                 ? filterData(
    //                     oldArray,
    //                     "contract_type_id",
    //                     this.state.selectedContractType
    //                   )
    //                 : [];

    //             this.setState({
    //               dispatchList: newArr.length > 0 ? newArr : [],
    //             });
    //           }
    //         );
    //       }}
    //       style={
    //         { width: "200px", margin: "0px" } //disabled={tp}
    //       }
    //       id="outlined-select-currency-native"
    //       select
    //       label="Contract Type"
    //       variant="outlined"
    //       InputLabelProps={{}}>
    //       {" "}
    //       <MenuItem key={"All"} value={"All"} id={"All"}>
    //         All
    //       </MenuItem>
    //       {contractType &&
    //         contractType.length > 0 &&
    //         contractType.map((option) => (
    //           <MenuItem
    //             key={option.contract_type}
    //             value={option.contract_type}
    //             id={option.id}>
    //             {option.contract_type}
    //           </MenuItem>
    //         ))}
    //     </CssTextField95>
    //   );
    // };
    const filterChild2 = () => {
      return (
        <BasicDateRangePicker
          selectedRange={selectedRange}
          onClear={() => {
            this.setState({
              invoiceListArr:
                invoiceListArrCopy.length > 0 ? invoiceListArrCopy : [],
              selectedRange: [null, null],
            });
          }}
          onChange={(val) => {
            const oldArray =
              invoiceListArrCopy && invoiceListArrCopy.length > 0
                ? invoiceListArrCopy
                : [];

            const dateFrom =
              val && val[0]
                ? moment(val[0]).format("MMM D YYYY")
                : moment(new Date()).format("MMM D YYYY");

            const dateTo =
              val && val[1]
                ? moment(val[1]).format("MMM D YYYY")
                : moment(new Date()).format("MMM D YYYY");
            const newArr = oldArray.filter((singleObj) => {
              const createdAt = moment(singleObj.invoice_date).format(
                "MMM D YYYY"
              );

              return (
                createdAt === dateFrom ||
                createdAt === dateTo ||
                (new Date(createdAt) > new Date(dateFrom) &&
                  new Date(dateTo) > new Date(createdAt))
              );
            });
            this.setState({
              invoiceListArr: newArr.length > 0 ? newArr : [],
              selectedRange: val,
            });
          }}
        />
      );
    };
    var labelField;

    labelField = "name";
    const setData = (singleDispatch) => {
      console.log(singleDispatch, "singleDispatch");
      const unit =
        singleDispatch.purchase_order_details &&
        singleDispatch.purchase_order_details.unit &&
        singleDispatch.purchase_order_details.unit;
      singleDispatch["Dispatch date"] = moment(
        singleDispatch.dispatch_date
      ).format("ddd, MMM Do YYYY");
      // singleDispatch["User Type"] = userRole
      //   ? userRole
      //   : "-";
      // singleDispatch["Invoice Number"] = singleDispatch.invoice_number
      //   ? singleDispatch.invoice_number
      //   : "-";

      // singleDispatch["Invoice Weight"] = singleDispatch.dispatch_weight
      //   ? `${Number(singleDispatch.dispatch_weight).toFixed(2)}`
      //   : "-";
      singleDispatch["Miller Advance"] =
        userRole === "admin" ||
        userRole === "miller"
          ? singleDispatch.miller_advance
            ? `Rs ${Number(singleDispatch.miller_advance).toFixed(2)}`
            : "-"
          : "";

      singleDispatch["Miller Bill Amount"] = singleDispatch.miller_bill_amount
        ? `Rs ${Number(singleDispatch.miller_bill_amount).toFixed(2)}`
        : "-";
      singleDispatch["Miller Bill Weight"] = singleDispatch.miller_bill_weight
        ? `Rs ${Number(singleDispatch.miller_bill_weight).toFixed(2)}`
        : "-";
      singleDispatch["Cash Discount"] = singleDispatch.cash_discount
        ? `Rs ${singleDispatch.cash_discount}`
        : "-";
      singleDispatch["Miller Discount Expiry Date"] = moment(
        singleDispatch.miller_discount_expiry_date
      ).format("ddd, MMM Do YYYY");

      singleDispatch["Miller Discounted Amount"] =
        userRole === "admin" ||
        userRole === "miller"
          ? singleDispatch.miller_discounted_amount
            ? `Rs ${singleDispatch.miller_discounted_amount}`
            : "-"
          : "";
      singleDispatch["Miller Gst Charge"] =
        userRole === "admin" ||
        userRole === "miller"
          ? singleDispatch.miller_gst_charge
            ? `${Number(singleDispatch.miller_gst_charge).toFixed(2)} %`
            : "-"
          : "";

      singleDispatch["Miller TDS"] =
        userRole === "admin" ||
        userRole === "miller"
          ? singleDispatch.miller_tds
            ? `${Number(singleDispatch.miller_tds).toFixed(2)}`
            : "-"
          : "";
      singleDispatch["Miller Total Amount"] =
        userRole === "admin" ||
        userRole === "miller"
          ? singleDispatch.miller_total_amount
            ? `Rs ${Number(singleDispatch.miller_total_amount).toFixed(2)}`
            : "-"
          : "";

      singleDispatch["Bill Rate"] = singleDispatch.bill_rate
        ? `Rs ${Number(singleDispatch.bill_rate).toFixed(2)}`
        : "-";
      singleDispatch["Brokerage Amount For Bri"] =
        userRole === "admin" ||
        userRole === "broker"
          ? singleDispatch.brokerage_amount_for_bri
            ? `Rs ${Number(singleDispatch.brokerage_amount_for_bri).toFixed(2)}`
            : "-"
          : "";

      singleDispatch["Brokerage Amount For Miller"] =
        singleDispatch.brokerage_amount_for_miller
          ? `Rs ${Number(singleDispatch.brokerage_amount_for_miller).toFixed(
              2
            )}`
          : "-";
      singleDispatch["Brokerage Rate For Bri"] =
        userRole === "admin" ||
        userRole === "broker"
          ? singleDispatch.brokerage_rate_for_bri
            ? `Rs ${Number(singleDispatch.brokerage_rate_for_bri).toFixed(
                2
              )}`
            : "-"
          : "";

      singleDispatch["Brokerage Rate For Miller"] =
        singleDispatch.brokerage_rate_for_miller
          ? `Rs ${Number(singleDispatch.brokerage_rate_for_miller).toFixed(2)}`
          : "-";
      singleDispatch["Unloading Bags Number"] =
        userRole === "admin" ||
        userRole === "broker"
          ? singleDispatch.unloading_bags_number
            ? singleDispatch.unloading_bags_number
            : "-"
          : "";

      singleDispatch["Final Rate"] =
        userRole === "admin" ||
        userRole === "miller"
          ? singleDispatch.final_rate
            ? `Rs ${Number(singleDispatch.final_rate).toFixed(2)}`
            : "-"
          : "";
      singleDispatch["Final Weight"] =
        userRole === "admin" ||
        userRole === "miller"
          ? singleDispatch.final_weight
            ? `Rs ${Number(singleDispatch.final_weight).toFixed(2)}`
            : "-"
          : "";

      // singleDispatch["Bill Amount"] = singleDispatch.miller_bill_amount
      //   ? `Rs ${Number(singleDispatch.miller_bill_amount).toFixed(2)}`
      //   : "-";

      // singleDispatch["Bill Number"] =
      //   singleDispatch.miller_bill_number &&
      //   singleDispatch.miller_bill_number.length > 0
      //     ? singleDispatch.miller_bill_number
      //     : "-";
      // singleDispatch["Bags qty"] =
      //   singleDispatch.subList &&
      //   singleDispatch.subList.length > 0 &&
      //   singleDispatch.subList.reduce(
      //     (previousValue, currentValue) =>
      //       Number(previousValue) +
      //       Number(
      //         currentValue.dispatch_packaging &&
      //           currentValue.dispatch_packaging.length > 0
      //           ? currentValue.dispatch_packaging.reduce(
      //               (previousValue1, currentValue1) =>
      //                 Number(previousValue1) +
      //                 (currentValue1.total_bags_dispatched
      //                   ? Number(currentValue1.total_bags_dispatched)
      //                   : 0),
      //               0
      //             )
      //           : 0
      //       ),
      //     0
      //   );
      // singleDispatch["Tcs"] =
      //   singleDispatch.miller_tcs && singleDispatch.miller_tcs.length > 0
      //     ? `${Number(singleDispatch.miller_tcs).toFixed(2)} %`
      //     : "-";
      // singleDispatch["Dispatch qty"] = singleDispatch.dispatch_weight
      //   ? `${Number(
      //       singleDispatch.dispatch_weight ? singleDispatch.dispatch_weight : 0
      //     ).toFixed(3)} ${unit}`
      //   : "-";
      // singleDispatch["Advance"] = singleDispatch.miller_advance
      //   ? `Rs ${Number(singleDispatch.miller_advance).toFixed(2)}`
      //   : "-";
      // singleDispatch["Total Amount"] =
      //   singleDispatch.miller_total_amount &&
      //   singleDispatch.miller_total_amount.length > 0
      //     ? `Rs ${Number(singleDispatch.miller_total_amount).toFixed(2)}`
      //     : "-";
      // singleDispatch["totalamount"] =
      //   singleDispatch.miller_total_amount &&
      //   singleDispatch.miller_total_amount.length > 0
      //     ? Number(singleDispatch.miller_total_amount).toFixed(2)
      //     : "-";
      // singleDispatch["Dispatch Status"] = singleDispatch.dispatch_status
      //   ? singleDispatch.dispatch_status
      //   : "-";
      // singleDispatch["Status"] = singleDispatch.dispatch_status
      //   ? singleDispatch.dispatch_status.charAt(0).toUpperCase() +
      //     singleDispatch.dispatch_status.slice(1)
      //   : "-";
      console.log(userRole,"userRole")

      singleDispatch["Action"] =  ( userRole === "admin" ||
      userRole === "accountant" ||
      userRole === "manager" )?"a": "";
      singleDispatch["ActionUI"] =
      (userRole === "admin"|| userRole === "accountant"||userRole==="manager" )? (
          
          <div>
            <StyledButton
              name={"Payment"}
              background={primary}
              color={white}
              onClick={() => {
                this.setState({
                  openStatusDialog: true,
                  loader: false,
                  selectedDispatch: singleDispatch,
                });
              }}
            />
          </div>
        ) : (
          ""
        );
      singleDispatch["subList"] = singleDispatch.subList
        ? singleDispatch.subList.map((singleSub) => {
            singleSub["Date"] = moment(singleSub.payment_datetime).format(
              "ddd, MMM Do YYYY"
            );
            singleSub["Payment Amount"] = singleSub.payment_amount ? `${Number(singleSub.payment_amount).toFixed(2)}`:"";
            singleSub["Payment Status"] = singleSub.payment_status;
            singleSub["Recorded By"] = singleSub.recorded_by;
            singleSub["Verified By"] = singleSub.verified_by;
            singleSub["Action"] = "rfrfgrgfrtgtr5g";
            singleSub["ActionUI"] = (
              <div>
                {/* <StyledButton
                  name={"Download PDF"}
                  background={white}
                  padding={"2px 6px"}
                  fontSize={"0.8rem"}
                  border={"1px solid rgb(241, 154, 48)"}
                  boxShadow={"none"}
                  // color={white}
                  onClick={() => {
                    this.setState({
                      downloadInvoicePdf: true,
                      selectedDispatch: singleDispatch,
                      selectedPartialPayment: singleSub,
                    });
                  }}
                /> */}
                 <div
              onClick={(e) => {
                e.stopPropagation();
                this.setState({anchorEl:e.currentTarget,popper:{open:true,data:singleSub,parent:singleDispatch}})
              }}
              className="d-flex alignC justifyC"
              style={{
                margin: "12px 10px",
                width: "50px",
              }}>
              <MoreHorizOutlined />
            </div>
              </div>
            );

            //filterData(
            //   BrandList,
            //   "id",
            //   singleSub.brand,
            //   "onlyOne"
            // )
            //   ? filterData(BrandList, "id", singleSub.brand, "onlyOne")
            //       .brand_name
            //   : "";

            // singleSub["Item Category"] = filterData(
            //   ItemCateoryList,
            //   "id",
            //   singleSub.item_category,
            //   "onlyOne"
            // )
            //   ? filterData(
            //       ItemCateoryList,
            //       "id",
            //       singleSub.item_category,
            //       "onlyOne"
            //     ).name
            //   : "";
            // singleSub["Item Type"] = filterData(
            //   ItemList,
            //   "id",
            //   singleSub.item_type,
            //   "onlyOne"
            // )
            //   ? `${Number(
            //       filterData(ItemList, "id", singleSub.item_type, "onlyOne")
            //         ? filterData(ItemList, "id", singleSub.item_type, "onlyOne")
            //             .broken_percentage
            //         : 0
            //     ).toFixed(2)} %`
            //   : "";
            // singleSub["Packaging"] =
            //   singleSub.dispatch_packaging &&
            //   singleSub.dispatch_packaging.length > 0
            //     ? `  ${
            //         singleSub.dispatch_packaging.map((sP) => {
            //           const singlePack = sP.packaging_details;

            //           return singlePack
            //             ? `${singlePack.size}${singlePack.unit}${" - "}${
            //                 singlePack.bag_type
            //               }`
            //             : "";
            //         })[0]
            //       } Total (...s)`
            //     : "-";
            // singleSub["PackagingUI"] = (
            //   <ShowPackaging
            //     noTotalbags={true}
            //     details={singleSub.dispatch_packaging.map((sP) => {
            //       const singlePack = sP.packaging_details;
            //       singlePack.used_bags = sP.total_bags_dispatched;

            //       return singlePack;
            //     })}
            //   />
            // );
            // singleSub["PackagingUIPdf"] =
            //   singleSub.dispatch_packaging &&
            //   singleSub.dispatch_packaging.length > 0
            //     ? singleSub.dispatch_packaging
            //         .map((sP) => {
            //           const singlePack = sP.packaging_details;

            //           return singlePack
            //             ? `${singlePack.size}${singlePack.unit}${" - "}${
            //                 singlePack.bag_type
            //               }`
            //             : "";
            //         })
            //         .join(", ")
            //     : "-";
            return singleSub;
          })
        : [];
      return singleDispatch;
    };
    const pdfFormat = (data) => {
      return {
        // "Payment Datetime":"efer",
        "Invoice Number":data.invoice_number,
        "Payment Status": data.payment_status,
        "Whose Payment": data.whose_payment ?? "",
        "Final Amount": data.final_amount,
        "Payment Amount": data.payment_amount,
        "Unpaid Amount": data.unpaid_amount,
        "Recorded By": data.recorded_by,
        "Verified By": data.verified_by,
        ...data,
      };
    };
    return (
      <Layout
        loading={loading[EndPoints["Millers"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Millers`,
          labelField: labelField,
        }}
        listClickHandler={this.listClickHandler}
        fabClickHandler={(value) => {
          localStorage.removeItem("fetchagain-bri");
          this.setState({
            openDialog: true,
            loadingFun: false,
            editList: false,
          });
        }}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px">
        {openDialog === true ? (
          <CreateDialog
            textFieldWidth="calc(50% - 20px)"
            heightNew="500px"
            maxHeight="calc(100vh - 40px)"
            widthNew="900px"
            noAddAnother={true}
            onEditUpdate={(res) => {
              this.onEditUpdate(res);
            }}
            // addFieldsToAdd={[{ name: "is_active", value: true }]}
            module={"Millers"}
            selectedList={editList ? editList : false}
            editList={editList ? editList : false}
            handleClose={() => {
              this.setState({
                openDialog: false,
                loadingFun: false,
                editList: true,
              });
            }}
            postApi="masters/miller/list"
            editApi="masters/miller"
            inputArray={[
              {
                name: "Active",
                type: "toggle",
                defaultValue: {
                  value: editList ? editList.is_active : true,
                },
                api: "is_active",
              },
              {
                name: "name",
                type: "textField",
                dataType: "text",
                api: "name",
                defaultValue: {
                  value: editList && editList.name,
                },
              },
              {
                name: "phone number",
                type: "textField",
                dataType: "number",
                onInput: "phone_number",
                only_number: true,
                not_required: true,
                api: "phone",
                defaultValue: {
                  value: editList && editList.phone,
                },
              },
              {
                name: "address",
                type: "addressTextField",
                dataType: "text",
                api: "address",
                defaultValue: {
                  value: editList && editList.address,
                },
              },
              {
                name: "GST IN",
                type: "textField",
                dataType: "text",
                onKeyPress: "alphaNum",
                onInput: "gst_in",
                api: "gstin",
                defaultValue: {
                  value: editList && editList.gstin,
                },
                helperText: "ex: 22AAAAA0000A1Z5",
              },
              {
                name: "contact person",
                type: "textField",
                dataType: "text",
                api: "contact_person",
                defaultValue: {
                  value: editList && editList.contact_person,
                },
              },

              {
                name: "state",
                type: "select",
                array: stateArray,
                value: "name",
                api: "state",
                defaultValue: {
                  value: editList && editList.state,
                },
              },
              {
                name: "city",
                type: "textField",
                dataType: "text",
                api: "city",
                onKeyPress: "textOnly",
                defaultValue: {
                  value: editList && editList.city,
                },
              },

              {
                name: "pincode",
                type: "textField",
                onInput: "pincode",
                dataType: "number",
                api: "pincode",
                only_number: true,
                defaultValue: {
                  value: editList && editList.pincode,
                },
              },
            ]}
            postData={this.props.MillersFunction}
            fetchAgain={() => {
              this.props.MillersFunction("get", EndPoints["Millers"]);
            }}
          />
        ) : (
          ""
        )}


<Popover
          open={this.state.popper.open}
          anchorEl={this.state.anchorEl}
          style={{ marginTop: "-2.5%" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}>
          <ClickAwayListener
            onClickAway={() => {
              this.setState({popper:{open:false,anchorEl:null}})
            }}>
            <List> 
            
                <ListItem
                   
                    button
                    onClick={() => {
                      console.log(this.state.popper.data,"this.state.popper.data")
                      this.setState({
                        openStatusDialog: true,
                        loader: false,
                        selectedDispatch: this.state.popper.parent,
                        Status: this.state.popper.data.payment_status,
                        searchedData:this.state.popper.data.payment_datetime,
                        PaymentAmount:this.state.popper.data.payment_amount,
                        selectedPartialPayment: this.state.popper.data,
                      });
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "0 0 0 0",
                      padding: "10px",
                    }}>
                    <p
                      className="d-flex alignC textC"
                      style={{
                        margin: "0",
                        fontSize: "0.9rem",
                        height: "24px",
                      }}>
                    Edit
                    </p>
                  </ListItem>
           {this.state.popper&&this.state.popper.data&&this.state.popper.data.payment_status!=="PENDING"&& <ListItem
                    button
                    onClick={() => {
                      this.setState({
                        downloadInvoicePdf: true,
                        selectedDispatch: this.state.popper.parent,
                        selectedPartialPayment: this.state.popper.data,
                      });
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "0 0 0 0",
                      padding: "10px",
                    }}>
                    <p
                      className="d-flex alignC textC"
                      style={{
                        margin: "0",
                        fontSize: "0.9rem",
                        height: "24px",
                      }}>
                     Download Pdf
                    </p>
                  </ListItem>}
            </List>
          </ClickAwayListener>
        </Popover>
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {openStatusDialog === true && (
            <CssDialog
              height={showInvoice ? "calc(100% - 63px)" : "400px"}
              width={showInvoice ? "calc(100% - 150px)" : "400px"}
              noReset={true}
              header={
                // showInvoice !== false
                //   ? "Invoice"
                //   : this.state.selectedListArray[0] &&
                //     this.state.selectedListArray[0].transporter_payment ===
                //       "PENDING"
                //   ? "Approve Payment"
                // :
                "Miller Payment"
              }
              onClose={() => {
                this.setState({
                  openStatusDialog: false,
                  editDialog: false,
                  added: false,
                  selectedListArray: false,
                  showInvoice: false,
                });
              }}
              children={() => {
                return (
                  <div className="d-flex justifyC alignC width-100 height-100 fontSize1 ">
                  
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <MuiPickersUtilsProvider
                          libInstance={moment()}
                          utils={DateFnsUtils}>
                          {labelText("Date")}
                          <CssKeyboardDatePicker
                            variant="inline"
                            onOpen={() => {
                              this.setState({ calenderOpen: true });
                            }}
                            onClose={() => {
                              this.setState({ calenderOpen: false });
                            }}
                            maxDate={new Date()}
                            disableFuture
                            error={false}
                            invalidLabel=""
                            onKeyPress={(ev) => {
                              ev.preventDefault();
                            }}
                            onKeyDown={(evt) => {
                              evt.keyCode === 8 && evt.preventDefault();
                            }}
                            allowKeyboardControl={false}
                            keyboardIcon={
                              calenderOpen ? <TopArrow /> : <BottomArrow />
                            }
                            autoOk
                            inputVariant="outlined"
                            style={
                              { width: "100%" } //disabled={tp}
                            }
                            format="do MMM, yyyy"
                            value={searchedData ? searchedData : new Date()}
                            //views={['year', 'month']}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={(newValue) => {
                              this.setState({ searchedData: newValue });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {labelText("Payment Amount")}
                        <CssTextField
                          required={false}
                          type="number"
                          autoComplete="off"
                          style={
                            { width: "100%" } //disabled={tp}
                          }
                          id="outlined-basic"
                          // label="Brokerage Amount"
                          variant="outlined"
                          name="Rate"
                          value={
                            this.state.PaymentAmount
                              ? this.state.PaymentAmount
                              : ""
                          }
                          onChange={(e) => {
                            this.setState({ PaymentAmount: e.target.value });
                          }}
                        />

                        {labelText("Status")}
                        <CssTextField
                          id="broker"
                          required={true}
                          autoComplete="off"
                          value={Status}
                          onChange={userRole === "admin" &&this.state.selectedPartialPayment?(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Status = false;
                            this.setState({
                              Status: e.target.value,
                            });
                          }:()=>{}
                        }
                          disabled={userRole === "admin" &&this.state.selectedPartialPayment?(this.state.selectedPartialPayment.recorded_by.includes(`${user.first_name} ${user.last_name}`))?true:false:true}
                          style={{
                            width: "100%",
                          }}
                          select
                          //     label="broker"
                          variant="outlined"
                          InputLabelProps={{}}>
                          {StatusList &&
                            StatusList &&
                            StatusList.map((option) => (
                              <MenuItem
                                key={option.status.toString()}
                                value={option.status}
                                id={option.id}>
                                {option.status}
                              </MenuItem>
                            ))}
                        </CssTextField>
                      </div>
                  </div>
                );
              }}
              bottomBarContent={() => {
                return (
                  <>
                    <StyledButton
                      padding="2px 5px"
                      onClick={() => {
                        this.setState({
                          openStatusDialog: false,
                          editDialog: false,
                          added: false,
                          selectedListArray: false,
                        });
                      }}
                      name="Cancel"
                    />
                    <StyledButton
                      onClick={() => {
                        if (this.state.PaymentAmount) {
                          this.setState({ loader: true });
                          //  this.state.PaymentAmount
                          // this.state.searchedData
                          const partialpayment = {
                            payment_datetime: moment(
                              this.state.searchedData
                            ).format("YYYY-MM-DD"),
                            payment_amount: this.state.PaymentAmount,
                            payment_status:
                              userRole === "admin" ? "PAID" : "PENDING",
                            whose_payment: "MILLER",
                            // "brokerage_of": "string",
                            miller_account: this.state.selectedDispatch.id,
                          };
                          console.log(
                            this.state.selectedDispatch,
                            partialpayment,
                            "selectedDispatch"
                          );
                          this.props
                            .MillersFunction(
                              // "post",
                              // "arrival/partial-payment/list",
                              // null,
                              // "invoiceUpdate",
                              // partialpayment
                              this.state.selectedPartialPayment?"patch":"post",
                              this.state.selectedPartialPayment? "arrival/partial-payment":"arrival/partial-payment/list",
                              this.state.selectedPartialPayment?.id?? null,
                              "invoiceUpdate",
                              partialpayment, {},
                              "noeditparam"
                            )
                            .then((invoiceId) => {
                              if (invoiceId) {
                                this.fetchDispatchList("miller_account");
                                
                                this.setState({ 
                                  loader: false,
                                  openStatusDialog: false,
                                  editDialog: false,
                                  added: false,
                                  selectedListArray: false,
                                });
                              }
                            });
                        }
                      }}
                      name="Submit"
                      withBg={true}
                      padding="2px 5px"
                    />
                  </>
                );
              }}
              loadingDialog={loader}
            />
          )}
          {downloadInvoicePdf === true ? (
            <InvoiceCardUi
              onlyShow={false}
              setLoader={() => {
                this.setState({ loader: false });
              }}
              onDownload={() => {
                this.setState({
                  openStatusDialog: false,
                  editDialog: false,
                  added: false,
                  selectedListArray: false,
                  showInvoice: false,
                  loader: false,
                  selectedDispatch: false,
                  selectedPartialPayment: false,
                  downloadInvoicePdf: false,
                  broken_amount: "",
                });
              }}
              onlyDownload={true}
              singlelistView={singlelistView}
              module="miller"
              pdfHeader={[
                // "Sr.No.",

                // "Payment Datetime",
                // "Invoice Number",
                "Payment Status",
                "Whose Payment",
                "Final Amount",
                "Payment Amount",
                "Unpaid Amount",
                "Recorded By",
                "Verified By",
              ]}
              header={[
                // "Sr.No.",
                // "Payment Datetime",
                // "Invoice Number",
                "Payment Status",
                "Whose Payment",
                "Final Amount",
                "Payment Amount",
                "Unpaid Amount",
                "Recorded By",
                "Verified By",
              ]}
              setData={pdfFormat}
              loader={loader ? loader : false}
              partial_payment={{
                ...this.state.selectedPartialPayment,
                account_detail: this.state.selectedDispatch,
              }}
            />
          ) : (
            ""
          )}
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Millers === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <TabComponent
              onChange={(newVal) => {
                const selctedTab =
                  newVal === 0
                    ? "miller_account"
                    : newVal === 3 && "Miller Details";
                this.setState({ selctedTab }, () => {
                  if (newVal === 0) {
                    this.fetchDispatchList("miller_account");
                  }
                });
              }}
              list={[
                {
                  name: "Miller Accounts",
                  component: (
                    <TableComponent
                      loader={loader ? loader : false}
                      subMenu={true}
                      // filterChild={() => {
                      //   return filterChild();
                      // }}
                      noAddButton={true}
                      module={"Dispatch"}
                      {...this.state}
                      header={[
                        "Sr.No.",
                        userRole === "admin"| userRole === "manager"||userRole === "accountant"
                          ? "Action"
                          : false,
                        "Dispatch date",
                        // "User Type",
                        // "Invoice Number",
                        // "Invoice Weight",

                        userRole === "admin"|| userRole === "miller"?"Miller Advance":false,

                        userRole === "admin"|| userRole === "miller"?"Miller Bill Amount":false,

                        userRole === "admin"|| userRole === "miller"?"Miller Bill Weight":false,
                        "Cash Discount",
                        userRole === "admin"|| userRole === "miller"?"Miller Discount Expiry Date":false,

                        userRole === "admin"|| userRole === "miller"?"Miller Discounted Amount":false,

                        userRole === "admin"|| userRole === "miller"?"Miller Gst Charge":false,

                        userRole === "admin"|| userRole === "miller"?"Miller TDS":false,
                        userRole === "admin"
                          ? "Miller Total Amount"
                          : false,

                        "Bill Rate",
                        userRole === "admin"|| userRole === "broker"?"Brokerage Amount For Bri":false,

                        "Brokerage Amount For Miller",

                  //  "Brokerage Amount From Bri",

                        // "Brokerage Amount From Miller",

                        userRole === "admin"|| userRole === "broker"?"Brokerage Rate For Bri":false,

                        "Brokerage Rate For Miller",

                        userRole === "admin"|| userRole === "miller"
                        ?"Unloading Bags Number":false,
                        userRole === "admin"|| userRole === "miller"?"Final Rate":false,

                        userRole === "admin"|| userRole === "miller"?"Final Weight":false,
                      ].filter((s) => s !== false)}
                      subMenuheader={[
                        "Sr.No.",
                        "Date",
                        "Payment Amount",
                        "Payment Status",
                        "Recorded By",
                        "Verified By",
                        "Action",
                      ]}
                      actions={[]}
                      {...this.props}
                      tableList={
                        dispatchList && dispatchList.length
                          ? dispatchList
                              .sort((a, b) => {
                                var dateB = moment(b.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                var dateA = moment(a.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                return new Date(dateB) - new Date(dateA);
                              })
                              .map((singleDispatch) => {
                                return setData(singleDispatch);
                              })
                          : []
                      }
                    />
                  ),
                },
                {
                  name: "Miller Details",
                  component: (
                    <Paper
                      className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}>
                      <div className="width-100 d-flex justifyFE">
                        {widget_permission?.widget?.has_update_permission && (
                          <Tooltip title="Edit">
                            <EditIcon
                              onClick={() => {
                                this.setState({
                                  openDialog: true,
                                  editList: singlelistView,
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </Tooltip>
                        )}
                      </div>

                      <div
                        className="overflowYA scrollBluePrimary"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          padding: "0 50px",
                          height: "calc(100% - 31.7px)",
                          textTransform: "capitalize",
                        }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            Name:{" "}
                          </p>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              textTransform: "capitalize",
                              wordBreak: "break-all",
                            }}>
                            {singlelistView.name}
                          </p>
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            Address:
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}>
                            {singlelistView.address}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            City:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}>
                            {singlelistView.city}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            State:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}>
                            {singlelistView.state}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            GSTIN:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}>
                            {singlelistView.gstin}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            Phone:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}>
                            {singlelistView.phone}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            Contact Person:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}>
                            {singlelistView.contact_person}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}>
                            Pincode:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}>
                            {singlelistView.pincode}
                          </p>
                        </div>
                      </div>
                    </Paper>
                  ),
                },
              ]}
            />
          )}
        </Paper>
      </Layout>
    );
  }
}

export default MillersComponent;
