import React, { useEffect } from "react";
import { connect } from "react-redux";
import MillersComponent from "./MillersComponent";
import { MillersFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function MillersContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.MillersFunction("get", EndPoints["Millers"]);
      props.MillersFunction("get", EndPoints["ContractTypes"], null, EndPoints["ContractTypes"], null, {
        is_active: "True",
      });
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <MillersComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  loading: state.modules.loading,
  [EndPoints["Millers"]]: state.modules[EndPoints["Millers"]],
  contractType: state.modules[EndPoints["ContractTypes"]],
});
const mapDispatchToProps = {
  MillersFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MillersContainer);
