module.exports = {
  background: "linear-gradient(to right, '#45B649', '#DCE35B')",
  primary: "rgb(241 154 48)",
  gray: "#989898",
  green: "rgb(39, 174, 96)",
  cardColor1: "#f3eff5",
  cardColor2: "#f8f0f0",
  cardColor3: "#f6f8f8",
  cardColor4: "#f9f4f3",
  lightGrey: "#9e9e9e0d",
  mostLightGrey: "#E8E8E8",
  white: "white",
  disableColor: "#E0E1E2",
  lightblue: "#03A9F4",
  red: "#FF3D00",
  lightGreen: "#388E3C",
  yellow: "#FBC02D",
  purple: "purple",
  darkBlue: "#26376E",
  orange: "#e59400",
  darkestGray: "#757575",
  color1: "rgb(241 154 48)",
  color2: "#FF3D00", // error
  color3: "#FFB300",
  color4: "#27C8D9",
  color5: "rgb(219 78 78)",
};
