import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Stepper, Typography, StepContent } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import { primary } from "../styles/colors";

const CssStepper = withStyles({
  root: {
    "&.MuiStepper-root": {
      display: "flex",
      padding: "0px",
      backgroundColor: "transparent",
      width: "fit-content",
    },
    "& .MuiStepContent-root": {
      borderLeft: "1px dashed white !important",
      // width: "100%",
    },
    "& .MuiStepLabel-label": { marginLeft: "10px !important" },
    "& .MuiStepConnector-horizontal": {
      maxWidth: "1px !important",
      padding: "0px",
    },
    "& .MuiStepConnector-lineVertical": {
      maxWidth: "2.67px !important",
      minWidth: "2.67px !important",
      overflow: "hidden !important",
      marginLeft: "0 !important",
      marginRight: "0 !important",
      marginTop: "3px !important",
      minHeight: "40px !important",
      borderLeft: `1px dashed ${primary} !important`,
      borderRight: "none !important",
      borderTop: "none !important",
      borderBottom: "none !important",
      // padding: "0px",
    },
    "&.MuiStepper-horizontal": {
      display: "flex",
      // width: "100%",
      // minWidth: "50px",
      justifyContent: "space-evenly",
      alignItems: "flex-start",
      flexDirection: "row",
    },
    "& .MuiStep-horizontal": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: primary,
      height: "24px",
    },
    "& .MuiStepLabel-iconContainer ": {
      paddingRight: " 0px",
      cursor: "pointer",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: primary,
    },
    "& .MuiStepConnector-root": {
      //flex: "1 1 auto",
      display: "flex",
      alignItems: "center",
    },
    "&.MuiStepConnector-line": {
      display: "flex",
      borderColor: " #bdbdbd",
    },
    "&.MuiStepIcon-active ": {
      color: primary,
    },
  },
})(Stepper);
// const CssStepperVertical = withStyles({
//   root: {
//     "&.MuiStepper-root": {
//       display: "flex",
//       padding: "0px",
//       backgroundColor: "transparent",
//       width: "100%",
//     },
//     "& .MuiStepContent-root": {
//       borderLeft: "1px dashed white !important",
//     },
//     "& .MuiStepLabel-label": { marginLeft: "10px !important" },
//     "& .MuiStepConnector-vertical": {
//       maxWidth: "1px !important",
//       padding: "0px",
//     },
//     "& .MuiStepConnector-lineVertical": {
//       maxWidth: "2.67px !important",
//       minWidth: "2.67px !important",
//       overflow: "hidden !important",
//       marginLeft: "0 !important",
//       marginRight: "0 !important",
//       marginTop: "3px !important",
//       minHeight: "40px !important",
//       borderLeft: `1px dashed ${primary} !important`,
//       borderRight: "none !important",
//       borderTop: "none !important",
//       borderBottom: "none !important",
//       // padding: "0px",
//     },
//     "&.MuiStepper-horizontal": {
//       alignItems: "center",
//       display: "flex",
//       flexDirection: " row",
//     },
//     "& .MuiStep-horizontal": {
//       paddingLeft: "0px",
//       paddingRight: "0px",
//     },
//     "& .MuiStepIcon-root.MuiStepIcon-active": {
//       color: primary,
//       height: "24px",
//     },
//     "& .MuiStepLabel-iconContainer ": {
//       paddingRight: " 0px",
//       cursor: "pointer",
//     },
//     "& .MuiStepIcon-root.MuiStepIcon-completed": {
//       color: primary,
//     },
//     "& .MuiStepConnector-root": {
//       //flex: "1 1 auto",
//       display: "flex",
//       alignItems: "center",
//     },
//     "&.MuiStepConnector-line": {
//       display: "none",
//       borderColor: " #bdbdbd",
//     },
//     "&.MuiStepIcon-active ": {
//       color: primary,
//     },
//   },
// })(Stepper);
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: primary,
      border: `1px dashed ${primary}`,
    },
  },
  completed: {
    "& $line": {
      borderColor: primary,
      border: `1px dashed ${primary}`,
    },
  },
  line: {
    borderRadius: 1,
    border: `1px dashed ${primary} !important`,
    width: "100%",
    minWidth: "40px",
    marginLeft: "5px",
    marginRight: "2px !important",
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  // root: {
  //   color: "#eaeaf0",
  //   display: "flex",
  //   height: 22,
  //   alignItems: "center",
  // },
  active: {
    color: primary,
    height: "24px",
  },
  // circle: {
  //   width: 8,
  //   height: 8,
  //   borderRadius: "50%",
  //   backgroundColor: primary,
  //   opacity: "100%",
  // },
  completed: {
    color: primary,
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <>kkk</>
      ) : (
        <>
          <Check className={classes.completed} />
          <div className={classes.circle} />
        </>
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {/* {icons[String(props.icon)]} */}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function CustomStepperPO(props) {
  const {
    steps,
    defaultStep,
    onlyLabel,
    orientation,
    currentAStep,
    enableAll,
  } = props;
  const [allSteps, setSteps] = React.useState(steps ? steps : []);

  // const [activeStep, setactiveStep] = React.useState(defaultStep ? defaultStep : 0);
  const [currentStep, setcurrentStep] = React.useState(
    currentAStep ? currentAStep : 0
  );
  // useEffect(() => {
  //   if (defaultStep || defaultStep === 0) {
  //     setactiveStep(defaultStep);
  //   }
  // }, [defaultStep]);
  useEffect(() => {
    if (currentAStep || currentAStep === 0) {
      setcurrentStep(currentAStep);
    }
  }, [currentAStep]);
  useEffect(() => {
    if (steps) {
      setSteps(steps);
    }
  }, [steps]);
  return (
    <div
    //   className={`${
    //     orientation && orientation === "Horizontal" ? "width-100 " : "width-100"
    //   } heightFC`}
    >
      <CssStepper
        //   style={{
        //     width:
        //       orientation && orientation === "Horizontal"
        //         ? "fit-content"
        //         : "100% !important",
        //   }}
        activeStep={currentStep}
        // connector={<QontoConnector />}
      >
        {allSteps &&
          allSteps.map((step, index) => (
            <Step
              onClick={(e) => {
                e.preventDefault();
              }}
              key={step.label}
              expanded={step.expanded && true}
            >
              <StepLabel
                onClick={() => {
                  // if (currentStep > index && step.onClick) {
                  step.onClick();
                  // }
                }}
                // optional={
                //   index === allSteps.length - 1 ? (
                //     onlyLabel ? (
                //       ""
                //     ) : (
                //       <Typography variant="caption">Last step</Typography>
                //     )
                //   ) : null
                // }
              >
                {step.label}
              </StepLabel>
              {onlyLabel ? (
                ""
              ) : (
                <StepContent
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {step.content()}
                </StepContent>
              )}
            </Step>
          ))}
      </CssStepper>
    </div>
  );
}
export default CustomStepperPO;
