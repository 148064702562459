import React from "react";
import NoData from "../styles/Illustrations/NoData";
function PendingScreen() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          marginTop: "20%",
        }}
      >
        {/* <div className="d-flex width-100 height-100 justifyC alignC"> */}
        <NoData />
        {/* </div> */}
      </div>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          marginTop:"20px",fontSize:"1.5rem"
        }}
      >
        Admin can Approve you
      </div>
    </div>
  );
}

export default PendingScreen;
