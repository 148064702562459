import React, { useState, useEffect } from "react";
import {
  lightGrey,
  primary,
  white,
  green,
  red,
  yellow,
} from "../styles/colors";
import { divide } from "lodash";

import {
  CssDialog,
  StyledButton,
  StyledSearchBarNew,
} from "../styles/StyledComponents";
import {
  Divider,
  Popover,
  List,
  ListItem,
  ClickAwayListener,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import { BottomArrow, PlusIcon, TopArrow } from "../styles/Icons";
import { MoreHorizOutlined } from "@material-ui/icons";
import CreateDialog from "./CreateDialog";
import CreateDialogPO from "./CreateDialogPO";
import CreateDialogForSurvayReport from "./CreateDialogForSurvayReport";
import { filterData } from "../Regex";
import SnackbarComponent from "./SnackbarComponent";
import { AddFileIcon } from "../../src/styles/Illustrations/AddFileIcon";
import Pagination from "@mui/material/Pagination";
const styles = {
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10px",
  },
  withDivider50: { width: "calc(40% - 5px)" },
};
const MainViewTable = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedList, setSelectedList] = useState(null);

  const [selectedListArray, setSelectedListArray] = useState([]);
  const [loader, setLoad] = useState(false);
  const [editList, setEditList] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [inputArr, setInputArray] = useState([
    { name: "title", type: "textField", dataType: "text" },
    // { name: "ssipluser", type: "select", dataType: "text" },
  ]);
  const [menuOpen, setmenuOpen] = useState(false);
  const [openCheckConfirmDialog, setOpenCheckConfirmDialog] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [widthList, setWidthList] = useState({});
  const [header, setHeader] = useState(["Sr.No."]);
  const [error, setError] = useState(false);

  const [fetched, setFetched] = useState(false);
  const {
    module,
    customCretae,
    filterChild,
    noAddButton,
    checkbox,
    bottomBar,
    singleOnclick,
    checkboxConfirmation,
    addModuleToRole,
    customButton,
    removeallOption,
    noSearchBar,
    Title,
    minHeight,
    nobottomBarAction,
    bottomBarheader,
    changeUnit,
    singleUnit,
    weight,
    setSelectedListArrayError,
    setSelectedListArrayErrorSet,
    padding,
    confirmToSelect,
    confirmToSelectDenied,
    pagination,
  } = props;
  console.log(pagination, "pagination");
  const [actions, setActions] = useState([
    {
      name: "edit",
      onClick: () => {
        setEditList(true);
      },
    },
  ]);
  useEffect(() => {
    if (
      selectedListArray &&
      props.selectedListArray &&
      props.selectedListArray &&
      props.selectedListArray
        .map((s) => {
          return s;
        })
        .join() !==
        selectedListArray
          .map((s) => {
            return s;
          })
          .join() &&
      fetched === false
    ) {
      setFetched(true);
      setSelectedListArray(props.selectedListArray);
    }
  }, [props.selectedListArray]);
  const [page, setPage] = React.useState(1);
  const handleChangePage = (_event, newPage) => {
    if (pagination && pagination.loadMore) {
      pagination.loadMore(newPage);
    }
    setPage(newPage);
  };
  // useEffect(()=>{
  //   if(pagination&&pagination.loadMore){
  //   pagination.loadMore(page)
  // }
  // },[page])
  useEffect(() => {
    if (
      selectedListArray &&
      props.setSelectedListArray &&
      (!props.selectedListArray ||
        (props.selectedListArray &&
          props.selectedListArray
            .map((s) => {
              return s;
            })
            .join() !==
            selectedListArray
              .map((s) => {
                return s;
              })
              .join()))
    ) {
      props.setSelectedListArray(selectedListArray);
    }
  }, [selectedListArray]);
  useEffect(() => {
    setLoad(props.loader);
  }, [props.loader]);
  useEffect(() => {
    if (setSelectedListArrayError) {
      setError(true);
    }
  }, [setSelectedListArrayError]);
  const [tableListCopy, setTableListCopy] = useState([]);
  const colorArr = [white, lightGrey];

  const longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key] && typeof it[key] === "function"
            ? 20
            : it[`${key}UIPdf`]
            ? it[`${key}UIPdf`].toString().length
            : it[key].toString().length;
        } else {
          return key.toString().length;
        }
      })
    );
  };
  const setSubmenuWidth = (subList) => {
    if (subList && subList[0] && !Array.isArray(subList[0])) {
      const widthList1 = widthList ? widthList : {};
      const array = props.subMenuheader
        ? props.subMenuheader
        : Object.keys(subList[0]);
      if (array.length > 0) {
        array.map((single, i) => {
          widthList1[`SUB${props.subMenuheader[i]}`] = `${Math.floor(
            10 *
              (single.toString().length > longest(single, subList)
                ? single.toString().length
                : longest(single, subList)
                ? longest(single, subList)
                : single.toString().length)
          )}px`;
          return single;
        });
      }
      setWidthList(widthList1);
    }
  };
  useEffect(() => {
    if (props.tableList && props.tableList.length > 0) {
      if (props.tableList[0] && !Array.isArray(props.tableList[0])) {
        const widthList = {}; //widthList?widthList:{}
        const array = props.header
          ? props.header
          : Object.keys(props.tableList[0]);
        array.map((single) => {
          return (widthList[single] = `${Math.floor(
            10 *
              (single.toString().length > longest(single, props.tableList)
                ? single.toString().length
                : longest(single, props.tableList)
                ? longest(single, props.tableList)
                : 1)
          )}px`);
        });
        setWidthList(widthList);
        if (props.subMenu && menuOpen !== false) {
          setSubmenuWidth(
            tableList[menuOpen] && tableList[menuOpen].subList
              ? tableList[menuOpen].subList
              : []
          );
        }
        setTableList(props.tableList ? props.tableList : []);
        setTableListCopy(props.tableList ? props.tableList : []);
        setActions(
          props.actions
            ? props.actions
            : [
                {
                  name: "edit",
                  onClick: () => {
                    setEditList(true);
                  },
                },
              ]
        );
        if (
          Object.keys(props.tableList[0]) &&
          Object.keys(props.tableList[0]).length > 0
        ) {
          const inputArr = [];
          Object.keys(props.tableList[0]).map((single) => {
            return inputArr.push({
              name: single,
              type: "textField",
              dataType: "text",
            });
          });
          setInputArray(inputArr);
        }
        setHeader(
          props.header
            ? props.header
            : props.tableList &&
              props.tableList.length > 0 &&
              props.tableList[0]
            ? Object.keys(props.tableList[0])
            : []
        );
      }
    } else {
      setTableList([]);
      setTableListCopy([]);
    }
  }, [props.tableList]);
  const selectALlCheckox = () => {
    if (selectedListArray.length === tableList.length) {
      setSelectedListArray([]);
    } else {
      const pvArr1 = [];
      tableList.map((s) => pvArr1.push(s.id));

      if (pvArr1.length === tableList.length) {
        setSelectedListArray(pvArr1);
      }
    }
  };
  const selectSingleCheckox = (singleList) => {
    setError(false);
    if (setSelectedListArrayErrorSet) {
      setSelectedListArrayErrorSet(false);
    }
    const currentIndex = selectedListArray.indexOf(singleList.id);
    const newChecked = [...selectedListArray];

    if (currentIndex === -1) {
      newChecked.push(singleList.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedListArray(newChecked);
  };

  const [successMsg, setSuccess] = useState("");
  return (
    <>
      <Paper
        style={{
          width: "100%",
          height: "100%",
          minHeight: minHeight ? minHeight : "",
          // padding: padding ? "" : "15px",
          position: "relative",
          boxShadow: "none",
        }}
        // onClick={(e) => {
        //   e.stopPropagation();
        // }}
      >
        {successMsg ? (
          <SnackbarComponent
            autoHideDuration={5000}
            success={successMsg}
            snackbarClose={() => {
              // setError(false);
              setSuccess("");
            }}
          />
        ) : (
          ""
        )}
        {openCheckConfirmDialog !== false && (
          <CssDialog
            noReset={true}
            height="240px"
            width="240px"
            header={`Confirmation`}
            addButtonWidth="75px"
            addButton={
              (openCheckConfirmDialog,
              openCheckConfirmDialog === true
                ? (tableList.length === selectedListArray.length) === true
                  ? "Remove"
                  : "Add"
                : (selectedListArray.indexOf(openCheckConfirmDialog.id) !==
                    -1) ===
                  true
                ? "Remove"
                : "Add")
            }
            onClose={() => {
              setOpenCheckConfirmDialog(false);
            }}
            snackbarClose={() => {
              setOpenCheckConfirmDialog(false);
            }}
            onSubmit={() => {
              // if (openCheckConfirmDialog === true) {
              //   selectALlCheckox();
              // } else {
              //   selectSingleCheckox(openCheckConfirmDialog);
              // }
              if (addModuleToRole) {
                addModuleToRole(
                  openCheckConfirmDialog === true
                    ? null
                    : openCheckConfirmDialog,
                  openCheckConfirmDialog === true
                    ? tableList.length === selectedListArray.length
                    : selectedListArray.indexOf(openCheckConfirmDialog.id) !==
                        -1
                );
              }
              setOpenCheckConfirmDialog(false);
            }}
            children={() => {
              return checkboxConfirmation
                ? checkboxConfirmation(
                    openCheckConfirmDialog,
                    openCheckConfirmDialog === true
                      ? tableList.length === selectedListArray.length
                      : selectedListArray.indexOf(openCheckConfirmDialog.id) !==
                          -1
                  )
                : "";
            }}
          />
        )}
        {/* {noSearchBar ? (
          ""
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            {Title ? (
              <div
                className="widthFC fontWeight400 alignC d-flex"
                style={{ fontSize: "23px" }}
              >
                {Title}
              </div>
            ) : (
              ""
            )}
            <StyledSearchBarNew
              padding={"9px"}
              bgC={"#F2F2F2"}
              fieldName={
                props.searchFiled
                  ? props.searchFiled
                  : props.header
                  ? props.header
                  : Object.keys(props.tableList[0])
                  ? Object.keys(props.tableList[0])
                  : ["id"]
              }
              copyData={tableListCopy}
              filteredData={(filterNotify) => {
                setTableList(filterNotify);
              }}
              onClear={() => {
                setTableList(tableListCopy);
              }}
              placeholder={`Search ${module}`}
            />
            {Title ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {filterChild ? filterChild() : ""}
                {noAddButton ? (
                  ""
                ) : (
                  <Button
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedList(null);
                      if (props.onAdd) {
                        props.onAdd();
                      }
                      if (props.reset) {
                        props.reset();
                      }
                    }}
                    style={{
                      background: "rgb(241 154 48)",
                      color: "white",
                      textTransform: "capitalize",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PlusIcon />
                    </div>
                    Add New {module}
                  </Button>
                )}
              </div>
            )}
          </div>
        )} */}
        {openDialog === true || editList ? (
          customCretae ? (
            customCretae === "survay report" ? (
              <CreateDialogForSurvayReport
                {...props}
                inputArray={props.inputArray ? props.inputArray : inputArr}
                editList={editList}
                selectedList={selectedList === null ? false : selectedList}
                handleClose={() => {
                  setOpenDialog(false);
                  setEditList(false);
                }}
                fetchAgain={(res, msg) => {
                  setSuccess(msg);
                  setOpenDialog(false);
                  setEditList(false);
                  if (props.fetchAgain) {
                    props.fetchAgain(res);
                  }
                }}
                postData={props.postData}
              />
            ) : (
              <CreateDialogPO
                {...props}
                inputArray={props.inputArray ? props.inputArray : inputArr}
                editList={editList}
                selectedList={selectedList === null ? false : selectedList}
                handleClose={() => {
                  setOpenDialog(false);
                  setEditList(false);
                }}
                fetchAgain={(res, msg) => {
                  setOpenDialog(false);
                  setEditList(false);
                  if (props.fetchAgain) {
                    props.fetchAgain(res);
                  }
                  setSuccess(msg);
                }}
                postData={props.postData}
              />
            )
          ) : (
            <CreateDialog
              {...props}
              inputArray={props.inputArray ? props.inputArray : inputArr}
              editList={editList}
              selectedList={selectedList === null ? false : selectedList}
              handleClose={() => {
                setOpenDialog(false);
                setEditList(false);
              }}
              fetchAgain={(res, msg) => {
                setOpenDialog(false);
                setEditList(false);

                if (props.fetchAgain) {
                  props.fetchAgain(res);
                }
                setSuccess(msg);
              }}
              postData={props.postData}
            />
          )
        ) : (
          ""
        )}{" "}
        <Popover
          open={openPopover}
          // onRequestClose={() => {
          //   setOpenPopover(false);
          //   setAnchorEl(null);
          // }}
          anchorEl={anchorEl}
          style={{ marginTop: "-2.5%" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}>
          <ClickAwayListener
            onClickAway={() => {
              setOpenPopover(false);
              setAnchorEl(null);
            }}>
            <List>
              {actions &&
                actions.map((singleAction) => (
                  <ListItem
                    key={`${singleAction.name}i`}
                    button
                    onClick={singleAction.onClick}
                    style={styles.listItemsStyle}>
                    <p
                      className="d-flex alignC textC"
                      style={{
                        margin: "0",
                        fontSize: "0.9rem",
                        height: "24px",
                      }}>
                      {singleAction.name}
                    </p>
                  </ListItem>
                ))}
            </List>
          </ClickAwayListener>
        </Popover>
        <>
          <div
            className="width-100"
            style={{
              // margin: padding ? "0" : "20px 0 0 0",
              height: "100%",
            }}>
            <Paper
              className="d-flex d-flex-column scrollBluePrimary overflowXA"
              style={{
                height:
                  bottomBar === true && tableList && tableList.length > 0
                    ? "calc(100% - 40px)"
                    : "calc(100% - 42px)",
                width: "100%",
                // borderRadius: bottomBar === true ? "7px 7px 0 0" : "7px",
                //border: `1px solid ${primary}`,

                boxShadow: nobottomBarAction ? "none" : "",
              }}>
              {loader && loader === true ? (
                <div className="d-flex justifyC alignC width-100 height-100">
                  <CircularProgress />
                </div>
              ) : tableList && tableList.length > 0 ? (
                <>
                  <div
                    style={{
                      backgroundColor: "rgb(209 204 204 / 17%)",
                      color: primary,
                      borderColor: primary,
                      width: "fit-content",
                      minWidth: "100%",
                      tableLayout: "fixed",
                      display: "flex",
                      alignItems: "center",
                      height: "50px",
                      minHeight: "50px",
                      overflow: "hidden",
                      // borderRadius: "7px 7px 0 0",
                      fontSize: "0.9rem",
                      fontWeight: "500",
                    }}>
                    <div className="d-flex alignC justifySE width-100">
                      {checkbox ? (
                        <div
                          className="justifyC d-flex alignC"
                          style={{
                            margin: "12px 10px",
                            width: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            color: "#100f0f",
                            textTransform: "capitalize",
                            minWidth: "30px",
                          }}>
                          {removeallOption ? (
                            ""
                          ) : error ? (
                            <Checkbox
                              style={{
                                border: "1px solid red",
                              }}
                              onChange={() => {
                                if (
                                  (confirmToSelect &&
                                    confirmToSelect() === "success") ||
                                  !confirmToSelect
                                ) {
                                  if (setSelectedListArrayErrorSet) {
                                    setSelectedListArrayErrorSet(false);
                                  }
                                  setError(false);
                                  if (checkboxConfirmation) {
                                    setOpenCheckConfirmDialog(true);
                                  } else {
                                    selectALlCheckox();
                                  }
                                } else {
                                  if (confirmToSelectDenied) {
                                    confirmToSelectDenied();
                                  }
                                }
                              }}
                              edge="start"
                              checked={
                                tableList.length === selectedListArray.length
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": "head" }}
                            />
                          ) : (
                            <Checkbox
                              onChange={() => {
                                if (
                                  (confirmToSelect &&
                                    confirmToSelect() === "success") ||
                                  !confirmToSelect
                                ) {
                                  if (setSelectedListArrayErrorSet) {
                                    setSelectedListArrayErrorSet(false);
                                  }
                                  setError(false);
                                  if (checkboxConfirmation) {
                                    setOpenCheckConfirmDialog(true);
                                  } else {
                                    selectALlCheckox();
                                  }
                                } else {
                                  if (confirmToSelectDenied) {
                                    confirmToSelectDenied();
                                  }
                                }
                              }}
                              edge="start"
                              checked={
                                tableList.length === selectedListArray.length
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": "head" }}
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {customButton ? (
                        <div
                          className="justifyC d-flex alignC"
                          style={{
                            margin: "12px 10px",
                            width: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            color: "#100f0f",
                            textTransform: "capitalize",
                            minWidth: "24px",
                          }}></div>
                      ) : (
                        ""
                      )}
                      {header &&
                        header.map((name) => (
                          <div
                            key={`${name}`}
                            className="justifyC d-flex alignC"
                            style={{
                              margin: "12px 10px",
                              width: widthList[name]
                                ? widthList[name]
                                : "fit-content",
                              display: "flex",
                              alignItems: "center",
                              color: "rgb(16 15 15 / 48%)",
                              textTransform: "capitalize",
                            }}>
                            {name}
                          </div>
                        ))}
                      {actions && actions.length > 0 && (
                        <div
                          className="justifyC d-flex alignC"
                          style={{
                            margin: "12px 10px",
                            width: "50px",
                            display: "flex",
                            alignItems: "center",
                            color: "#100f0f",
                            textTransform: "capitalize",
                          }}>
                          Action
                        </div>
                      )}
                      {props.subMenu && (
                        <div
                          className="justifyC d-flex alignC"
                          style={{
                            margin: "12px 10px",
                            width: "23px",
                            display: "flex",
                            alignItems: "center",
                            color: "#100f0f",
                            textTransform: "capitalize",
                          }}
                        />
                      )}{" "}
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      height: "calc(100% - 50px)",
                      overflowY: "auto",
                      width: "fit-content",
                      minWidth: "100%",
                      position: "relative",
                    }}>
                    {tableList.map((singleList, i) => (
                      <div
                        key={`${i}`}
                        onClick={() => {
                          if (singleOnclick) {
                            singleOnclick(singleList);
                          }
                        }}
                        style={
                          singleOnclick
                            ? {
                                cursor: "pointer",
                                background: singleList.background
                                  ? singleList.background
                                  : "",
                              }
                            : {
                                background: singleList.background
                                  ? singleList.background
                                  : "",
                              }
                        }
                        className={`d-flex width-100 d-flex-column ${
                          tableList.length === i + 1 ? "" : "dividerBorder"
                        }`}>
                        <div
                          className="d-flex width-100 justifySE"
                          style={{
                            margin: "1px 0 0 0",
                            backgroundColor: singleList.background
                              ? singleList.background
                              : colorArr[i % colorArr.length],
                            height: "54px",
                            fontSize: "0.9rem",
                          }}>
                          {checkbox ? (
                            <div
                              className="d-flex alignC justifyC"
                              style={{
                                margin: "12px 10px",
                                width: "fit-content",
                                textTransform: "capitalize",
                              }}>
                              {error === true ? (
                                <Checkbox
                                  style={{
                                    border: "1px solid red",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      (confirmToSelect &&
                                        confirmToSelect(singleList) ===
                                          "success") ||
                                      !confirmToSelect
                                    ) {
                                      if (checkboxConfirmation) {
                                        setOpenCheckConfirmDialog(singleList);
                                      } else {
                                        selectSingleCheckox(singleList);
                                      }
                                    } else {
                                      if (confirmToSelectDenied) {
                                        confirmToSelectDenied(singleList);
                                      }
                                    }
                                  }}
                                  edge="start"
                                  checked={
                                    selectedListArray.indexOf(singleList.id) !==
                                    -1
                                  }
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": "head" }}
                                />
                              ) : (
                                <Checkbox
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      (confirmToSelect &&
                                        confirmToSelect(singleList) ===
                                          "success") ||
                                      !confirmToSelect
                                    ) {
                                      if (checkboxConfirmation) {
                                        setOpenCheckConfirmDialog(singleList);
                                      } else {
                                        selectSingleCheckox(singleList);
                                      }
                                    } else {
                                      if (confirmToSelectDenied) {
                                        confirmToSelectDenied(singleList);
                                      }
                                    }
                                  }}
                                  edge="start"
                                  checked={
                                    selectedListArray.indexOf(singleList.id) !==
                                    -1
                                  }
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": "head" }}
                                />
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {customButton ? (
                            <div
                              className="d-flex alignC justifyC"
                              style={{
                                margin: "12px 10px",
                                width: "fit-content",
                                textTransform: "capitalize",
                              }}>
                              {customButton()}
                            </div>
                          ) : (
                            ""
                          )}
                          {header &&
                            header.map((name) => (
                              <div
                                key={`${name}2`}
                                className="d-flex alignC justifyC"
                                style={{
                                  margin: "12px 10px",
                                  width: widthList[name]
                                    ? widthList[name]
                                    : "fit-content",
                                  textTransform:
                                    name === "Email"
                                      ? "lowercase"
                                      : "capitalize",
                                }}>
                                {name === "Sr.No." ? (
                                  props.descending ? (
                                    tableList.length - i < 10 ? (
                                      `0${tableList.length - i}`
                                    ) : (
                                      tableList.length - i
                                    )
                                  ) : i + 1 < 10 ? (
                                    `0${i + 1}`
                                  ) : (
                                    i + 1
                                  )
                                ) : singleList[name] ||
                                  singleList[name] === 0 ? (
                                  typeof singleList[name] === "object" ? (
                                    singleList[name] &&
                                    singleList[name].length &&
                                    singleList[name].length >
                                      (0)
                                        .map((sName) => {
                                          return sName[
                                            singleList[`${name}Join`]
                                              ? singleList[`${name}Join`]
                                              : ""
                                          ];
                                        })
                                        .join(" ,")
                                  ) : singleList[name] === "paid" ||
                                    singleList[name] === "Paid" ||
                                    singleList[name] === "PAID" ||
                                    singleList[name] === "pending" ||
                                    singleList[name] === "Pending" ||
                                    singleList[name] === "PENDING" ||
                                    singleList[name] === "unpaid" ||
                                    singleList[name] === "UNPAID" ||
                                    singleList[name] === "Unpaid" ? (
                                    <div
                                      style={{
                                        padding: "5px",
                                        background:
                                          singleList[name] === "paid" ||
                                          singleList[name] === "PAID" ||
                                          singleList[name] === "Paid"
                                            ? green
                                            : singleList[name] === "pending" ||
                                              singleList[name] === "Pending" ||
                                              singleList[name] === "PENDING"
                                            ? yellow
                                            : red,
                                        borderRadius: "7px",
                                        color: "white",
                                        textTransform: "capitalize",
                                      }}>
                                      {singleList[name]}
                                    </div>
                                  ) : singleList[`${name}Unit`] ? (
                                    singleList[`${name}Unit`]
                                  ) : singleList[`${name}UI`] ? (
                                    typeof singleList[`${name}UI`] ===
                                    "function" ? (
                                      singleList[`${name}UI`]()
                                    ) : (
                                      singleList[`${name}UI`]
                                    )
                                  ) : typeof singleList[name] === "function" ? (
                                    singleList[name]()
                                  ) : (
                                    singleList[name]
                                  )
                                ) : (
                                  "-"
                                )}
                              </div>
                            ))}
                          {actions && actions.length > 0 && (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenPopover(true);
                                setAnchorEl(e.currentTarget);
                                setSelectedList(singleList);
                                if (props.setSelectedList) {
                                  props.setSelectedList(singleList);
                                }
                              }}
                              className="d-flex alignC justifyC"
                              style={{
                                margin: "12px 10px",
                                width: "50px",
                              }}>
                              <MoreHorizOutlined />
                            </div>
                          )}{" "}
                          {props.subMenu && (
                            <StyledButton
                              minWidth="10px"
                              margin="0"
                              onClick={() => {
                                setSubmenuWidth(singleList.subList);
                                setmenuOpen(
                                  menuOpen === false
                                    ? i
                                    : i !== menuOpen
                                    ? i
                                    : false
                                );
                              }}
                              name={
                                menuOpen === i ? (
                                  <TopArrow color={"rgba(0, 0, 0, 0.87)"} />
                                ) : (
                                  <BottomArrow color={"rgba(0, 0, 0, 0.87)"} />
                                )
                              }
                            />
                          )}
                        </div>
                        <Divider className="width-100" />
                        {menuOpen === i ? (
                          singleList &&
                          singleList.subList &&
                          singleList.subList.length > 0 ? (
                            <div className="d-flex width d-flex-column">
                              <div
                                style={{
                                  color: primary,
                                  borderColor: primary,
                                  width: "100%",
                                  minWidth: "fit-content",
                                  display: "flex",
                                  alignItems: "center",
                                  height: "34px",
                                  minHeight: "34px",
                                  overflow: "hidden",
                                  borderRadius: "7px 7px 0 0",
                                }}>
                                <div className="d-flex alignC width-100">
                                  {props.subMenu && (
                                    <div
                                      className="justifyC d-flex alignC"
                                      style={{
                                        margin: "12px 10px",
                                        width: "23px",
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#100f0f",
                                        textTransform: "capitalize",
                                      }}
                                    />
                                  )}
                                  {props.subMenuheader &&
                                    props.subMenuheader.map((name) => (
                                      <div
                                        key={`${name}2`}
                                        className="justifyC d-flex alignC"
                                        style={{
                                          margin: "12px 10px",
                                          width: widthList[`SUB${name}`]
                                            ? widthList[`SUB${name}`]
                                            : "fit-content",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "#100f0f",
                                          textTransform: "capitalize",
                                          fontSize: "0.9rem",
                                          fontWeight: "500",
                                        }}>
                                        {name}
                                      </div>
                                    ))}
                                  {props.subMenuAction &&
                                    props.subMenuAction.length > 0 && (
                                      <div
                                        className="justifyC d-flex alignC"
                                        style={{
                                          margin: "12px 10px",
                                          width: "50px",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "#100f0f",
                                          textTransform: "capitalize",
                                        }}>
                                        Action
                                      </div>
                                    )}
                                </div>
                              </div>
                              {singleList &&
                              singleList.subList &&
                              singleList.subList.length > 0
                                ? singleList.subList.map((sList, i3) => (
                                    <div
                                      key={`${i3}2`}
                                      className="d-flex width-100"
                                      style={{
                                        margin: "1px 0 0 0",
                                        backgroundColor:
                                          colorArr[i3 % colorArr.length],
                                        height: "34px",
                                        minHeight: "34px",
                                        minWidth: "fit-content",
                                      }}>
                                      {props.subMenu && (
                                        <div
                                          className="justifyC d-flex alignC"
                                          style={{
                                            margin: "12px 10px",
                                            width: "23px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#100f0f",
                                            textTransform: "capitalize",
                                          }}
                                        />
                                      )}
                                      {props.subMenuheader &&
                                        props.subMenuheader.map((name) => (
                                          <div
                                            key={`${name}2name`}
                                            className="d-flex alignC justifyC"
                                            style={{
                                              margin: "12px 10px",
                                              width: widthList[`SUB${name}`]
                                                ? widthList[`SUB${name}`]
                                                : "fit-content",
                                              textTransform: "capitalize",
                                              fontSize: "0.9rem",
                                            }}>
                                            {name === "Sr.No." ? (
                                              props.descending ? (
                                                singleList.subList.length - i3 <
                                                10 ? (
                                                  `0${
                                                    singleList.subList.length -
                                                    i3
                                                  }`
                                                ) : (
                                                  singleList.subList.length - i3
                                                )
                                              ) : i3 + 1 < 10 ? (
                                                `0${i3 + 1}`
                                              ) : (
                                                i3 + 1
                                              )
                                            ) : sList[name] ? (
                                              sList[name] === "paid" ||
                                              sList[name] === "Paid" ||
                                              sList[name] === "PAID" ||
                                              singleList[name] === "pending" ||
                                              singleList[name] === "Pending" ||
                                              singleList[name] === "PENDING" ||
                                              sList[name] === "unpaid" ||
                                              sList[name] === "UNPAID" ||
                                              sList[name] === "Unpaid" ? (
                                                <div
                                                  style={{
                                                    padding: "5px",
                                                    background:
                                                      sList[name] === "paid" ||
                                                      sList[name] === "PAID" ||
                                                      sList[name] === "Paid"
                                                        ? green
                                                        : singleList[name] ===
                                                            "pending" ||
                                                          singleList[name] ===
                                                            "Pending" ||
                                                          singleList[name] ===
                                                            "PENDING"
                                                        ? yellow
                                                        : red,
                                                  }}>
                                                  {sList[name]}
                                                </div>
                                              ) : sList[`${name}Unit`] ? (
                                                sList[`${name}Unit`]
                                              ) : sList[`${name}UI`] ? (
                                                sList[`${name}UI`]
                                              ) : (
                                                sList[name]
                                              )
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  ))
                                : ""}
                            </div>
                          ) : (
                            <div className="d-flex width d-flex-column">
                              <div
                                style={{
                                  color: primary,
                                  borderColor: primary,
                                  justifyContent: "center",
                                  width: "fit-content",
                                  minWidth: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  height: "34px",
                                  minHeight: "34px",
                                  overflow: "hidden",
                                  borderRadius: "7px 7px 0 0",
                                }}>
                                No List added!
                              </div>
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                  <AddFileIcon />
                  <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
                    {/* No {module} yet ! */}
                    No data yet !
                  </span>
                </div>
              )}

              {console.log(pagination, "pagination")}
            </Paper>
            {tableList && tableList.length > 0 && (
              // &&((pagination?.count??0)>tableList.length)
              <div
                className="width-100 d-flex alignC justifyC"
                style={{ margin: "10px 0 0" }}>
                <style>{`.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected{border-radius:50px;background-color:rgba(241, 154, 48, 1)}`}</style>
                <Pagination
                  showFirstButton
                  showLastButton
                  onChange={handleChangePage}
                  defaultPage={1}
                  rowsPerPage={10}
                  count={
                    pagination?.count
                      ? Number(
                          parseInt(
                            `${Math.ceil(divide(pagination?.count, 10))}`
                          )
                        )
                      : 0
                  }
                  page={page}
                />
              </div>
            )}
            {bottomBar === true && tableList && tableList.length > 0 && (
              <div
                className={`${nobottomBarAction ? "dividerBorder" : ""}`}
                style={{
                  backgroundColor: nobottomBarAction
                    ? ""
                    : "rgb(158 158 158 / 48%)",

                  color: primary,
                  width: "100%",
                  minWidth: "100%",
                  tableLayout: "fixed",
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                  minHeight: "40px",
                  overflow: "hidden",
                  borderRadius: "0 0 7px 7px",
                  fontSize: "0.9rem",
                  fontWeight: "500",
                }}>
                <div
                  className="d-flex alignC width-100"
                  style={{
                    justifyContent: nobottomBarAction
                      ? "space-evenly"
                      : "flex-end",
                  }}>
                  {nobottomBarAction ? (
                    <>
                      {" "}
                      {checkbox && (
                        <div
                          className="d-flex alignC justifyC"
                          style={{
                            margin: "12px 10px",
                            width: "30px",
                            textTransform: "capitalize",
                            minWidth: "30px",
                          }}></div>
                      )}
                      {header &&
                        header.map((name) => (
                          <div
                            key={`${name}2`}
                            className="d-flex alignC justifyC"
                            style={{
                              margin: "12px 10px",
                              width: widthList[name]
                                ? widthList[name]
                                : "fit-content",
                              textTransform:
                                name === "Email" ? "lowercase" : "capitalize",
                              color:
                                bottomBarheader &&
                                ((bottomBarheader.includes(name) &&
                                  name === "Total Quantity") ||
                                  (bottomBarheader.includes(name) &&
                                    name === "Shipped Quantity"))
                                  ? checkbox
                                    ? selectedListArray.length > 0 && weight
                                      ? selectedListArray
                                          .map((s) => {
                                            return filterData(
                                              tableList,
                                              "id",
                                              Number(s),
                                              "onlyOne"
                                            )
                                              ? filterData(
                                                  tableList,
                                                  "id",
                                                  Number(s),
                                                  "onlyOne"
                                                )
                                              : "";
                                          })
                                          .reduce(
                                            (previousValue, currentValue) =>
                                              Number(previousValue) +
                                              Number(
                                                currentValue[name]
                                                  ? changeUnit &&
                                                    (name ===
                                                      "Total Quantity" ||
                                                      name ===
                                                        "Shipped Quantity") &&
                                                    currentValue.unit
                                                    ? changeUnit(
                                                        currentValue[name],
                                                        currentValue.unit
                                                      )
                                                    : currentValue[name]
                                                  : 0
                                              ),
                                            0
                                          ) > weight
                                        ? "#FF3D00"
                                        : ""
                                      : ""
                                    : tableList.reduce(
                                        (previousValue, currentValue) =>
                                          Number(previousValue) +
                                          Number(
                                            currentValue[name]
                                              ? changeUnit &&
                                                (name === "Total Quantity" ||
                                                  name ===
                                                    "Shipped Quantity") &&
                                                currentValue.unit
                                                ? changeUnit(
                                                    currentValue[name],
                                                    currentValue.unit
                                                  )
                                                : currentValue[name]
                                              : 0
                                          ),
                                        0
                                      ) > weight
                                    ? "#FF3D00"
                                    : ""
                                  : "",
                            }}>
                            {bottomBarheader && bottomBarheader.includes(name)
                              ? checkbox
                                ? selectedListArray.length > 0 && weight
                                  ? `${selectedListArray
                                      .map((s) => {
                                        return filterData(
                                          tableList,
                                          "id",
                                          Number(s),
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              tableList,
                                              "id",
                                              Number(s),
                                              "onlyOne"
                                            )
                                          : "";
                                      })
                                      .reduce(
                                        (previousValue, currentValue) =>
                                          Number(previousValue) +
                                          Number(
                                            currentValue[name]
                                              ? changeUnit &&
                                                (name === "Total Quantity" ||
                                                  name ===
                                                    "Shipped Quantity") &&
                                                currentValue.unit
                                                ? changeUnit(
                                                    currentValue[name],
                                                    currentValue.unit
                                                  )
                                                : currentValue[name]
                                              : 0
                                          ),
                                        0
                                      )} ${
                                      name === "Total Quantity" ||
                                      name === "Shipped Quantity"
                                        ? singleUnit
                                          ? singleUnit
                                          : ""
                                        : ""
                                    }`
                                  : ""
                                : `${
                                    name === "Bags"
                                      ? tableList.reduce(
                                          (previousValue, currentValue) =>
                                            Number(previousValue) +
                                            Number(
                                              currentValue[name]
                                                ? changeUnit &&
                                                  (name === "Total Quantity" ||
                                                    name ===
                                                      "Shipped Quantity") &&
                                                  currentValue.unit
                                                  ? changeUnit(
                                                      currentValue[name],
                                                      currentValue.unit
                                                    )
                                                  : currentValue[name]
                                                : 0
                                            ),
                                          0
                                        )
                                      : tableList
                                          .reduce(
                                            (previousValue, currentValue) =>
                                              Number(previousValue) +
                                              Number(
                                                currentValue[name]
                                                  ? changeUnit &&
                                                    (name ===
                                                      "Total Quantity" ||
                                                      name ===
                                                        "Shipped Quantity") &&
                                                    currentValue.unit
                                                    ? changeUnit(
                                                        currentValue[name],
                                                        currentValue.unit
                                                      )
                                                    : currentValue[name]
                                                  : 0
                                              ),
                                            0
                                          )
                                          .toFixed(3)
                                  } ${
                                    name === "Total Quantity" ||
                                    name === "Shipped Quantity"
                                      ? singleUnit
                                        ? singleUnit
                                        : ""
                                      : ""
                                  }`
                              : ""}
                          </div>
                        ))}
                    </>
                  ) : (
                    <>
                      {checkbox ? (
                        <div
                          className="justifyC d-flex alignC"
                          style={{
                            margin: "12px 10px",
                            width: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            color: "#100f0f",
                            textTransform: "capitalize",
                          }}>
                          {selectedListArray.length === 0
                            ? ""
                            : `Total Selected : ${selectedListArray.length}`}{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      <StyledButton
                        onClick={() => {
                          if (props.onCheckSubmit) {
                            if (selectedListArray.length > 0) {
                              const arreyToSEnd = selectedListArray.map(
                                (id) => {
                                  const filterData = tableList.filter(
                                    (sList) => sList.id === id
                                  )[0];

                                  return filterData;
                                }
                              );
                              props.onCheckSubmit(arreyToSEnd).then(() => {
                                setSelectedListArray([]);
                              });
                            } else {
                              setError(true);
                            }
                          }
                        }}
                        name={
                          props.buttonName ? props.buttonName : "Mark as Paid"
                        }
                        withBg={true}
                        // width="130px"
                        margin="0 10px"
                        padding="2px 5px"
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      </Paper>
    </>
  );
};

export default MainViewTable;
