import React, { useEffect } from "react";
import { connect } from "react-redux";
import NotifysComponent from "./NotifysComponent";
import { BrandsManagementsFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";

function NotifysContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.BrandsManagementsFunction("get", EndPoints["Notifys"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <NotifysComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  [EndPoints["Notifys"]]: state.modules[EndPoints["Notifys"]],
  loading: state.modules.loading,
});
const mapDispatchToProps = {
  BrandsManagementsFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotifysContainer);
