import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SalesIntentsComponent from "./SalesIntentsComponent";
import { SalesIntentsFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";

function SalesIntentsContainer(props) {
  const [masters, setMasters] = useState({});
  const [loader, setLoader] = useState(true);


  const fetchUsers = () => {
    if (props.user.uid) {

      props.SalesIntentsFunction("get", EndPoints["SalesIntents"]);
      props
      .SalesIntentsFunction(
        "get",
        "masters/item-category/list",
        null,
        //state.singlelistView.id,
        "ItemCateoryList",
        null,
        { is_active: "True" }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          const newMasters = masters;
          newMasters.ItemCateoryList = ItemCateoryList;
          setMasters(newMasters);
        }
        props
          .SalesIntentsFunction(
            "get",
            "masters/item-type/list",
            null,
            //state.singlelistView.id,
            "ItemList",
            null,
            { is_active: "True" }
          )
          .then((ItemList) => {
            if (ItemList) {
              const newMasters = masters;
              newMasters.ItemList = ItemList;
              setMasters(newMasters);
            }
            setLoader(false);

            props
            .SalesIntentsFunction(
              "get",
              "masters/contract-type/list",
              null,
              "ContractTypeList",
              null,
              { is_active: "True" }
            )
            .then((ContractTypeList) => {
              if (ContractTypeList) {
                const newMasters = masters;
                newMasters.ContractTypeList = ContractTypeList;
                setMasters(newMasters);
              }})
            
            // props
            //   .RatesFunction(
            //     "get",
            //     "dispatch/purchase-rate/list",
            //     null,
            //     //state.singlelistView.id,
            //     "PurchaseRate",
            //     null,
            //     { is_active: "True" }
            //   )
            //   .then((PurchaseRate) => {
  
            //     if (PurchaseRate) {
            //       const newMasters = masters;
            //       newMasters.PurchaseRate = PurchaseRate;
            //       setMasters(newMasters);
            //     }

            //     setLoader(false);
            //   });
          });
      });
      // props.SalesIntentsFunction(
      //   "get",
      //   EndPoints["SalesIntents"],
      //   null,
      //   EndPoints["SalesIntents"],
      //   null,
      //   // { is_active: "True" }
      // );
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <SalesIntentsComponent {...props} params={params}  masters={masters}/>;
}

const mapStateToProps = (state) => ({
 
  user: state.authentication.user,
  [EndPoints["SalesIntents"]]: state.modules[EndPoints["SalesIntents"]],
  SalesIntents: state.modules.SalesIntents,
  // [EndPoints["SalesIntents"]]: state.modules[EndPoints["SalesIntents"]],
  loading: state.modules.loading,
});
const mapDispatchToProps = {
  SalesIntentsFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesIntentsContainer);
