import React, { useEffect } from "react";
import { connect } from "react-redux";
import ContractTypesComponent from "./ContractTypesComponent";
import { ContractTypesFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function ContractTypesContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    // if (props.user.uid) {
    props.ContractTypesFunction("get", EndPoints["ContractTypes"]);
    // }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <ContractTypesComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  ContractTypes: state.modules.ContractTypes,
  loading: state.modules.loading,
  [EndPoints["ContractTypes"]]: state.modules[EndPoints["ContractTypes"]],
});
const mapDispatchToProps = {
  ContractTypesFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractTypesContainer);
