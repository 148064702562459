import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import { moduleURL } from "../../../http/config";
import { requests } from "../../../requests";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Backdrop } from "@material-ui/core";
import { StyledCircularProgress } from "../../../styles/StyledComponents";
import { StopScroll } from "../../../Regex";

export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "0 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  textField: {
    width: "40%",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
};

const NewForm = ({ closePopup, getFn }) => {
  const [fieldValues, setFieldValues] = useState({
    brokenPercentageInput: "",
    statusInput: true,
  });

  const [isValid, setIsValid] = useState({
    brokenPercentageInput: true,
  });
  const [error, setError] = useState("");
  const [pushNotier, setPushNotier] = useState(false);
  const [pushNoti, setPushNoti] = useState(false);
  const [loading, setLoading] = useState(false);

  const addNewHandler = (e) => {
    e.preventDefault();
    if (fieldValues.brokenPercentageInput.length === 0) {
      setIsValid((prev) => ({ ...prev, brokenPercentageInput: false }));
    }
    if (fieldValues.brokenPercentageInput.length > 0) {
      setLoading(true);

      const postURL = moduleURL("masters/item-type/list");
      requests({
        method: "post",
        url: postURL,
        data: {
          broken_percentage: fieldValues.brokenPercentageInput,
          is_active: fieldValues.statusInput,
        },
      }).then((res) => {
        if (res.error) {
         
          setError(res.data[0].message[0]);
         
          setPushNotier(true);
        } else {
          getFn();
          setError("");
          setPushNoti(true);
        }
      });
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={addNewHandler}>
    
        <div style={classes.formGroup}>
        
        <div style={{  margin: "10px" }}>
                      {labelText("Broken Percentage")}
          <TextField
            type="number"
            onFocus={StopScroll}
            // style={classes.textField}
            style={{width:"100%"}}
            variant="outlined"
     //       label="Broken Percentage *"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onKeyDown={(e) => {
              // evt.key === "." && evt.preventDefault();

              e.key === "e" && e.preventDefault();

              e.key === "E" && e.preventDefault();
              e.key === "-" && e.preventDefault();
              e.keyCode === 38 && e.preventDefault();
              e.keyCode === 40 && e.preventDefault();

              // if (e.key !== "Backspace") {
              //   if (fieldValues.brokenPercentageInput.includes(".")) {
              //     if (
              //       fieldValues.brokenPercentageInput.split(".")[1].length >= 2
              //     ) {
              //       var num = parseFloat(fieldValues.brokenPercentageInput);
              //       var cleanNum = num.toFixed(2);
              //       fieldValues.brokenPercentageInput = cleanNum;
              //       e.preventDefault();
              //     }
              //   }
              // }
            }}
            // endAdornment={<InputAdornment position="end">%</InputAdornment>}
            value={fieldValues.brokenPercentageInput}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({
                  ...prev,
                  brokenPercentageInput: true,
                }));
              }

              if (target.value <= 100) {
                setFieldValues((prev) => ({
                  ...prev,
                  brokenPercentageInput: target.value,
                }));
              }
            }}
            inputProps={{
              inputPattern: "d+.dd$",
            }}
            error={!isValid.brokenPercentageInput}
            helperText={
              isValid.brokenPercentageInput ? "" : "Enter Broken Percentage"
            }
          />
          </div>
        </div>
        <div style={classes.formActions}>
          <FormControlLabel
            control={
              <Switch
                disabled
                defaultChecked
                style={{ color: "#B0B0B0" }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: true,
                    }));
                  } else {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: false,
                    }));
                  }
                }}
              />
            }
            label={fieldValues.statusInput ? "Active" : "Inactive"}
          />
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "rgb(241 154 48)",
              color: "white",
              textTransform: "capitalize",
            }}
          >
            Add
          </Button>
        </div>
      </form>
      {loading ? (
        <Backdrop
          style={{ zIndex: 1, color: "#fff", position: "absolute" }}
          open={loading}
        >
          <StyledCircularProgress color="rgb(30, 53, 89)" />
        </Backdrop>
      ) : (
        ""
      )}

      <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setPushNoti(false);
          setLoading(false);

          closePopup();
        }}
      >
        <Alert style={{ backgroundColor: "rgb(241 154 48)" }}>
          New Item Type Added!
        </Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
      <Snackbar
        open={pushNotier}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="error"
        autoHideDuration={5000}
        onClose={() => {
          // setOpenPopup(false);
          setPushNotier(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}
      >
        <Alert severity="error">{error}</Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
    </div>
  );
};

export default NewForm;
