import React, { Component } from "react";
import NoData from "../styles/Illustrations/NoData";

class CommingSoon extends Component {
  render() {
    return (
      <div className="d-flex d-flex-column justifyC alignC height-100 width-100" style={{}}>
        <NoData />

        <div style={{ fontSize: "1.5rem", color: "grey", marginTop: "20px" }}>Coming Soon</div>
      </div>
    );
  }
}

export default CommingSoon;
