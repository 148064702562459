import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import { moduleURL } from "../../../http/config";
import { requests } from "../../../requests";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import { Backdrop } from "@material-ui/core";
import { StyledCircularProgress } from "../../../styles/StyledComponents";
import { StopScroll } from "../../../Regex";

const allUnits = [
  {
    value: "Kgs",
    label: "Kgs",
  },
  {
    value: "Qtl",
    label: "Qtl",
  },
  {
    value: "MT",
    label: "MT",
  },
];
const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    paddingTop: "15px",
    flexDirection: "column",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  textField: {
    width: "40%",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
};

const NewForm = ({ closePopup, getFn }) => {
  const [fieldValues, setFieldValues] = useState({
    bagInput: "",
    sizeInput: "",
    unitInput: "",
    bagWeightInput:"",
    statusInput: true,
  });

  const [isValid, setIsValid] = useState({
    bagInput: true,
    sizeInput: true,
    unitInput: true,
    bagWeightInput:true,
  });

  const [selectUnit, setSelectUnit] = useState("");

  const handleChange = (event) => {
    setSelectUnit(event.target.value);
    setFieldValues((prev) => ({
      ...prev,
      unitInput: event.target.value,
    }));
    if (event.target.value.length !== 0) {
      setIsValid((prev) => ({ ...prev, unitInput: true }));
    }
    // setFieldValues((prev) => ({
    //   ...prev,
    //   unitInput: event.target.value,
    // }));
  };
  const [error, setError] = useState("");
  const [pushNotier, setPushNotier] = useState(false);
  const [pushNoti, setPushNoti] = useState(false);
  const [loading, setLoading] = useState(false);

  const addNewHandler = (e) => {
    e.preventDefault();
    if (fieldValues.sizeInput.length === 0) {
      setIsValid((prev) => ({ ...prev, sizeInput: false }));
    }
    if (fieldValues.unitInput.length === 0) {
      setIsValid((prev) => ({ ...prev, unitInput: false }));
    }
    if (fieldValues.bagInput.length === 0) {
      setIsValid((prev) => ({ ...prev, bagInput: false }));
    }
    if (fieldValues.bagWeightInput.length === 0) {
      setIsValid((prev) => ({ ...prev, bagWeightInput: false }));
    }
    if (
      fieldValues.sizeInput.length > 0 &&
      fieldValues.bagInput.length > 0 &&
      fieldValues.unitInput.length > 0 &&
      fieldValues.bagWeightInput.length > 0 
    ) {
      setLoading(true);

      const postURL = moduleURL("masters/packaging/list");
      requests({
        method: "post",
        url: postURL,
        data: {
          bag_type: fieldValues.bagInput,
          size: fieldValues.sizeInput,
          unit: fieldValues.unitInput,
          is_active: fieldValues.statusInput,
          bag_weight:fieldValues.bagWeightInput
          // created_by: 0,
          // modified_by: 0,
        },
      }).then((res) => {
        if (res.error) {
         
          // setError(res.data[0].message[0]);
          setError(
            typeof res.data === "string"
              ? res.data
              : res.data.length > 0
              ? res.data
                  .map((s) => {
                    return `${s.field ? `${s.field} :` : ""} ${
                      typeof s.message === "string"
                        ? s.message
                        : s.message.length > 0 && s.message[0] && s.message[0]
                    }`;
                  })
                  .join(" ,")
              : res.data.response
              ? res.data.response.data.msg.error
                ? res.data.response.data.msg.error
                : res.data.response.data.msg
              : "Error !"
          );
         
          setPushNotier(true);
        } else {
          getFn();
          setError("");
          setPushNoti(true);
        }
      });
    }
  };

  const handleReset = () => {
    setFieldValues((prev) => ({ ...prev, bagInput: "" }));
    setFieldValues((prev) => ({ ...prev, sizeInput: "" }));
    setFieldValues((prev) => ({ ...prev, unitInput: "" }));
    setFieldValues((prev) => ({ ...prev, bagWeightInput: "" }));
    setSelectUnit("");
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={addNewHandler}>
        <div style={classes.formGroup}>
          <TextField
            type="number"
            onFocus={StopScroll}
            style={classes.textField}
            onKeyDown={(evt) => {
              // evt.key === "." && evt.preventDefault();

              evt.key === "e" && evt.preventDefault();

              evt.key === "E" && evt.preventDefault();
              evt.key === "-" && evt.preventDefault();
              evt.keyCode === 38 && evt.preventDefault();
              evt.keyCode === 40 && evt.preventDefault();
            }}
            variant="outlined"
            label="Size *"
            value={fieldValues.sizeInput}
            onChange={({ target }) => {
              setFieldValues((prev) => ({
                ...prev,
                sizeInput: target.value,
              }));
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, sizeInput: true }));
              }
            }}
            error={!isValid.sizeInput}
            helperText={isValid.sizeInput ? "" : "Enter Size "}
          />

          <TextField
            type="text"
            select
            style={classes.textField}
            variant="outlined"
            label="Unit *"
            // value={fieldValues.unitInput}
            value={selectUnit}
            // onChange={({ target }) => {
     
            //   setFieldValues((prev) => ({
            //     ...prev,
            //     unitInput: target.value,
            //   }));
            //   if (target.length !== 0) {
            //     setIsValid((prev) => ({ ...prev, unitInput: true }));
            //   }
            // }}
            onChange={handleChange}
            error={!isValid.unitInput}
            helperText={isValid.unitInput ? "" : "Select Unit"}>
            {allUnits.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div style={classes.formGroup}>
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
            label="Bag Type *"
            value={fieldValues.bagInput}
            onChange={({ target }) => {
              setFieldValues((prev) => ({
                ...prev,
                bagInput: target.value,
              }));
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, bagInput: true }));
              }
            }}
            error={!isValid.bagInput}
            helperText={isValid.bagInput ? "" : "Enter Bag Type "}
          />
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
            label="Bag Weight *"
            value={fieldValues.bagWeightInput}
            InputProps={{
              endAdornment: "grams",
            }}
            onChange={({ target }) => {
              setFieldValues((prev) => ({
                ...prev,
                bagWeightInput: target.value,
              }));
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, bagWeightInput: true }));
              }
            }}
            error={!isValid.bagWeightInput}
            helperText={isValid.bagWeightInput ? "" : "Enter Bag Weight "}
          />
        </div>

        <div style={classes.formActions}>
          <FormControlLabel
            control={
              <Switch
                disabled
                style={{ color: "#B0B0B0" }}
                defaultChecked
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: true,
                    }));
                  } else {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: false,
                    }));
                  }
                }}
              />
            }
            label={fieldValues.statusInput ? "Active" : "Inactive"}
          />

          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleReset}
              style={{
                marginRight: "20px",
                border: "none",
                textTransform: "capitalize",
              }}>
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "rgb(241 154 48)",
                color: "white",
                textTransform: "capitalize",
              }}>
              Add
            </Button>
          </div>
        </div>
      </form>
      {loading ? (
        <Backdrop
          style={{ zIndex: 1, color: "#fff", position: "absolute" }}
          open={loading}>
          <StyledCircularProgress color="rgb(30, 53, 89)" />
        </Backdrop>
      ) : (
        ""
      )}
      <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setPushNoti(false);
          setLoading(false);

          closePopup();
        }}>
        <Alert style={{ backgroundColor: "rgb(241 154 48)" }}>
          New Packaging Added!
        </Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
      <Snackbar
        open={pushNotier}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="error"
        autoHideDuration={5000}
        onClose={() => {
          // setOpenPopup(false);
          setPushNotier(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}>
        <Alert severity="error">{error}</Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
    </div>
  );
};

export default NewForm;
