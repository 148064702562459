import { EndPoints } from "../../utils/EndPoints";

const initialState = {
  loading: {
    //loads
    Chats: false,
    Rates: false,
    CashDiscount:false,
    Precarriers: false,
    Policys: false,
    SOpaymentTerms: false,
    Incoterms: false,
    ShippingTerms: false,
    Currencys: false,
    Notifys: false,
    RiceBagSellers: false,
    Beneficiarys: false,
    Shippers: false,
    UsersAndPermissions: false,
    UsersPermissions: false,
    BrandsManagements: false,
    Millers: false,
    Brokers: false,
    [EndPoints["Transporters"]]: false,
    Transporters: false,
    Ports: false,
    HsNs: false,
    Packagings: false,
    [EndPoints["ContractTypes"]]: false,
    ContractTypes: false,
    PaymentTerms: false,
    [EndPoints["ItemTypes"]]: false,
    ItemTypes: false,
    [EndPoints["ItemCategories"]]: false,
    ItemCategories: false,
    [EndPoints["SurveyCompanys"]]: false,
    SurveyCompanys: false,
    Clients: false,
    Todos: false,

    Trips: false,
    Dispatches: false,
    PurchaseOrders: false,
    [EndPoints["SalesOrders"]]: false,
   SalesIntents:false,

    [EndPoints["SalesOrders"]]: false,
    SalesOrders: false,
    MillerList: false,
    BrandList: false,

    // Users: false,
  },
  //init states
  Chats: [],
  Rates: [],
  SOpaymentTerms:[],
  Precarriers: [],
  Policys: [],
  SOpaymentTerms: [],
  Incoterms: [],
  ShippingTerms: [],
  Currencys: [],
  Notifys: [],
  RiceBagSellers: [],
  Beneficiarys: [],
  Shippers: [],
  UsersAndPermissions: [],
  UsersPermissions: [],
  BrandsManagements: [],
  Millers: [],
  Brokers: [],
  [EndPoints["Transporters"]]: [],
  Transporters: [],
  Ports: [],
  HsNs: [],
  Packagings: [],
  [EndPoints["ContractTypes"]]: [],
  ContractTypes: [],
  PaymentTerms: [],
  [EndPoints["ItemTypes"]]: [],
  ItemTypes: [],
  [EndPoints["ItemCategories"]]: [],
  ItemCategories: [],
  [EndPoints["SurveyCompanys"]]: [],
  SurveyCompanys: [],
  Clients: [],

  Todos: [],
  Trips: [],
  Dispatches: [],
  PurchaseOrders: [],
  [EndPoints["SalesOrders"]]: [],
  [EndPoints["SalesOrders"]]: [],
  SalesOrders: [],
  MillerList: [],
  BrandList: [],

  // Users: [],
};

const moduleReducers = (state = initialState, action) => {
  const load = state.loading;
  switch (action.type) {
    case "FETCH_REQUEST":
      load[action.payload] = true;
      state[action.payload] = [];
      state.loading = load;
      return { ...state };
    case `FETCH_${action.payload}_SUCCESS`:
      load[action.payload] = false;
      state[action.payload] = action.data;
      state.loading = load;
      return { ...state };
    default:
      return { ...state };
  }
};

export default moduleReducers;
