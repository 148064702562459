import React from "react";
import Viewer, { SpecialZoomLevel } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { Worker } from "@phuocng/react-pdf-viewer";
import { CrossIcon } from "../../styles/Icons";
import PinchToZoom from "react-pinch-and-zoom";
import Layout from "../../layouts/Layout";
import { primary } from "../../styles/colors";
const AlwaysShowSidebar = ({ fileUrl, fileDownloadAccess, handleClose }) => {
  const renderToolbar = (toolbarSlot) => {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          width: "100%",
          fontSize: "0.5rem",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: "center",
            width: "calc(100% - 30px)",
            color: "white",
          }}
        >
          <div style={{ color: "white", padding: "0" }}>{toolbarSlot.previousPageButton}</div>
          <div style={{ color: "white", padding: "0 2px" }}>
            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
          </div>
          <div style={{ color: "white", padding: "0" }}>{toolbarSlot.nextPageButton}</div>
          <div style={{ color: "white", padding: "0" }}>{toolbarSlot.zoomOutButton}</div>
          <div style={{ color: "white", padding: "0" }}>{toolbarSlot.zoomPopover}</div>
          <div style={{ color: "white", padding: "0" }}>{toolbarSlot.zoomInButton}</div>
          <div style={{ color: "white", padding: "0" }}>{toolbarSlot.fullScreenButton}</div>
          {/* <div style={{ padding: "0 2px" }}>{toolbarSlot.openFileButton}</div> */}
          {/* <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div> */}

          {/* <div style={{ padding: "0" }}>{toolbarSlot.moreActionsPopover}</div> */}
        </div>{" "}
        <div
          style={{ padding: "0 10px" }}
          onClick={() => {
            handleClose();
          }}
        >
          <CrossIcon strokeWidth="1.5" />
        </div>
      </div>
    );
  };

  const layout = (isSidebarOpened, container, main, toolbar, sidebar) => {
    return (
      <div
        className="scrollBluePrimarythin"
        {...container.attrs}
        style={Object.assign(
          {},
          {
            height: "100%",
            width: "100%",
          },
          container.attrs.style
        )}
      >
        {container.children}
        <div
          style={{
            alignItems: "center",
            backgroundColor: primary,
            borderBottom: "1px solid rgba(0, 0, 0, .1)",
            display: "flex",
            gridArea: "toolbar",
            justifyContent: "center",
            padding: "4px",
            color: "white",
          }}
        >
          {toolbar(renderToolbar)}
        </div>

        <div
          className="list"
          {...main.attrs}
          style={Object.assign(
            {},
            {
              height: "calc(100% - 44px)",
              overflowY: "auto",
            },
            main.attrs.style
          )}
        >
          <PinchToZoom>{main.children}</PinchToZoom>
        </div>
      </div>
    );
  };
  return (
    // <Layout
    //   noAppbarNoBottomNav={true}
    //   arrowBackIconFunction={() => {
    //     handleClose();
    //   }}
    // >
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Viewer fileUrl={fileUrl} layout={layout} defaultScale={SpecialZoomLevel.PageFit} />
    </Worker>
    // </Layout>
  );
};

export default AlwaysShowSidebar;
