import React, { useEffect } from "react";
import { useState } from "react";
import CreateDialog from "../../layoutComponents/CreateDialog";
import {
  calculateExchangeValue,
  calculateGstAmount,
  calculatePercentage,
  calculatePrice,
  filterData,
  StopScroll,
} from "../../Regex";
import {
  CssTextField95,
  HeadingLine,
  ShowDetails,
  StyledButton,
  StyledLoadingButton,
} from "../../styles/StyledComponents";
import { sum, multiply, divide } from "lodash";
import { primary, red } from "../../styles/colors";
import { lightGrey, white } from "../../styles/colors";
import { Divider } from "@material-ui/core";
function ItemTableWIthINpitDetails(props) {
  const {
    postData,
    selected,
    selectedSo,
    masters,
    errorMsg,
    setErrorMsg,
    setData,
    editData,
    ci_type,
    onlyView,
    alldisabled,
  } = props;

  const [editDetail, seteditDetail] = useState({});
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);

  const [dialogOpen, setDialogOpen] = useState({ open: false, details: false });
  const unit = selectedSo && selectedSo.unit ? selectedSo.unit : "";
  const [submittedData, setSubmittedData] = useState({});
  useEffect(() => {
    setSubmittedData(editData);
    seteditDetail(editData);
  }, [editData]);
  const [inputArr, setInputArr] = useState([
    {
      disabled: true,
      name: "Net Weight",
      type: "textField",
      dataType: "number",
      api: "net_weight",
      defaultValue: {
        value:
          editDetail && editDetail[`Net Weight`]
            ? editDetail[`Net Weight`]
            : "",
      },
      endAdornment: unit,
    },
    {
      name: "Gross Weight",
      type: "textField",
      dataType: "number",
      endAdornment: unit,
      defaultValue: {
        value:
          editDetail && editDetail["Gross Weight"]
            ? editDetail["Gross Weight"]
            : "",
      },
      api: "gross_weight",
      disabled: alldisabled,
      onChange: async (sub) => {
        setSubmittedData((prev) => {
          return {
            ...prev,
            "Gross Weight": sub["Gross Weight"],
          };
        });
        return sub;
      },
    },
  ]);
  const inputArrCopy = [
    {
      disabled: true,
      name: "Net Weight",
      type: "textField",
      dataType: "number",
      api: "Net Weight",
      defaultValue: {
        value:
          editDetail && editDetail[`Net Weight`]
            ? editDetail[`Net Weight`]
            : "",
      },
      endAdornment: unit,
    },
    {
      name: "Gross Weight",
      type: "textField",
      dataType: "number",
      endAdornment: unit,
      defaultValue: {
        value:
          editDetail && editDetail["Gross Weight"]
            ? editDetail["Gross Weight"]
            : "",
      },
      api: "Gross Weight",
      disabled: alldisabled,
      onChange: async (sub) => {
        setSubmittedData((prev) => {
          return {
            ...prev,
            "Gross Weight": sub["Gross Weight"],
          };
        });
        return sub;
      },
    },
  ];
  const changeTo = (to, weigths, opposite) => {
    if (opposite) {
      if (to === "MT") {
        return multiply(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return multiply(weigths, 100);
      }
    } else {
      if (to === "MT") {
        return divide(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return divide(weigths, 100);
      }
    }
  };
  const changeUnit = (from, to, weigths, opposite) => {
    //default in kg

    if (opposite) {
      if (from === "MT") {
        return changeTo(to, divide(weigths, 1000), opposite);
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, divide(weigths, 100), opposite);
      }
    } else {
      if (from === "MT") {
        return changeTo(to, multiply(weigths, 1000));
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, multiply(weigths, 100));
      }
    }
  };
  const calculateNetWeight = (submiited) => {
    const allWeights =
      selected.packaging &&
      selected.packaging.length > 0 &&
      selected.packaging.map((sP) => {
        const pVal = filterData(
          masters.PackagingList,
          "id",
          Number(sP),
          "onlyOne"
        )
          ? filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
          : "";
        return changeUnit(
          pVal.unit,
          unit,
          multiply(
            Number(
              submiited[`${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`]
                ? submiited[
                    `${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`
                  ]
                : 0
            ),
            Number(pVal.size)
          ).toFixed(3)
        );
      });

    return allWeights.reduce(
      (previousValue, currentValue) =>
        Number(previousValue) + Number(currentValue ? currentValue : 0),
      0
    );
  };
  const rowHeader = [
    "Exchange rate",
    `Price (${
      selected && selected["Currency"] ? `${selected["Currency"]}` : ""
    }) PER ${unit}`,
    `Amount in ${
      selected && selected["Currency"] ? `${selected["Currency"]}` : ""
    }`,
    `PRICE (INR) PER ${unit}`,
    `Amount in (INR)`,
  ];
  useEffect(() => {
    if (selected && selected.packaging && dialogOpen.open === true) {
      const newInput = inputArrCopy.map((s, i) => {
        return {
          ...s,
          defaultValue: {
            value: editDetail && editDetail[s.api] ? editDetail[s.api] : "",
          },
        };
      });
      const ids = [];
      selected.packaging &&
        selected.packaging.length > 0 &&
        selected.packaging.map((sP) => {
          const pVal = filterData(
            masters.PackagingList,
            "id",
            Number(sP),
            "onlyOne"
          )
            ? filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
            : "";
          if (!ids.includes(sP)) {
            ids.push(sP);
            newInput.push({
              disabled: alldisabled,
              name: `${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`,
              type: "textField",
              dataType: "number",
              only_number: true,
              api: `${sP}-bags`,
              defaultValue: {
                value:
                  editDetail && editDetail[`${sP}-bags`]
                    ? editDetail[`${sP}-bags`]
                    : "",
              },
              endAdornment: `bags`,
              helperText: `${
                submittedData[`${sP}-bags`]
                  ? `Weight : ${multiply(
                      Number(submittedData[`${sP}-bags`]),
                      Number(pVal.size)
                    ).toFixed(3)}${pVal.unit}`
                  : ""
              }`,
              shrink: true,
              onChange: async (submiited) => {
                submiited["Net Weight"] = calculateNetWeight(submiited);
                const ofExhange = submittedData[
                  `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                ]
                  ? submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`]
                  : 0;
                const fvexchange = submittedData[
                  `Fob Value ${rowHeader.slice(2, 5)[2]}`
                ]
                  ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                  : 0;

                const insurance = submittedData[
                  `Insurance ${rowHeader.slice(2, 5)[2]}`
                ]
                  ? submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`]
                  : 0;
                setSubmittedData((prev) => {
                  return {
                    ...prev,
                    [`${sP}-bags`]:
                      submiited[
                        `${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`
                      ],
                    "Net Weight": calculateNetWeight(submiited),
                    [`Ocean Freight ${rowHeader.slice(2, 5)[1]}`]: Number(
                      calculatePrice(ofExhange, calculateNetWeight(submiited))
                    ).toFixed(3),
                    [`Fob Value ${rowHeader.slice(2, 5)[1]}`]: Number(
                      calculatePrice(fvexchange, calculateNetWeight(submiited))
                    ).toFixed(3),
                    [`Insurance ${rowHeader.slice(2, 5)[1]}`]: Number(
                      calculatePrice(insurance, calculateNetWeight(submiited))
                    ).toFixed(3),
                    [`Total ${rowHeader.slice(2, 5)[1]}`]: Number(
                      calculatePrice(
                        sum([ofExhange, insurance, insurance]),
                        calculateNetWeight(submiited)
                      )
                    ).toFixed(3),
                  };
                });
                return submiited;
              },
            });
          }
          return sP;
        });
     
      newInput.push({
        name: "Exchange Rate",
        type: "textField",
        dataType: "number",
        endAdornment: "INR",
        startAdornment: `1${
          selected && selected["Currency"] ? `${selected["Currency"]}` : ""
        }=`,
        defaultValue: {
          value:
            editDetail && editDetail["exchange_rate"]
              ? editDetail["exchange_rate"]
              : "",
        },
       
        onChange: async (sub) => {
          setSubmittedData((prev) => {
            const ofExhange = calculateExchangeValue(
              sub["Exchange Rate"] ? sub["Exchange Rate"] : 0,
              submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[0]}`]
                ? submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[0]}`]
                : 0
            );
            const fvexchange = calculateExchangeValue(
              sub["Exchange Rate"] ? sub["Exchange Rate"] : 0,
              submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                : 0
            );
            const insurance = calculateExchangeValue(
              sub["Exchange Rate"] ? sub["Exchange Rate"] : 0,
              submittedData[`Insurance ${rowHeader.slice(2, 5)[0]}`]
                ? submittedData[`Insurance ${rowHeader.slice(2, 5)[0]}`]
                : 0
            );
         
            return {
              ...prev,
              exchange_rate: sub["Exchange Rate"],
              [`Ocean Freight ${rowHeader.slice(2, 5)[2]}`]: ofExhange,
              [`Fob Value ${rowHeader.slice(2, 5)[2]}`]: fvexchange,
              [`Insurance ${rowHeader.slice(2, 5)[2]}`]: insurance,
              [`IGST Amount ${rowHeader.slice(2, 5)[2]}`]: calculatePercentage(
                sum([fvexchange]),
                Number(
                  submittedData["rate_of_igst"]
                    ? submittedData["rate_of_igst"]
                    : 0
                )
              ),
              [`Ocean Freight ${rowHeader.slice(2, 5)[1]}`]: Number(
                calculatePrice(
                  ofExhange,
                  submittedData["Net Weight"] ? submittedData["Net Weight"] : 0
                )
              ).toFixed(3),
              [`Fob Value ${rowHeader.slice(2, 5)[1]}`]: Number(
                calculatePrice(
                  fvexchange,
                  submittedData["Net Weight"] ? submittedData["Net Weight"] : 0
                )
              ).toFixed(3),
              [`Insurance ${rowHeader.slice(2, 5)[1]}`]: Number(
                calculatePrice(
                  insurance,
                  submittedData["Net Weight"] ? submittedData["Net Weight"] : 0
                )
              ).toFixed(3),

              [`Total ${rowHeader.slice(2, 5)[2]}`]: Number(
                sum([ofExhange, fvexchange, insurance])
              ).toFixed(3),

              [`Total ${rowHeader.slice(2, 5)[1]}`]: Number(
                calculatePrice(
                  sum([ofExhange, fvexchange, insurance]),
                  submittedData["Net Weight"] ? submittedData["Net Weight"] : 0
                )
              ).toFixed(3),
              [`Final ${rowHeader.slice(2, 5)[2]}`]:
                ci_type === "GST"
                  ? calculateGstAmount(
                      sum([ofExhange, fvexchange, insurance]),
                      Number(
                        submittedData["rate_of_igst"]
                          ? submittedData["rate_of_igst"]
                          : 0
                      ),
                      fvexchange
                    )
                  : sum([ofExhange, fvexchange, insurance]),
              // : submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`],
            };
          });
          return sub;
        },
        api: "exchange_rate",
        disabled: alldisabled,
      });
      if (ci_type === "GST") {
        newInput.push({
          name: "Rate of IGST ( IN % )",
          type: "textField",
          dataType: "number",
          endAdornment: "%",
          percentage: "100",
          defaultValue: {
            value:
              editDetail && editDetail["rate_of_igst"]
                ? editDetail["rate_of_igst"]
                : "",
          },
          onChange: async (sub) => {
            setSubmittedData((prev) => {
              return {
                ...prev,
                rate_of_igst: sub["Rate of IGST ( IN % )"],

                [`IGST Amount ${rowHeader.slice(2, 5)[2]}`]:
                  calculatePercentage(
                    sum([
                      Number(
                        submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                          ? submittedData[
                              `Fob Value ${rowHeader.slice(2, 5)[2]}`
                            ]
                          : 0
                      ),
                    ]),
                    Number(
                      sub["Rate of IGST ( IN % )"]
                        ? sub["Rate of IGST ( IN % )"]
                        : 0
                    )
                  ),
                [`Final ${rowHeader.slice(2, 5)[0]}`]: calculateGstAmount(
                  sum([
                    Number(
                      submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[0]}`]
                        ? submittedData[
                            `Ocean Freight ${rowHeader.slice(2, 5)[0]}`
                          ]
                        : 0
                    ),
                    Number(
                      submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                        ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                        : 0
                    ),
                    Number(
                      submittedData[`Insurance ${rowHeader.slice(2, 5)[0]}`]
                        ? submittedData[`Insurance ${rowHeader.slice(2, 5)[0]}`]
                        : 0
                    ),
                  ]),
                  Number(
                    sub["Rate of IGST ( IN % )"]
                      ? sub["Rate of IGST ( IN % )"]
                      : 0
                  ),
                  Number(
                    submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                      ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                      : 0
                  )
                ),
                [`Final ${rowHeader.slice(2, 5)[2]}`]: calculateGstAmount(
                  sum([
                    Number(
                      submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`]
                        ? submittedData[
                            `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                          ]
                        : 0
                    ),
                    Number(
                      submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                        ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                        : 0
                    ),
                    Number(
                      submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`]
                        ? submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`]
                        : 0
                    ),
                  ]),
                  Number(
                    sub["Rate of IGST ( IN % )"]
                      ? sub["Rate of IGST ( IN % )"]
                      : 0
                  ),
                  Number(
                    submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                      ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                      : 0
                  )
                ),
              };
            });
            return sub;
          },
          api: "rate_of_igst",
          disabled: alldisabled,
        });
      }
      if (
        sum([ci_type === "GST" ? 4 : 3, selected.packaging.length]) ===
        newInput.length
      ) {
        setInputArr(newInput.reverse());
        setLoader(false);
      }
    }
  }, [dialogOpen.open, submittedData, editDetail, ci_type]);

  const colorArr = [white, lightGrey];
  const valuesTable = () => {
    return (
      <div
        className="d-flex width-100 flexWrap "
        style={{ margin: "0 10px 10px" }}
      >
        {/* <Divider style={{ width: "100%", margin: "0" }} /> */}
        {/* <HeadingLine maxWidth="fit-content" width="fit-content" children={`Amount Details`} center={true} /> */}
        <div className="width-100 marginT10B20">
          <div
            style={{
              backgroundColor: "rgba(251, 224, 191, 1)",
              color: primary,
              borderColor: primary,
              width: "fit-content",
              minWidth: "100%",
              tableLayout: "fixed",
              display: "flex",
              minHeight: "30px",
              overflow: "hidden",
              borderRadius: "7px 7px 0 0",
            }}
          >
            <div
              className="d-flex justifySE width-100"
              style={{ background: "rgba(241, 154, 48, 1)" }}
            >
              {rowHeader.map((sC, i) => (
                <div
                  key={sC}
                  className="justifyC d-flex "
                  style={{
                    width: i === 0 ? "112px" : "140px",
                    minWidth: i === 0 ? "112px" : "140px",
                    maxWidth: i === 0 ? "112px" : "140px",
                    margin: "12px 10px",
                    display: "flex",
                    color: "#100f0f",
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                >
                  {i === 0
                    ? `${sC} ( ${
                        submittedData
                          ? submittedData["exchange_rate"]
                            ? submittedData["exchange_rate"]
                            : ""
                          : ""
                      } )`
                    : sC}
                </div>
              ))}{" "}
            </div>
          </div>
          <div
            style={{
              height: "fit-content",
              width: "100%",
              minWidth: "100%",
              position: "relative",
              borderRadius: "0 0 7px 7px",
              borderRight: "1px solid rgba(128, 128, 128, 0.64)",
              borderLeft: "1px solid rgba(128, 128, 128, 0.64)",
              borderBottom: "1px solid rgba(128, 128, 128, 0.64)",
              // padding: "5px 0",
            }}
          >
            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[2 % colorArr.length],
                minHeight: "60px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  minWidth: "274px",
                  margin: "12px 10px",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Fob Value
              </div>
              {rowHeader.slice(2, 5).map((singleInput, i) => (
                <CssTextField95
                  // autoFocus={}
                  required={true}
                  disabled={onlyView ? true : i === 2 || i === 1 ? true : false}
                  onFocus={StopScroll}
                  error={error[`Fob Value ${singleInput}`] ? true : false}
                  autoComplete="off"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{ width: "140px", minWidth: "140px", margin: "10px" }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedData[`Fob Value ${singleInput}`]
                      ? submittedData[`Fob Value ${singleInput}`]
                      : ""
                  }
                  name={singleInput}
                  onKeyDown={(evt) => {
                    evt.key === "e" && evt.preventDefault();
                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    evt.keyCode === 38 && evt.preventDefault();
                    evt.keyCode === 40 && evt.preventDefault();
                  }}
                  onChange={(event) => {
                    setSubmittedData({
                      ...submittedData,
                      [`Fob Value ${singleInput}`]: event.target.value,
                      [`Total ${singleInput}`]: sum([
                        Number(event.target.value ? event.target.value : 0),
                        Number(
                          submittedData[`Ocean Freight ${singleInput}`]
                            ? submittedData[`Ocean Freight ${singleInput}`]
                            : 0
                        ),
                        Number(
                          submittedData[`Insurance ${singleInput}`]
                            ? submittedData[`Insurance ${singleInput}`]
                            : 0
                        ),
                      ]),
                      [`Fob Value ${rowHeader.slice(2, 5)[1]}`]: Number(
                        i == 0
                          ? calculatePrice(
                              calculateExchangeValue(
                                submittedData["exchange_rate"]
                                  ? submittedData["exchange_rate"]
                                  : 0,
                                event.target.value ? event.target.value : 0
                              ),
                              submittedData["Net Weight"]
                                ? submittedData["Net Weight"]
                                : 0
                            )
                          : submittedData[
                              `Fob Value ${rowHeader.slice(2, 5)[1]}`
                            ]
                      ).toFixed(3),
                      [`IGST Amount ${rowHeader.slice(2, 5)[2]}`]:
                        calculatePercentage(
                          i === 0
                            ? sum([
                                calculateExchangeValue(
                                  submittedData["exchange_rate"]
                                    ? submittedData["exchange_rate"]
                                    : 0,
                                  event.target.value ? event.target.value : 0
                                ),
                              ])
                            : submittedData[
                                `Total ${rowHeader.slice(2, 5)[2]}`
                              ],
                          Number(
                            submittedData.rate_of_igst
                              ? submittedData.rate_of_igst
                              : 0
                          )
                        ),
                      [`Final ${singleInput}`]:
                        i === 0
                          ? calculateGstAmount(
                              sum([
                                Number(
                                  event.target.value ? event.target.value : 0
                                ),
                                Number(
                                  submittedData[`Ocean Freight ${singleInput}`]
                                    ? submittedData[
                                        `Ocean Freight ${singleInput}`
                                      ]
                                    : 0
                                ),
                                Number(
                                  submittedData[`Insurance ${singleInput}`]
                                    ? submittedData[`Insurance ${singleInput}`]
                                    : 0
                                ),
                              ]),
                              Number(
                                submittedData.rate_of_igst
                                  ? submittedData.rate_of_igst
                                  : 0
                              ),
                              Number(
                                event.target.value ? event.target.value : 0
                              )
                            )
                          : "",
                      [`Fob Value ${rowHeader.slice(2, 5)[2]}`]:
                        i === 0
                          ? calculateExchangeValue(
                              submittedData["exchange_rate"]
                                ? submittedData["exchange_rate"]
                                : 0,
                              event.target.value ? event.target.value : 0
                            )
                          : submittedData[
                              `Fob Value ${rowHeader.slice(2, 5)[2]}`
                            ],
                      [`Total ${rowHeader.slice(2, 5)[2]}`]:
                        i === 0
                          ? sum([
                              calculateExchangeValue(
                                submittedData["exchange_rate"]
                                  ? submittedData["exchange_rate"]
                                  : 0,
                                event.target.value ? event.target.value : 0
                              ),
                              submittedData[
                                `Insurance ${rowHeader.slice(2, 5)[2]}`
                              ],
                              submittedData[
                                `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                              ],
                            ])
                          : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`],
                      [`Total ${rowHeader.slice(2, 5)[1]}`]: Number(
                        calculatePrice(
                          i === 0
                            ? sum([
                                calculateExchangeValue(
                                  submittedData["exchange_rate"]
                                    ? submittedData["exchange_rate"]
                                    : 0,
                                  event.target.value ? event.target.value : 0
                                ),
                                submittedData[
                                  `Insurance ${rowHeader.slice(2, 5)[2]}`
                                ],
                                submittedData[
                                  `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                                ],
                              ])
                            : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]
                        ).toFixed(3),
                        submittedData["Net Weight"]
                          ? submittedData["Net Weight"]
                          : 0
                      ),

                      [`Final ${rowHeader.slice(2, 5)[2]}`]:
                        i === 0
                          ? calculateGstAmount(
                              sum([
                                calculateExchangeValue(
                                  submittedData["exchange_rate"]
                                    ? submittedData["exchange_rate"]
                                    : 0,
                                  event.target.value ? event.target.value : 0
                                ),
                                submittedData[
                                  `Insurance ${rowHeader.slice(2, 5)[2]}`
                                ],
                                submittedData[
                                  `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                                ],
                              ]),
                              Number(
                                submittedData.rate_of_igst
                                  ? submittedData.rate_of_igst
                                  : 0
                              ),
                              event.target.value ? event.target.value : 0
                            )
                          : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`],
                    });

                    setError({ ...error, [`Fob Value ${singleInput}`]: false });
                  }}
                  variant="outlined"
                />
              ))}
            </div>

            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[3 % colorArr.length],
                minHeight: "60px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  minWidth: "274px",
                  margin: "12px 10px",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Ocean Freight
              </div>
              {rowHeader.slice(2, 5).map((singleInput, i) => (
                <CssTextField95
                  // autoFocus={}

                  disabled={onlyView ? true : i === 2 || i === 1 ? true : false}
                  required={true}
                  onFocus={StopScroll}
                  error={error[`Ocean Freight ${singleInput}`] ? true : false}
                  autoComplete="off"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{ width: "140px", minWidth: "140px", margin: "10px" }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedData[`Ocean Freight ${singleInput}`]
                      ? submittedData[`Ocean Freight ${singleInput}`]
                      : ""
                  }
                  name={singleInput}
                  onKeyDown={(evt) => {
                    evt.key === "e" && evt.preventDefault();
                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    evt.keyCode === 38 && evt.preventDefault();
                    evt.keyCode === 40 && evt.preventDefault();
                  }}
                  onChange={(event) => {
                    setSubmittedData({
                      ...submittedData,
                      [`Ocean Freight ${singleInput}`]: event.target.value,
                      [`Ocean Freight ${rowHeader.slice(2, 5)[1]}`]: Number(
                        i == 0
                          ? calculatePrice(
                              calculateExchangeValue(
                                submittedData["exchange_rate"]
                                  ? submittedData["exchange_rate"]
                                  : 0,
                                event.target.value ? event.target.value : 0
                              ),
                              submittedData["Net Weight"]
                                ? submittedData["Net Weight"]
                                : 0
                            )
                          : submittedData[
                              `Ocean Freight ${rowHeader.slice(2, 5)[1]}`
                            ]
                      ).toFixed(3),
                      [`Total ${singleInput}`]: sum([
                        Number(event.target.value ? event.target.value : 0),
                        Number(
                          submittedData[`Fob Value ${singleInput}`]
                            ? submittedData[`Fob Value ${singleInput}`]
                            : 0
                        ),
                        Number(
                          submittedData[`Insurance ${singleInput}`]
                            ? submittedData[`Insurance ${singleInput}`]
                            : 0
                        ),
                      ]),
                      [`Final ${singleInput}`]:
                        i === 0 || i === 2
                          ? calculateGstAmount(
                              sum([
                                Number(
                                  event.target.value ? event.target.value : 0
                                ),
                                Number(
                                  submittedData[`Fob Value ${singleInput}`]
                                    ? submittedData[`Fob Value ${singleInput}`]
                                    : 0
                                ),
                                Number(
                                  submittedData[`Insurance ${singleInput}`]
                                    ? submittedData[`Insurance ${singleInput}`]
                                    : 0
                                ),
                              ]),
                              Number(
                                submittedData.rate_of_igst
                                  ? submittedData.rate_of_igst
                                  : 0
                              ),
                              Number(
                                submittedData[`Fob Value ${singleInput}`]
                                  ? submittedData[`Fob Value ${singleInput}`]
                                  : 0
                              )
                            )
                          : "",
                      [`Ocean Freight ${rowHeader.slice(2, 5)[2]}`]:
                        i === 0
                          ? calculateExchangeValue(
                              submittedData["exchange_rate"]
                                ? submittedData["exchange_rate"]
                                : 0,
                              event.target.value ? event.target.value : 0
                            )
                          : submittedData[
                              `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                            ],
                      [`Total ${rowHeader.slice(2, 5)[2]}`]:
                        i === 0
                          ? sum([
                              calculateExchangeValue(
                                submittedData["exchange_rate"]
                                  ? submittedData["exchange_rate"]
                                  : 0,
                                event.target.value ? event.target.value : 0
                              ),
                              submittedData[
                                `Fob Value ${rowHeader.slice(2, 5)[2]}`
                              ],
                              submittedData[
                                `Insurance ${rowHeader.slice(2, 5)[2]}`
                              ],
                            ])
                          : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`],
                      [`Total ${rowHeader.slice(2, 5)[1]}`]: Number(
                        calculatePrice(
                          i === 0
                            ? sum([
                                calculateExchangeValue(
                                  submittedData["exchange_rate"]
                                    ? submittedData["exchange_rate"]
                                    : 0,
                                  event.target.value ? event.target.value : 0
                                ),
                                submittedData[
                                  `Fob Value ${rowHeader.slice(2, 5)[2]}`
                                ],
                                submittedData[
                                  `Insurance ${rowHeader.slice(2, 5)[2]}`
                                ],
                              ])
                            : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]
                        ).toFixed(3),
                        submittedData["Net Weight"]
                          ? submittedData["Net Weight"]
                          : 0
                      ),

                      [`Final ${rowHeader.slice(2, 5)[2]}`]:
                        i === 0
                          ? calculateGstAmount(
                              sum([
                                calculateExchangeValue(
                                  submittedData["exchange_rate"]
                                    ? submittedData["exchange_rate"]
                                    : 0,
                                  event.target.value ? event.target.value : 0
                                ),
                                submittedData[
                                  `Fob Value ${rowHeader.slice(2, 5)[2]}`
                                ],
                                submittedData[
                                  `Insurance ${rowHeader.slice(2, 5)[2]}`
                                ],
                              ]),
                              Number(
                                submittedData.rate_of_igst
                                  ? submittedData.rate_of_igst
                                  : 0
                              ),
                              submittedData[
                                `Fob Value ${rowHeader.slice(2, 5)[2]}`
                              ]
                            )
                          : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`],
                    });

                    setError({
                      ...error,
                      [`Ocean Freight ${singleInput}`]: false,
                    });
                  }}
                  variant="outlined"
                />
              ))}
            </div>

            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[4 % colorArr.length],
                minHeight: "60px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  minWidth: "274px",
                  margin: "12px 10px",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Insurance
              </div>
              {rowHeader.slice(2, 5).map((singleInput, i) => (
                <CssTextField95
                  // autoFocus={}
                  disabled={onlyView ? true : i === 2 || i === 1 ? true : false}
                  required={true}
                  onFocus={StopScroll}
                  error={error[`Insurance ${singleInput}`] ? true : false}
                  autoComplete="off"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{ width: "140px", minWidth: "140px", margin: "10px" }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedData[`Insurance ${singleInput}`]
                      ? submittedData[`Insurance ${singleInput}`]
                      : ""
                  }
                  name={singleInput}
                  onKeyDown={(evt) => {
                    evt.key === "e" && evt.preventDefault();
                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    evt.keyCode === 38 && evt.preventDefault();
                    evt.keyCode === 40 && evt.preventDefault();
                  }}
                  onChange={(event) => {
                    setSubmittedData({
                      ...submittedData,
                      [`Insurance ${singleInput}`]: event.target.value,
                      [`Insurance ${rowHeader.slice(2, 5)[1]}`]: Number(
                        i == 0
                          ? calculatePrice(
                              calculateExchangeValue(
                                submittedData["exchange_rate"]
                                  ? submittedData["exchange_rate"]
                                  : 0,
                                event.target.value ? event.target.value : 0
                              ),
                              submittedData["Net Weight"]
                                ? submittedData["Net Weight"]
                                : 0
                            )
                          : submittedData[
                              `Insurance ${rowHeader.slice(2, 5)[1]}`
                            ]
                      ).toFixed(3),

                      [`Total ${singleInput}`]: sum([
                        Number(event.target.value ? event.target.value : 0),
                        Number(
                          submittedData[`Fob Value ${singleInput}`]
                            ? submittedData[`Fob Value ${singleInput}`]
                            : 0
                        ),
                        Number(
                          submittedData[`Ocean Freight ${singleInput}`]
                            ? submittedData[`Ocean Freight ${singleInput}`]
                            : 0
                        ),
                      ]),

                      [`Final ${singleInput}`]:
                        i === 0 || i === 2
                          ? calculateGstAmount(
                              sum([
                                Number(
                                  event.target.value ? event.target.value : 0
                                ),
                                Number(
                                  submittedData[`Fob Value ${singleInput}`]
                                    ? submittedData[`Fob Value ${singleInput}`]
                                    : 0
                                ),
                                Number(
                                  submittedData[`Ocean Freight ${singleInput}`]
                                    ? submittedData[
                                        `Ocean Freight ${singleInput}`
                                      ]
                                    : 0
                                ),
                              ]),
                              Number(
                                submittedData.rate_of_igst
                                  ? submittedData.rate_of_igst
                                  : 0
                              ),
                              Number(
                                submittedData[`Fob Value ${singleInput}`]
                                  ? submittedData[`Fob Value ${singleInput}`]
                                  : 0
                              )
                            )
                          : "",
                      [`Insurance ${rowHeader.slice(2, 5)[2]}`]:
                        i === 0
                          ? calculateExchangeValue(
                              submittedData["exchange_rate"]
                                ? submittedData["exchange_rate"]
                                : 0,
                              event.target.value ? event.target.value : 0
                            )
                          : submittedData[
                              `Insurance ${rowHeader.slice(2, 5)[2]}`
                            ],
                      [`Total ${rowHeader.slice(2, 5)[2]}`]:
                        i === 0
                          ? sum([
                              calculateExchangeValue(
                                submittedData["exchange_rate"]
                                  ? submittedData["exchange_rate"]
                                  : 0,
                                event.target.value ? event.target.value : 0
                              ),
                              submittedData[
                                `Fob Value ${rowHeader.slice(2, 5)[2]}`
                              ],
                              submittedData[
                                `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                              ],
                            ])
                          : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`],
                      [`Total ${rowHeader.slice(2, 5)[1]}`]: Number(
                        calculatePrice(
                          i === 0
                            ? sum([
                                calculateExchangeValue(
                                  submittedData["exchange_rate"]
                                    ? submittedData["exchange_rate"]
                                    : 0,
                                  event.target.value ? event.target.value : 0
                                ),
                                submittedData[
                                  `Fob Value ${rowHeader.slice(2, 5)[2]}`
                                ],
                                submittedData[
                                  `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                                ],
                              ])
                            : submittedData[
                                `Total ${rowHeader.slice(2, 5)[2]}`
                              ],
                          submittedData["Net Weight"]
                            ? submittedData["Net Weight"]
                            : 0
                        )
                      ).toFixed(3),
                      [`Final ${rowHeader.slice(2, 5)[2]}`]:
                        i === 0
                          ? calculateGstAmount(
                              sum([
                                calculateExchangeValue(
                                  submittedData["exchange_rate"]
                                    ? submittedData["exchange_rate"]
                                    : 0,
                                  event.target.value ? event.target.value : 0
                                ),
                                submittedData[
                                  `Fob Value ${rowHeader.slice(2, 5)[2]}`
                                ],
                                submittedData[
                                  `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                                ],
                              ]),
                              Number(
                                submittedData.rate_of_igst
                                  ? submittedData.rate_of_igst
                                  : 0
                              ),
                              submittedData[
                                `Fob Value ${rowHeader.slice(2, 5)[2]}`
                              ]
                            )
                          : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`],
                    });

                    setError({ ...error, [`Insurance ${singleInput}`]: false });
                  }}
                  variant="outlined"
                />
              ))}
            </div>
            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[5 % colorArr.length],
                minHeight: "60px",
                borderRadius: "0 0 7px 7px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  minWidth: "274px",
                  margin: "12px 10px",
                  fontWeight: "500",
                }}
              >
                Total
              </div>
              {rowHeader.slice(2, 5).map((singleInput, i) => (
                <CssTextField95
                  // autoFocus={}
                  required={true}
                  disabled={onlyView ? true : true} //} // false}
                  onFocus={StopScroll}
                  error={error[`Total ${singleInput}`] ? true : false}
                  autoComplete="off"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{ width: "140px", minWidth: "140px", margin: "10px" }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedData[`Total ${singleInput}`]
                      ? submittedData[`Total ${singleInput}`]
                      : ""
                  }
                  name={singleInput}
                  onKeyDown={(evt) => {
                    evt.key === "e" && evt.preventDefault();
                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    evt.keyCode === 38 && evt.preventDefault();
                    evt.keyCode === 40 && evt.preventDefault();
                  }}
                  onChange={(event) => {
                    setSubmittedData({
                      ...submittedData,
                      [`Total ${singleInput}`]: event.target.value,
                    });
                    setError({ ...error, [`Total ${singleInput}`]: false });
                  }}
                  variant="outlined"
                />
              ))}
            </div>
            {ci_type === "GST" ? (
              <div
                className="d-flex width-100 justifySE"
                style={{
                  backgroundColor: colorArr && colorArr[5 % colorArr.length],
                  minHeight: "60px",
                  borderRadius: "0 0 7px 7px",
                }}
              >
                <div
                  className="d-flex alignC"
                  style={{
                    minWidth: "274px",
                    margin: "12px 10px",
                    fontWeight: "500",
                  }}
                >
                  IGST Amount on FOB Value
                </div>
                {rowHeader.slice(2, 5).map((singleInput, i) =>
                  i === 1 || i === 0 ? (
                    <div
                      style={{ minWidth: "140px", margin: "12px 10px" }}
                    ></div>
                  ) : (
                    <CssTextField95
                      // autoFocus={}

                      disabled={onlyView ? true : true} // false}
                      required={true}
                      onFocus={StopScroll}
                      error={error[`IGST Amount ${singleInput}`] ? true : false}
                      autoComplete="off"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                        endAdornment: "",
                        startAdornment: "",
                      }}
                      style={{
                        width: "140px",
                        minWidth: "140px",
                        margin: "10px",
                      }}
                      id="outlined-basic"
                      label={""}
                      type={"number"}
                      value={
                        submittedData[`IGST Amount ${singleInput}`]
                          ? submittedData[`IGST Amount ${singleInput}`]
                          : ""
                      }
                      name={singleInput}
                      onKeyDown={(evt) => {
                        evt.key === "e" && evt.preventDefault();
                        evt.key === "E" && evt.preventDefault();
                        evt.key === "-" && evt.preventDefault();
                        evt.keyCode === 38 && evt.preventDefault();
                        evt.keyCode === 40 && evt.preventDefault();
                      }}
                      onChange={(event) => {
                        setSubmittedData({
                          ...submittedData,
                          [`IGST Amount ${singleInput}`]: event.target.value,
                        });

                        setError({
                          ...error,
                          [`IGST Amount ${singleInput}`]: false,
                        });
                      }}
                      variant="outlined"
                    />
                  )
                )}
              </div>
            ) : (
              ""
            )}
            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[5 % colorArr.length],
                minHeight: "60px",
                borderRadius: "0 0 7px 7px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  minWidth: "112px",
                  margin: "12px 10px",
                  fontWeight: "bold",
                }}
              >
                Final value
              </div>
              {rowHeader.slice(1, 5).map((singleInput, i) =>
                i === 0 || i === 2 ? (
                  <div
                    style={{ minWidth: "140px", margin: "12px 10px" }}
                    className={`d-flex ${
                      i !== 0 ? "justifyC" : "justifyC"
                    } alignC`}
                  >
                    {i === 0
                      ? selected && selected["Currency"]
                        ? `${selected["Currency"]}`
                        : ""
                      : "INR"}
                  </div>
                ) : (
                  <CssTextField95
                    required={true}
                    disabled={onlyView ? true : true} // false}
                    onFocus={StopScroll}
                    error={error[`Final ${singleInput}`] ? true : false}
                    autoComplete="off"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                      endAdornment: "",
                      startAdornment: "",
                    }}
                    style={{
                      width: "140px",
                      minWidth: "140px",
                      margin: "10px",
                    }}
                    id="outlined-basic"
                    label={""}
                    type={"number"}
                    value={
                      submittedData[`Final ${singleInput}`]
                        ? submittedData[`Final ${singleInput}`]
                        : ""
                    }
                    name={singleInput}
                    onKeyDown={(evt) => {
                      evt.key === "e" && evt.preventDefault();
                      evt.key === "E" && evt.preventDefault();
                      evt.key === "-" && evt.preventDefault();
                      evt.keyCode === 38 && evt.preventDefault();
                      evt.keyCode === 40 && evt.preventDefault();
                    }}
                    onChange={(event) => {
                      setSubmittedData({
                        ...submittedData,
                        [`Final ${singleInput}`]: event.target.value,
                      });

                      setError({ ...error, [`Final ${singleInput}`]: false });
                    }}
                    variant="outlined"
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {dialogOpen && dialogOpen.open && loader === false && (
        <CreateDialog
          onlyView={onlyView ? true : false}
          extraDetial={valuesTable}
          // extraDetialValue={selectedBrandsArr}
          noTitle={true}
          module={`${selected["Brand"]}-${selected["Item Category"]}-${selected["Item Type"]}%`}
          closeOnSave={true}
          selectedList={dialogOpen.details ? dialogOpen.details : false}
          editList={dialogOpen.details ? dialogOpen.details : false}
          handleClose={() => {
            setDialogOpen({ open: false, details: false });
          }}
          detailUI={() => {
            return (
              <ShowDetails
                isMobile={true}
                width="100%"
                lists={[
                  {
                    name: "HSN Code",
                    value:
                      selected && selected["HSN Code"]
                        ? `${selected["HSN Code"]}`
                        : "",
                  },
                  {
                    name: "Contract Rate",
                    value:
                      selected && selected["Contract Rate"]
                        ? `${selected["Contract Rate"]}`
                        : "",
                  },
                  {
                    name: "Currency",
                    value:
                      selected && selected["Currency"]
                        ? `${selected["Currency"]}`
                        : "",
                  },
                ]}
                height={"fit-content"}
                margin="10px"
              />
            );
          }}
          textFieldStyle={{ width: "calc(33% - 20px)", margin: "10px" }}
          heightNew={onlyView ? "fit-content" : "fit-content"}
          maxHeight="calc(100vh - 60px)" //{onlyView ? : "calc(100vh - 140px)"}
          widthNew="900px"
          inputArray={inputArr}
          checkAllFields={() => {
            const checkData = (name) => {
              if (submittedData[name]) {
                return true;
              } else {
                setError((prev) => {
                  return { ...prev, [name]: true };
                });
                return false;
              }
            };

            if (checkData(`Fob Value ${rowHeader[2]}`) === true) {
              if (checkData([`Fob Value ${rowHeader[3]}`]) === true) {
                if (checkData([`Fob Value ${rowHeader[4]}`]) === true) {
                  if (checkData([`Ocean Freight ${rowHeader[2]}`]) === true) {
                    if (checkData([`Ocean Freight ${rowHeader[3]}`]) === true) {
                      if (
                        checkData([`Ocean Freight ${rowHeader[4]}`]) === true
                      ) {
                        if (checkData([`Insurance ${rowHeader[2]}`]) === true) {
                          if (
                            checkData([`Insurance ${rowHeader[3]}`]) === true
                          ) {
                            if (
                              checkData([`Insurance ${rowHeader[4]}`]) === true
                            ) {
                              if (
                                checkData([`Total ${rowHeader[2]}`]) === true
                              ) {
                                if (
                                  checkData([`Total ${rowHeader[3]}`]) === true
                                ) {
                                  if (
                                    checkData([`Total ${rowHeader[4]}`]) ===
                                    true
                                  ) {
                                    // if (checkData([`IGST Amount ${rowHeader[2]}`]) === true || ci_type !== "GST") {
                                    // if (checkData([`IGST Amount ${rowHeader[3]}`]) === true || ci_type !== "GST") {
                                    if (
                                      checkData([
                                        `IGST Amount ${rowHeader[4]}`,
                                      ]) === true ||
                                      ci_type !== "GST"
                                    ) {
                                      if (
                                        checkData([`Final ${rowHeader[2]}`]) ===
                                        true
                                      ) {
                                        if (
                                          checkData([
                                            `Final ${rowHeader[4]}`,
                                          ]) === true
                                        ) {
                                          return true;
                                        } else {
                                          return false;
                                        }
                                      } else {
                                        return false;
                                      }
                                    } else {
                                      return false;
                                    }
                                    // } else {
                                    //   return false;
                                    // }
                                    // } else {
                                    //   return false;
                                    // }
                                  } else {
                                    return false;
                                  }
                                } else {
                                  return false;
                                }
                              } else {
                                return false;
                              }
                            } else {
                              return false;
                            }
                          } else {
                            return false;
                          }
                        } else {
                          return false;
                        }
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          }}
          postDataToState={(data) => {
            if (setData) {
          
              setData(submittedData);
            }
            setErrorMsg();
            setDialogOpen({ open: false, details: false });
          }}
        />
      )}

      <StyledLoadingButton
        width="120px"
        loading={loader}
        withBg={true}
        background={
          (errorMsg && errorMsg["addDetail"]) ||
          errorMsg[`${selected.id}addDetail`]
            ? red
            : false
        }
        name={
          onlyView
            ? "View Details"
            : editData
            ? "Update details"
            : "add details"
        }
        onClick={() => {
          setLoader(true);
          if (!editData) {
            setSubmittedData({});
          }
          setError({});
          setDialogOpen({ open: true, details: false });
        }}
      />
    </>
  );
}

export default ItemTableWIthINpitDetails;
