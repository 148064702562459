

import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { connect, useDispatch } from "react-redux";
import { AppName } from "./Defaults";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { StyledLoader } from "./styles/StyledComponents";
import routes from "./routes";
import LoginContainer from "./Modules/Login/LoginContainer";
import { BrandsManagementsFunction } from "./redux/index";
import { HashLoader } from 'react-spinners';

import "./styles/styles.css";
import { filterData } from "./Regex";
function App(props) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  document.title = AppName;
  const dispatch = useDispatch();
  // console.clear();

  useEffect(() => {
    const user = localStorage.getItem("briuser")
      ? JSON.parse(localStorage.getItem("briuser"))
      : "";

    setUser(user);
    if (user.isLoggedIn && user.uid && user.id) {
      setLoading(true);
      props.BrandsManagementsFunction("get", "users", user.id).then((res) => {
        if (res && res.id) {
          const modulePermission =
          res.access_details&&res.access_details.length > 0
            ? res.access_details[0].modules.filter((m)=>{
              const user_widgets_list = m.widgets.filter(
                (widget) =>
                  widget.widget.has_create_permission ===
                    true ||
                  widget.widget.has_update_permission ===
                    true ||
                  widget.widget.has_read_permission === true
              );
         
              return user_widgets_list.length>0?true:false
            }).map(
                (module) => module.module.module_name 
              )??[]
            : [];
        
            console.log(modulePermission, "user details");
          const users = {
            ...user,
            uid: user.uid,
            username: res.first_name,
            email: res.email,
            userData: user,
            roles: res.role,
            accessibleModules: res.access_details?modulePermission:res.modules_access,
            user_accesses:res.access_details??res.access_details,
            id: res.id,
            user_accesses:res.access_details??res.access_details,
                        credential_id: res.credential_id,
            isLoggedIn: true,
            status: res.status,
            miller: res.miller,
            broker: res.broker,
          };
          const localStorageUser = {
            uid: user.uid,
            id: res.id,
            ...user,
            username: res.first_name,
            email: res.email,
            roles: res.role,
            isLoggedIn: true,
            user_accesses:res.access_details??res.access_details,
                        credential_id: res.credential_id,
            accessibleModules: res.access_details?modulePermission:res.modules_access,
            user_accesses:res.access_details??res.access_details,
            status: res.status,
            miller: res.miller,
            broker: res.broker,
          };
          localStorage.setItem("briuser", JSON.stringify(localStorageUser));
console.log(res,"resres")
          dispatch({
            type: "AUTH_USER_LOGGEDIN",
            payload: users,
          });
          setLoading(false);
        }
      });
    }
    // app.js
// console.clear();

// Your application code follows here...

  }, []);
  useEffect(() => {
    const user = localStorage.getItem("briuser")
      ? JSON.parse(localStorage.getItem("briuser"))
      : "";

    setUser(user);
  }, [props.user]);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isMobile, setMobile] = React.useState(width <= 500);
  const handler = useCallback(() => {
    setWidth(window.innerWidth);
    setMobile(width <= 500);
  }, [setWidth]);
  useEventListener("resize", handler);
  const { loader, userRole } = props;
  return (
    <Router>
      <Fragment>
        {loader || loading === true ? (
          <div className="mainDivForLogin">
          <HashLoader color="rgb(241 154 48)" 
           loading={loading}
        // cssOverride={override}
        size={80}
        aria-label="Loading Spinner"
        data-testid="loader"/>

          </div>
        ) : (
          <Routes>
            {console.log(user, "useruser")}
            {user &&
            user.isLoggedIn && user.accessibleModules&& user.accessibleModules!==null&&
            user.accessibleModules.length > 0 &&
            user.status === "approved" ? (
              (user&&user.roles === "Miller" && !user.miller && !user.username) ||
              (user&&user.roles === "Broker" && !user.broker && !user.username)|| 
              (user&&user.roles === "Client" && !user.client && !user.username) ? (
                <>
                  {window.location.pathname !== "/Signin" && (
                    <Route
                      path={"/pending"}
                      element={<Navigate to={"/Signin"} replace={true} />}
                    />
                  )}
                  <Route
                    path={`/login`}
                    element={<Navigate to={"/Signin"} replace={true} />}
                  />
                  <Route
                    path={`/`}
                    element={<Navigate to={"/Signin"} replace={true} />}
                  />
                </>
              ) :user.accessibleModules.includes("Purchase Order") ?
               (
                <>
                  {window.location.pathname !== "/PurchaseOrders" && (
                    <Route
                      path={"/pending"}
                      element={<Navigate to={"/PurchaseOrders"} replace={true} />}
                    />
                  )}
                  <Route
                    path={`/login`}
                    element={<Navigate to={"/PurchaseOrders"} replace={true} />}
                  />
                  <Route
                    path={`/Signin`}
                    element={<Navigate to={"/PurchaseOrders"} replace={true} />}
                  />
                  <Route
                    path={`/`}
                    element={<Navigate to={"/PurchaseOrders"} replace={true} />}
                  />
                </>
              )
               : filterData(
                  routes,
                  "access",
                  user.accessibleModules[0],
                  "onlyOne"
                ) ? (
                <>
                  <Route
                    path={`/login`}
                    element={
                      <Navigate
                        to={
                          filterData(
                            routes,
                            "access",
                            user.accessibleModules[0]
                          )[2].path
                        }
                        replace={true}
                      />
                    }
                  />
                  <Route
                    path={`/`}
                    element={
                      <Navigate
                        to={
                          filterData(
                            routes,
                            "access",
                            user.accessibleModules[0]
                          )[2].path
                        }
                        replace={true}
                      />
                    }
                  />
                </>
              ) : (
                <>
                {/* {routes.map((ss)=>ss.path).includes(window.location.pathname)?<></>:
                window.location.pathname !== "/pending" && (
                  <Route
                    path={window.location.pathname}
                    element={<Navigate to={"/pending"} replace={true} />}
                  />
                )} */}
                </>
              )
            ) : user && user.isLoggedIn && user.accessibleModules&& user.accessibleModules!==null&&user.accessibleModules.length > 0 ? (
              <>
                {window.location.pathname !== "/pending" && (
                  <Route
                    path={window.location.pathname}
                    element={<Navigate to={"/pending"} replace={true} />}
                  />
                )}
              </>
            ) : (
              <>
                <Route
                  path={`/`}
                  element={<Navigate to="/login" replace={true} />}
                />
                <Route
                  path={`/PurchaseOrders`}
                  element={<Navigate to="/login" replace={true} />}
                />
              </>
            )}
             {/* {routes.map((ss)=>ss.path).includes(window.location.pathname)?<></>:
                window.location.pathname !== "/pending" && (
                  <Route
                    path={window.location.pathname}
                    element={<Navigate to={"/pending"} replace={true} />}
                  />
                )} */}
            {/* 
            {user &&
            user.isLoggedIn &&
            user.accessibleModules.length > 0 &&
            user.status === "approved" &&
            user === "brokers" ? (
              user.accessibleModules.includes("Brokers") ? (
                <>
                  {window.location.pathname !== "/Brokers" && (
                    <Route
                      path={"/pending"}
                      element={<Navigate to={"/Brokers"} replace={true} />}
                    />
                  )}
                  <Route
                    path={`/login`}
                    element={<Navigate to={"/Brokers"} replace={true} />}
                  />
                  <Route
                    path={`/`}
                    element={<Navigate to={"/Brokers"} replace={true} />}
                  />
                </>
              ) : filterData(
                  routes,
                  "access",
                  user.accessibleModules[0],
                  "onlyOne"
                ) ? (
                <>
                  <Route
                    path={`/login`}
                    element={
                      <Navigate
                        to={
                          filterData(
                            routes,
                            "access",
                            user.accessibleModules[0]
                          )[2].path
                        }
                        replace={true}
                      />
                    }
                  />
                  <Route
                    path={`/`}
                    element={
                      <Navigate
                        to={
                          filterData(
                            routes,
                            "access",
                            user.accessibleModules[0]
                          )[2].path
                        }
                        replace={true}
                      />
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : user && user.isLoggedIn && user.accessibleModules.length > 0 ? (
              <>
                {window.location.pathname !== "/pending" && (
                  <Route
                    path={window.location.pathname}
                    element={<Navigate to={"/pending"} replace={true} />}
                  />
                )}
              </>
            ) : (
              <>
                <Route
                  path={`/`}
                  element={<Navigate to="/login" replace={true} />}
                />
                <Route
                  path={`/Brokers`}
                  element={<Navigate to="/login" replace={true} />}
                />
              </>
            )} */}
            <Route
              exact
              path="/login"
              element={<LoginContainer {...props} isMobile={isMobile} />}
            />

            {routes.map((route, i) => {
              return (
                user &&
                user.isLoggedIn &&
                // user.accessibleModules &&
                // (user.accessibleModules.includes(route.access) ||
                //   route.access === "all")
                  //  && 
                   (
                  <Route
                    key={i}
                    exact
                    path={route.path}
                    element={<route.component {...props} isMobile={isMobile} />}
                  />
                )
              );
            })}
          </Routes>
        )}
      </Fragment>
    </Router>
  );
}
function useEventListener(eventName, handler, element = window) {
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
const mapStateToProps = (state) => ({
  user: state.authentication.user,
});
const mapDispatchToProps = { BrandsManagementsFunction };

export default connect(mapStateToProps, mapDispatchToProps)(App);
