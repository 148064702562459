import React, { useEffect } from "react";
import { connect } from "react-redux";
import ItemCategoriesComponent from "./ItemCategoriesComponent";
import { ItemCategoriesFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function ItemCategoriesContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    // if (props.user.uid) {
    props.ItemCategoriesFunction("get", EndPoints["ItemCategories"]);
    //}
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <ItemCategoriesComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  ItemCategories: state.modules.ItemCategories,
  loading: state.modules.loading,
  [EndPoints["ItemCategories"]]: state.modules[EndPoints["ItemCategories"]],
});
const mapDispatchToProps = {
  ItemCategoriesFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCategoriesContainer);
