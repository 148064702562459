// import React, { useEffect } from "react";
import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import BrandsComponent from "./BrandsComponent";
import { BrandsManagementsFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";

function BrandsContainer(props) {
  const [BrandList, setBrandList] = useState([])
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
       props.BrandsManagementsFunction("get", EndPoints["Brands"],null,null, null,{ is_group:"False" });
      // props
      //   .BrandsManagementsFunction(
      //     'get',
      //     'masters/brand/list',
      //     null,
      //     'BrandList',
      //     null,
      //     { is_active: "True",is_group:"False" }
      //   )
      //   .then((BrandList) => {
      //     if (BrandList) {
      //       setBrandList(BrandList)
      //     }
      //   })
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <BrandsComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  Brands: state.modules.Brands,
  [EndPoints["Brands"]]: state.modules[EndPoints["Brands"]],
  loading: state.modules.loading,
});
const mapDispatchToProps = {
  BrandsManagementsFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsContainer);
