import React from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import { BottomArrow, TopArrow } from "../styles/Icons";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiInputBase-root": {background:"rgb(0 0 0 / 1%)"},
    "&.PickerWithState-root-34 .MuiOutlinedInput-root .MuiOutlinedInput-input": {background:"none"},
    "&:hover": {
      border: "none",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: "0",
    },
    // "& .MuiInputBase-root.Mui-disabled": { color: "black" },
    // "& .MuiFormControl-root": {
    //   backgroundColor: "white",
    // },

    "& .MuiOutlinedInput-root": {
      background: "white",
      border: "0px solid transparent",
      boxShadow:
        "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgba(251, 224, 191, 1)",
      borderRadius: "7px",
      "& .MuiFormControl-root": {
        background: "white",
      },
      "& .MuiOutlinedInput-input ": {
        caretColor: "transparent",
        background: "white",
        padding: "10px 14px",
      },
    },
  },
})(KeyboardDatePicker);
export default function RaisedMonthBar({ data, labelField, searchedData, handleSearch, hintText }) {
  var calenderOpen = false;
  return (
    <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
      <style>{`
                  .MuiPopover-paper {  left: 292px !important ;}
              `}</style>
      <CssKeyboardDatePicker
        variant="inline"
        onOpen={() => {
          calenderOpen = true;
        }}
        onClose={() => {
          calenderOpen = false;
        }}
        maxDate={new Date()}
        disableFuture
        // minDate={"2022-01-22"}
        error={false}
        invalidLabel=""
        onKeyPress={(ev) => {
          ev.preventDefault();
        }}
        allowKeyboardControl={false}
        keyboardIcon={calenderOpen ? <TopArrow /> : <BottomArrow />}
        autoOk
        inputVariant="outlined"
        label={searchedData ? "" : "Select Month"}
        format="MMM, yyyy"
        style={{
          width: "calc(100% - 10px)",
          margin: "16px 5px 0px",
        }}
        value={searchedData ? searchedData : ""}
        views={["year", "month"]}
        InputAdornmentProps={{ position: "end" }}
        onChange={handleSearch}
      />
    </MuiPickersUtilsProvider>
  );
}
