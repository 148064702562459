import moment from "moment";

function MessageBox(props) {
  const { detail, type, noName, item } = props;
  const { created_at, user_details } = detail;
  return (
    <div className="width-100 height-100">
      {noName ? (
        ""
      ) : (
        <div
          className={`user-name textTransC textOverflowEllipsis ${
            detail.message_type === "message" ? "colorPrimary" : "colorWhite"
          } `}
        >{`${user_details?.name ?? ""}`}</div>
      )}
      {detail.message_type === "message" ? (<>
        
        <div className={` d-flex justifySB alignFE  width-100`}>
          
          <div
            className={`PoppinsThin d-flex  wordBreak flex8 textAlignStart`}
            style={{
              textAlign: "start",
              fontSize: "0.8rem",
              marginRight: "5px",
              color:"black"
            }}
          >
            {detail.message ?? ""}
          </div>
          <div
            style={{ width: "fit-content" }}
            className={`textTransC textOverflowEllipsis time-font d-flex justifyFE`}
          >
            {moment(new Date(created_at)).format("h:mm A")}
          </div>
        </div>
        </> ) : (
        <>
          <div
            style={{ marginBottom: "4px" }}
            className={`${
              type === "left" ? "weight-left justifyFE" : "weight-right  "
            } d-flex width-100 alignC`}
          >
            <div className={`textTransC textOverflowEllipsis`}></div>
          </div>
        </>
      )}
      {detail.message_type === "message" ? (
        ""
      ) : (
        <div
          // style={{ marginBottom: "8px" }}
          className={`${
            type === "left"
              ? detail.message_type === "message"
                ? "weight-to-left"
                : "weight-to-left flex-reverse"
              : "weight-to-right  "
          } d-flex width-100 alignC ${
            detail.message_type === "message"
              ? type === "left"
                ? ""
                : " justifyFE "
              : "justifySB"
          }`}
        >
          <div
            className={`textTransC textOverflowEllipsis time-font colorWhite`}
          >
            {moment(new Date(created_at)).format("h:mm A")}
          </div>
        </div>
      )}
    </div>
  );
}

export default MessageBox;
