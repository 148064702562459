import React, { useEffect } from "react";
import { connect } from "react-redux";
import BeneficiarysComponent from "./BeneficiarysComponent";
import { BrandsManagementsFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";

function BeneficiarysContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.BrandsManagementsFunction("get", EndPoints["Beneficiarys"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <BeneficiarysComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  [EndPoints["Beneficiarys"]]: state.modules[EndPoints["Beneficiarys"]],
  loading: state.modules.loading,
});
const mapDispatchToProps = {
  BrandsManagementsFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiarysContainer);
