import React, { useRef, useEffect } from "react";

import { savePDF } from "@progress/kendo-react-pdf";
import { Divider } from "@material-ui/core";

import PdfLayout from "../../layoutComponents/PdfLayout";

function PurchaseOrderPdf(props) {
  const {
    downloadPdf,
    module,
    singlelistView,
    PaymentTermList,
    header,
    widthList,
    tableList,
    getSpecificationName,
    CashDiscountList,
  } = props;
  const contentArea = useRef(null);

  const handleExportWithFunction = (event) => {
    savePDF(contentArea.current, {
      paperSize: "A4",
      // landscape: true,
      fileName: `${singlelistView && singlelistView.po_number}.pdf`,
    });
    props.onClose();
  };
  useEffect(() => {
    if (contentArea) {
      handleExportWithFunction();
    }
  }, [downloadPdf]);

  const HDivider = <Divider style={{ minWidth: "100%", width: "100%" }} />;
  const VDivider = (
    <Divider
      variant="vertical"
      style={{
        height: "100%",
        minHeight: "29.19px",
      }}
    />
  );

  return (
    <div style={{ height: "0", width: "0", overflow: "hidden" }}>
      <div
        ref={contentArea}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}>
        <PdfLayout
          title="Purchase Order"
          file={{
            name: "Purchase Order",
            po_num: `P.O No. ${singlelistView && singlelistView.po_number}`,
            value: singlelistView && singlelistView.po_number,
            date: singlelistView.contract_date,
          }}>
          <div
            style={{
              width: "calc(100% + 1px)",
              height: "calc(100% + 2px)",
              padding: "0",
              fontSize: "0.6rem",
            }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr 1fr 3fr",
                gap: "1px",
                padding: "2px",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}>
                <div>Supplier Name</div>
                {/* <div>{VDivider}</div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                {" "}
                <div>
                  {singlelistView &&
                    singlelistView.miller_details &&
                    singlelistView.miller_details.name}
                </div>
                {/* <div>{VDivider}</div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                }}>
                <div>Invoice To</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {/* <div style={{}}>{VDivider}</div> */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  BALAJI RICE INDUSTRIES NEAR BUS STAND, MAIN ROAD, KHARORA
                  ,RAIPUR-493225,CHATTISGARH (INDIA)
                </div>
              </div>
            </div>
            {HDivider}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr 1fr 3fr",
                gap: "1px",
                padding: "2px",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}>
                <div>Address</div>
                {/* <div>{VDivider}</div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                {" "}
                <div>
                  {singlelistView &&
                    singlelistView.miller_details &&
                    singlelistView.miller_details.address}
                </div>
                {/* <div>{VDivider}</div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                  padding:"0 5px",
                }}>
                <div>Contact Person</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {/* <div style={{}}>{VDivider}</div> */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {singlelistView &&
                    singlelistView.miller_details.contact_person}
                </div>
              </div>
            </div>
            {HDivider}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr 1fr 3fr",
                gap: "1px",
                padding: "2px",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}>
                <div>Miller GST No.</div>
                {/* <div>{VDivider}</div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                {" "}
                <div>
                  {singlelistView &&
                    singlelistView.miller_details &&
                    singlelistView.miller_details.gstin}
                </div>
                {/* <div>{VDivider}</div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                }}>
                <div>Gst No.</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {/* <div style={{}}>{VDivider}</div> */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  22AALCS6148P1ZR
                </div>
              </div>
            </div>
            {HDivider}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr 1fr 3fr",
                gap: "1px",
                padding: "2px",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}>
                <div>Delivery Condition</div>
                {/* <div>{VDivider}</div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                {" "}
                <div>
                  {singlelistView &&
                    singlelistView.ports_details &&
                    singlelistView.ports_details.map((list, index) => {
                      return ` ${list.city} `;
                    })}
                </div>
                {/* <div>{VDivider}</div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                }}>
                <div>Broker Name</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {/* <div style={{}}>{VDivider}</div> */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {singlelistView && singlelistView.broker_details.name}
                </div>
              </div>
            </div>
            {HDivider}
            {/* {HDivider}{" "} */}
            <div style={{ display: "flex", width: "100%" }}>
              {tableList && tableList.length > 0 ? (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    width: "100%",
                  }}>
                  <div style={{ display: "flex", width: "100%" }}>
                    {header &&
                      header.length > 0 &&
                      header.map((name, i) => (
                        <div
                          style={{
                            // width: "100%",
                            // minHeight: "fit-content",
                            // maxHeight: "fit-content",
                            display: "flex",
                            fontWeight: "bold",
                            justifyContent: "center",
                            alignItems: "center",
                            textTransform: "capitalize",
                            borderRight:
                              i === header.length - 1
                                ? ""
                                : "1px solid rgba(0, 0, 0, 0.12)",
                            padding: "5px",
                            width:
                              widthList && widthList[i] ? widthList[i] : "",
                            minWidth:
                              widthList && widthList[i] ? widthList[i] : "",
                            // height: "fit-content",
                          }}>
                          {name}
                        </div>
                      ))}
                  </div>
                  {tableList.map((single, i2) => (
                    <>
                      {HDivider}
                      <div style={{ display: "flex", width: "100%" }}>
                        {header &&
                          header.length > 0 &&
                          header.map((name, i) => (
                            <div
                              style={{
                                // width: "100%",
                                // minHeight: "fit-content",
                                // maxHeight: "fit-content",
                                display: "flex",
                                fontWeight: "bold",
                                justifyContent: "center",
                                alignItems: "center",
                                textTransform: "capitalize",
                                borderRight:
                                  i === header.length - 1
                                    ? ""
                                    : "1px solid rgba(0, 0, 0, 0.12)",
                                padding: "5px",
                                width:
                                  widthList && widthList[i] ? widthList[0] : "",
                                minWidth:
                                  widthList && widthList[i] ? widthList[i] : "",
                                fontWeight: "bold",
                                // height: "fit-content",
                              }}>
                              {name === "Serial No"
                                ? i2 + 1 < 10
                                  ? `0${i2 + 1}`
                                  : i2 + 1
                                : i === 1
                                ? 
                                // `${single["Brand"] ? single["Brand"] : ""}, 
                                `${
                                    single["Item Category"]
                                      ? single["Item Category"]
                                      : ""
                                  }, ${
                                    single["Item Type"]
                                      ? single["Item Type"]
                                      : ""
                                  } % ${
                                    single.Specifications &&
                                    single.Specifications.length > 0 &&
                                    single.Specifications.map(
                                      (sp) =>
                                        `${sp.name} - ${sp.value}${
                                          sp.name === "Moisture" ||
                                          sp.name === "Broken" ||
                                          sp.name === "Damaged/Coloured" ||
                                          sp.name === "Chalky"
                                            ? "%"
                                            : sp.name === "Length"
                                            ? "MM"
                                            : sp.name === "Paddy"
                                            ? `/${
                                                singlelistView &&
                                                singlelistView.unit
                                              }`
                                            : ""
                                        }${getSpecificationName(sp.condition)} `
                                    )
                                  }`
                                : // `${
                                //   single["Specifications"][i].name
                                // }: ${single["Specifications"][i ].condition.replace(/_/g, " ")}-
                                //  ${single["Specifications"][i].value}`
                                //       ${
                                //         single["Specifications"][i].name
                                //       }: ${single["Specifications"][
                                //         i
                                //       ].condition.replace(/_/g, " ")}-
                                // ${single["Specifications"][i].value}
                                i === 2
                                ? singlelistView && singlelistView.unit
                                  ? `${
                                      single["Total Item Quantity"]
                                        ? single["Total Item Quantity"]
                                        : "-"
                                    } ${singlelistView.unit}`
                                  : ""
                                : i === 3
                                ? single["Contract Rate"]
                                  ? ` ${Number(single["Contract Rate"]).toFixed(
                                      2
                                    )} / ${
                                      singlelistView && singlelistView.unit
                                        ? singlelistView.unit
                                        : ""
                                    }`
                                  : ""
                                : i === 4
                                ? "NIL"
                                : single[name]
                                ? single[name]
                                : ""}
                            </div>
                          ))}
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
            {HDivider}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr 1fr 2fr",
                gap: "1px",
                padding: "2px",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  //   borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}>
                <div>Payment Terms: </div>
                {/* <div>{VDivider}</div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                {" "}
                <div>
                  {"Payment shall be made by RTGS within " +
                    `${
                      singlelistView && singlelistView.payment_terms
                        ? PaymentTermList &&
                          PaymentTermList.length > 0 &&
                          PaymentTermList.filter(
                            (sPT) => sPT.id === singlelistView.payment_terms
                          ) &&
                          PaymentTermList.filter(
                            (sPT) => sPT.id === singlelistView.payment_terms
                          )[0] &&
                          PaymentTermList.filter(
                            (sPT) => sPT.id === singlelistView.payment_terms
                          )[0].days_range
                        : ""
                    }` +
                    " WD after receipt of material in our CHA's godown /CFS."}
                </div>
                {/* <div>{VDivider}</div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                  padding:"0 5px",
                }}>
                <div>Cash Discount</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
               {singlelistView && singlelistView.cash_discount?
                <div style={{ display: "flex", flexDirection: "row" }}>
               

            {singlelistView && singlelistView.cash_discount
                        ? CashDiscountList &&
                          CashDiscountList.length > 0 &&
                          CashDiscountList.filter(
                            (sPT) => sPT.id === singlelistView.cash_discount
                          ) &&
                          CashDiscountList.filter(
                            (sPT) => sPT.id === singlelistView.cash_discount
                          )[0] &&
                          CashDiscountList.filter(
                            (sPT) => sPT.id === singlelistView.cash_discount
                          )[0].discount 
                        : ""} % {" "}
                        will expire in
                        {singlelistView && singlelistView.cash_discount
                        ? CashDiscountList &&
                          CashDiscountList.length > 0 &&
                          CashDiscountList.filter(
                            (sPT) => sPT.id === singlelistView.cash_discount
                          ) &&
                          CashDiscountList.filter(
                            (sPT) => sPT.id === singlelistView.cash_discount
                          )[0] &&
                          CashDiscountList.filter(
                            (sPT) => sPT.id === singlelistView.cash_discount
                          )[0].expiry_days 
                        : ""} days

{/* CashDiscount:
            CashDiscountList &&
            CashDiscountList.length > 0 &&
            CashDiscountList.filter((sM) =>
              sM.id === editDialog.cash_discount
                ? editDialog.cash_discount
                : 0
            )[0]
              ? CashDiscountList.filter((sM) =>
                  sM.id === editDialog.cash_discount
                    ? editDialog.cash_discount
                    : 0
                )[0].discount
              : "", */}

                </div>
                :"-"}
              </div>
            </div>









            
            {/*            
            {HDivider}
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "3px",
              }}
            >
              Payment Terms:{" "}
              {"Payment shall be made by RTGS within " +
                `${
                  singlelistView && singlelistView.payment_terms
                    ? PaymentTermList &&
                      PaymentTermList.length > 0 &&
                      PaymentTermList.filter(
                        (sPT) => sPT.id === singlelistView.payment_terms
                      ) &&
                      PaymentTermList.filter(
                        (sPT) => sPT.id === singlelistView.payment_terms
                      )[0] &&
                      PaymentTermList.filter(
                        (sPT) => sPT.id === singlelistView.payment_terms
                      )[0].days_range
                    : ""
                }` +
                " WD after receipt of material in our CHA's godown /CFS."}
            </div> */}
            {HDivider}
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                padding: "3px",
              }}>
              <div style={{}}>
                Packing Condition:{" "}
                {tableList && tableList.length > 0
                  ? tableList.map((tableData) =>
                      tableData[`packagageArrUsed`] &&
                      tableData[`packagageArrUsed`].length > 0
                        ? tableData[`packagageArrUsed`].map((single, i) => (
                            <>{`In ${single.size} ${single.unit} Net. new ${
                              single.bag_type
                            } bags${
                              tableData[`packagageArrUsed`].length - 1 === i
                                ? "."
                                : ", "
                            }`}</>
                          ))
                        : ""
                    )
                  : ""}
              </div>
              <div
                style={{
                  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                }}>
                {`Delivery Condition: ` +
                  `${singlelistView.contract_type_details.contract_type}` +
                  " delivered in buyer’s nominated CHA’s warehouse in " +
                  `${
                    singlelistView &&
                    singlelistView.ports_details &&
                    singlelistView.ports_details.map((list, index) => {
                      return ` ${list.city} `;
                    })
                  }`}
                {/* // `by trucks.`} */}
              </div>
            </div>
            {HDivider}
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "3px",
              }}>
              GST as applicable in goods and tax.
            </div>
            {HDivider}
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "3px",
              }}>
              Quality: Quality before loading shall be approved by buyer
              nominated independent surveyor. Buyer can also depute in house
              inspectors in the rice mill .No truck to leave rice mill unless
              quality/weight approved by the surveyor.
            </div>
            {HDivider}
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "3px",
              }}>
              Weighment Condition: Weight/Quality shall be final at CHA's
              weighbridge. / It Does'nt Verify more than 0.5 % at Port
              weighbridge.
            </div>
            {HDivider}
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "3px",
              }}>
              Jurisdiction: All disputes subject to Raipur jurisdiction.
            </div>
            {HDivider}
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "3px",
              }}>
              Other conditions: The Seller assures that there is no lien, charge
              or encumbrance of any nature created in respect of the Materials
              sold by the Seller and assures that a clear, good and valid title
              shall be conveyed to Buyer. Seller also confirms that there is no
              restriction whatsoever by means of any statute, regulations,
              contracts, covenants or the like, restricting the Seller from
              performing any of its obligations under this Purchase Order.
              Seller shall be primarily responsible to prove all the time to the
              Buyer for providing of all necessary documents required by any
              Government agency to substantiate the legal ownership of the cargo
              as per any regulation. Seller shall also undertake that he shall
              provide all the necessary support to the Buyer to handle any issue
              come up with respect to quality & legal title of the material
              either before or after export of the cargo. Seller also confirms
              that the Material supplied under this agreement is not meant for
              Public Distribution System (PDS) or diverted from PDS. Seller
              confirms they are not Fair Price Shop or Stage I contractor of the
              District. Seller abides by the Essential Commodities Act and its
              Provision as contemplated under Sec 6A of the Act. The Seller
              shall indemnify the buyer for any cost, expenses, penalties that
              may be incurred/liable to be paid by buyer in respect of the title
              of the goods sold by buyer. Cargo should be fresh milled from the
              rice mill & there should not be any live insect or weevil.
            </div>
            {HDivider}
            {/* <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "100px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Delivery Terms:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                {`Delivered in buyer’s nominated CHA’s warehouse in` +
                  `${
                    singlelistView &&
                    singlelistView.ports_details &&
                    singlelistView.ports_details.map((list) => {
                      return ` ${list.city} `;
                    })
                  }` +
                  `by trucks.`}
              </div>
            </div>
            {HDivider}{" "}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "120px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",

                  padding: "5px 5px 0",
                }}
              >
                Packing Condition:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px 5px 0",
                }}
              >
                {tableList && tableList.length > 0
                  ? tableList.map((tableData) =>
                      tableData[`packagageArrUsed`] &&
                      tableData[`packagageArrUsed`].length > 0
                        ? tableData[`packagageArrUsed`].map((single, i) => (
                            <>{`In ${single.size} ${single.unit} Net. new ${
                              single.bag_type
                            } bags${
                              tableData[`packagageArrUsed`].length - 1 === i
                                ? "."
                                : ", "
                            }`}</>
                          ))
                        : ""
                    )
                  : ""}
              </div>
            </div>{" "}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "120px",
                  minHeight: "6px",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "0px 5px 5px",
                }}
              >
                Loading:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "6px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "0 5px 5px",
                }}
              >
                Loading to be done immediately on receipt of bags.
              </div>
            </div>
            {HDivider}{" "}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "100px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Payment Terms:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                {"Payment shall be made by RTGS within " +
                  `${
                    singlelistView && singlelistView.payment_terms
                      ? PaymentTermList &&
                        PaymentTermList.length > 0 &&
                        PaymentTermList.filter(
                          (sPT) => sPT.id === singlelistView.payment_terms
                        ) &&
                        PaymentTermList.filter(
                          (sPT) => sPT.id === singlelistView.payment_terms
                        )[0] &&
                        PaymentTermList.filter(
                          (sPT) => sPT.id === singlelistView.payment_terms
                        )[0].days_range
                      : ""
                  }` +
                  " WD after receipt of material in our CHA godown /CFS."}
              </div>
            </div>
            {HDivider}{" "}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "80px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Quality:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                Quality before loading shall be approved by buyer nominated
                independent surveyor. Buyer can also depute in house inspectors
                in the rice mill .No truck to leave rice mill unless
                quality/weight approved by the surveyor.
              </div>
            </div>
            {HDivider}{" "}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Weighment Condition:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                Weight/Quality shall be final at rake point weighbridge.
              </div>
            </div>
            {HDivider}{" "}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Jurisdiction:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                All disputes subject to Raipur jurisdiction.
              </div>
            </div>
            {HDivider}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Other conditions:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                The Seller assures that there is no lien, charge or encumbrance
                of any nature created in respect of the Materials sold by the
                Seller and assures that a clear, good and valid title shall be
                conveyed to Buyer. Seller also confirms that there is no
                restriction whatsoever by means of any statute, regulations,
                contracts, covenants or the like, restricting the Seller from
                performing any of its obligations under this Purchase Order.
                Seller shall be primarily responsible to prove all the time to
                the Buyer for providing of all necessary documents required by
                any Government agency to substantiate the legal ownership of the
                cargo as per any regulation. Seller shall also undertake that he
                shall provide all the necessary support to the Buyer to handle
                any issue come up with respect to quality & legal title of the
                material either before or after export of the cargo. Seller also
                confirms that the Material supplied under this agreement is not
                meant for Public Distribution System (PDS) or diverted from PDS.
                Seller confirms they are not Fair Price Shop or Stage I
                contractor of the District. Seller abides by the Essential
                Commodities Act and its Provision as contemplated under Sec 6A
                of the Act. The Seller shall indemnify the buyer for any cost,
                expenses, penalties that may be incurred/liable to be paid by
                buyer in respect of the title of the goods sold by buyer. Cargo
                should be fresh milled from the rice mill & there should not be
                any live insect or weevil.
              </div>
            </div>
            {HDivider} */}
          </div>{" "}
        </PdfLayout>
      </div>
    </div>
  );
}

export default PurchaseOrderPdf;
