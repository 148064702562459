import { Paper } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import TableComponent from "../../layoutComponents/TableComponent";
import { filterData } from "../../Regex";
import {
  CssDialog,
  StyledButton,
  StyledCircularProgress,
  StyledLoadingButton,
} from "../../styles/StyledComponents";
import { multiply, divide } from "lodash";
import CommercialPackingList from "./PDFS/CommercialPackingList";
import { Download } from "../../styles/Icons";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
function ComPackingList(props) {
  const {
    selectedCustomInvoice,
    noAddButton,
    postData,
    updateValues,
    changeTab,
    masters,
    singlelistView,
  } = props;

  const [downloadCI, setDownloadCI] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [unit, setUnit] = useState(false);

  const [dowLoader, setDowLoad] = useState({});

  const [soItems, setSoItems] = useState([]);
  const fetchSoItems = (soId, download) => {

    if (soId) {
   
      if (download) {
        setDowLoad({ [download.id]: true });
      } else {
        setLoad(true);
      }
      postData("get", "dispatch/so-items/list", null, "PortCIs", null, {
        so_id: soId,
      }).then((soItems) => {
        setSoItems(soItems);
        if (download) {
          setDowLoad(false);
        } else {
          setLoad(false);
        }

        if (download) {
          
          download.unit = unit;
          setDownloadCI(download);
        }
      });
    }
  };
  const [loader, setLoad] = useState(false);
  const [selectedCustomIn, setSelectedCustomIn] = useState(
    selectedCustomInvoice ? selectedCustomInvoice : {}
  );

  // const [openDialog, setOpenDialog] = useState(false);
  const [packingLists, setSubmittedPl] = useState([]);
  const [error, setError] = useState("");
  const loadData = () => {
    if (selectedCustomIn) {
     
      setLoad(true);
      postData(
        "get",
        "arrival/commercial-invoice/packing-list/list",
        null,
        "docs",
        null,
        {
          commercial_invoice_ids: selectedCustomIn.id,
          //  encodeURI([selectedCustomIn.id]),
        }
      ).then((docs) => {
        if (docs) {
          const arr =
            docs && docs.length > 0
              ? docs.map((d) => {
                  return {
                    ...d,
                  };
                })
              : [];
          setSubmittedPl(arr.length > 0 ? arr : []);
        } else {
          setSubmittedPl([]);
        }
        setLoad(false);
      });
    }
  };

  useEffect(() => {
    if (selectedCustomIn) {
      loadData();
    }
  }, [selectedCustomIn]);
  useEffect(() => {
    if (selectedCustomInvoice) {
      setUnit(
        selectedCustomInvoice.custom_invoice_details[0].sales_order_details[0]
          .unit
      );
      fetchSoItems(
        selectedCustomInvoice.custom_invoice_details[0].sales_order_details[0]
          .id
      );
      setSelectedCustomIn(selectedCustomInvoice);
    }
  }, [selectedCustomInvoice]);
  const changeTo = (to, weigths, opposite) => {
    if (opposite) {
      if (to === "MT") {
        return multiply(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return multiply(weigths, 100);
      }
    } else {
      if (to === "MT") {
        return divide(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return divide(weigths, 100);
      }
    }
  };
  const changeUnit = (from, to, weigths, opposite) => {
    //default in kg

    if (opposite) {
      if (from === "MT") {
        return changeTo(to, divide(weigths, 1000), opposite);
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, divide(weigths, 100), opposite);
      }
    } else {
      if (from === "MT") {
        return changeTo(to, multiply(weigths, 1000));
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, multiply(weigths, 100));
      }
    }
  };
  const design = () => {
    return (
      <div className="width-100" style={{ minWidth: "100%" }}>
        <TableComponent
          singleUnit={
            selectedCustomIn &&
            selectedCustomIn.custom_invoice_details &&
            selectedCustomIn.custom_invoice_details[0] &&
            selectedCustomIn.custom_invoice_details[0].sales_order_details &&
            selectedCustomIn.custom_invoice_details[0].sales_order_details[0] &&
            selectedCustomIn.custom_invoice_details[0].sales_order_details[0]
              .unit &&
            selectedCustomIn.custom_invoice_details[0].sales_order_details[0]
              .unit
          }
          weight={
            selectedCustomIn &&
            selectedCustomIn.net_weight &&
            selectedCustomIn.net_weight
          }
          changeUnit={(value, unit) => {
            return changeUnit(
              unit,
              selectedCustomIn &&
                selectedCustomIn.custom_invoice_details &&
                selectedCustomIn.custom_invoice_details[0] &&
                selectedCustomIn.custom_invoice_details[0]
                  .sales_order_details &&
                selectedCustomIn.custom_invoice_details[0]
                  .sales_order_details[0] &&
                selectedCustomIn.custom_invoice_details[0]
                  .sales_order_details[0].unit &&
                selectedCustomIn.custom_invoice_details[0]
                  .sales_order_details[0].unit,
              value
            );
          }}
          bottomBarheader={["Bags", "Total Quantity"]}
          nobottomBarAction={true}
          bottomBar={true}
          loading={loader}
          minHeight={"130px"}
          heightNew="100%"
          maxHeight="100%"
          widthNew="100%"
          module={"Packings"}
          actions={[]}
          noAddButton={true}
          header={[
            "Sr.No.",
            "BL Number",
            "Size",
            "Bag Type",
            "Bags",
            "Total Quantity",
          ]}
          noSearchBar={true}
          {...props}
          tableList={
            packingLists && packingLists.length
              ? packingLists
                  .map((singleInventory) => {
                 
                    const custom_invoice = filterData(
                      selectedCustomIn &&
                        selectedCustomIn.custom_invoice_details,
                      "id",
                      singleInventory.custom_invoice,
                      "onlyOne"
                    )
                      ? filterData(
                          selectedCustomIn &&
                            selectedCustomIn.custom_invoice_details,
                          "id",
                          singleInventory.custom_invoice,
                          "onlyOne"
                        )
                      : false;
                    const pData =
                      singleInventory.packaging_details &&
                      singleInventory.packaging_details.length > 0
                        ? singleInventory.packaging_details[0]
                        : {};
                    const bagCount = pData
                      ? singleInventory.packaging_count &&
                        singleInventory.packaging_count[pData.id]
                        ? singleInventory.packaging_count[pData.id]
                        : ""
                      : [];

                

                    return {
                      ...singleInventory,
                      "BL Number": custom_invoice
                        ? custom_invoice.bl_number
                        : "",
                      Bags:
                        bagCount && bagCount.length > 0
                          ? bagCount.reduce(
                              (previousValue, currentValue) =>
                                Number(previousValue) +
                                Number(currentValue ? currentValue : 0),
                              0
                            )
                          : singleInventory.packaging_count[
                              singleInventory.packaging_details[0].id
                            ],
                      "Bag Type": pData ? pData.bag_type : "",
                      Size: pData ? `${pData.size} ${pData.unit}` : "",
                      "Total Quantity": multiply(
                        bagCount && bagCount.length > 0
                          ? bagCount.reduce(
                              (previousValue, currentValue) =>
                                Number(previousValue) +
                                Number(currentValue ? currentValue : 0),
                              0
                            )
                          : singleInventory.packaging_count[
                              singleInventory.packaging_details[0].id
                            ],
                        pData.size ? Number(pData.size) : 0
                      ),
                      "Total QuantityUnit": `${multiply(
                        bagCount && bagCount.length > 0
                          ? bagCount.reduce(
                              (previousValue, currentValue) =>
                                Number(previousValue) +
                                Number(currentValue ? currentValue : 0),
                              0
                            )
                          : singleInventory.packaging_count[
                              singleInventory.packaging_details[0].id
                            ],
                        pData.size ? Number(pData.size) : 0
                      )} ${pData.unit}`,
                      unit: pData ? pData.unit : "",
                    };
                  })
                  .sort((a, b) => b.id - a.id)
              : []
          }
          filterChild={false}
        />
      </div>
    );
  };
  const formatData = (s) => {
    const singleInventoryCustomInv =
      packingLists && packingLists.length > 0
        ? filterData(packingLists, "custom_invoice", s.id)
          ? filterData(packingLists, "custom_invoice", s.id)
          : []
        : [];

    const customInv = s;
    const newObj = {
      "Cus In. No.": customInv.invoice_number,
      "Cus In. Date": moment(customInv.invoice_date).format("ddd, MMM Do YYYY"),
      "Exporter Ref.": customInv.exporter_ref_no,
      "BL No.": s.bl_number,
      "Port of Discharge": customInv.discharge_port,
      "Place of Delivery": customInv.delivery_place,
      Consignee: "",
      Buyer:
        customInv && customInv.buyer
          ? filterData(
              masters && masters.buyersList,
              "id",
              customInv.buyer,
              "onlyOne"
            )
            ? filterData(
                masters && masters.buyersList,
                "id",
                customInv.buyer,
                "onlyOne"
              ).client_name
            : ""
          : "",
    };
    const selected = selectedCustomInvoice
      ? selectedCustomInvoice
      : downloadCI
      ? downloadCI
      : {};
    const items =
      customInv.custom_invoice_item_details &&
      customInv.custom_invoice_item_details.length > 0
        ? customInv.custom_invoice_item_details.map((sCItem) => {
            const details = filterData(soItems, "id", sCItem.so_item, "onlyOne")
              ? filterData(soItems, "id", sCItem.so_item, "onlyOne")
              : false;
            const singleInventory =
              singleInventoryCustomInv && singleInventoryCustomInv.length > 0
                ? filterData(
                    singleInventoryCustomInv,
                    "brand",
                    details && details.brand
                  )
                  ? filterData(
                      singleInventoryCustomInv,
                      "brand",
                      details && details.brand
                    )
                  : []
                : [];
   
            // (singleInventoryCustomInv && singleInventoryCustomInv.brand) ===
            // (details && details.brand)
            //   ? singleInventoryCustomInv
            //   : {};

            const commercialItem = filterData(
              selected.commercial_invoice_item_details,
              "custom_invoice_item",
              sCItem.id,
              "onlyOne"
            )
              ? filterData(
                  selected.commercial_invoice_item_details,
                  "custom_invoice_item",
                  sCItem.id,
                  "onlyOne"
                )
              : false;

            return details
              ? {
                  ...sCItem,
                  Brand: `${details.brand_details.brand_name} - ${
                    filterData(
                      masters.ItemCateoryList,
                      "id",
                      details.brand_details.item_category,
                      "onlyOne"
                    )
                      ? filterData(
                          masters.ItemCateoryList,
                          "id",
                          details.brand_details.item_category,
                          "onlyOne"
                        ).name
                      : ""
                  } - ${
                    filterData(
                      masters.ItemList,
                      "id",
                      details.brand_details.item_type,
                      "onlyOne"
                    )
                      ? `${Number(
                          filterData(
                            masters.ItemList,
                            "id",
                            details.brand_details.item_type,
                            "onlyOne"
                          ).broken_percentage
                        ).toFixed(2)}%`
                      : ""
                  }`,
                  "Item Category": filterData(
                    masters.ItemCateoryList,
                    "id",
                    details.brand_details.item_category,
                    "onlyOne"
                  )
                    ? filterData(
                        masters.ItemCateoryList,
                        "id",
                        details.brand_details.item_category,
                        "onlyOne"
                      ).name
                    : "",
                  "Item Type": filterData(
                    masters.ItemList,
                    "id",
                    details.brand_details.item_type,
                    "onlyOne"
                  )
                    ? `${Number(
                        filterData(
                          masters.ItemList,
                          "id",
                          details.brand_details.item_type,
                          "onlyOne"
                        ).broken_percentage
                      ).toFixed(2)} `
                    : "",
                  packagingDetail: details.packaging.map((sP) => {
                    const pVal = filterData(
                      masters.PackagingList,
                      "id",
                      Number(sP),
                      "onlyOne"
                    )
                      ? filterData(
                          masters.PackagingList,
                          "id",
                          Number(sP),
                          "onlyOne"
                        )
                      : "";
                    const bags = [];
                    if (singleInventory && singleInventory.length > 0) {
                      singleInventory.map((ss) => {
                        if (ss.packaging_count[sP]) {
                          bags.push(ss.packaging_count[sP]);
                        }
                        return ss;
                      });
                    }

                    return {
                      ...pVal,
                      total_bags:
                        bags && bags.length > 0
                          ? bags.reduce(
                              (previousValue, currentValue) =>
                                Number(previousValue) +
                                Number(currentValue ? currentValue : 0),
                              0
                            )
                          : 0,
                    };
                  }),
                  "HSN Code": filterData(
                    masters.HSNlist,
                    "id",
                    details.hsn_code,
                    "onlyOne"
                  )
                    ? filterData(
                        masters.HSNlist,
                        "id",
                        details.hsn_code,
                        "onlyOne"
                      ).hsn_code
                    : "",
                  "Total Item Quantity": `${details.total_quantity} ${
                    unit ? unit : ""
                  }`,
                  "Contract Rate": `${
                    filterData(
                      masters["CurrencyList"],
                      "id",
                      details.currency,
                      "onlyOne"
                    )
                      ? filterData(
                          masters["CurrencyList"],
                          "id",
                          details.currency,
                          "onlyOne"
                        ).name
                      : ""
                  } ${Number(details.contract_rate).toFixed(2)}/${
                    unit ? unit : ""
                  }`,
                  "Buyer Marking": details.buyers_marking
                    ? details.buyers_marking
                    : "-",
                  Currency: filterData(
                    masters["CurrencyList"],
                    "id",
                    details.currency,
                    "onlyOne"
                  )
                    ? filterData(
                        masters["CurrencyList"],
                        "id",
                        details.currency,
                        "onlyOne"
                      ).name
                    : "",
                  Amount:
                    commercialItem && commercialItem.amount_in_other
                      ? `${Number(commercialItem.amount_in_other).toFixed(2)} ${
                          filterData(
                            masters["CurrencyList"],
                            "id",
                            details.currency,
                            "onlyOne"
                          )
                            ? filterData(
                                masters["CurrencyList"],
                                "id",
                                details.currency,
                                "onlyOne"
                              ).name
                            : ""
                        }`
                      : "",
                  ...commercialItem,
                  // Specifications: "WEFERFRT4G56YH6",
                  // SpecificationsUI: (
                  //   <ShowSpecifications
                  //     details={
                  //       details.so_specifications && details.so_specifications.length > 0
                  //         ? details.so_specifications.map((sP) => {
                  //             // sP.used_bags =0
                  //             return sP;
                  //           })
                  //         : []
                  //     }
                  //   />
                  // ),
                }
              : { ...sCItem };
          })
        : [];
    return { ...s, ...newObj, subList: items };
  };
 
  return (
    <Paper className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary d-flex d-flex-column  height-100 width-100 textC minHeightFC">
      {downloadCI !== false ? (
        <CommercialPackingList
          masters={masters}
          downloadCI={selectedCustomIn}
          {...props}
          tableList={
            // packingLists && packingLists.length
            //   ? packingLists
            //       .sort((a, b) => b.id - a.id)
            //       .map((s) => {
            //         return formatData(s);
            //       })
            //   : []
            selectedCustomIn &&
            selectedCustomIn.custom_invoice_details &&
            selectedCustomIn.custom_invoice_details.length
              ? selectedCustomIn.custom_invoice_details
                  .sort((a, b) => b.id - a.id)
                  .map((s) => {
                    return formatData(s);
                  })
              : []
          }
          onClose={() => {
            setDownloadCI(false);
          }}
        />
      ) : (
        ""
      )}{" "}
      <div
        className="width-100 d-flex justifyFE "
        style={{ padding: "0 15px" }}
      >
        {noAddButton || selectedCustomIn.status === "CLOSED" ? (
          ""
        ) :packingLists && packingLists.length>0? (
          <StyledButton
            name="Mark as closed"
            withBg={true}
            maxHeight="35.74px"
            margin="5px 10px"
            onClick={(e) => {
              e.stopPropagation();
              setOpenDialog(true);
            }}
          />
        ):""}
        <StyledLoadingButton
          loading={
            dowLoader[selectedCustomInvoice?.id]
              ? dowLoader[selectedCustomInvoice.id]
              : false
          }
          tooltip="Download PDF"
          name={<Download />}
          margin="5px 0"
          maxHeight="35.74px"
          onClick={(e) => {
            setUnit(
              selectedCustomIn.custom_invoice_details[0].sales_order_details[0]
                .unit
            );
            fetchSoItems(
              selectedCustomIn.custom_invoice_details[0].sales_order_details[0]
                .id,
              selectedCustomIn
            );
          }}
          width={"43px"}
          minWidth={"43px"}
          withBg={true}
          background="rgb(241 154 48)"
        />
      </div>
      {openDialog === true ? (
        <CssDialog
          headerPrimary={true}
          resetText={"Cancel"}
          height="250px"
          width="350px"
          maxWidth={"350px"}
          header={`Invoice No. ${selectedCustomIn.invoice_number}`}
          addButtonWidth="60px"
          addButton={"Submit"}
          reset={() => {
            setOpenDialog(false);
          }}
          onClose={() => {
            setOpenDialog(false);
          }}
          snackbarClose={() => {
            setOpenDialog(false);
          }}
          onSubmit={() => {
            if (postData) {
              setLoad(true);

              if (selectedCustomIn) {
                postData(
                  "patch",
                  "arrival/commercial-invoice",
                  selectedCustomIn.id,
                  "patch",
                  {
                    status: "CLOSED",
                    invoice_number: selectedCustomIn.invoice_number,
                  }
                ).then((res2) => {
                  if (res2.error) {
                    setLoad(false);
                    setError(res2.error);
                  } else {
                    if (updateValues) {
                      updateValues(res2);
                    }
                    setOpenDialog(false);
                    setLoad(false);
                  }
                });
              }
            }
          }}
          children={() => {
            return loader ? (
              <StyledCircularProgress />
            ) : (
              <div
                className="width-100 d-flex flexWrap wordBreak"
                style={{ fontSize: "1.3rem" }}
              >
                Are you sure to mark this invoice as closed ?
              </div>
            );
          }}
        />
      ) : (
        ""
      )}
      {error ? (
        <SnackbarComponent
          error={error}
          autoHideDuration={5000}
          // success={success}
          snackbarClose={() => {
            setError(false);
          }}
        />
      ) : (
        ""
      )}
      {loader === true ? (
        <StyledCircularProgress />
      ) : noAddButton || selectedCustomIn.status === "CLOSED" ? (
        design()
      ) : (
        <>
          {design()}
          <div
            className="width-100 padding15 d-flex justifyFE"
            style={{ minWidth: "100%" }}
          >
            {packingLists.length > 0 ? (
              selectedCustomIn &&
              selectedCustomIn.shipment_process_status !==
                "COMMERCIAL INVOICE GENERATED" &&
              ""
            ) : (
              // <StyledButton
              //   name="Proceed"
              //   withBg={true}
              //   onClick={() => {
              //     if (packingLists.length) {
              //       setOpenDialog(true);
              //     } else {
              //       setError("Please add atleast one packing list!");
              //     }
              //   }}
              // />
              <div className="width-100 d-flex marginT10">
                <div
                  style={{ width: "calc(100% - 270.01px)" }}
                  className="fontWeight500 fontSize1 d-flex alignC "
                >
                  to add packings
                </div>
                <StyledButton
                  name="Go to intransit"
                  margin="0 10px"
                  withBg={true}
                  onClick={() => {
                    if (changeTab) {
                      changeTab(1);
                    }
                  }}
                />
                <StyledButton
                  name="Go to inventory"
                  withBg={true}
                  onClick={() => {
                    if (changeTab) {
                      changeTab(2);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}
    </Paper>
  );
}

export default ComPackingList;
