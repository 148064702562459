import moment from "moment";
import React, { useEffect, useState } from "react";
import DownloadUi from "../../../layoutComponents/DownloadUi";
import { filterData } from "../../../Regex";
import PackingList from "./PackingList";
import converter from "number-to-words";

function CustomIn(props) {
  const { downloadCI, onClose, masters, soItems } = props;
  const selectedShipper =
    downloadCI && downloadCI.shipper
      ? filterData(masters && masters.shippersList, "id", downloadCI.shipper, "onlyOne")
        ? filterData(masters && masters.shippersList, "id", downloadCI.shipper, "onlyOne")
        : ""
      : "";
  const selectedBenificiary =
    downloadCI && downloadCI.beneficiary
      ? filterData(masters && masters.benificiarysList, "id", downloadCI.beneficiary, "onlyOne")
        ? filterData(masters && masters.benificiarysList, "id", downloadCI.beneficiary, "onlyOne")
        : ""
      : "";
  const selectedSo =
    downloadCI && downloadCI.sales_order
      ? filterData(masters && masters.salesOrderList, "id", Number(downloadCI.sales_order), "onlyOne")
        ? filterData(masters && masters.salesOrderList, "id", Number(downloadCI.sales_order), "onlyOne")
        : ""
      : "";
  // const [soItems, setsoItems] = useState([]);

  // useEffect(() => {
  //   if (soItems) {
  //     setsoItems(soItems);
  //   }
  // }, [soItems]);
  const details = (name, value, v1, v2, v3, border1, flex, center, height, bheight, noP, padding) => {
    return (
      <div
        style={{
          width: "100%",
          padding: noP ? "" : border1 ? "" : height === "10px" ? "" : "2.5px",
          display: flex ? "flex" : "",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            maxWidth: "100%",
            width: flex ? "fit-content" : "100%",
            borderBottom: border1 ? "1px solid rgba(0, 0, 0, 0.12)" : "",
            justifyContent: center ? center : "",
            textAlign: center ? center : "",
            display: "flex",
            padding: border1 ? "2.5px" : "",
            minHeight: height ? height : "",
            alignItems: "center",
            wordBreak: "break-word",
          }}
        >
          {name}
        </div>
        {value && (
          <div
            style={{
              padding: padding ? padding : border1 ? "5px" : "",
              width: flex ? "fit-content" : "100%",
              justifyContent: center ? "center" : "",
              display: "flex",
              minHeight: bheight ? bheight : "",
              height: "fit-content",
              wordBreak: "break-word",
              flexWrap: "wrap",
            }}
          >
            {value}
          </div>
        )}
        {v1 && <div style={{ padding: border1 ? "5px" : "", width: "100%" }}>{v1}</div>}
        {v2 && <div style={{ padding: border1 ? "5px" : "", width: "100%" }}>{v2}</div>}
        {v3 && <div style={{ padding: border1 ? "5px" : "", width: "100%" }}>{v3}</div>}
      </div>
    );
  };
  const buyer =
    downloadCI && downloadCI.buyer
      ? filterData(masters && masters.buyersList, "id", downloadCI.buyer, "onlyOne")
        ? filterData(masters && masters.buyersList, "id", downloadCI.buyer, "onlyOne")
        : ""
      : "";
  const unit = downloadCI && downloadCI.unit ? downloadCI.unit : "";
  const ciUI = (
    <div style={{ padding: "10px 20px", fontSize: "0.45rem" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: "0.8rem",
        }}
      >
        Tax Invoice
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: "0.6rem",
          textTransform: "uppercase",
        }}
      >
        {downloadCI.invoice_type === "GST"
          ? "Supply meant for export on payment of integrated tax"
          : "Supply meant for export under bond or letter of undertaking without payment of IGST"}
      </div>
      <div style={{ width: "100%", color: "black", border: "1px solid rgba(0, 0, 0, 0.12)", margin: "10px 0 0 0" }}>
        <div style={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", display: "flex" }}>
          <div style={{ width: "calc(30% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
            {details(
              "Shipper :",
              selectedShipper && selectedShipper.name,
              selectedShipper && selectedShipper.address,
              `GST Number: ${selectedShipper && selectedShipper.gst_no ? selectedShipper.gst_no : ""}`
            )}
          </div>
          <div style={{ width: "calc(70% - 0.5px)" }}>
            <div style={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", display: "flex" }}>
              {/* <div
            style={{ width: "calc(50% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)", display: "flex" }}
          > */}
              <div style={{ width: "calc(50% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                {details("INVOICE NO.:", downloadCI && downloadCI.invoice_number)}
              </div>
              <div style={{ width: "calc(50% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                {details("DATE :", moment(downloadCI.invoice_date).format("ddd, MMM Do YYYY"))}
              </div>
              {/* </div> */}
              <div style={{ width: "calc(50% - 0.5px)" }}>
                {details("EXPORTER REF. :", downloadCI && downloadCI.exporter_ref_no ? downloadCI.exporter_ref_no : "")}
              </div>
            </div>
            <div style={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", display: "flex" }}>
              {/* {downloadCI.invoice_type === "GST" && ( */}
                <div style={{ width: "calc(34% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                  {details("LC NUMBER:", downloadCI && downloadCI.lc_no)}
                </div>
              {/* )} */}
              {/* {downloadCI.invoice_type === "GST" && ( */}
                <div style={{ width: "calc(17% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                  {details("LC Date :", moment(downloadCI.lc_date).format("ddd, MMM Do YYYY"))}
                </div>
              {/* )} */}

              <div
                style={{
                  width: downloadCI.invoice_type === "GST" ? "calc(35% - 0.5px)" : "calc(50% - 0.5px)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  display: "flex",
                }}
              >
                {details("CONTRACT NUMBER :", selectedSo && selectedSo.so_number ? selectedSo.so_number : "")}
              </div>
              <div style={{ width: downloadCI.invoice_type === "GST" ? "calc(15% - 0.5px)" : "calc(50% - 0.5px)" }}>
                {details("DATED :", moment(downloadCI.sales_order_date).format("ddd, MMM Do YYYY"))}
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", textTransform: "uppercase" }}>
              {details(
                "place of receipt by pre-carrier:",
                downloadCI && downloadCI.delivery_place ? downloadCI.delivery_place : ""
              )}
            </div>
          </div>
        </div>
        <div style={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", display: "flex" }}>
          <div style={{ width: "calc(40% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
            {details(
              "CONSIGNEE :",
              "TO ORDER",
              selectedSo && selectedSo.consignee ? selectedSo.consignee : "",
              null,
              null,
              null,
              null,
              null,
              null
            )}
          </div>
          <div style={{ width: "calc(60% - 0.5px)" }}>
            {details(
              "BUYER :",
              buyer ? buyer.client_name : "",
              buyer && `${buyer.address} ${buyer.city} ${buyer.state}`,
              `Phone : ${buyer && buyer.phone}`,
              null,
              null,
              null,
              null,
              null,
              null
            )}
          </div>
        </div>
        <div style={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", display: "flex" }}>
          <div style={{ width: "calc(40% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
            {details("NOTIFY 1 :",
       
            
           `${   filterData(
                masters && masters.notifyList,
                "id",
                downloadCI && downloadCI.notify_1 ? Number(downloadCI.notify_1) : "",
                "onlyOne"
              )
                ? filterData(
                    masters && masters.notifyList,
                    "id",
                    downloadCI && downloadCI.notify_1 ? Number(downloadCI.notify_1) : "",
                    "onlyOne"
                  ).notify
                : " "}`
            
            )}

          </div>
          <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
            <div style={{ width: "calc(40% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
              {/* {details("NOTIFY 2 :", downloadCI && downloadCI.notify_2 ? downloadCI.notify_2 : "")} */}
              {details("NOTIFY 2 :",

          `${filterData(
                masters && masters.notifyList,
                "id",
                downloadCI && downloadCI.notify_2 ? Number(downloadCI.notify_2) : "",
                "onlyOne"
              )
                ? filterData(
                    masters && masters.notifyList,
                    "id",
                    downloadCI && downloadCI.notify_2 ? Number(downloadCI.notify_2) : "",
                    "onlyOne"
                  ).notify
                : ""}`
         
            )}
            </div>
            <div style={{ width: "calc(60% - 0.5px)" }}>
              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "uppercase",
                }}
              >
                {details("Country of origin of Goods: INDIA", null, null, null, null, null, null, "center")}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "uppercase",
                }}
              >
                {details(
                  "Place of DELIVERY/supply:",
                  downloadCI && downloadCI.delivery_place ? downloadCI.delivery_place : "",
                  null,
                  null,
                  null,
                  null,
                  null,
                  "center"
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            display: "flex",
            textTransform: "uppercase",
          }}
        >
          <div style={{ width: "calc(40% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
            {details(
              "name of carrying vessel/flight no.",
              `${downloadCI && downloadCI.vessel_number} ${downloadCI && downloadCI.flight_number}`,
              null,
              null,
              null,
              "border",
              null,
              null
              // "27.46px"
            )}
            <div style={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", minHeight: "24.98px" }}></div>
            <div style={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
              {details("Port of loading:", "any port in INDIA")}
            </div>
            {details("Port of discharge", downloadCI && downloadCI.discharge_port ? downloadCI.discharge_port : "")}
          </div>
          <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
            <div style={{ width: "calc(40% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
             
              {details(
                "term of delivery & payment :",
                `Delivery : ${downloadCI && downloadCI.delivery_place ? downloadCI.delivery_place : ""} ,`,
                `Payment : ${
                  filterData(
                    masters && masters.soPaymentTermsList,
                    "id",
                    selectedSo && selectedSo.so_payment_term ? Number(selectedSo.so_payment_term) : "",
                    "onlyOne"
                  )
                    ? filterData(
                        masters && masters.soPaymentTermsList,
                        "id",
                        selectedSo && selectedSo.so_payment_term ? Number(selectedSo.so_payment_term) : "",
                        "onlyOne"
                      ).term
                    : "false"
                } `,
                null,
                null,
                "border"
              )}
            </div>
            <div style={{ width: "calc(60% - 0.5px)" }}>
              {details(
                "beneficiary name :",
                selectedBenificiary && selectedBenificiary.name,
                null,
                null,
                null,
                "border",
                null,
                null,
                // "27.46px",
                null,
                null,
                "5px 2.5px 0"
              )}
              {details(
                "A/C NO.:",
                selectedBenificiary && selectedBenificiary.account_no,
                null,
                null,
                null,
                null,
                "d-flex"
              )}
              {details(
                "BANK NAME :",
                selectedBenificiary && selectedBenificiary.bank_name,
                null,
                null,
                null,
                null,
                "d-flex"
              )}
              {details(selectedBenificiary && selectedBenificiary.bank_address)}
              {details(
                "SWIFT CODE :",
                selectedBenificiary && selectedBenificiary.swift_code,
                null,
                null,
                null,
                null,
                "d-flex"
              )}
            </div>
          </div>
        </div>

        {downloadCI.custom_invoice_item_details &&
          downloadCI.custom_invoice_item_details.length > 0 &&
          downloadCI.custom_invoice_item_details.map((s) => {
            const currency = filterData(masters["CurrencyList"], "id", s.currency, "onlyOne")
              ? filterData(masters["CurrencyList"], "id", s.currency, "onlyOne").name
              : "";
          
            const soItemDetail = filterData(soItems, "id", s.so_item, "onlyOne")
              ? filterData(soItems, "id", s.so_item, "onlyOne")
              : false;
            const rowHeader = [
              "Exchange rate",
              `Price (${currency}) PER ${unit}`,
              `Amount in ${currency}`,
              `PRICE (INR) PER ${unit}`,
              `Amount in (INR)`,
            ];
            return (
              <>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    textTransform: "uppercase",
                  }}
                >
                  <div
                    style={{
                      width: "calc(40% - 0.5px)",
                      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "calc(40% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                      {details("marks and number of container no.", null, null, null, null, null, null, "center")}
                    </div>
                    <div style={{ width: "calc(60% - 0.5px)" }}>
                      {details("No. and kind of package", null, null, null, null, null, null, "center", "27.46px")}
                    </div>
                  </div>
                  <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                    <div
                      style={{
                        width: "calc(40% - 0.5px)",
                        display: "flex",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <div style={{ width: "calc(50% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {details("Description of goods", null, null, null, null, null, null, "center", "27.46px")}
                      </div>
                      <div style={{ width: "calc(50% - 0.5px)" }}>
                        {details(
                          `Quantity in (${downloadCI && downloadCI.unit ? downloadCI.unit : ""})`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                      </div>
                    </div>
                    <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                      <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {details(
                          rowHeader[1]
                            ? rowHeader[1]
                            : `Price USD PER ${downloadCI && downloadCI.unit ? downloadCI.unit : ""}`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {details(
                          rowHeader[2] ? rowHeader[2] : `Amount in (USD)`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {details(
                          rowHeader[3]
                            ? rowHeader[3]
                            : `Price (INR) PER ${downloadCI && downloadCI.unit ? downloadCI.unit : ""}`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)" }}>
                        {details(
                          rowHeader[4] ? rowHeader[4] : `Amount in INR`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    textTransform: "uppercase",
                  }}
                >
                  <div
                    style={{
                      width: "calc(40% - 0.5px)",
                      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{ width: "calc(40% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}
                      className="d-flex d-flex-column justifySA"
                    >
                      {/* {downloadCI && downloadCI.container_details && downloadCI.container_details.length > 0
                      ? downloadCI.container_details.map((s) =>
                          details(s.container_brand, null, null, null, null, null, null, null, null, null, true)
                        )
                      : ""}
                    
                    {downloadCI && downloadCI.container_details && downloadCI.container_details.length > 0
                      ? downloadCI.container_details.map((s) =>
                          s.container_number && s.container_number.length > 0
                            ? s.container_number.map((ss) =>
                                details(ss, null, null, null, null, null, null, null, null, null, true)
                              )
                            : ""
                        )
                      : ""}
                  </div> */}

                      <div style={{ padding: "5px" }}>
                        {details(
                          soItemDetail.buyers_marking ? soItemDetail.buyers_marking : "-",
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          true
                        )}
                      </div>
                      {details(
                        "HSN CODE :",
                        soItemDetail && soItemDetail.hsn_code
                          ? filterData(masters && masters.HSNlist, "id", Number(soItemDetail.hsn_code), "onlyOne")
                            ? filterData(masters && masters.HSNlist, "id", Number(soItemDetail.hsn_code), "onlyOne")
                                .hsn_code
                            : ""
                          : "",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null
                      )}
                    </div>
                    <div style={{ width: "calc(60% - 0.5px)" }}>
                      {details(
                        downloadCI && downloadCI.container_details && downloadCI.container_details.length > 0
                          ? `${downloadCI.container_details.map((s) =>
                              s.container_number && s.container_number.length > 0 ? s.container_number.length : ""
                            )} X  20' CONTS`
                          : "",
                        downloadCI.number_of_containers && downloadCI.number_of_containers,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center",
                        "27.46px"
                      )}
                      {details(
                        `Number of bags`,
                        soItemDetail
                          ? soItemDetail.packaging.reduce(
                              (previousValue, currentValue) =>
                                Number(previousValue) +
                                Number(s.pkg_counter[currentValue] ? s.pkg_counter[currentValue] : 0),
                              0
                            )
                          : // .map((sP) => {
                            //     // const pVal = filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
                            //     //   ? filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
                            //     //   : "";

                            //     return `${s.pkg_counter[sP] ? s.pkg_counter[sP] : ""}`;
                            //   })
                            "",
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center",
                        "27.46px"
                      )}
                      {details(
                        soItemDetail
                          ? `Packaging in  ${soItemDetail.packaging.map((sP) => {
                              const pVal = filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
                                ? filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
                                : "";
                     
                              return `${pVal.size ? pVal.size : ""}${pVal.unit ? pVal.unit : ""} ${
                                pVal.bag_type ? pVal.bag_type : ""
                              } `;
                            })} bags`
                          : "",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center",
                        "27.46px"
                      )}
                    </div>
                  </div>
                  <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                    <div
                      style={{
                        width: "calc(40% - 0.5px)",
                        display: "flex",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <div style={{ width: "calc(50% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                      
                        {details(
                          soItemDetail && soItemDetail.brand_details
                            ? `${soItemDetail.brand_details.brand_name} - ${
                                filterData(
                                  masters.ItemCateoryList,
                                  "id",
                                  soItemDetail.brand_details.item_category,
                                  "onlyOne"
                                )
                                  ? filterData(
                                      masters.ItemCateoryList,
                                      "id",
                                      soItemDetail.brand_details.item_category,
                                      "onlyOne"
                                    ).name
                                  : ""
                              } - ${
                                filterData(masters.ItemList, "id", soItemDetail.brand_details.item_type, "onlyOne")
                                  ? `${Number(
                                      filterData(
                                        masters.ItemList,
                                        "id",
                                        soItemDetail.brand_details.item_type,
                                        "onlyOne"
                                      ).broken_percentage
                                    ).toFixed(2)}%`
                                  : ""
                              }`
                            : "",
                          "",
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center"
                          // "20px"
                        )}
                      </div>
                      <div style={{ width: "calc(50% - 0.5px)" }}>
                        {details(
                          `GROSS WT.`,
                          s.gross_weight ? s.gross_weight : "",
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "20px"
                        )}
                        {details(downloadCI && downloadCI.unit, null, null, null, null, null, null, "center", "10px")}
                        {details(
                          `NET WT.`,
                          s.net_weight ? s.net_weight : "",
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "20px"
                        )}
                        {details(downloadCI && downloadCI.unit, null, null, null, null, null, null, "center", "11px")}
                      </div>
                    </div>
                    <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                      <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {details(
                          s.delivery_terms
                            ? `${s.delivery_terms[0]} ${Number(s.delivery_terms[1]).toFixed(2)}/${
                                s.delivery_terms[2]
                              }, ${s.delivery_terms[3]},
                     `
                            : "",
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {details(``, null, null, null, null, null, null, "center", "27.46px")}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {details(``, null, null, null, null, null, null, "center", "27.46px")}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)" }}>
                        {details(``, null, null, null, null, null, null, "center", "27.46px")}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    textTransform: "uppercase",
                  }}
                >
                  <div
                    style={{
                      width: "calc(40% - 0.5px)",
                      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {details("CARGO IN TRANSIT TO BURKINA FASO", null, null, null, null, null, null, null, "30.46px")}
                    {details("UNDER RODTEP SCHEME", null, null, null, null, null, null, null, "27.46px")}
                    {details(
                      "CARGO PROCURED FROM PRIVATELY HELD STOCK",
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      "20px"
                    )}

                    {details(
                      "LETTER OF UNDERTAKING WITHOUT PAYMENT OF IGST",
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      "20px"
                    )}
                  </div>
                  <div style={{ width: "calc(60% - 0.5px)" }}>
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        display: "flex",
                        textTransform: "uppercase",
                      }}
                    >
                      {details(`Exchange Rate`, s.exchange_rate ? s.exchange_rate : "")}
                    </div>
                    <div style={{ width: "100%", display: "flex", height: "100%" }}>
                      <div
                        style={{
                          width: "calc(40% - 0.5px)",

                          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        {details(`FOB VALUE`, "", null, null, null, "border1", null, null, "20px")}

                        {details(`OCEAN FREIGHT`, "", null, null, null, "border1", null, null, "20px")}
                        {details(`INSURANCE`, "", null, null, null, "border1", null, null, "20px")}
                        {details(`TOTAL`, "", null, null, null, null, null, null, "20px")}
                      </div>
                      <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                        <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                          {details(` `, null, null, null, null, "border1", null, null, "20px")}
                          {details(`  `, null, null, null, null, "border1", null, null, "20px")}
                          {details(`  `, null, null, null, null, "border1", null, null, "20px")}
                          {details(`  `, null, null, null, null, null, null, null, "20px")}
                        </div>
                        <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                          {details(
                            `${s.fob_value_in_other ? s.fob_value_in_other : ""}`,
                            null,
                            null,
                            null,
                            null,
                            "border1",
                            null,
                            null,
                            "20px"
                          )}
                          {details(
                            `${s.ocean_freight_in_other ? s.ocean_freight_in_other : ""}`,
                            null,
                            null,
                            null,
                            null,
                            "border1",
                            null,
                            null,
                            "20px"
                          )}
                          {details(
                            `${s.insurance_in_other ? s.insurance_in_other : ""}`,
                            null,
                            null,
                            null,
                            null,
                            "border1",
                            null,
                            null,
                            "20px"
                          )}
                          {details(
                            `${s.total_amount_in_other ? s.total_amount_in_other : ""}`,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "20px"
                          )}
                        </div>
                        <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                          {details(
                            `${s.fob_value_per_unit ? s.fob_value_per_unit : ""}`,
                            null,
                            null,
                            null,
                            null,
                            "border1",
                            null,
                            null,
                            "20px"
                          )}

                          {details(
                            `${s.ocean_freight_per_unit ? s.ocean_freight_per_unit : ""}`,
                            null,
                            null,
                            null,
                            null,
                            "border1",
                            null,
                            null,
                            "20px"
                          )}
                          {details(
                            `${s.insurance_per_unit ? s.insurance_per_unit : ""}`,
                            null,
                            null,
                            null,
                            null,
                            "border1",
                            null,
                            null,
                            "20px"
                          )}
                          {details(
                            `${s.total_amount_per_unit ? s.total_amount_per_unit : ""}`,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "20px"
                          )}
                        </div>
                        <div style={{ width: "calc(25% - 0.75px)" }}>
                          {details(
                            `${s.fob_value_in_inr ? s.fob_value_in_inr : ""}`,
                            null,
                            null,
                            null,
                            null,
                            "border1",
                            null,
                            null,
                            "20px"
                          )}

                          {details(
                            `${s.ocean_freight_in_inr ? s.ocean_freight_in_inr : ""}`,
                            null,
                            null,
                            null,
                            null,
                            "border1",
                            null,
                            null,
                            "20px"
                          )}
                          {details(
                            `${s.insurance_in_inr ? s.insurance_in_inr : ""}`,
                            null,
                            null,
                            null,
                            null,
                            "border1",
                            null,
                            null,
                            "20px"
                          )}
                          {details(
                            `${s.total_amount_in_inr ? s.total_amount_in_inr : ""}`,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "20px"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    textTransform: "uppercase",
                  }}
                >
                  <div style={{ width: "calc(40% - 0.5px)", borderRight: "1px solid white", display: "flex" }}>
                    <div style={{ width: "calc(50% - 0.5px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                      {details(
                        `RATE OF IGST (IN %) ${
                          downloadCI.invoice_type === "GST" ? (s.rate_of_igst ? s.rate_of_igst : "0") : "0"
                        }%`,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center",
                        ""
                      )}
                    </div>
                    <div style={{ width: "calc(50% - 0.5px)" }}>
                      {details(`IGST AMOUNT ON FOB VALUE`, null, null, null, null, null, null, "center", "")}
                    </div>
                  </div>
                  <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                    <div style={{ minWidth: "calc(40% - 0.5px)", display: "flex", borderRight: "1px solid white" }}>
                      <div style={{ minWidth: "calc(50% - 0.5px)", borderRight: "1px solid white" }}></div>
                      <div style={{ minWidth: "calc(50% - 0.5px)" }}></div>
                    </div>
                    <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                      <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid white" }}>
                        {details(`  `, null, null, null, null, null, null, null, "20px")}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)" }}>
                        {details(
                          `${
                            downloadCI.invoice_type === "GST"
                              ? s.igst_amount_in_other
                                ? s.igst_amount_in_other
                                : "0.00"
                              : "0.00"
                          }`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "flex-end",
                          "20px"
                        )}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)" }}>
                        {details(``, null, null, null, null, null, null, null, "20px")}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)" }}>
                        {details(
                          `${
                            downloadCI.invoice_type === "GST"
                              ? s.igst_amount_in_inr
                                ? s.igst_amount_in_inr
                                : ""
                              : "0.00"
                          }`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "flex-end",
                          "20px"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    textTransform: "uppercase",
                  }}
                >
                  <div style={{ width: "calc(40% - 0.5px)", borderRight: "1px solid white", display: "flex" }}>
                    <div style={{ width: "calc(50% - 0.5px)" }}>
                      {details(``, null, null, null, null, null, null, "center", "")}
                    </div>
                    <div style={{ width: "calc(50% - 0.5px)" }}>
                      {details(
                        downloadCI.invoice_type === "GST"
                          ? "TOTAL SHIPMENT VALUE OF GOODS INCLUDING IGST"
                          : `TOTAL VALUE OF SHIPPED CARGO`,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center",
                        ""
                      )}
                    </div>
                  </div>
                  <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                    <div
                      style={{
                        minWidth: "calc(40% - 0.5px)",
                        display: "flex",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <div style={{ minWidth: "calc(50% - 0.5px)", borderRight: "1px solid white" }}></div>
                      <div style={{ minWidth: "calc(50% - 0.5px)" }}></div>
                    </div>
                    <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                      <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {details(currency, null, null, null, null, null, null, null, "20px")}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {details(
                          `${s.final_shipped_amount_in_other ? s.final_shipped_amount_in_other : ""}`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "flex-end",
                          "20px"
                        )}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {details(`INR`, null, null, null, null, null, null, null, "20px")}
                      </div>
                      <div style={{ width: "calc(25% - 0.75px)" }}>
                        {details(
                          `${s.final_shipped_amount_in_inr ? s.final_shipped_amount_in_inr : ""}`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "flex-end",
                          "20px"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    textTransform: "uppercase",
                  }}
                >
                  {details(
                    `AMOUNT IN WORDS :   ${currency} ${converter.toWords(
                      Number(s.final_shipped_amount_in_other ? s.final_shipped_amount_in_other : "")
                    )} ONLY`
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    textTransform: "uppercase",
                  }}
                >
                  {details(
                    `AMOUNT IN WORDS :   RS. ${converter.toWords(
                      Number(s.final_shipped_amount_in_inr ? s.final_shipped_amount_in_inr : "")
                    )} ONLY`
                  )}
                </div>
              </>
            );
          })}

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            minHeight: "60px",
            height: "60px",
          }}
        >
          <div
            style={{
              padding: "5px",
              display: "flex",
              minHeight: "100%",
              height: "100%",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div style={{ padding: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>
             For BALAJI RICE INDUSTRIES
              <div style={{ margin: "0 20px 0 0" }}></div>
            </div>
            <div
              style={{
                padding: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              Authorized Signatory Director
              <div style={{ margin: "0 20px 0 0" }}></div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "calc(100% - 40px)",
          display: "flex",
          flexDirection: "column",

          margin: "5px 0",
        }}
      >
        {details("CIN NUMBER : U14200CT2007PTC020290", null, null, null, null, null, null, null, null, null, true)}
        {details("EMAIL : bri-team@smilebots.com", null, null, null, null, null, null, null, null, null, true)}
        {details("WEB : www.balaji.com", null, null, null, null, null, null, null, null, null, true)}
        {details("FAX : +91 771 4032240", null, null, null, null, null, null, null, null, null, true)}
      </div>
    </div>
  );
  return (
    <DownloadUi download={downloadCI} onClose={onClose} noLayout={true} fileName={downloadCI.invoice_number}>
      {ciUI}
      <div className="page-break"></div>
      <PackingList
        {...props}
        selectedShipper={selectedShipper}
        selectedBenificiary={selectedBenificiary}
        selectedSo={selectedSo}
        details={details}
        buyer={buyer}
        soItemList={soItems}
      />
    </DownloadUi>
  );
}

export default CustomIn;
