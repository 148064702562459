import React, { useRef, useEffect, useState } from "react";

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Divider } from "@material-ui/core";
import { filterData } from "../../Regex";

import PdfLayoutSo from "../../layoutComponents/PdfLayoutSo";
import parse from "html-react-parser";
import { CompanyFullName } from "../../Defaults";
import moment from "moment";
import { primary } from "../../styles/colors";

function SalesOrderPdf(props) {
  const {
    downloadPdf,
    module,
    singlelistView,
    PaymentTermList,
    header,
    widthList,
    tableList,
    ClientList,
    CurrencyList,
    PortsList,
    soPolicyList,
    SoPaymentList,
    Incoterms,
    masters,
  } = props;
  const [singlePrice, setSinglePrice] = useState("");
  const [value, setValue] = React.useState(0);
  const contentArea = useRef(null);
  const price =
    singlelistView.so_delivery_terms[0] +
    singlelistView.so_delivery_terms[0][0] +
    "/" +
    singlelistView.so_delivery_terms[2] +
    singlelistView.so_delivery_terms[3] +
    singlelistView.so_delivery_terms[4] +
    singlelistView.so_delivery_terms[5] +
    singlelistView.so_delivery_terms[6][0];


  const getSpecificationName = (sp) => {
    switch (sp) {
      case "equal_to":
        return "only";

      case "not_equal_to":
        return "not";

      case "greater_than":
        return "above";

      case "greater_than_or_equal_to":
        return "min";

      case "less_than":
        return "below";

      case "less_than_or_equal_to":
        return "max";
    }
  };
  var converter = require("number-to-words");
  let p =
    singlelistView &&
    singlelistView.length > 0 &&
    singlelistView.load_ports.map((sp) =>
      sp === "Any Indian Port"
        ? "Any Indian Port"
        : filterData(PortsList, "id", sp, "onlyName")
        ? filterData(PortsList, "id", sp, "onlyName").name
        : ""
    );

  const handleExportWithFunction = (event) => {
 
    if (contentArea.current) {
      contentArea.current.save();
    }
    props.onClose();
  };
  useEffect(() => {
    if (contentArea) {
      handleExportWithFunction();
    }
  }, [downloadPdf]);

  const HDivider = <Divider style={{ minWidth: "100%", width: "100%" }} />;
  const VDivider = <Divider variant="vertical" style={{ height: "100%", minHeight: "29.19px" }} />;

  const buyerDetails = filterData(ClientList, "id", singlelistView.buyer, "onlyOne")
    ? filterData(ClientList, "id", singlelistView.buyer, "onlyOne")
    : "";
  const handlePrice = (s, index) => {
    let cc = "";
    cc =
      singlelistView.so_delivery_terms[0] +
      singlelistView.so_delivery_terms[0][index] +
      "/" +
      singlelistView.so_delivery_terms[2] +
      singlelistView.so_delivery_terms[3] +
      singlelistView.so_delivery_terms[4] +
      singlelistView.so_delivery_terms[5] +
      singlelistView.so_delivery_terms[6][index];
    {
      cc && setSinglePrice(cc.toString().trim().split(/ +/).join(" "));
    }
  };

  const file = {
    name: "Sales Order No.",
    value: singlelistView && singlelistView.so_number,
    date: singlelistView.contract_date,
    buyer: buyerDetails.client_name,
    buyerAddress: buyerDetails.address,
    buyerCity: buyerDetails.city,
    buyerState: buyerDetails.state,
    buyerPhone: buyerDetails.phone,
    totalQuantity: singlelistView.total_quantity,
    unit: singlelistView.unit,
    terms: singlelistView.terms,
  };
  const buyerDet =
    file.buyer +
    ", " +
    file.buyerAddress +
    ", " +
    file.buyerCity +
    ", " +
    file.buyerState +
    ", " +
    "TEL:" +
    " " +
    file.buyerPhone;
  const PageTemplate = (props) => {
    const title = "Sales Order";

    const HDarkDivider = <Divider style={{ minWidth: "100%", width: "100%" ,background:"black",opacity:"0.6"}} />;
    // const HDivider = <Divider style={{ minWidth: "100%", width: "100%" }} />;
    // const today = moment().format("DD-MM-YYYY");
    // const so_num = file.value;

    return (
      <>
      <div
          style={{
            position: "absolute",
            top: "10px",
            height: "120px",
            width:"calc(100% - 20px)",
            left: "10px",
            right: "10px",
          }}
        >

<div
        style={{
          height: "40px",
          padding: "5px 0",
          width: "calc(100% - 40px)",
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          textTransform: "capitalize",
          margin: "0 20px",
        }}
      >
        <div
          style={{
            width: "100px",
            height: "100%",
            overflow: "hidden",
            minWidth: "100px",
          }}
        >
          {/* {" "}
      <img
        alt=""
        src="ssipllogo.png"
        style={{ height: "calc(100% + 40px)", width: "100%", margin: "-30px 0 -10px 0", overflow: "hidden" }}
      />{" "} */}
        </div>
        <div
          style={{
            width: "calc(100% - 270px)",
            margin: "0 10px",
           display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              color: "black",
              textAlign: "center",
              textTransform: "uppercase",
              margin: "0 2px 0 0",
              fontSize: "0.9rem",
              width: "100%",
            }}
          >
            {CompanyFullName}
          </h2>
          <div style={{ fontSize: "0.6rem", fontWeight: "600" }}>
            CIN No: U14200CT2007PTC020290
          </div>
        </div>
  
        <div
          style={{
            width: "150px",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "150px",
              display: "flex",

              fontSize: "0.6rem",
              justifyContent: "flex-end",
            }}
          >
            {title}   
          </div>
        </div>
      </div>
      {HDarkDivider} <div
            style={{
              width: "calc(100% - 40px)",
              margin:"15px 20px 0",
              display: "flex",
              // justifyContent: "flex-start",
              // alignItems: "center",
              
              fontSize: "0.6rem",
              textDecoration: "underline",
              fontWeight:"800"
            }}
          >
            CONTRACT NO: {file.value} <br /> DATED:{" "}
            {file.date ? moment(file.date).format("Do MMM, YYYY") : ""}
          </div>
        </div>
       <div
          style={{
            position: "absolute",
            bottom: "0",
            height: "70px",
       width:"calc(100% - 20px)",
            left: "10px",
            right: "10px",
          }}
        >
          <div 
          style={{width:"100%",display:"flex",justifyContent:"flex-end",fontSize:"0.7rem"}}>{`Page:${props.pageNum}/${props.totalPages}`}</div>
            
        
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "0.6rem",
              height: "40px",
              margin: "5px 0",
              fontSize: "0.6rem",
            }}
          >
            {HDarkDivider}
            <div style={{ width: "100%", fontWeight: "bold" }}>
              Registered Off : Behind Samrat Talkies, Civil Station Road, Raipur -492001,(C.G.) Ph:+91 771
              2887453,6460574
            </div>
            <div
              style={{
                width: "100%",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                Fax: +91 771 4032240, Email : bri-team@smilebots.com Web:{" "}
                <div style={{ color: "blue" }}>www.balaji.com</div>
              </div>
            </div>
            {HDarkDivider}
          </div>
          {/* Page {props.pageNum} of {props.totalPages} */}
        </div>
      </>
    );
  };
  return (
    <div style={{ height: "0", width: "0", overflow: "hidden" }}>
      <PDFExport
        ref={contentArea}
        // keepTogether="p"
        paperSize="A4"
        // margin="2cm"
        forcePageBreak=".page-break"
        fileName={`${singlelistView && singlelistView.so_number}.pdf`}
        allPages={true}
        margin={{
          bottom: 70,
          left: 30,
          right: 30,
          top: 120,
        }}
        pageTemplate={PageTemplate}
      >
        <div
          // ref={contentArea}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PdfLayoutSo
            title="Sales Order"
            tableList={tableList}
            file={{
              name: "Sales Order No.",
              value: singlelistView && singlelistView.so_number,
              date: singlelistView.contract_date,
              buyer: buyerDetails.client_name,
              buyerAddress: buyerDetails.address,
              buyerCity: buyerDetails.city,
              buyerState: buyerDetails.state,
              buyerPhone: buyerDetails.phone,
              totalQuantity: singlelistView.total_quantity,
              unit: singlelistView.unit,
              terms: singlelistView.terms,
            }}
          >
           
            <div
              style={{
                width: "calc(100% + 1px)",
                height: "calc(100% + 2px)",
                padding: "4px",
                fontSize: "0.6rem",
              }}
            >
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bolder",
                  marginTop: "10px 0",
                }}
              >
                Article 1: COMMODITY AND SPECIFICATION
              </div>
              {tableList && tableList.length > 0 ? (
                tableList.map((single, index) => (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px",
                          minWidth: "150px",
                        }}
                      >
                        PRODUCT{" "}
                      </p>
                      <p style={{ margin: "0px 0 10px 10px" }}>
                        : {single["Item Category"] ? single["Item Category"] : ""}&nbsp;
                        {/* {single["Brand"] ? single["Brand"] : ""}&nbsp; */}
                        {/* {single["Item Category"] ? single["Item Category"] : ""}&nbsp; */}
                        {/* {single["Item Type"] ? single["Item Type"] : ""} % */}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px ",
                          minWidth: "150px",
                        }}
                      >
                        SPECIFICATIONS{" "}
                      </p>
                      <div>
                        <p
                          style={{
                            margin: "0px 0 10px 10px",
                            textDecoration: "underline",
                            fontWeight: "bold",
                            // display: "flex",
                            // width: "100%",
                          }}
                        >
                          : {single["Brand"] ? single["Brand"] : ""}
                          {single["Item Category"] ? single["Item Category"] : ""}
                          {single["Item Type"] ? single["Item Type"] : ""} %
                        </p>
                        {single["Specifications"].map((item) => (
                          <>
                            <div
                              style={{
                                margin: "0px 0 10px 10px",
                                display: "flex",
                                flexDirection: "column",
                                // width: "100%",
                              }}
                            >
                              {item.name}: {item.value}{" "}
                              {item.name === "Moisture" ||
                              item.name === "Broken" ||
                              item.name === "Damaged/Coloured" ||
                              item.name === "Chalky"
                                ? "%"
                                : item.name === "Length"
                                ? "MM"
                                : item.name === "Paddy"
                                ? `/${singlelistView && singlelistView.unit}`
                                : ""}{" "}
                              {getSpecificationName(item.condition)}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px ",
                          minWidth: "150px",
                        }}
                      >
                        ORIGIN
                      </p>
                      <p style={{ margin: "0px 0 10px 10px" }}>: INDIA</p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px ",
                          minWidth: "150px",
                        }}
                      >
                        PACKING{" "}
                      </p>
                      <p style={{ margin: "0px 0 10px 10px" }}>
                        :{" "}
                        {single[`packagageArrUsed`] && single[`packagageArrUsed`].length > 0
                          ? single[`packagageArrUsed`].map((item, i) => (
                              <>{`In ${item.size} ${item.unit} Net. new ${item.bag_type} bags${
                                single[`packagageArrUsed`].length - 1 === i ? "." : ", "
                              }`}</>
                            ))
                          : ""}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px ",
                          minWidth: "150px",
                        }}
                      >
                        MARKING
                      </p>
                      <p style={{ margin: "0px 0 10px 10px" }}>: {single["Buyer Marking"]}</p>
                    </div>
                  </>
                ))
              ) : (
                <div>NO PRODUCT</div>
              )}
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                Article 2: QUANTITY / PRICE/ TOTAL AMOUNT
              </div>
              {tableList && tableList.length > 0 ? (
                tableList.map((single, index) => (
                  <>
                    {/* {handlePrice(single,index)} */}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px ",
                          minWidth: "150px",
                        }}
                      >
                        QUANTITY
                      </p>
                      <p style={{ margin: "0px 0 10px 10px" }}>
                        : {single["Total Item QuantityUnit"] ? single["Total Item QuantityUnit"] : ""}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px ",
                          minWidth: "150px",
                        }}
                      >
                        PRICE
                      </p>
                      <p
                        style={{
                          margin: "0px 0 10px 10px",
                          // whiteSpace: "pre",
                        }}
                      >
                        :{" "}
                        {singlelistView.so_delivery_terms[0] +
                          " " +
                          singlelistView.so_delivery_terms[1][index] +
                          "P" +
                          singlelistView.so_delivery_terms[2] +
                          " " +
                          singlelistView.so_delivery_terms[3] +
                          " " +
                          " " +
                          "in" +
                          " " +
                          singlelistView.so_delivery_terms[4] +
                          " " +
                          singlelistView.so_delivery_terms[5] +
                          " " +
                          singlelistView.so_delivery_terms[6][index] +
                          " " +
                          " " +
                          "in" +
                          " " +
                          singlelistView.so_delivery_terms[7]}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px ",
                          minWidth: "150px",
                        }}
                      >
                        VALUE
                      </p>
                      <p style={{ margin: "0px 0 10px 10px" }}>
                        :{" "}
                        {filterData(CurrencyList, "id", singlelistView.currency, "id", "onlyOne")
                          ? filterData(CurrencyList, "id", singlelistView.currency, "id", "onlyOne").name +
                            " " +
                            Number(single["Contract Rate"]) * Number(single["total_quantity"]) +
                            "/- (" +
                            filterData(CurrencyList, "id", singlelistView.currency, "id", "onlyOne").name +
                            " " +
                            converter.toWords(Number(single["Contract Rate"]) * Number(single["total_quantity"])) +
                            " only.)"
                          : " "}
                      </p>
                    </div>
                  </>
                ))
              ) : (
                <div>NO PRODUCT</div>
              )}
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                Article 3: DELIVERY/ LOADPORT/ DISCHARGE PORT/ SHIPPING TERMS:
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "0.6rem",
                    margin: "0px ",
                    minWidth: "150px",
                  }}
                >
                  Shipment
                </p>
                <p style={{ margin: "0px 0 10px 10px" }}>: In containers</p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "0.6rem",
                    margin: "0px ",
                    minWidth: "150px",
                  }}
                >
                  Load Port
                </p>
                <p style={{ margin: "0px 0 10px 10px" }}>
                  :{" "}
                  {singlelistView.load_ports.map((sp) =>
                    sp === "Any Indian Port"
                      ? "Any Indian Port"
                      : filterData(PortsList, "id", Number(sp), "onlyOne")
                      ? filterData(PortsList, "id", Number(sp), "onlyOne").name
                      : ""
                  )}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "0.6rem",
                    margin: "0px ",
                    minWidth: "150px",
                  }}
                >
                  Discharge Port
                </p>
                <p style={{ margin: "0px 0 10px 10px" }}>
                  : {singlelistView.discharge_port ? singlelistView.discharge_port : ""}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "0.6rem",
                    margin: "0px ",
                    minWidth: "150px",
                  }}
                >
                  Shipment by :
                </p>
                <p style={{ margin: "0px 0 10px 10px" }}>
                  :
                  {singlelistView.shipping_term &&
                  filterData(masters["ShippingTerms"], "id", Number(singlelistView.shipping_term), "onlyOne")
                    ? filterData(masters["ShippingTerms"], "id", Number(singlelistView.shipping_term), "onlyOne").value
                    : ""}
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bolder",
                  marginTop: "15px",
                }}
              >
                Article 4: PAYMENT{" "}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "0.6rem",
                    margin: "0px ",
                    minWidth: "150px",
                  }}
                >
                  Payment
                </p>
                <p style={{ margin: "0px 0 10px 10px" }}>
                  :{" "}
                  {singlelistView.so_payment_term &&
                  filterData(SoPaymentList, "id", Number(singlelistView.so_payment_term), "onlyOne")
                    ? filterData(SoPaymentList, "id", Number(singlelistView.so_payment_term), "onlyOne").term
                    : ""}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "0.6rem",
                    margin: "0px ",
                    minWidth: "150px",
                  }}
                >
                  Seller’s Bank Details
                </p>
                <p style={{ margin: "0px 0 10px 10px" }}>
                  : BANK OF BARODA, MAIN BRANCH <br /> GAUSHALA COMPLEX,K.K.ROAD,RAIPUR-CHATTISGARH-INDIA <br /> Swift
                  Code: BARBINBBRAI <br /> Account No. 05100200001242
                </p>
              </div>

              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bolder",
                  marginTop: "15px",
                }}
              >
                POLICY
              </div>
              {HDivider}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginLeft: "30px",
                }}
              >
                <p
                  style={{
                    margin: "10px 0 10px 10px",
                  }}
                >
                
                  {parse(singlelistView.policy_of_so_policy)}
                  {/* // &&
                // filterData(
                //   soPolicyList,
                //   "id",
                //   Number(singlelistView.so_policy),
                //   "onlyOne"
                // )
                //   ? parse(
                //       filterData(
                //         soPolicyList,
                //         "id",
                //         Number(singlelistView.so_policy),
                //         "onlyOne"
                //       ).policy
                //     )
                //   : ""} */}
                </p>
              </div>
              <div
            style={{
              display: "flex",
              width: "100%",
              // justifyContent: "space-evenly",
              flexDirection: "row",

              // justifyContent: "center",
              // alignItems: "center",
              // margin: "0 20px",
              marginTop: "40px",
              fontSize: "0.6rem",
            }}
          >
            <div style={{width:"fit-content",minWidth:"50%"}}>
              SELLER
              <br />
              BALAJI RICE INDUSTRIES 
              <br />
              <br />
              <br />
              <br />
              <br />
              (Authorised Signatory)
            </div>
            <div>
              BUYER
              <br />
              {buyerDetails.client_name.toUpperCase()}
              <br />
              <br />
              <br />
              <br />
              Name and designation of the person <br />
              (Authorised Signatory)
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
            </div>
          </PdfLayoutSo>
        </div>
      </PDFExport>
    </div>
  );
}

export default SalesOrderPdf;
