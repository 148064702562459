import { combineReducers } from "redux";

import authenticationReducer from "./authentications/authenticationReducer";
import moduleReducers from "./modules/moduleReducers";
const rootReducer = 
combineReducers({
  authentication: authenticationReducer,

  modules: moduleReducers,
});

export default rootReducer;
