// import React, { useEffect, useState, useRef } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import List from "@material-ui/core/List";
// import IconButton from "@material-ui/core/IconButton";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// import Collapse from "@mui/material/Collapse";
// import ReactDOM from "react-dom";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import Tooltip from "@material-ui/core/Tooltip";
// import { primary } from "../styles/colors";
// import { connect } from "react-redux";
// import StaticVersionDisplay from "./StaticVersionDisplay";
// import { Divider } from "@material-ui/core";
// import ProjectName from "./ProjectName";
// import RightIcon from "./RightIcon";
// import routes from "../routes/sidebar";
// import { Version } from "../Defaults";
// import { withStyles } from "@material-ui/core/styles";
// const drawerWidth = 240;

// const CssListItem = withStyles({
//   root: {
//     "& label.Mui-focused": {
//       border: "none",
//     },
//     "& .MuiTypography-body1": {
//       fontSize: "1rem",
//       fontWeight: "400",
//       lineHeight: "1.5",
//     },

//     overflowX: "hidden",
//   },
// })(ListItem);
// const CssDivider = withStyles({
//   root: {
//     "&.MuiDivider-middle": {
//       marginLeft: "0px",
//       marginRight: "0px",
//     },
//   },
// })(Divider);

// const useStyles = makeStyles((theme) => ({
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//     whiteSpace: "nowrap",
//   },
//   drawerOpen: {
//     width: drawerWidth,
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   drawerClose: {
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     width: theme.spacing(7) + 1,
//   },
//   toolbar: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     width: "100%",
//   },
//   nested: {
//     paddingLeft: theme.spacing(4),
//   },
//   nestedClose: {
//     paddingLeft: theme.spacing(3),
//   },
//   // position: 'absolute',
//   // bottom: 0,
//   // right: 5,
//   root: {
//     width: "100%",
//     maxWidth: 360,
//     backgroundColor:
//       "transparent linear- gradient(1deg, #3B69B0 0 %, #1E3558 100 %) 0% 0 % no - repeat padding- box",
//     height: "100%",
//   },
//   divBigAvatar: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     flexDirection: "column",
//     padding: 10,
//   },
// }));

// const keyMap = {
//   DASHBOARD: { name: "Expand square area", sequence: "1" },
//   SELLORDERS: { name: "Expand square area", sequence: "2" },
//   DISPATCHES: { name: "Expand square area", sequence: "3" },
//   MODULES: { name: "Expand square area", sequence: "4" },
//   USERANDPERMISSION: { name: "Expand square area", sequence: "5" },
// };

// function DrawerContent(props) {
//   const classes = useStyles();

//   // const masters = [
//   //   { title: "Clients", icon: "", path: "/masters/clients" },
//   //   { title: "Survey Companies", icon: "", path: "/masters/survey-companies" },
//   //   { title: "Payment Terms", icon: "", path: "/masters/payment-terms" },
//   //   { title: "Contract types", icon: "", path: "/masters/contract-types" },
//   //   { title: "Item Category", icon: "", path: "/masters/item-category" },
//   //   { title: "Item Types", icon: "", path: "/masters/item-types" },
//   //   { title: "Packaging", icon: "", path: "/masters/packaging" },
//   // ];
//   const tofilterWIth =
//     props.pathname.indexOf("/", props.pathname.indexOf("/") + 1) === -1
//       ? props.pathname
//       : props.pathname
//           .toString()
//           .slice(
//             0,
//             props.pathname.indexOf("/", props.pathname.indexOf("/") + 1)
//           );
//   const childRoutArr = [
//     "/Policys",
//     "/Incoterms",
//     "/SOpaymentTerms",
//     "/ShippingTerms",
//     "/Currencys",
//     "/Notifys",
//     "/RiceBagSellers",
//     "/Beneficiarys",
//     "/Shippers",
//     "/Clients",
//     "/SurveyCompanys",
//     "/ItemCategories",
//     "/ItemTypes",
//     "/PaymentTerms",
//     "/ContractTypes",
//     "/Packagings",
//     "/HsNs",
//   ];
//   const [isMasterOpen, setIsMasterOpen] = useState(
//     childRoutArr.includes(tofilterWIth) ? true : false
//   );
//   const [selectedMastersIndex, setSelectedMastersIndex] = useState(0);

//   const [open, setOpen] = React.useState(props.open);

//   // const [scrollTo, setscrollTo] = React.useState("");

//   const user = localStorage.getItem("briuser")
//     ? JSON.parse(localStorage.getItem("briuser"))
//     : "";
//   //   useEffect(() => {
//   // }, [props.user])

//   useEffect(() => {
//     setOpen(props.open);
//   }, [props.open]);
//   //const [innerMenu, setInnerMenu] = React.useState(false);
//   const [selectedIndex, setSelectedIndex] = React.useState(null);
//   const handlePageTitle = (title, index) => setSelectedIndex(index);

//   const myRef = useRef(null);

//   const handleScrollToElement = (path) => {
//     if (childRoutArr.includes(tofilterWIth)) {
//       const chat = document.getElementById(path);
//       chat.scrollIntoView();
//     }
//   };
//   useEffect(() => {
//     handleScrollToElement(tofilterWIth);
//   }, []);
//   const handleDrawerOpen = () => {
//     setOpen(true);
//     if (props.setOpen) props.setOpen(true);
//   };
//   const handleDrawerClose = () => {
//     setOpen(false);
//     if (props.setOpen) props.setOpen(false);
//   };
//   // const handleShortcut = {
//   //   DASHBOARD: (e) => props.navigate("/dashboard"),
//   //   SELLORDERS: (e) => props.navigate("/selloders"),
//   //   DISPATCHES: (e) => props.navigate("/dispatches"),
//   //   MODULES: (e) => props.navigate("/masters"),
//   //   USERANDPERMISSION: (e) => props.navigate("/userandpermmision"),
//   // };

//   const chectSelected = (path) => {
//     const tofilterWIth =
//       props.pathname.indexOf("/", props.pathname.indexOf("/") + 1) === -1
//         ? props.pathname
//         : props.pathname
//             .toString()
//             .slice(
//               0,
//               props.pathname.indexOf("/", props.pathname.indexOf("/") + 1)
//             );
//     if (
//       isMasterOpen === false &&
//       path === null &&
//       childRoutArr.includes(tofilterWIth)
//     ) {
//       //&& !innerMenu && childRoutesARR.includes(tofilterWIth))
//       return true;
//     } else {
//       if (tofilterWIth === path) {
//         return true;
//       } else {
//         return false;
//       }
//     }
//   };
//   const selectedStyle = (path, noIcon) => ({
//     color: chectSelected(path) ? primary : "white",
//     background: chectSelected(path) ? "white" : "",
//     overflow: "hidden",
//     borderRadius: chectSelected(path) ? "20px" : "0px 7px 7px 0",
//     // margin: chectSelected(path) ? "5px 0 5px 0" : open ? "5px" : "5px 0",
//     width: chectSelected(path) ? "100%" : open ? "" : "109%",
//     // padding: open ? "" : noIcon ? "8px 0" : "0",
//     minWidth: !open ? "0" : "40px",
//     justifyContent: !open ? "center" : "",
//     display: !open ? "flex" : "",
//     padding: "5px 18.5px",
//     height: open ? "" : "42px",
//   });

//   return (
//     <div
//       style={{ height: "100%", width: "100%", borderRadius: "20px" }}
//       className="d-flex d-flex-column"
//     >
//       {/* <GlobalHotKeys keyMap={keyMap} handlers={handleShortcut} />{' '} */}
//       {
//         <List disablePadding className={classes.root}>
//           {open && (
//             <div className={classes.divBigAvatar}>
//               <ProjectName {...props} />
//             </div>
//           )}
//           {open ? (
//             <Divider variant="middle" />
//           ) : (
//             <div style={{ height: "72px" }} />
//           )}
//           <div
//             style={{
//               height: "calc(100% - 172.35px)",
//               overflowY: "auto",
//               overflowX: "hidden",
//               width: "100%",
//             }}
//           >
//             {routes
//               .map((list, index) => {
//            
//                 if (
//                   user &&
//                   user.accessibleModules &&
//                   user.accessibleModules.includes(list.access)
//                 ) {
//                   return (
//                     <div key={`ListItemContainer-${index}`}>
//                       <CssListItem
//                         button
//                         key={`ListItem-${index}`}
//                         open={open}
//                         onClick={(e) => {
//                           e.preventDefault();

//                           if (list.title === "Masters") {
//                             setIsMasterOpen(!isMasterOpen);
//                           } else {
//                             handlePageTitle(list.title, index);
//                             props.navigate(list.path);
//                           }
//                         }}
//                         style={selectedStyle(list.path, "noIcon")}
//                       >
//                         <ListItemIcon
//                           style={{
//                             padding: open ? "" : "0",
//                             minWidth: !open ? "0" : "",
//                           }}
//                         >
//                           <Tooltip title={list.title} placement="right-start">
//                             {list.icon(list.path, chectSelected)}
//                           </Tooltip>
//                         </ListItemIcon>
//                         {props.open ? (
//                           list.title !== "Masters" ? (
//                             <ListItemText primary={list.title} />
//                           ) : (
//                             <>
//                               <ListItemText primary={list.title} />
//                               {isMasterOpen ? <ExpandLess /> : <ExpandMore />}
//                             </>
//                           )
//                         ) : null}
//                       </CssListItem>

//                       {list.title === "Masters" && (
//                         <Collapse in={isMasterOpen} unmountOnExit>
//                           <List className={classes.root}>
//                             {list.childRoutes.map((item, index) => (
//                               <div
//                                 id={item.path}
//                                 key={`NestedListCont-${index}`}
//                                 style={{ overflowX: "hidden" }}
//                               >
//                                 <CssListItem
//                                   button
//                                   onClick={(e) => {
//                                     e.preventDefault();
//                                     setSelectedMastersIndex(index);
//                                     props.navigate(item.path);
//                                   }}
//                                   style={selectedStyle(item.path, "noIcon")}
//                                 >
//                                   <ListItemIcon
//                                     style={{
//                                       paddingLeft: props.open ? "15px" : "14px",
//                                       minWidth: "51px",
//                                     }}
//                                   >
//                                     <Tooltip
//                                       title={item.title}
//                                       placement="right-start"
//                                     >
//                                       {item.icon(item.path, chectSelected)}
//                                     </Tooltip>
//                                   </ListItemIcon>
//                                   {props.open ? (
//                                     <ListItemText
//                                       style={{ marginLeft: "25px" }}
//                                       primary={item.title}
//                                     />
//                                   ) : (
//                                     ""
//                                   )}
//                                 </CssListItem>
//                               </div>
//                             ))}
//                           </List>
//                         </Collapse>
//                       )}
//                     </div>
//                   );
//                 } else return null;
//               })
//               .reverse()}
//           </div>

//           <CssDivider
//             variant="middle"
//             style={{ background: "#ffffff61", marginBottom: "8px" }}
//           />

//           <div className="d-flex width-100">
//             <RightIcon {...props} />
//           </div>
//           {/* <Divider variant="middle" style={{margin:"0 50px"}}/> */}

//           <div className={classes.toolbar}>
//             <div style={{ minWidth: props.open && "45px" }}></div>
//             {props.open && <StaticVersionDisplay version={Version} />}
//             <IconButton
//               onClick={open ? handleDrawerClose : handleDrawerOpen}
//               style={{ padding: "10px 12px" }}
//             >
//               <Tooltip
//                 title={!props.open ? "Click to Expand" : "Click to Collapse"}
//                 placement="right-start"
//               >
//                 {!props.open ? (
//                   <ChevronRightIcon
//                     style={{ color: "white", marginTop: "7px" }}
//                   />
//                 ) : (
//                   <ChevronLeftIcon style={{ color: "white" }} />
//                 )}
//               </Tooltip>
//             </IconButton>
//           </div>

//           <div style={{ margin: "0 50px 10px" }} />
//         </List>
//       }
//     </div>
//   );
// }

// const mapStateToProps = (state) => ({
//   user: state.authentication.user,
// });
// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(DrawerContent);
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ReactDOM from "react-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import { primary } from "../styles/colors";
import { connect } from "react-redux";
import StaticVersionDisplay from "./StaticVersionDisplay";
import { Divider } from "@material-ui/core";
import ProjectName from "./ProjectName";
import RightIcon from "./RightIcon";
import routes from "../routes/sidebar";
import { Version } from "../Defaults";
import { withStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const CssListItem = withStyles({
  root: {
    "& label.Mui-focused": {
      border: "none",
    },
    "& .MuiTypography-body1": {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5",
    },
    "& .MuiListItemIcon-root": {
      // color: rgba(0, 0, 0, 0.54);
      // display: inline-flex;
      minWidth: "40px",
      // flex-shrink: 0;
    },

    overflowX: "hidden",
  },
})(ListItem);
const CssDivider = withStyles({
  root: {
    "&.MuiDivider-middle": {
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
})(Divider);

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedClose: {
    paddingLeft: theme.spacing(3),
  },
  // position: 'absolute',
  // bottom: 0,
  // right: 5,
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor:
      "transparent linear- gradient(1deg, #3B69B0 0 %, #1E3558 100 %) 0% 0 % no - repeat padding- box",
    height: "100%",
  },
  divBigAvatar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // flexDirection: "column",
    padding: 10,
    background: "white",
    borderRadius: "0 0 6px 6px",
  },
}));

const keyMap = {
  DASHBOARD: { name: "Expand square area", sequence: "1" },
  SELLORDERS: { name: "Expand square area", sequence: "2" },
  DISPATCHES: { name: "Expand square area", sequence: "3" },
  MODULES: { name: "Expand square area", sequence: "4" },
  USERANDPERMISSION: { name: "Expand square area", sequence: "5" },
};

function DrawerContent(props) {
  const classes = useStyles();

  // const masters = [
  //   { title: "Clients", icon: "", path: "/masters/clients" },
  //   { title: "Survey Companies", icon: "", path: "/masters/survey-companies" },
  //   { title: "Payment Terms", icon: "", path: "/masters/payment-terms" },
  //   { title: "Contract types", icon: "", path: "/masters/contract-types" },
  //   { title: "Item Category", icon: "", path: "/masters/item-category" },
  //   { title: "Item Types", icon: "", path: "/masters/item-types" },
  //   { title: "Packaging", icon: "", path: "/masters/packaging" },
  // ];
  const tofilterWIth =
    props.pathname.indexOf("/", props.pathname.indexOf("/") + 1) === -1
      ? props.pathname
      : props.pathname
          .toString()
          .slice(
            0,
            props.pathname.indexOf("/", props.pathname.indexOf("/") + 1)
          );
  const childRoutArr = [
    "/Policys",
    "/Incoterms",
    "/SOpaymentTerms",
    "/ShippingTerms",
    "/Currencys",
    "/Notifys",
    "/RiceBagSellers",
    "/Beneficiarys",
    "/Shippers",
    "/Clients",
    "/SurveyCompanys",
    "/ItemCategories",
    "/ItemTypes",
    "/PaymentTerms",
    "/ContractTypes",
    "/Packagings",
    "/HsNs",
  ];
  const childRoutArrPO = ["/HsNs", "/PaymentTerms", "/Rates,/SalesIntents,/CashDiscount"];
  const childRoutArrVendors = ["/Transporter", "/Brokers", "/Millers"];
  const childRoutArrSalesOrder = [
    "/SalesOrders",
    "/Clients",
    "/Policys",
    "/SOpaymentTerms",
  ];
  const childRoutArrPort = ["/Ports", "/Shippers"];
  const childRoutArrRiceBags = [
    "/BrandsManagements",
    "/Packagings",
    "/RiceBagSellers",
  ];
  const childRoutArrSettings = [
    "/UsersAndPermissions",
    "/SurveyCompanys",
    "/ItemCategories",
    "/ItemTypes",
    "/Currencys",
  ];
  const generateSubMenuOpenObject = () => {
    const subMenuOpen = {};

    routes.forEach((route) => {
      if (route.childRoutes && route.childRoutes.length > 0) {
        const includesFilter = route.childRoutes.some(
          (childRoute) => childRoute.path === tofilterWIth
        );
        subMenuOpen[route.title] = includesFilter;
      }
    });

    return subMenuOpen;
  };
  const [subMenuOpen, setSubMenuOpen] = useState(generateSubMenuOpenObject());
  const [selectedMastersIndex, setSelectedMastersIndex] = useState(0);

  const [open, setOpen] = React.useState(props.open);

  // const [scrollTo, setscrollTo] = React.useState("");

  const user = localStorage.getItem("briuser")
    ? JSON.parse(localStorage.getItem("briuser"))
    : "";
  //   useEffect(() => {
  // }, [props.user])
console.log(user,"usersuser")
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  //const [innerMenu, setInnerMenu] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const handlePageTitle = (title, index) => setSelectedIndex(index);

  const myRef = useRef(null);
  const checkIfChildRouteIncludesValue = () => {
    for (const route of routes) {
      if (route.childRoutes) {
        const found = route.childRoutes.some(
          (childRoute) => childRoute.path === tofilterWIth
        );
        if (found) {
          return true;
        }
      }
    }
    return false;
  };

  const handleScrollToElement = (path) => {
    if (checkIfChildRouteIncludesValue()) {
      // const chat = document.getElementById(path);
      // chat.scrollIntoView();
    }
  };
  useEffect(() => {
    handleScrollToElement(tofilterWIth);
  }, []);
  const handleDrawerOpen = () => {
    setOpen(true);
    if (props.setOpen) props.setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    if (props.setOpen) props.setOpen(false);
  };
  // const handleShortcut = {
  //   DASHBOARD: (e) => props.navigate("/dashboard"),
  //   SELLORDERS: (e) => props.navigate("/selloders"),
  //   DISPATCHES: (e) => props.navigate("/dispatches"),
  //   MODULES: (e) => props.navigate("/masters"),
  //   USERANDPERMISSION: (e) => props.navigate("/userandpermmision"),
  // };

  const chectSelected = (path, title) => {
    const tofilterWIth =
      props.pathname.indexOf("/", props.pathname.indexOf("/") + 1) === -1
        ? props.pathname
        : props.pathname
            .toString()
            .slice(
              0,
              props.pathname.indexOf("/", props.pathname.indexOf("/") + 1)
            );
    const shouldSubMenuBeOpen = () => {
      const route = routes.find((route) => route.title === title);

      if (route && route.childRoutes && route.childRoutes.length > 0) {
        if (
          route.childRoutes.some(
            (childRoute) => childRoute.path === tofilterWIth
          )
        ) {
          return subMenuOpen[title] === false;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    if (title && path === null && shouldSubMenuBeOpen()) {
      //&& !innerMenu && childRoutesARR.includes(tofilterWIth))
      return true;
    } else {
      if (tofilterWIth === path) {
        return true;
      } else {
        return false;
      }
    }
  };
  const selectedStyle = (path, noIcon) => ({
    color: chectSelected(path) ? primary : "white",
    background: chectSelected(path) ? "white" : "",
    overflow: "hidden",
    borderRadius: chectSelected(path) ? "6px" : "0px 7px 7px 0",
    // margin: chectSelected(path) ? "5px 0 5px 0" : open ? "5px" : "5px 0",
    width: chectSelected(path) && !open ? "80%" : open ? "" : "109%",
    // padding: open ? "" : noIcon ? "8px 0" : "0",
    minWidth: !open ? "0" : "40px",
    justifyContent: !open ? "center" : "",
    display: !open ? "flex" : "",
    padding: "5px 18.5px",
    height: open ? "" : "42px",
  });

  return (
    <>
      <div
        style={{ height: "100%", width: "100%", borderRadius: "20px", }}
        className="d-flex d-flex-column">
        {/* <GlobalHotKeys keyMap={keyMap} handlers={handleShortcut} />{' '} */}
        {
          <List disablePadding className={classes.root}>
            {open && (
              <div className={classes.divBigAvatar}>
                <img src="/BriIcon&Name.jpg" alt="loading..." />
                {/* <ProjectName {...props} /> */}
              </div>
            )}
            {open ? (
              <Divider variant="middle" />
            ) : (
              <div
                style={{
                  height: "68px",
                  background: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0 0 6px 6px",
                }}>
                <img src="/BriIcon.jpg" alt="loading..." />
              </div>
            )}
            <div
              style={{
                height: open
                  ? "calc(100% - 106.35px)"
                  : "calc(100% - 148.35px)",
                overflowY: "auto",
                overflowX: "hidden",
                width: "100%",
              }}>


{/* {routes
                .map((list, index) => {

                  if (
                    user &&
                    user.accessibleModules &&
                    user.accessibleModules.includes(list.access)
                  ) { */}
              {routes
                .map((list, index) => {
                   const modulePermission =
                    user && user.user_accesses&&user.user_accesses.length > 0
                      ? user.user_accesses[0].modules.filter((m)=>{
                        const user_widgets_list = m.widgets.filter(
                          (widget) =>
                            widget.widget.has_create_permission ===
                              true ||
                            widget.widget.has_update_permission ===
                              true ||
                            widget.widget.has_read_permission === true
                        );
                   
                        return user_widgets_list.length>0?true:false
                      }).find(
                          (module) => module.module.module_name === list.access
                        )??null
                      : null;

                  if (modulePermission !== null) {
                 
                    return (
                      <div key={`ListItemContainer-${index}`}>
                        <CssListItem
                          button
                          key={`ListItem-${index}`}
                          open={open}
                          onClick={(e) => {
                            e.preventDefault();
                            if (list.childRoutes?.length > 0) {
                              setSubMenuOpen({
                                ...subMenuOpen,
                                [list.title]: subMenuOpen[list.title]
                                  ? !subMenuOpen[list.title]
                                  : true,
                              });
                            } else {
                              handlePageTitle(list.title, index);
                              props.navigate(list.path);
                            }
                          }}
                          // style={selectedStyle(list.path, "noIcon")}
                          style={selectedStyle(
                            list.path,
                            "noIcon",
                            list.childRoutes?.length > 0
                              ? list.title
                              : undefined
                          )}>
                          <ListItemIcon
                            style={{
                              padding: open ? "" : "0",
                              minWidth: !open ? "0" : "",
                              color:"white",
                              margin:
                              open? (
                              (list.access === "Sales Order" )
                                ? "0 7px 0 -7px "
                                : ""):""
                            }}>
                            <Tooltip
                              style={{ marginRight: "20px" }}
                              title={list.title}
                              placement="right-end">
                              {/* {list.icon(list.path, chectSelected)} */}
                              {list.icon(
                                list.path,
                                chectSelected,
                                list.childRoutes?.length > 0
                                  ? list.title
                                  : undefined
                              )}
                            </Tooltip>
                          </ListItemIcon>
                          {props.open ? (
                            !list.childRoutes ? (
                              <ListItemText primary={list.title} />
                            ) : (
                              <>
                                <ListItemText primary={list.title} />
                                {subMenuOpen[list.title] ?? false ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </>
                            )
                          ) : null}
                        </CssListItem>

                        {list.childRoutes?.length > 0 && (
                          <Collapse
                            in={subMenuOpen[list.title] ?? false}
                            unmountOnExit>
                            <List className={classes.root}>
                              {list.childRoutes.map((item, index) => 
                                {
                                const childAccess =modulePermission?
                                  modulePermission.widgets.filter(
                                    (widget) =>
                                      widget.widget.has_create_permission ===
                                        true ||
                                      widget.widget.has_update_permission ===
                                        true ||
                                      widget.widget.has_read_permission === true
                                  ).find(
                                    (it) =>
                                      it.widget.widget_name === item.access
                                  ):null;
                                  console.log(item.access,childAccess, modulePermission.widgets.filter(
                                    (widget) =>
                                      widget.widget.has_create_permission ===
                                        true ||
                                      widget.widget.has_update_permission ===
                                        true ||
                                      widget.widget.has_read_permission === true
                                  ),"rolist.childRoutesutes")
                
                                return (
                                  childAccess && (
                                    <div
                                      id={item.path}
                                      key={`NestedListCont-${index}`}
                                      style={{
                                        overflowX: "hidden",
                                        display: open ? "" : "flex",
                                        justifyContent: open ? "" : "center",
                                      }}>
                                      <CssListItem
                                        button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSelectedMastersIndex(index);
                                          props.navigate(item.path);
                                        }}
                                        style={selectedStyle(
                                          item.path,
                                          "noIcon"
                                        )}>
                                        <ListItemIcon
                                          style={{
                                            paddingLeft: props.open
                                              ? "15px"
                                              : "14px",
                                            minWidth: "51px",
                                          }}>
                                          <Tooltip
                                            title={item.title}
                                            placement="right-start">
                                            {item.icon(
                                              item.path,
                                              chectSelected
                                            )}
                                          </Tooltip>
                                        </ListItemIcon>
                                        {props.open ? (
                                          <ListItemText
                                            style={{ marginLeft: "25px" }}
                                            primary={item.title}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </CssListItem>
                                    </div>
                                  )
                                );
                              }
                              
                               )}
                            </List>
                          </Collapse>
                        )}
                      </div>
                    );
                  } else return null;
                })
                .reverse()}
            </div>

            {/* <CssDivider
              variant="middle"
              style={{ background: "#ffffff61", marginBottom: "10px" }}
            /> */}
            {/* 
            <div className="d-flex width-100">
              <RightIcon {...props} />
            </div> */}
            {/* <Divider variant="middle" style={{margin:"0 50px"}}/> */}

            <div className={classes.toolbar}>
              <div style={{ minWidth: props.open && "45px" }}></div>
              {props.open && <StaticVersionDisplay version={Version} />}
              {/* <IconButton
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                style={{ padding: "10px 12px" }}>
                <Tooltip
                  title={!props.open ? "Click to Expand" : "Click to Collapse"}
                  placement="right-start">
                  {!props.open ? (
                    <ChevronRightIcon
                      style={{ color: "white", marginTop: "7px" }}
                    />
                  ) : (
                    <ChevronLeftIcon style={{ color: "white" }} />
                  )}
                </Tooltip>
              </IconButton> */}
            </div>

            <div style={{ margin: "0 50px 10px" }} />
          </List>
        }
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContent);
