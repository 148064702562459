import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@material-ui/core";

const Popup = ({ children, openPopup, setOpenPopup, isNew }) => {
  return (
    <Dialog
      open={openPopup!==false}
      keepMounted
      onClose={() => setOpenPopup(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        style={{
          fontSize: "20.8px",
          fontWeight: "400",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgb(241 154 48)",
          color: "#fff",
          padding: "10px 24px",
        }}
      >
        {isNew ? "Add New Contract Type" : "Edit Contract Details"}
        <Tooltip title="Close">
          <CloseIcon
            style={{ cursor: "pointer" }}
            color="#fff"
            onClick={() => setOpenPopup(false)}
          />
        </Tooltip>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Popup;
