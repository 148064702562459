import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import NoData from "../../styles/Illustrations/NoData";
import { StyledCircularProgress } from "../../styles/StyledComponents";
import { Paper, Tooltip } from "@material-ui/core";
import { EndPoints } from "../../utils/EndPoints";
import CreateDialog from "../../layoutComponents/CreateDialog";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { filterData, getSingleModule_widget } from "../../Regex";
import { accessText } from "../../routes/sidebar";
import MouseOverPopover from "../Rates/MouseOverPopover";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TableComponent from "../../layoutComponents/TableComponent";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
export class SalesIntentsComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: "SalesIntents",
        tooltip: "Click to approve pending SalesIntents",
        data: [],
        route: "/SalesIntents/SalesIntents",
        goBackRoute: "/SalesIntents",
        //widget: null,
        primaryField: "category_Weight",
        primarySecondValue: "amount",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "amount_unit",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        // avatarField: "src",
        salesList: true,
        onlySecondary: true,
        dispatchList: true,

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "green", "red"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      SalesIntents: [],
      salesIntentList: [],
      loader: false,
      addDailog: false,
      offersNav: false,
      MillerList: [],
      resultsnew: [],
      userRole:
        this.props.user && this.props.user.roles
          ? this.props.user.roles.toString().toLowerCase()
          : "",
      singlelistView: null,
      loading: false,
      openDialog: false,
      editList: false,
      benificiaryList: [],
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    // this.fetchPurchaseRate();
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["SalesIntents"].split("/")[0] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {}
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }
  fetchSalesIntent = (id) => {
    this.props
      .SalesIntentsFunction(
        "get",
        "dispatch/sales-intent/list",
        null,
        "salesIntentList",
        null,
        {
          purchase_rate: id,
        }
      )
      .then((salesIntentList) => {
        if (salesIntentList && salesIntentList.results&&salesIntentList.results.length > 0) {
          this.setState({ loadingDispatch: false, salesIntentList });
        } else {
          this.setState({
            loadingDispatch: false,
            salesIntentList: { results: [] },
          });
        }
      });
  };
  listClickHandler = (value) => {
    const { masters } = this.props;
    const { salesIntentList, singlelistView } = this.state;

    this.fetchPurchaseRate();
    this.fetchSalesIntent(value.id);
    this.setState({
      singlelistView: value,
    });

    this.props
      .SalesIntentsFunction(
        "get",
        "masters/miller/list",
        null,
        "MillerList",
        null,
        {
          is_active: "True",
          profile_used: "True",
        }
      )
      .then((MillerList) => {
        this.setState({ MillerList, loading: false });
      });
  };
  fetchPurchaseRate = async (category) => {
    this.setState({ loadingPurchaseItent: true });
    // const {masters}=this.props;
    // const {salesIntentList,singlelistView}=this.state;
    // this.props
    // .SalesIntentsFunction(
    //   "get",
    //   "dispatch/sales-intent/list",
    //   null,
    //   "salesIntentList",
    //   null,
    //   {
    //     purchase_rate: this.state.singlelistView.id,
    //   }
    // )
    // .then((salesIntentList) => {

    //   if (salesIntentList && salesIntentList.length > 0) {

    //     this.setState({ loadingDispatch: false, salesIntentList });

    //   }

    // });
    // const resultsnew =
    // salesIntentList &&
    // salesIntentList.results &&
    // salesIntentList.results.length > 0
    //   ? salesIntentList.results
    //   : [];
    //  console.log(salesIntentList,"sssssssssssssssss")
    // const dataArr =
    // salesIntentList &&
    // salesIntentList.results &&
    // salesIntentList.results.length > 0
    //   ? salesIntentList.results.map((s) => {
    //       return {
    //         ...s,
    //         category_Weight: `₹${s.amount}/ ${s.unit}`,

    //         amount_unit: `${
    //           filterData(
    //             masters.ItemCateoryList,
    //             "id",
    //             Number(s.item_category),
    //             "onlyone"
    //           )
    //             ? filterData(
    //                 masters.ItemCateoryList,
    //                 "id",
    //                 Number(s.item_category),
    //                 "onlyone"
    //               ).name
    //             : ""
    //         } - W ${s.required_qty} `,    };
    //       })
    //     : [];
    this.props
      .SalesIntentsFunction(
        "get",
        "dispatch/purchase-rate/list",
        null,
        //state.singlelistView.id,
        "PurchaseRate",
        null,
        category
          ? { is_active: "True", item_category: category.id }
          : { is_active: "True" }
        // purchase_rate: this.state.singlelistView.id
      )
      .then((res) => {
        this.setState({
          loadingPurchaseItent: false,
          listViewArray: res,
          fetchFirst: false,
        });
      });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  setInitialValues(props) {
    const { masters, user } = props;
    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.purchase_order,
      accessText.sales_intent
    );
    const sales_intent_data = props[EndPoints["SalesIntents"]];
    const results =
      sales_intent_data &&
      sales_intent_data.results &&
      sales_intent_data.results.length > 0
        ? sales_intent_data.results
        : [];
    let uniqueIds = new Set();

    // Filter the array to keep only unique IDs
    let uniqueArray = results.filter((item) => {
      // Check if the ID is already in the set
      // If not, add it to the set and return true to keep the item
      // If yes, return false to remove the duplicate item
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id);
        return true;
      }
      return false;
    });

    const dataArr =
      uniqueArray && uniqueArray.length > 0
        ? uniqueArray.map((s) => {
            return {
              ...s,
              category_Weight: `₹${s.amount}/ ${s.unit}`,

              amount_unit: `${
                filterData(
                  masters.ItemCateoryList,
                  "id",
                  Number(s.item_category),
                  "onlyone"
                )
                  ? filterData(
                      masters.ItemCateoryList,
                      "id",
                      Number(s.item_category),
                      "onlyone"
                    ).name
                  : ""
              } - W ${s.required_qty} `,
              // ${moment(
              //   s.created_at
              // ).format("YY-MM-DD")}`,
              // ${
              //   filterData(
              //     masters.ItemList,
              //     "id",
              //     Number(s.item_type),
              //     "onlyone"
              //   )
              //     ? filterData(
              //         masters.ItemList,
              //         "id",
              //         Number(s.item_type),
              //         "onlyone"
              //       ).broken_percentage
              //     : ""
              // }
            };
          })
        : [];
    console.log(sales_intent_data, dataArr, "dataArr");
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      const id = [];
      tab.data = dataArr.sort((a, b) => {
        var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
        var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
        return new Date(dateA) - new Date(dateB);
      });
      return tab;
    });
    this.setState({
      listViewArray:
        props[EndPoints["SalesIntents"]] &&
        props[EndPoints["SalesIntents"]].length > 0
          ? props[EndPoints["SalesIntents"]]
          : [],
      tabList: masters.ItemCateoryList,
      tabListItemType: masters.ItemList,
      tabListContractType:masters.ContractTypeList,
      tabs,
      pagination:
        tabs[0].data.length > 0
          ? {
              count: sales_intent_data.count,
              loadMore: () => {
                this.props.SalesIntentsFunction(
                  "get",
                  EndPoints["SalesIntents"],
                  null,
                  null,
                  null,
                  null,
                  null,
                  true,
                  tabs[0].data,
                  sales_intent_data.next
                );
              },
              next: sales_intent_data.next,
              currentLength: tabs[0].data.length,
            }
          : undefined,
      loading: props.loading,
    });
    if (
      masters.ItemCateoryList &&
      masters.ItemCateoryList.length > 1 &&
      this.state.fetchFirst === true
    ) {
      this.fetchPurchaseRate(masters.ItemCateoryList[0]);
    }
  }

  render() {
    const {
      tabs,
      SalesIntents,
      singlelistView,
      loading,
      openDialog,
      editList,
      benificiaryList,
      userRole,
      MillerList,
      listViewArray,
      salesIntentList,ContractTypeList,
      dataArr,
    } = this.state;
    // const { isMobile ,user} = this.props;
console.log(ContractTypeList,"cccccccccccc")
    const { masters, ItemList, user } = this.props;
    var labelField = "title";

    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.purchase_order,
      accessText.sales_intent
    );
    return (
      <Layout
        loading={loading[EndPoints["SalesIntents"]]}
        pagination={this.state.pagination}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Sales Intents`,
          labelField: labelField,
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        selectHandler={this.selectHandler}
        fabClickHandler={() => {
          this.setState({ openDialog: true, editList: false });
        }}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px">
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.SalesIntents === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  margin: "0 15px",
                  background: "#80808012",
                  padding: "0 10px",
                  borderRadius: "5px",
                }}>
                <div style={{ fontSize: "1rem" }}>
                  Rate : ₹ {singlelistView.amount}/{singlelistView.unit}
                </div>
                <div style={{ margin: "5px 0 0 10px" }}>
                  <MouseOverPopover
                    msg={
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ color: "rgb(241, 154, 48)" }}
                      />
                    }
                    details={
                      <div className="column-content">
                        <Typography variant="subtitle1">
                          Item Category :{" "}
                          {singlelistView &&
                          filterData(
                            masters.ItemCateoryList,
                            "id",
                            Number(singlelistView.item_category),
                            "onlyone"
                          )
                            ? filterData(
                                masters.ItemCateoryList,
                                "id",
                                Number(singlelistView.item_category),
                                "onlyone"
                              ).name
                            : ""}
                        </Typography>
                        <Typography variant="subtitle1">
                          {" "}
                          Item Type :
                          {singlelistView &&
                          filterData(
                            masters.ItemList,
                            "id",
                            Number(singlelistView.item_type),
                            "onlyone"
                          )
                            ? filterData(
                                masters.ItemList,
                                "id",
                                Number(singlelistView.item_type),
                                "onlyone"
                              ).broken_percentage
                            : ""}
                        </Typography>
                        <Typography variant="subtitle1">
                          {" "}
                          Required qty :{singlelistView.required_qty}{" "}
                        </Typography>
                        <Typography variant="subtitle1">
                          {" "}
                          Status :{singlelistView.status}{" "}
                        </Typography>
                      </div>
                    }
                  />
                </div>
              </div>

              <TableComponent
                // textFieldWidth="calc(33% - 20px)"
                // heightNew="500px"
                // maxHeight="calc(100vh - 40px)"
                // widthNew="900px"
                textFieldStyle={{ width: "calc(100% - 20px)", margin: "10px" }}
heightNew="590px"
maxHeight="calc(100vh - 140px)"
widthNew="400px"
borderRadius="20px"
                noAddButton={userRole && userRole !== "miller" ? false : true}
                module={"Sales Intent"}
                {...this.state}
                actions={[]}
                header={[
                  "Sr.No.",
                  "Date",
                  "Item Category",
                  "Item Type",
                  "Quantity",
                  "Amount",
                  "Created By",
                  "User Name",
                  "Broker Detail",
                  "Miller Detail",
                  "Status",
                  //  "Action",
                ]}
                formatData={(Sub, edit) => {
                  console.log(
                    Sub,
                    this.props.user.credential_id,
                    "wedwedwedwe",
                    this.props.user.roles === "Broker"
                  );
                  const newObj = {
                    sales_intent:
                      this.props.user.roles === "Broker"
                        ? {
                            purchase_rate: singlelistView.id,
                            amount: Sub.Amount,
                            quantity: Sub.Quantity,
                            broker: this.props.user.credential_id,
                            status: "Pending",
                            miller: Sub["MillerId"],
                            // "miller_details": {
                            //   "id": Sub["MillerId"],
                            //   "name": Sub["Miller"],
                            //   "contact_no": ""
                            //   },
                          }
                        : {
                            purchase_rate: singlelistView.id,
                            amount: Sub.Amount,
                            quantity: Sub.Quantity,

                            miller: Sub["MillerId"],
                            // "broker": 2,
                            status: "Pending",
                          },
                  };
                  switch (userRole) {
                    case "miller":
                      newObj.sales_intent.miller =
                        this.props.user.credential_id;
                      break;
                    case "broker":
                      newObj.sales_intent.broker =
                        this.props.user.broker_details.id;
                      break;
                  }
                  return newObj;
                }}
                {...this.props}
                // addFieldsToAdd={[{name:"id",value:singlelistView?.id??""}]}
                tableList={
                  salesIntentList &&
                  salesIntentList.results &&
                  salesIntentList.results.length > 0
                    ? salesIntentList.results.map((singleDispatch) => {
                        singleDispatch["Date"] = moment(
                          singleDispatch.date
                        ).format("ddd, MMM Do YYYY");

                        singleDispatch["Item Category"] = filterData(
                          masters.ItemCateoryList,
                          "id",
                          singleDispatch.item_category,
                          "onlyOne"
                        )
                          ? filterData(
                              masters.ItemCateoryList,
                              "id",
                              singleDispatch.item_category,
                              "onlyOne"
                            ).name
                          : "";
                        singleDispatch["Item CategoryId"] =
                          singleDispatch.item_category;

                        singleDispatch["Item Type"] = filterData(
                          masters.ItemList,
                          "id",
                          singleDispatch.item_type,
                          "onlyOne"
                        )
                          ? filterData(
                              masters.ItemList,
                              "id",
                              singleDispatch.item_type,
                              "onlyOne"
                            ).broken_percentage
                          : "";
                        singleDispatch["Item TypeId"] =
                          singleDispatch.item_type;
                        singleDispatch["Quantity"] =
                          singleDispatch.quantity + " " + singleDispatch.unit;

                        singleDispatch["Amount"] =
                          singleDispatch.amount + " / " + singleDispatch.unit;

                        singleDispatch["Created By"] = singleDispatch
                          .created_by_profile.role
                          ? singleDispatch.created_by_profile.role
                          : "";

                        singleDispatch["User Name"] = singleDispatch
                          .created_by_profile.first_name
                          ? singleDispatch.created_by_profile.first_name
                          : "" + singleDispatch.created_by_profile.last_name
                          ? singleDispatch.created_by_profile.last_name
                          : "";
                        singleDispatch["Broker Detail"] = () => {
                          return (
                            <>
                              {singleDispatch &&
                              singleDispatch.broker_profile &&
                              singleDispatch.broker_profile.name ? (
                                <>
                                  <div onClick={() => {}}>
                                    {singleDispatch &&
                                    singleDispatch.broker_profile &&
                                    singleDispatch.broker_profile
                                      ? singleDispatch.broker_profile.name
                                      : ""}
                                  </div>
                                  <div style={{ margin: "5px 0 0 5px" }}>
                                    <MouseOverPopover
                                      msg={
                                        <InfoOutlinedIcon
                                          fontSize="small"
                                          style={{ color: "rgb(241, 154, 48)" }}
                                        />
                                      }
                                      details={
                                        <div className="column-content">
                                          {/* <Typography variant="subtitle1">
                                     Name :  { singleDispatch && singleDispatch.broker_profile && singleDispatch.broker_profile
                              ? singleDispatch.broker_profile.name
                              : ""}
                                        </Typography> */}
                                          <Typography variant="subtitle1">
                                            {" "}
                                            Phone NO. :{" "}
                                            {singleDispatch &&
                                            singleDispatch.broker_profile &&
                                            singleDispatch.broker_profile
                                              ? singleDispatch.broker_profile
                                                  .phone
                                              : ""}
                                          </Typography>
                                          <Typography variant="subtitle1">
                                            {" "}
                                            Address :{" "}
                                            {singleDispatch &&
                                            singleDispatch.broker_profile &&
                                            singleDispatch.broker_profile
                                              ? singleDispatch.broker_profile
                                                  .address
                                              : ""}
                                          </Typography>
                                        </div>
                                      }
                                    />
                                  </div>
                                </>
                              ) : (
                                "-"
                              )}
                            </>
                          );
                        };
                        // singleDispatch["Broker Name"] =
                        //   singleDispatch && singleDispatch.broker_profile && singleDispatch.broker_profile
                        //     ? singleDispatch.broker_profile.name
                        //     : "";
                        // singleDispatch["Broker Phone No"] =
                        //   singleDispatch && singleDispatch.broker_profile && singleDispatch.broker_profile
                        //     ? singleDispatch.broker_profile.phone
                        //     : "";
                        // singleDispatch["Broker Address"] =
                        //   singleDispatch && singleDispatch.broker_profile && singleDispatch.broker_profile
                        //     ? singleDispatch.broker_profile.address
                        //     : "";

                        singleDispatch["Miller Detail"] = () => {
                          return (
                            <>
                              {singleDispatch &&
                              singleDispatch.broker_profile &&
                              singleDispatch.broker_profile.name ? (
                                <>
                                  <div onClick={() => {}}>
                                    {singleDispatch &&
                                    singleDispatch.miller_profile &&
                                    singleDispatch.miller_profile
                                      ? singleDispatch.miller_profile.name
                                      : ""}
                                  </div>
                                  <div style={{ margin: "5px 0 0 5px" }}>
                          
                                    <MouseOverPopover
                                      msg={
                                        singleDispatch &&
                                        singleDispatch.miller_profile &&
                                        singleDispatch.miller_profile
                                          &&singleDispatch.miller_profile.name ?(<>
                                        <InfoOutlinedIcon
                                          fontSize="small"
                                          style={{ color: "rgb(241, 154, 48)" }}
                                        /></>):"-"
                                      }
                                      details={
                                        <div className="column-content">
                                          {/* <Typography variant="subtitle1">
                                     Name :  { singleDispatch && singleDispatch.miller_profile && singleDispatch.miller_profile
                              ? singleDispatch.miller_profile.name
                              : ""}
                                        </Typography> */}
                                          <Typography variant="subtitle1">
                                            {" "}
                                            Phone NO. :{" "}
                                            {singleDispatch &&
                                            singleDispatch.miller_profile &&
                                            singleDispatch.miller_profile
                                              ? singleDispatch.miller_profile
                                                  .phone
                                              : ""}
                                          </Typography>
                                          <Typography variant="subtitle1">
                                            {" "}
                                            Address :{" "}
                                            {singleDispatch &&
                                            singleDispatch.miller_profile &&
                                            singleDispatch.miller_profile
                                              ? singleDispatch.miller_profile
                                                  .address
                                              : ""}
                                          </Typography>
                                        </div>
                                      }
                                    />
                                  </div>
                                </>
                              ) : (
                                "-"
                              )}
                            </>
                          );
                        };

                        //   singleDispatch["Broker Name"] = singleDispatch &&
                        //   singleDispatch.broker_profile &&
                        //   singleDispatch.broker_profile
                        //     ? singleDispatch.broker_profile.name
                        //     : "";
                        //     singleDispatch["Broker Phone No"] = singleDispatch &&
                        // singleDispatch.broker_profile &&
                        // singleDispatch.broker_profile
                        //   ? singleDispatch.broker_profile.phone
                        //   : "";
                        //   singleDispatch["Broker Address"] = singleDispatch &&
                        // singleDispatch.broker_profile &&
                        // singleDispatch.broker_profile
                        //   ? singleDispatch.broker_profile.address
                        //   : "";
                        // singleDispatch["Miller Name"] = singleDispatch &&
                        // singleDispatch.miller_profile &&
                        // singleDispatch.miller_profile
                        //   ? singleDispatch.miller_profile.name
                        //   : "";

                        // singleDispatch["Miller Phone No"] =
                        // singleDispatch &&
                        // singleDispatch.miller_details &&
                        // singleDispatch.miller_details
                        //   ? singleDispatch.miller_details.phone
                        //   : "";
                        //   singleDispatch["Miller Address"] =
                        //   singleDispatch &&
                        //   singleDispatch.miller_details &&
                        //   singleDispatch.miller_details
                        //     ? singleDispatch.miller_details.address
                        //     : "";

                        singleDispatch["Status"] = singleDispatch.status;
                        // singleDispatch["Action"] = () => {
                        //   return (
                        //     <div
                        //       onClick={() => {
                        //         this.props
                        //           .SalesIntentsFunction(
                        //             "delete",
                        //             "dispatch/sales-intent",
                        //             singleDispatch && singleDispatch.id,
                        //             "postdata",
                        //             null
                        //           )
                        //           .then((res3) => {
                        //             if (res3.error) {
                        //               this.setState({
                        //                 error: res3.singleDispatch,
                        //                 loading: false,
                        //               });
                        //             } else {
                        //               this.setState(
                        //                 {
                        //                   success: "Rate has been deleted",
                        //                   // success: editList
                        //                   //   ? `${module} Edited`
                        //                   //   : "",
                        //                   // deleteDialog: false,
                        //                 },
                        //                 () => {
                        //                   this.props
                        //                     .SalesIntentsFunction(
                        //                       "get",
                        //                       salesIntentList
                        //                     )
                        //                     .then((res) => {
                        //                       if (
                        //                         res &&
                        //                         res.length > 0 &&
                        //                         listViewArray &&
                        //                         listViewArray.id
                        //                       ) {
                        //                         res.map((s) => {
                        //                           if (s.id === listViewArray.id) {
                        //                             this.setState({
                        //                               listViewArray: s,
                        //                               // New:
                        //                               //   s.godown_details &&
                        //                               //   s.godown_details
                        //                               //     .length > 0
                        //                               //     ? s.godown_details
                        //                               //     : [""],
                        //                             });
                        //                           }
                        //                           return s;
                        //                         });
                        //                       }
                        //                     });
                        //                   // if (editList) {
                        //                   //   this.props.fetchAgain();
                        //                   // }
                        //                 }
                        //               );
                        //             }
                        //           });
                        //       }}>

                        //         <DeleteOutlineOutlinedIcon
                        //           fontSize="small"
                        //           style={{ color: "rgba(234, 84, 85, 1)" }}
                        //   />
                        //     </div>
                        //   );
                        // };
                        return singleDispatch;
                      })
                    : []
                }
                postData={this.props.SalesIntentsFunction}
                postApi="dispatch/sales-intent/create"
                editApi="dispatch/sales-intent/list"
                // method="patch"
                inputArray={[
                  // {
                  //   name: "Miller Name",
                  //   type: "select",
                  //   dataType: "text",
                  //   // disabled: true,
                  //   array: masters.MillerList ?? [],
                  //   value: "name",
                  //   defaultValue: {
                  //     value:
                  //       singlelistView &&
                  //       filterData(
                  //         masters.MillerList,
                  //         "id",
                  //         Number(singlelistView.name),
                  //         "onlyone"
                  //       )
                  //         ? filterData(
                  //             masters.MillerList,
                  //             "id",
                  //             Number(singlelistView.name),
                  //             "onlyone"
                  //           ).name
                  //         : "",
                  //     id:
                  //       singlelistView && singlelistView.id
                  //         ? singlelistView.id
                  //         : "",
                  //   },
                  //   api: "miller",
                  // },

                  userRole && userRole === "broker"
                    ? {
                        name: "Miller",
                        type: "select",
                        dataType: "text",
                        not_required: true,
                        array:
                          MillerList && MillerList.length > 0 ? MillerList : [],
                        value: "name",
                        api: "miller",

                        // defaultValue: {
                        //   value:
                        //     singlelistView && singlelistView.MillerList
                        //       ? singlelistView.MillerList.name //default_brokerage
                        //       : "",
                        //   id:
                        //     singlelistView && singlelistView.MillerList
                        //       ? singlelistView.MillerList.id
                        //       : "",
                        // },
                        // onChange: async (submittedData) => {
                        //   if (submittedData["Broker"]) {
                        //     const selected = filterData(
                        //       MillerList && MillerList.length > 0
                        //         ? MillerList
                        //         : [],
                        //       "id",
                        //       Number(submittedData["MillerId"]),
                        //       "onlyOne"
                        //     )
                        //       ? filterData(
                        //           MillerList && MillerList.length > 0
                        //             ? MillerList
                        //             : [],
                        //           "id",
                        //           Number(submittedData["MillerId"]),
                        //           "onlyOne"
                        //         )
                        //       : {};
                        //     submittedData["First Name"] = selected
                        //       ? selected.name
                        //       : "";
                        //     submittedData["Phone number"] = selected
                        //       ? selected.phone
                        //       : "";
                        //   }
                        //   this.setState({
                        //     selectedMiller: submittedData["Broker"],
                        //   });

                        //   return submittedData;
                        // },
                      }
                    : false,
                  {
                    name: "Item Category",
                    type: "select",
                    dataType: "text",
                    disabled: true,
                    array: masters.ItemCateoryList ?? [],
                    value: "name",
                    defaultValue: {
                      value:
                        singlelistView &&
                        filterData(
                          masters.ItemCateoryList,
                          "id",
                          Number(singlelistView.item_category),
                          "onlyone"
                        )
                          ? filterData(
                              masters.ItemCateoryList,
                              "id",
                              Number(singlelistView.item_category),
                              "onlyone"
                            ).name
                          : "",
                      id:
                        singlelistView && singlelistView.id
                          ? singlelistView.id
                          : "",
                    },
                    api: "item_category",
                  },
                  {
                    name: "Item Type",
                    type: "select",
                    dataType: "text",
                    disabled: true,

                    array: masters.ItemList ?? [],
                    value: "broken_percentage",
                    defaultValue: {
                      value:
                        singlelistView &&
                        filterData(
                          masters.ItemList,
                          "id",
                          Number(singlelistView.item_type),
                          "onlyone"
                        )
                          ? filterData(
                              masters.ItemList,
                              "id",
                              Number(singlelistView.item_type),
                              "onlyone"
                            ).broken_percentage
                          : "",
                      id:
                        singlelistView && singlelistView.id
                          ? singlelistView.id
                          : "",
                    },
                    api: "item_type",
                  },
                  {
                    name: "Unit",
                    type: "textField",
                    dataType: "text",
                    disabled: true,
                    value: "unit",
                    defaultValue: {
                      value:
                        singlelistView && singlelistView.unit
                          ? singlelistView.unit
                          : "",
                    },
                    api: "unit",
                  },

                  {
                    name: "Quantity",
                    type: "textField",
                    not_required: false,
                    dataType: "number",
                  
                      //max: singlelistView ? Number(singlelistView.required_qty):0,
                
                    endAdornment:
                      singlelistView && singlelistView.unit
                        ? singlelistView.unit
                        : "",

                    // defaultValue: {
                    //   value:
                    //     singlelistView && singlelistView.pending
                    //       ? singlelistView.pending
                    //       : "",
                    // },
                    api: "required_qty",
                  },
                  {
                    name: "Amount",
                    type: "textField",
                    not_required: false,
                    dataType: "number",
                    endAdornment: `/${
                      singlelistView && singlelistView.unit
                        ? singlelistView.unit
                        : ""
                    }`,

                    startAdornment: "₹",
                    defaultValue: {
                      value:
                        singlelistView && singlelistView.amount
                          ? singlelistView.amount
                          : "",
                    },
                    // InputProps={{
                    //   startAdornment: "₹",
                    //   endAdornment: "/" + Unit,
                    // }}
                    api: "amount",
                  },
                  {
                    name: "Contract Type",
                    type: "select",
                    dataType: "text",
                    disabled: true,
                    array: masters.ContractTypeList ?? [],
                    value: "contract_type",
                    defaultValue: {
                      value:
                        singlelistView &&
                        filterData(
                          masters.ContractTypeList,
                          "id",
                          Number(singlelistView.contract_type),
                          "onlyone"
                        )
                          ? filterData(
                              masters.ContractTypeList,
                              "id",
                              Number(singlelistView.contract_type),
                              "onlyone"
                            ).contract_type
                          : "",
                      id:
                        singlelistView && singlelistView.id
                          ? singlelistView.id
                          : "",
                    },
                    api: "contract_type",
                  },
                  // {name:"Status",
                  // api:"status"},
                ]}
                fetchAgain={() => {
                  this.fetchSalesIntent(singlelistView.id);
                  this.props.SalesIntentsFunction(
                    "get",
                    EndPoints["SalesIntents"]
                  );
                }}
              />
            </>
          )}
        </Paper>
      </Layout>
    );
  }
}

export default SalesIntentsComponent;
