import { moduleIdURL, moduleURL, noFmoduleIdURL } from "../../http/config";
import { requests } from "../../requests";
export const ChatsFunction =
(method, path, id, state, addData, param, noeditparam,noLoader,oldData,nextApi,noSlash) =>
  async (dispatch, getState) => {
    try {
      const module = path ? path : "Chats";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url:nextApi?nextApi: id ? moduleIdURL(module, id) : moduleURL(module,noSlash),
        params:nextApi|| noeditparam ? {} : param ? param : { id: id },
        data:nextApi?"": addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
  
  export const CashDiscountFunction =
  (method, path, id, state, addData, param, noeditparam) =>
  async (dispatch, getState) => {
    try {
      const module = path ? path : "CashDiscount";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: noeditparam ? {} : param ? param : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const RatesFunction =
  (method, path, id, state, addData, param, noeditparam,api) =>
  async (dispatch, getState) => {
    try {
      console.log(api,"aeeeeeepi")
      const module = path ? path : "Rates";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url:api?? id ? moduleIdURL(module, id) : moduleURL(module),
        params: noeditparam ? {} : param ? param : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
  export const SalesIntentsFunction =
  (method, path, id, state, addData, param, noeditparam,noLoader,oldData,nextApi,noSlash) =>
  async (dispatch, getState) => {
    try {
      const module = path ? path : "SalesIntents"; if(noLoader){}else{
      dispatch(fetchingRequest(state ? state : module));}
      const options = {
        method: method,
        url:nextApi?nextApi: id ? moduleIdURL(module, id) : moduleURL(module,noSlash),
        params:nextApi|| noeditparam ? {} : param ? param : { id: id },
        data:nextApi?"": addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        const resData=response.data
        if(oldData&&oldData.length>0){
          if (resData.results.length > 0) {
            resData.results = [...oldData, ...resData.results];
          } else {
            resData.results = oldData;
          }
        
        }
        console.log(resData,oldData,"resData")
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: resData,
        });
        return resData
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const PrecarriersFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Precarriers";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const PolicysFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Policys";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const SOpaymentTermsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "SOpaymentTerms";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const IncotermsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Incoterms";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const ShippingTermsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "ShippingTerms";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const CurrencysFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Currencys";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const NotifysFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Notifys";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const RiceBagSellersFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "RiceBagSellers";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const BeneficiarysFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Beneficiarys";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const ShippersFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Shippers";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const UsersAndPermissionsFunction =
  (method, path, id, state, addData, params) => async (dispatch, getState) => {
    try {
      const module = path ? path : "UsersAndPermissions";

      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const UsersPermissionsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "UsersPermissions";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const BrandsManagementsFunction =

(method, path, id, state, addData, param, noeditparam,noLoader,oldData,nextApi,noSlash) =>
  async (dispatch, getState) => {
    try {
      console.log(noSlash,"noSlash")
      const module = path ? path : "BrandsManagements";
      if(noLoader){}else{
      dispatch(fetchingRequest(state ? state : module));}
      const options = {
        method: method,
        url:nextApi?nextApi: id ? moduleIdURL(module, id) : moduleURL(module,noSlash),
        params:nextApi|| noeditparam ? {} : param ? param : { id: id },
        data:nextApi?"": addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        const resData=response.data
        if(oldData&&oldData.length>0){
          if (resData.results.length > 0) {
            resData.results = [...oldData, ...resData.results];
          } else {
            resData.results = oldData;
          }
        
        }
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: resData,
        });
        return response.error ? response : resData;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const MillersFunction =
  (method, path, id, state, addData, params,noeditparam) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Millers";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id,noeditparam) : moduleURL(module),
        params: noeditparam?{}:params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const BrokersFunction =
  (method, path, id, state, addData, params,noeditparam) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Brokers";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method, url: id ? moduleIdURL(module, id,noeditparam) : moduleURL(module),
        params: noeditparam?{}:params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const TransportersFunction =
  (method, path, id, state, addData, params,noeditparam) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Transporters";
      console.log(noeditparam,moduleIdURL(module, id,noeditparam) ,"moduleIdURL(module, id,noeditparam) ")
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id,noeditparam) : moduleURL(module),
        params: noeditparam?{}:params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const PortsFunction =
  (method, path, id, state, addData, params, noForwordSlash) =>
  async (dispatch, getState) => {
    try {
      const module = path ? path : "Ports";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: noForwordSlash
          ? noFmoduleIdURL(module)
          : id
          ? moduleIdURL(module, id)
          : moduleURL(module),
        params: params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const HsNsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "HsNs";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

export const PurchaseOrdersFunction =
  (method, path, id, state, addData, param, noeditparam,noLoader) =>
  async (dispatch, getState) => {
    try {
      const module = path ? path : "PurchaseOrders";
   
      if(noLoader){}else{
      dispatch(fetchingRequest(state ? state : module));
      }
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: noeditparam ? {} : param ? param : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const SalesOrdersFunction =
  (method, path, id, state, addData, param, noeditparam) =>
  async (dispatch, getState) => {
    try {
      const module = path ? path : "SalesOrders";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: noeditparam ? {} : param ? param : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const PackagingsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Packaging";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const ContractTypesFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "ContractTypes";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const PaymentTermsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "PaymentTerms";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const ItemTypesFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "ItemTypes";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

class ItemCategory {
  constructor(id, categoryName, status) {
    this.id = id;
    this.categoryName = categoryName;
    this.status = status;
  }
}

const itemCategories = [
  new ItemCategory("cat1", "White Rice", true),
  new ItemCategory("cat2", "Parboiled Rice", true),
];

export const ItemCategoriesFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "ItemCategories";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
          //data: itemCategories,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const SurveyCompanysFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "SurveyCompanys";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

class Client {
  constructor(id, name, address, city, state, country, phone) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.city = city;
    this.state = state;
    this.country = country;
    this.phone = phone;
  }
}

export const ClientsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Clients";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
          //data: clients,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

// export const PortsFunction =
//   (method, path, id, state, data) => async (dispatch, getState) => {
//     try {
//       const module = path ? path : "Ports";
//       dispatch(fetchingRequest(state ? state : module));
//       const options = {
//         method: method,
//         url: id ? moduleIdURL(module, id) : moduleURL(module),
//         params: { id: id },
//       };
//       const data = await requests(options).then((response) => {
//         dispatch({
//           type: `FETCH_${state ? state : module}_SUCCESS`,
//           payload: state ? state : module,
//           data: response.data,
//         });
//
//return response.error ? response : response.data;
//       });

//       return data;
//     } catch (error) {
//       return {
//         error: true,
//         data: error.response
//           ? error.response.data
//           : "Some error occured, please try again!",
//       };
//     }
//   };

export const DispatchesFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Dispatches";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

export const UsersFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Users";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

const fetchingRequest = (data) => {
  return {
    type: "FETCH_REQUEST",
    payload: data,
    data: [],
  };
};
