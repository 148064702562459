import React, { useEffect } from "react";
import { connect } from "react-redux";
import UsersAndPermissionsComponent from "./UsersAndPermissionsComponent";
import { BrandsManagementsFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function UsersAndPermissionsContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.BrandsManagementsFunction("get", EndPoints["UsersAndPermissions"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <UsersAndPermissionsComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  UsersAndPermissions: state.modules.UsersAndPermissions,
  loading: state.modules.loading,
  [EndPoints["UsersAndPermissions"]]: state.modules[EndPoints["UsersAndPermissions"]],
});
const mapDispatchToProps = {
  BrandsManagementsFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersAndPermissionsContainer);
