import React, { useEffect } from "react";
import { connect } from "react-redux";
import CurrencysComponent from "./CurrencysComponent";
import { BrandsManagementsFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";

function CurrencysContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.BrandsManagementsFunction("get", EndPoints["Currencys"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <CurrencysComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  [EndPoints["Currencys"]]: state.modules[EndPoints["Currencys"]],
  loading: state.modules.loading,
});
const mapDispatchToProps = {
  BrandsManagementsFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencysContainer);
