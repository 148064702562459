import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TabComponent from "../../layoutComponents/TabComponent";
import { StyledCircularProgress } from "../../styles/StyledComponents";
import TableComponent from "../../layoutComponents/TableComponent";
import { CrossIcon, EditIcon } from "../../styles/Icons";
import ReactDOM from "react-dom";
import moment from "moment";
import NoData from "../../styles/Illustrations/NoData";
import { withStyles } from "@material-ui/styles";
import {
  primary,
  white,
  red,
  mostLightGrey,
  lightGrey,
} from "../../styles/colors";
import { EndPoints } from "../../utils/EndPoints";
import { divide, multiply, sum, subtract } from "lodash";

import { accessText } from "../../routes/sidebar";
// import {
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
import {
  Divider,
  Paper,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  MenuItem,
  Tooltip,
  Button,
  Backdrop,
  DialogContent,
  Checkbox,
} from "@material-ui/core";
import {
  CssTextField,
  StyledButton,
  CssTextField95,
} from "../../styles/StyledComponents";
import TextField from "@mui/material/TextField";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import { CheckCircle } from "@material-ui/icons";
import { filterData, StopScroll, getSingleModule_widget } from "../../Regex";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import Details from "./Details";
import ShowSpecifications, {
  getSpecificationId,
} from "../../layoutComponents/ShowSpecifications";
import MultipleItemTable from "../../layoutComponents/MultipleItemTable";
// import DateFnsUtils from "@date-io/date-fns"; // choose your libs
// import { TopArrow, BottomArrow } from "../../styles/Icons";
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  heading1: {
    fontSize: "1rem",
    color: "rgba(150, 150, 150, 1)",
  },
  subHeading: {
    fontSize: "0.8rem",
    color: "black",
  },
};
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "0 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
// const CssKeyboardDatePicker = withStyles({
//   root: {
//     "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
//       padding: "5px",
//     },
//     "& .MuiOutlinedInput-input ": {
//       padding: "14px 14px",
//     },
//     "& .MuiIconButton-root ": {
//       padding: "0px",
//     },
//   },
// })(KeyboardDatePicker);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const getMinValue = (...args) => {
  if (args.length === 0) {
    throw new Error("At least one number must be provided");
  }

  let smallest = Number(args[0]);

  for (let i = 1; i < args.length; i++) {
    if (Number(args[i]) < smallest || smallest === 0) {
      if (args[i] !== 0) {
        smallest = Number(args[i]);
      }
    }
  }
  console.log(...args, "argsargs", smallest);
  return smallest;
};
export const CssButton = withStyles({
  root: {
    "&.MuiButton-text": {
      padding: " 4px 8px",
    },
  },
})(Button);
export const CssCreateTextField = withStyles({
  root: {
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        background: "rgb(0 0 0 / 1%)",
      },
    "& .MuiFormHelperText-root": {
      color: "balck",
    },
    "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled": {
      color: "black",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: "20px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(20px , 5px) scale(1)",
      color: "black",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.Mui-focused": {
      color: primary,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },

      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
  },
})(TextField);
export class PurchaseOrdersComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["PurchaseOrders"],
        tooltip: `Click to approve pending ${EndPoints["PurchaseOrders"]}`,
        data: [],
        route: `/PurchaseOrders/${EndPoints["PurchaseOrders"].split("/")[0]}`,
        goBackRoute: "/PurchaseOrders",
        colorArr: [white, lightGrey],
        primaryField: "po_number",
        primarySecondValue: "total_dispatched_quantity",
        primaryFieldFallback: "total_trips",
        widget: null,
        secondaryField: "id",
        primaryFieldThirdFallback: "total_po_quantity",
        unit: true,
        avatarField: "src",
        dispatchList: true,
        progressBar: true,
        salesList: true,
        selectedPoid: false,
        dispatchPackagingArray: [],
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "rgb(241 154 48)", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      survayorList: [],
      PODispatchedList: [],
      PO_Items_List: [],
      summaryData: [],
      PurchaseOrders: [],
      // singlelistView: null,
      listViewArray: [],
      loading: false,
      editingDispatch: false,
      loadingFun: false,
      success: false,
      error: false,
      calenderOpen: false,
      dispatchTab: true,
      selectedSrvayorid: false,
      selectedPoIdinSiurvayor: "",
      status: [{ name: "OPEN" }, { name: "CLOSED" }, { name: "CANCELLED" }],
      statusDispatch: [
        { name: "dispatched" },
        { name: "reached" },
        { name: "full_return" },
        { name: "semi_returned" },
        { name: "unloaded" },
      ],
      selctedPOStatus: "OPEN",
      total_quantity: "",
      last_survey: new Date(),
      unit: [{ name: "Kgs" }, { name: "MT" }, { name: "Qtl" }],
      selctedStatus: "OPEN",
      openDialog: false,
      searchedData: new Date(),
      calenderOpen: false,
      calenderOpenLS: false,
      poItemheader: [
        "Brand",
        "Item Category",
        "Item Type",
        "Packaging",
        "HSN Code",
        "Total Item Quantity",
        "Contract Rate",
        "Specifications",
      ],
      survayItemheader: [
        "Brand",
        "Item Category",
        "Item Type",
        "Moisture",
        "Broken",
        "Damaged/Discoloured",
        "Chalky",
        "Length",
        "Paddy",
        "Foreign Matter",
        "Double Polish",
        "REMARK",
        "Survey Company",
        "Surveyor",
        //"Sampling Date"
      ],
      widthList: {},
      dates: [],
      selectedPVItemArray: [],
      contractTypeName: "",
      contractTypeId: "",
      PaymentTerm: "",
      PaymentTermId: "",
      MillerName: "",
      MillerNameId: "",
      MillerAddress: "",
      MillerAddressId: "",
      MillerGST: "",
      MillerGSTId: "",
      ContactPerson: "",
      ContactPersonId: "",
      ItemCategory: "",
      ItemCategoryId: "",
      ItemType: "",
      itemsId: "",
      BorkerName: "",
      BorkerNameId: "",
      BrandName: false,
      selectedBrandMultipleName: false,
      BrandNameId: "",
      Borkerage: "",
      BorkerageId: "",
      Remark: "",
      Unit: "",
      UnitId: "",
      portArry: [],
      packagingArr: [],
      selectedValue: 0,
      added: false,
      cashdiscount: "no",
      addItemMultiple: "false",
      contarctTypeFields: [{ type: "EX FACTORY" }, { type: "FOR" }],
      HSN_id: "",
      poItemsArray: [],
      poItemsArrayCopy: [],
      poItemsArrayTOPreview: [],
      poItemsArrayTOPreviewCopy: [],
      poItemsArrayTOPreviewCopy2: [],
      alreadyFetched: false,
      alreadyFetchedData: false,
      loader: false,
      loadingRight: false,
      submittedDataOfUsedBags: {},
      surveyReport: [],
      surveyorItemArrayCopy: [],
      errorMsg: {
        contractTypeName: false,
        PaymentTerm: false,
        MillerName: false,
        MillerAddress: false,
        MillerGST: false,
        ContactPerson: false,
        ItemCategory: false,
        ItemType: false,
        BorkerName: false,
        BrandName: false,
        Borkerage: false,
        Remark: false,
        portArry: false,
        packagingArr: false,
        Rate: false,
        Unit: false,
        HSN: false,
      },
      errorMsgForDispatch: {},
      openAddBagDialog: false,
      editDialog: false,
    };
  }
  handleClearFields = () => {
    this.setState({
      added: false,
      loading: false,
      loadingFun: false,
      success: false,
      error: false,
    });
  };
  reset = (noSnack) => {
    const {
      contractTypeName,

      PaymentTerm,
      MillerName,
      MillerAddress,
      MillerGST,
      ContactPerson,
      ItemCategory,
      ItemType,
      BorkerName,
      BrandName,
      Borkerage,
      Remark,
      portArry,
      packagingArr,
      Rate,
      Unit,
      HSN,
      SalesOrder,
    } = this.state;

    if (
      !Rate &&
      !Unit &&
      !HSN &&
      !Remark &&
      !Borkerage &&
      !BrandName &&
      !BorkerName &&
      !ItemType &&
      !ItemCategory &&
      !ContactPerson &&
      !MillerGST &&
      !MillerAddress &&
      !MillerName &&
      !PaymentTerm &&
      !contractTypeName &&
      packagingArr.length === 0 &&
      portArry.length === 0 &&
      !SalesOrder
    ) {
      this.setState({
        error: noSnack ? false : "Nothing to reset !",
        errorMsg: {
          contractTypeName: false,
          PaymentTerm: false,
          MillerName: false,
          MillerAddress: false,
          MillerGST: false,
          ContactPerson: false,
          ItemCategory: false,
          ItemType: false,
          BorkerName: false,
          BrandName: false,
          Borkerage: false,
          Remark: false,
          portArry: false,
          packagingArr: false,
          Rate: false,
          Unit: false,
          HSN: false,
          SalesOrder: false,
        },
      });
    } else {
      this.setState(
        {
          BrandNameId: "",
          BrandName: false,
          selectedBrandMultipleName: false,
          selctedPOStatus: "OPEN",
          SalesOrder: "",
          SalesOrderId: "",
          contractTypeName: "",
          PaymentTerm: "",
          MillerName: "",
          MillerAddress: "",
          MillerGST: "",
          ContactPerson: "",
          ItemCategory: "",
          itemsId: "",
          ItemCategoryId: "",
          ItemType: "",
          BorkerName: "",
          Borkerage: "",
          Remark: "",
          portArry: [],
          packagingArr: [],
          Rate: "",
          Unit: "",
          HSN: "",
          HSN_id: "",
          enteredDispatchdData: false,
          errorMsg: {
            contractTypeName: false,
            PaymentTerm: false,
            MillerName: false,
            MillerAddress: false,
            MillerGST: false,
            ContactPerson: false,
            ItemCategory: false,
            ItemType: false,
            BorkerName: false,
            BrandName: false,
            Borkerage: false,
            Remark: false,
            portArry: false,
            packagingArr: false,
            Rate: false,
            Unit: false,
            HSN: false,
            SalesOrder: false,
          },
        },
        () => {
          this.setState({ success: noSnack ? false : "Reset successfully !" });
        }
      );
    }
  };
  UNSAFE_componentWillReceiveProps(next) {
    //console.log(next.masters,"mastallers")
    this.setInitialValues(next);
    if (next.onlyCreate) {
      this.setState({ openDialog: true });
    }
  }
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props);
  }
  listClickHandler = (value) => {
    this.setState(
      { loadingRight: true, singlelistView: value },

      () => {
        if (this.state.singlelistView && this.state.singlelistView.id) {
          this.setState({
            moistureCondition: "",
            damagedCondition: "",
            chalkyCondition: "",
            paddyCondition: "",
            lengthCondition: "",
            foreignCondition: "",
            doubleCondition: "",
            brokenCondition: "",
          });
          this.props
            .PurchaseOrdersFunction(
              "get",
              "dispatch/list",
              null,
              "PODispatchedList",
              null,
              {
                po_id: this.state.singlelistView.id,
              }
            )
            .then((res) => {
              if (res && res.length > 0) {
                const blankarr = [];
                res.map((sData) => {
                  this.props
                    .PurchaseOrdersFunction(
                      "get",
                      "dispatch/dispatch-items/list",
                      null,
                      "dispatch-item",
                      null,
                      {
                        dispatch_id: sData.id,
                      }
                    )
                    .then((res2) => {
                      sData.subList = res2
                        ? res2.map((s) => {
                            const brandData =
                              this.state.poItemsArrayTOPreview?.length > 0
                                ? this.state.poItemsArrayTOPreview.find(
                                    (dis) => dis.brand_details?.id === s.brand
                                  )
                                : false;
                            if (brandData !== false) {
                              s["Brand"] = brandData
                                ? brandData.brand_details.brand_name
                                : s.brand;
                              s["BrandId"] = brandData
                                ? brandData.brand_details.id
                                : s.brand;
                              s.brand_details = brandData
                                ? brandData.brand_details
                                : {};
                            }
                            return s;
                          })
                        : [];
                      blankarr.push(sData);
                      if (res.length === blankarr.length) {
                        this.setState({ PODispatchedList: res });
                      }
                      return sData;
                    });
                  return sData;
                });
              } else {
                this.setState({ PODispatchedList: [] });
              }
            });
          this.props
            .PurchaseOrdersFunction(
              "get",
              "dispatch/po-items/list",
              null,
              "PO_Items_List",
              null,
              {
                po_id: this.state.singlelistView.id,
                is_active: "True",
              }
            )
            .then(() => {
              this.props
                .PurchaseOrdersFunction(
                  "get",
                  "dispatch/po-packaging/list",
                  null,
                  //state.singlelistView.id,
                  "poPackahging",
                  null,
                  { is_active: "True" }
                )
                .then((poPackahging) => {
                  if (poPackahging) {
                    this.setState({ poPackahging }, () => {
                      this.setData();
                    });
                  } else {
                    this.setData();
                  }
                });
            });
          this.props
            .PurchaseOrdersFunction(
              "get",
              "dispatch/purchase-order/survey-report/list",
              null,
              "surveyReport",
              null,
              {
                po_id: this.state.singlelistView.id,
              }
            )
            .then((res) => {
              if (res && res.length > 0) {
                const blankarr = [];
                res.map((sData) => {
                  this.props
                    .PurchaseOrdersFunction(
                      "get",
                      "dispatch/purchase-order/survey-report/items/list",
                      null,
                      "survay-item",
                      null,
                      { survey_report_id: sData.id }
                    )
                    .then((res2) => {
                      const newobj = sData;
                      sData.subList = res2
                        ? res2.map((s) => {
                            const brandData =
                              this.state.poItemsArrayTOPreview?.length > 0
                                ? this.state.poItemsArrayTOPreview.find(
                                    (dis) => dis.id === s.po_item
                                  )
                                : false;
                            //console.log(brandData,"brandDatabrandData",this.state.poItemsArrayTOPreview,s)
                            if (brandData !== false) {
                              s["Brand"] =
                                brandData && brandData.brand_details
                                  ? brandData.brand_details.brand_name ?? ""
                                  : s.brand;

                              s["BrandId"] =
                                brandData && brandData.brand_details
                                  ? brandData.brand_details.id ?? ""
                                  : s.brand;
                              s.brand_details = brandData
                                ? brandData.brand_details
                                : {};
                            }
                            return s;
                          })
                        : [];
                      blankarr.push(newobj);
                      if (res.length === blankarr.length) {
                        this.setState({ surveyReport: blankarr });
                      }
                      return sData;
                    });
                  return sData;
                });
              } else {
                this.setState({ surveyReport: [] });
              }
            });
        }
      }
    );
  };
  selectHandler = (value) => {
    this.setState({ selctedStatus: value }, () => {
      this.props.PurchaseOrdersFunction(
        "get",
        EndPoints["PurchaseOrders"],
        null,
        null,
        null,
        {
          status: this.state.selctedStatus,
        }
      );
    });
  };
  searchHandler = (value) => {
    this.setState({ selectedMonth: value }, () => {
      const { selectedMonth } = this.state;
      this.props.PurchaseOrdersFunction(
        "get",
        EndPoints["PurchaseOrders"],
        null,
        null,
        null,
        {
          month: selectedMonth.getMonth() + 1,
          status: this.state.selctedStatus,
        }
      );
    });
  };
  setMasters = (props) => {
    const masters = props.masters;
    if (masters) {
      this.setState({
        mastersList: masters,
        loader: props.loader,
        dispatchPackagingArray: masters["dispatchPackagingArray"]
          ? masters["dispatchPackagingArray"]
          : [],
        allsurveyorList: masters["allsurveyorList"]
          ? masters["allsurveyorList"]
          : [],
        ricebagsList: masters["ricebag"] ? masters["ricebag"] : [],
        poPackahging: masters["poPackahging"] ? masters["poPackahging"] : [],
        MillerList: masters["MillerList"] ? masters["MillerList"] : [],
        ItemCateoryList: masters["ItemCateoryList"]
          ? masters["ItemCateoryList"]
          : [],
        ItemList: masters["ItemList"] ? masters["ItemList"] : [],
        BrandList: masters["BrandList"] ? masters["BrandList"] : [],
        PackagingList: masters["PackagingList"] ? masters["PackagingList"] : [],
        BrokerList: masters["BrokerList"] ? masters["BrokerList"] : [],
        ContractTypeList: masters["ContractTypeList"]
          ? masters["ContractTypeList"]
          : [],
        PortsList: masters["PortsList"] ? masters["PortsList"] : [],
        PaymentTermList: masters["PaymentTermList"]
          ? masters["PaymentTermList"]
          : [],
        TransporterList: masters["TransporterList"]
          ? masters["TransporterList"]
          : [],
        HSNlist: masters["HSNlist"] ? masters["HSNlist"] : [],
        SalesOrderList: masters["SalesOrderList"]
          ? masters["SalesOrderList"]
          : [],
        SurveyCompanyList: masters["SurveyCompanyList"]
          ? masters["SurveyCompanyList"]
          : [],
        CashDiscountList: masters["CashDiscountList"]
          ? masters["CashDiscountList"]
          : [],
      });
    }
  };
  setInitialValues(props) {
    if (
      props &&
      props[EndPoints["PurchaseOrders"]] &&
      props[EndPoints["PurchaseOrders"]].length > 0 &&
      typeof props[EndPoints["PurchaseOrders"]] === "string"
    ) {
      props[EndPoints["PurchaseOrders"]].sort((a, b) => b.id - a.id);
    }
    if (Array.isArray(props[EndPoints["PurchaseOrders"]])) {
      // const tabs = this.state.tabs.map((e, i) => {
      //   const tab = e;
      const { widget_permission } = getSingleModule_widget(
        props.user,
        accessText.purchase_order,
        accessText.purchase_order_detail
      );
      const tabs = this.state.tabs.map((e, i) => {
        const tab = e;
        // has_read_permission:true,has_create_permission:true
        tab.widget = widget_permission?.widget
          ? { ...widget_permission?.widget }
          : null;
        tab.data =
          props[EndPoints["PurchaseOrders"]] &&
          props[EndPoints["PurchaseOrders"]].length > 0
            ? props[EndPoints["PurchaseOrders"]]
                .map((data, index) => {
                  return data;
                })
                .filter((sD) => {
                  if (sD.status) {
                    return sD.status === this.state.selctedStatus;
                  } else {
                    return null;
                  }
                })
                .sort((a, b) => b.id - a.id)
            : [];

        if (this.state.selctedStatus && tab.data.length > 0) {
          tab.data = tab.data
            .map((data, index) => {
              return data;
            })
            .filter((sD) => {
              if (sD.status) {
                return sD.status === this.state.selctedStatus;
              } else {
                return null;
              }
            });
        }

        return tab;
      });
      const masters = props.masters;
      if (masters) {
        this.setState({
          loader: props.loader,
          mastersList: masters,
          dispatchPackagingArray: masters["dispatchPackagingArray"]
            ? masters["dispatchPackagingArray"]
            : [],
          allsurveyorList: masters["allsurveyorList"]
            ? masters["allsurveyorList"]
            : [],
          ricebagsList: masters["ricebag"] ? masters["ricebag"] : [],
          poPackahging: masters["poPackahging"] ? masters["poPackahging"] : [],
          MillerList: masters["MillerList"] ? masters["MillerList"] : [],
          ItemCateoryList: masters["ItemCateoryList"]
            ? masters["ItemCateoryList"]
            : [],
          BrandList: masters["BrandList"] ? masters["BrandList"] : [],
          ItemList: masters["ItemList"] ? masters["ItemList"] : [],
          PackagingList: masters["PackagingList"]
            ? masters["PackagingList"]
            : [],
          BrokerList: masters["BrokerList"] ? masters["BrokerList"] : [],
          ContractTypeList: masters["ContractTypeList"]
            ? masters["ContractTypeList"]
            : [],
          PortsList: masters["PortsList"] ? masters["PortsList"] : [],
          PaymentTermList: masters["PaymentTermList"]
            ? masters["PaymentTermList"]
            : [],
          TransporterList: masters["TransporterList"]
            ? masters["TransporterList"]
            : [],
          HSNlist: masters["HSNlist"] ? masters["HSNlist"] : [],
          SalesOrderList: masters["SalesOrderList"]
            ? masters["SalesOrderList"]
            : [],
          SurveyCompanyList: masters["SurveyCompanyList"]
            ? masters["SurveyCompanyList"]
            : [],
          CashDiscountList: masters["CashDiscountList"]
            ? masters["CashDiscountList"]
            : [],
        });
      }
      this.setState(
        {
          listViewArray:
            props[EndPoints["PurchaseOrders"]] &&
            props[EndPoints["PurchaseOrders"]].length > 0
              ? props[EndPoints["PurchaseOrders"]].map((data, index) => {
                  return data;
                })
              : [],
          PurchaseOrders: props[EndPoints["PurchaseOrders"]],
          tabs,
          loading: props.loading,

          PO_Items_List:
            props.PO_Items_List && props.PO_Items_List.length > 0
              ? props.PO_Items_List
              : [],
        },
        () => {
          const {
            singlelistView,
            listViewArray,
            ItemCateoryList,
            ItemList,
            HSNlist,
          } = this.state;
          const oldData = localStorage.getItem("selected-po");

          if (
            singlelistView &&
            listViewArray.length > 0 &&
            HSNlist &&
            HSNlist.length > 0 &&
            ItemCateoryList &&
            ItemCateoryList.length > 0 &&
            ItemList &&
            ItemList.length > 0
          ) {
            if (this.state.alreadyFetchedData === false) {
              this.props
                .PurchaseOrdersFunction(
                  "get",
                  "dispatch/po-items/list",
                  null,
                  "PO_Items_List",
                  null,
                  {
                    po_id: this.state.singlelistView.id,
                    is_active: "True",
                  }
                )
                .then((PO_Items_List) => {
                  this.props
                    .PurchaseOrdersFunction(
                      "get",
                      "dispatch/po-packaging/list",
                      null,
                      "poPackahging",
                      null,
                      {
                        is_active: "True",
                      }
                    )
                    .then((poPackahging) => {
                      if (poPackahging) {
                        this.setState({ poPackahging }, () => {
                          this.setData();
                        });
                      } else {
                        this.setData();
                      }
                    });
                });

              this.setState({ alreadyFetchedData: true });
            }
            const newsinglelistView =
              listViewArray.filter((sLV) => sLV.id === singlelistView.id) &&
              listViewArray.filter((sLV) => sLV.id === singlelistView.id)[0];

            if (newsinglelistView && newsinglelistView.status) {
              if (newsinglelistView.status === this.state.selctedStatus) {
                // this.listClickHandler(newsinglelistView)
                if (oldData && newsinglelistView) {
                  this.setState({
                    singlelistView: newsinglelistView,
                  });
                  localStorage.removeItem("selected-po");
                }
              } else {
                this.setState({ singlelistView: null }, () => {
                  this.reset("no snac");
                });
              }
            } else {
              this.setState({ singlelistView: null }, () => {
                this.reset("no snac");
              });
            }
          } else if (listViewArray.length > 0) {
            const next = this.props;

            if (next.params && next.params.tab) {
              let tab = next.params.tab;

              if (
                tab === EndPoints["PurchaseOrders"].split("/")[0] &&
                next.params.id
              ) {
                if (this.state.alreadyFetched === false) {
                  this.listClickHandler(
                    this.state.tabs[0].data[next.params.id]
                  );
                  this.setState({ alreadyFetched: true });
                }
              } else if (tab === "NEW") {
                this.setState({ singlelistView: null }, () => {
                  this.reset("no snac");
                });
              }
            } else {
              this.setState({ singlelistView: null }, () => {
                this.reset("no snac");
              });
            }
          } else {
            if (oldData) {
            } else {
              this.setState({}, () => {
                this.reset("no snac");
              });
            }
          }
        }
      );
    } else {
      this.setMasters(props);
    }
  }
  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };

  longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  setWidth = (list, header) => {
    const { poItemheader } = this.state;
    if (list && list.length > 0) {
      if (list[0]) {
        const widthList = {};
        const array = header
          ? header
          : poItemheader
          ? poItemheader
          : Object.keys(list[0]);

        if (array && array.length > 0) {
          array.map((single, i) => {
            if (single) {
              return (widthList[array[i]] = `${
                Math.floor(
                  10 *
                    (single.toString().length > this.longest(single, list)
                      ? single.toString().length
                      : this.longest(single, list)
                      ? this.longest(single, list)
                      : 1)
                ) < 110
                  ? 110
                  : Math.floor(
                      10 *
                        (single.toString().length > this.longest(single, list)
                          ? single.toString().length
                          : this.longest(single, list)
                          ? this.longest(single, list)
                          : 1)
                    )
              }px`);
            } else {
              return single;
            }
          });
        }
        this.setState({ widthList });
      }
    }
  };
  setData = () => {
    const {
      editDialog,
      ItemCateoryList,
      ItemList,
      PackagingList,
      PortsList,
      PO_Items_List,
      poItemheader,
      HSNlist,
      singlelistView,
    } = this.state;
    const oldnewPortArr = editDialog.ports ? editDialog.ports : [];
    const newPortArr = [];
    if (PortsList && PortsList.length > 0) {
      PortsList.map((sPack) => {
        if (oldnewPortArr.includes(sPack.id.toString())) {
          return newPortArr.push(sPack);
        } else {
          return null;
        }
      });
    }

    const newPOItemArray = [];
    if (
      PO_Items_List &&
      typeof PO_Items_List === "object" &&
      PO_Items_List.length > 0
    ) {
      PO_Items_List &&
        PO_Items_List.map((sList) => {
          const packaging_details = [];
          const pId = filterData(this.state.poPackahging, "po_items", sList.id)
            ? filterData(this.state.poPackahging, "po_items", sList.id).map(
                (s) => {
                  return s.packaging.toString();
                }
              )
            : [];

          if (PackagingList && PackagingList.length > 0) {
            PackagingList.map((sPack) => {
              const newObject =
                this.state.poPackahging &&
                filterData(this.state.poPackahging, "po_items", sList.id) &&
                filterData(
                  this.state.poPackahging &&
                    filterData(this.state.poPackahging, "po_items", sList.id),
                  "packaging",
                  sPack.id,
                  "onlyOne"
                )
                  ? filterData(
                      this.state.poPackahging &&
                        filterData(
                          this.state.poPackahging,
                          "po_items",
                          sList.id
                        ),
                      "packaging",
                      sPack.id,
                      "onlyOne"
                    )
                  : null;

              if (pId.includes(sPack.id.toString()) || pId.includes(sPack.id)) {
                sPack.remaining_bags = newObject ? newObject.remaining_bags : 0;
                sPack.used_bags = newObject
                  ? newObject.used_bags
                    ? newObject.used_bags
                    : 0
                  : 0;
                return packaging_details.push(sPack);
              } else {
                return null;
              }
            });
          }
          const addData = {
            brand: sList.brand,

            hsn_code: sList.hsn_code ? sList.hsn_code : "",
            packagingArr: packaging_details.map((sP) => {
              sP.sizeUnit = `${sP.size} ${sP.unit}`;
              return sP;
            }),
            total_quantity: sList.total_quantity,

            packaging: sList.packaging,
          };
          addData["id"] = sList.id;
          addData["remaining_bags"] = sList.remaining_bags;
          addData["dispatched_quantity"] = sList.dispatched_quantity;
          addData[poItemheader[1]] =
            ItemCateoryList &&
            ItemCateoryList.length > 0 &&
            ItemCateoryList.filter((sM) =>
              sM.id === sList.item_category ? sList.item_category : 0
            )[0]
              ? ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0].name &&
                ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0].name
              : "";
          addData[`${poItemheader[1]}id`] = sList.item_category;
          const spArr = sList["specifications"] ? sList["specifications"] : [];
          addData["Specifications"] = spArr;
          addData["specificationArr"] = spArr;
          addData[poItemheader[2]] =
            ItemList &&
            ItemList.length > 0 &&
            ItemList.filter((sM) =>
              sM.id === sList.item_type ? sList.item_type : 0
            )[0]
              ? ItemList.filter((sM) =>
                  sM.id === sList.item_type ? sList.item_type : 0
                )[0].broken_percentage
              : "";
          addData[`${poItemheader[2]}id`] = sList.item_type;

          addData["item_type"] = sList.item_type;
          addData[poItemheader[0]] = sList.brand_details.brand_name;
          addData[`${poItemheader[0]}id`] = sList.brand_details.id;
          addData.brand_details = sList.brand_details;
          addData.selectedBrandMultipleName = `${sList.brand}${
            sList.brand ? "-" : ""
          }${
            filterData(ItemCateoryList, "id", sList.item_category, "onlyOne")
              ? filterData(
                  ItemCateoryList,
                  "id",
                  sList.item_category,
                  "onlyOne"
                ).name
              : ""
          }-${
            filterData(ItemList, "id", sList.item_type, "onlyOne")
              ? filterData(ItemList, "id", sList.item_type, "onlyOne")
                  .broken_percentage
              : ""
          } % `;
          addData[poItemheader[3]] =
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }) &&
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }).length > 0
              ? packaging_details.map((sP) => {
                  return `${sP.size} ${sP.unit}`;
                })[0]
              : "-";
          addData[`${poItemheader[3]}Id`] = packaging_details;
          addData["packagageArrUsed"] = packaging_details
            .map((sP) => {
              const newObj = {};
              newObj.id = sP.id;
              newObj.bag_type = sP.bag_type;
              newObj.size = sP.size;
              newObj.unit = sP.unit;
              newObj.sizeUnit = `${sP.size} ${sP.unit}`;
              newObj.used_bags_po = sP.used_bags ? sP.used_bags : 0;
              newObj.bag_weight = sP.bag_weight ?? "";
              newObj.remaining_bags = sP.remaining_bags;
              return newObj;
            })
            .map((s) => {
              s.used_bags = 0;
              return s;
            });
          addData[poItemheader[4]] =
            HSNlist &&
            HSNlist.length > 0 &&
            HSNlist.filter(
              (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
            )[0]
              ? HSNlist.filter(
                  (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
                )[0].hsn_code
              : "";

          addData[`${poItemheader[4]}id`] = sList.hsn_code
            ? sList.hsn_code
            : "";
          addData[poItemheader[5]] = sList.total_quantity;
          addData[`${poItemheader[5]}Unit`] = `${sList.total_quantity} ${
            singlelistView && singlelistView.unit ? singlelistView.unit : ""
          }`;

          addData[poItemheader[6]] = sList.contract_rate;
          //console.log(sList,"poitemsList")
          addData[`${poItemheader[6]}Unit`] = `${sList.contract_rate}/${
            singlelistView && singlelistView.unit ? singlelistView.unit : ""
          }`;

          addData.id = sList.id;
          addData.po_item_id = sList.id;
          newPOItemArray.push(addData);
          return sList;
        });
    }

    this.setState(
      {
        poItemsArrayTOPreview: newPOItemArray,
        poItemsArrayTOPreviewCopy: newPOItemArray,
        poItemsArrayTOPreviewCopy2: newPOItemArray,
        poItemsArray: [],
        poItemsArrayCopy: [],
        loadingRight: false,
      },
      () => {
        if (
          this.state.poItemsArrayTOPreview &&
          this.state.poItemsArrayTOPreview.length > 0
        ) {
          this.setWidth(this.state.poItemsArrayTOPreview);
        }
      }
    );
  };
  changeTo = (to, weigths, opposite) => {
    if (opposite) {
      if (to === "MT") {
        return multiply(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return multiply(weigths, 100);
      }
    } else {
      if (to === "MT") {
        return divide(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return divide(weigths, 100);
      }
    }
  };
  changeUnit = (from, to, weigths, opposite) => {
    //default in kg

    if (opposite) {
      if (from === "MT") {
        return this.changeTo(to, divide(weigths, 1000), opposite);
      } else if (from === "Kgs") {
        return this.changeTo(to, weigths);
      } else if (from === "Qtl") {
        return this.changeTo(to, divide(weigths, 100), opposite);
      }
    } else {
      if (from === "MT") {
        return this.changeTo(to, multiply(weigths, 1000));
      } else if (from === "Kgs") {
        return this.changeTo(to, weigths);
      } else if (from === "Qtl") {
        return this.changeTo(to, multiply(weigths, 100));
      }
    }
  };
  fetchpoItems = () => {
    this.props
      .PurchaseOrdersFunction(
        "get",
        "dispatch/po-items/list",
        null,
        "PO_Items_List",
        null,
        {
          po_id: this.state.singlelistView.id,
          is_active: "True",
        }
      )
      .then((PO_Items_List) => {
        this.props
          .PurchaseOrdersFunction(
            "get",
            "dispatch/po-packaging/list",
            null,
            "poPackahging",
            null,
            {
              is_active: "True",
            }
          )
          .then((poPackahging) => {
            if (poPackahging) {
              this.setState({ poPackahging }, () => {
                this.setData();
              });
            } else {
              this.setData();
            }
          });
      });
  };
  render() {
    const {
      submittedDataOfUsedBags,
      errorMsgForDispatch,
      surveyReport,
      openAddBagDialog,
      allsurveyorList,
      selectedPVItemArray,
      poItemheader,
      widthList,
      colorArr,
      added,
      success,
      error,
      tabs,
      singlelistView,
      loading,
      status,
      selctedStatus,
      MillerList,
      ItemCateoryList,
      ItemList,
      BrokerList,
      ContractTypeList,
      errorMsg,
      ItemType,
      ItemCategory,
      ItemCategoryId,
      BrandName,
      BrandNameId,
      selectedBrandMultipleName,
      editDialog,
      itemsId,
      PODispatchedList,
      unit,
      CashDiscountList,
      TransporterList,
      HSNlist,

      SurveyCompanyList,
      statusDispatch,
      survayorList,
      poItemsArrayCopy,
      poItemsArrayTOPreview,
      poItemsArrayTOPreviewCopy,
      loader,
      survayorListForSurvay,
      loadingRight,
      survayItemheader,
      Moisture,
      Broken,
      //  SamplingDate,
      Chalky,
      Length,
      Paddy,
      Foreign,
      Double,
      REMARK,
      Damaged,
      surveyorItemArrayCopy,
      surveyorItemArray,
      selectedSrvayorid,
      selectedPoIdinSiurvayor,
      survayorCompany,
      survayorCompanyId,
      survayor,
      survayorId,
      poItemsArrayTOPreviewCopy2,
      // calenderOpen,
      selectedValue,
      moistureCondition,
      moistureValue,
      brokenCondition,
      brokenValue,
      damagedCondition,
      damagedValue,
      chalkyCondition,
      chalkyValue,
      paddyCondition,
      paddyValue,
      lengthCondition,
      lengthValue,
      doubleCondition,
      doubleValue,
      foreignCondition,
      foreignValue,
      PO_Items_List,
      dispatchTab,
      // spec,
    } = this.state;
    const { user } = this.props;

    // this.setState({ survayorList: false });
    // var dispatchTab=true;
    // spec= PO_Items_List.specifications?

    const POITemTable = (
      edit,
      array,
      noTotalQuantity,
      showTotal,
      width,
      addBag,
      submittedData,
      fromPO,
      editFunct,
      Unit,
      noMargin
    ) => {
      //console.log(array,"ghfjjjjjjjjjjjjjj");
      return (
        <>
          {/* <MultipleItemTable header={poItemheader} edit={edit} addBag={addBag} array={array} module={"Po Item"} /> */}
          <div
            style={{
              margin: noMargin ? "" : "20px 0 20px 0",
              height: "auto",
              width: width ? width : edit ? "100%" : "calc(100% - 30px)",
              minWidth: width && "100%",
              fontSize: "0.9rem",
            }}>
            <Paper
              className="d-flex d-flex-column  overflowXA positionR scrollBluePrimary"
              style={{
                height: "auto",
                width: "100%",
                borderRadius: "7px",
              }}>
              <div
                style={{
                  background: "rgb(242, 242, 242)",
                  color: primary,
                  borderColor: primary,
                  width: "fit-content",
                  minWidth: "100%",
                  tableLayout: "fixed",
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                  minHeight: "30px",
                  overflow: "hidden",
                  borderRadius: "7px 7px 0 0",
                }}>
                <div className="d-flex alignC justifySE width-100">
                  {edit === "checkBox" ? (
                    <div
                      onClick={() => {
                        var errorMsg = this.state.errorMsg;
                        delete errorMsg.selectedPVItemArray;
                        if (selectedPVItemArray.length === array.length) {
                          this.setState({ selectedPVItemArray: [], errorMsg });
                        } else {
                          const pvArr1 = [];
                          const totalBag = [];

                          array.map((s) => {
                            pvArr1.push(s.po_item_id);

                            s["packagageArrUsed"].map((sData) => {
                              if (sData.remaining_bags && sData.used_bags) {
                                totalBag.push(sData);
                              } else {
                              }
                            });
                          });
                          const errors = {};
                          const arrayOfPrevState = [
                            "Gross Weight",
                            "Tare Weight",
                            "Net Weight",
                            "Miller Bill Number",
                          ];
                          arrayOfPrevState.map((ss) => {
                            if (submittedData[ss]) {
                            } else {
                              errors[ss] = `Please Add ${ss}`;
                            }
                            return ss;
                          });
                          if (
                            Object.values(errors) &&
                            Object.values(errors).length > 0
                          ) {
                            this.setState({
                              errorMsgForDispatch: {
                                ...errorMsgForDispatch,
                                ...errors,
                              },
                            });
                          } else {
                            if (pvArr1.length === array.length) {
                              this.setState({
                                selectedPVItemArray: pvArr1,
                                errorMsg,
                              });
                            }
                          }
                        }
                      }}
                      className="justifyC d-flex alignC"
                      style={{
                        margin: "0px 10px",
                        width: "65px",
                        display: "flex",
                        alignItems: "center",
                        color: "#100f0f",
                        textTransform: "capitalize",
                      }}>
                      <Checkbox
                        style={{ margin: "0 0 0 10px" }}
                        edge="start"
                        checked={array.length === selectedPVItemArray.length}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": "head" }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {poItemheader &&
                    poItemheader.map((name, i) =>
                      noTotalQuantity && name === "Total Item Quantity" ? (
                        ""
                      ) : (
                        <div
                          key={name}
                          className="justifyC d-flex alignC"
                          style={{
                            margin: "12px 10px",
                            width:
                              widthList && widthList[poItemheader[i]]
                                ? name === "Specifications"
                                  ? "180px"
                                  : name === "Packaging" && addBag
                                  ? `222.08px`
                                  : name === "Packaging"
                                  ? "135.49px"
                                  : widthList[poItemheader[i]]
                                : "fit-content",
                            display: "flex",
                            alignItems: "center",
                            color: "#100f0f",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}>
                          {name}
                        </div>
                      )
                    )}
                  {edit && edit !== "checkBox" && (
                    <div
                      className="justifyC d-flex alignC"
                      style={{
                        margin: "12px 10px",
                        width: "65px",
                        display: "flex",
                        alignItems: "center",
                        color: "#100f0f",
                        textTransform: "capitalize",
                      }}>
                      Action
                    </div>
                  )}
                </div>
              </div>
              {array && array.length > 0 ? (
                <>
                  <div
                    className="scrollBluePrimary"
                    style={{
                      height: "fit-content",
                      overflowY: "auto",
                      width: "fit-content",
                      minWidth: "100%",
                      position: "relative",
                      // background: "rgba(251, 224, 191, 1)",
                    }}>
                    {array.map(
                      (singleDate, i) =>
                        singleDate && (
                          <div
                            key={`${i}tal`}
                            className="d-flex width-100 d-flex-column">
                            <div className="d-flex width-100 d-flex-column justifySE">
                              <div
                                className="d-flex width-100 justifySE dividerBorder"
                                style={{
                                  backgroundColor:
                                    colorArr && colorArr[i % colorArr.length],
                                  height: edit === "checkBox" ? "44px" : "35px",
                                  color:
                                    edit === "checkBox"
                                      ? selectedPVItemArray.indexOf(
                                          singleDate.po_item_id
                                        ) !== -1
                                        ? "black"
                                        : "grey"
                                      : "black",
                                  // (selectedPVItemArray.indexOf(singleDate.po_item_id) !== -1)?"black":"grey"
                                }}>
                                {edit === "checkBox" ? (
                                  <div
                                    className="d-flex alignC justifyC minWidthFC"
                                    style={{
                                      margin: "0 10px",
                                      width: "65px",
                                      // minWidth: '65px',
                                      textTransform: "capitalize",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const errorMsg = this.state.errorMsg;
                                      delete errorMsg.poItemsArrayTOPreview;
                                      const currentIndex =
                                        selectedPVItemArray.indexOf(
                                          singleDate.po_item_id
                                        );
                                      const newChecked = [
                                        ...selectedPVItemArray,
                                      ];

                                      if (currentIndex === -1) {
                                        newChecked.push(singleDate.po_item_id);
                                      } else {
                                        newChecked.splice(currentIndex, 1);
                                      }

                                      const totalBag = [];
                                      singleDate["packagageArrUsed"].map(
                                        (sData) => {
                                          if (
                                            sData.remaining_bags &&
                                            sData.used_bags
                                          ) {
                                            totalBag.push(sData);
                                          } else {
                                          }
                                        }
                                      );
                                      const errors = {};
                                      const arrayOfPrevState = [
                                        "Gross Weight",
                                        "Tare Weight",
                                        "Net Weight",
                                        "Miller Bill Number",
                                      ];
                                      arrayOfPrevState.map((ss) => {
                                        if (submittedData[ss]) {
                                        } else {
                                          errors[ss] = `Please Add ${ss}`;
                                        }
                                        return ss;
                                      });
                                      if (
                                        Object.values(errors) &&
                                        Object.values(errors).length > 0
                                      ) {
                                        this.setState({
                                          errorMsgForDispatch: {
                                            ...errorMsgForDispatch,
                                            ...errors,
                                          },
                                        });
                                      } else {
                                        this.setState({
                                          selectedPVItemArray: newChecked,
                                          errorMsg,
                                        });
                                      }
                                    }}>
                                    {errorMsg.poItemsArrayTOPreview ||
                                    (selectedPVItemArray.length === 0 &&
                                      this.state.addbagError === true) ? (
                                      <Checkbox
                                        style={{
                                          margin: "0 0 0 10px",
                                          border: "1px solid red",
                                        }}
                                        edge="start"
                                        checked={
                                          selectedPVItemArray.indexOf(
                                            singleDate.po_item_id
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby":
                                            singleDate.po_item_id,
                                        }}
                                      />
                                    ) : (
                                      <Checkbox
                                        style={{ margin: "0 0 0 10px" }}
                                        edge="start"
                                        checked={
                                          selectedPVItemArray.indexOf(
                                            singleDate.po_item_id
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby":
                                            singleDate.po_item_id,
                                        }}
                                      />
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {poItemheader &&
                                  poItemheader.map((name) =>
                                    noTotalQuantity &&
                                    name === "Total Item Quantity" ? (
                                      ""
                                    ) : (
                                      <div
                                        key={name}
                                        className="d-flex alignC justifyC minWidthFC"
                                        style={{
                                          margin: "12px 10px",
                                          width:
                                            widthList && widthList[name]
                                              ? name === "Specifications"
                                                ? "180px"
                                                : name === "Packaging" && addBag
                                                ? `222.08px`
                                                : name === "Packaging"
                                                ? "135.49px"
                                                : widthList[name]
                                              : "fit-content",
                                          minWidth:
                                            widthList && widthList[name]
                                              ? widthList[name]
                                              : "fit-content",
                                          textTransform: "capitalize",
                                        }}>
                                        {/* {name === "Packaging" &&
                                          //console.log(
                                            singleDate[`packagageArrUsed`],
                                            "namename"
                                          )} */}
                                        {name === "Packaging" &&
                                        singleDate[name] ? (
                                          <ShowPackaging
                                            addBag={addBag}
                                            details={
                                              dispatchTab &&
                                              !this.state.editingDispatch
                                                ? singleDate[
                                                    `packagageArrUsed`
                                                  ] &&
                                                  singleDate[`packagageArrUsed`]
                                                    .length > 0
                                                  ? singleDate[
                                                      `packagageArrUsed`
                                                    ].map((sP) => {
                                                      sP.new_remaining_bags =
                                                        Number(
                                                          sP.remaining_bags
                                                        ) -
                                                        Number(sP.used_bags);

                                                      // Number(
                                                      //   sP.remaining_bags
                                                      // ) - Number(sP.used_bags);

                                                      // this.state.editingDispatch !== false
                                                      //   ? sP.used_bags
                                                      //     ? sP.used_bags
                                                      //     : 0
                                                      //   0
                                                      // );

                                                      //                         this.state[`${singlePackaging.id}Remaining`]
                                                      // ? subtract(
                                                      //     this.state[`${singlePackaging.id}Remaining`],
                                                      //     singlePackaging.used_bags ? singlePackaging.used_bags : 0
                                                      //   )
                                                      // : singlePackaging.remaining_bags
                                                      // ? singlePackaging.remaining_bags
                                                      //   ? Number(singlePackaging.remaining_bags)
                                                      //   : "NA"
                                                      // : "NA"
                                                      if (edit === "checkBox") {
                                                      } else {
                                                        sP.bag_type =
                                                          sP.bag_type
                                                            ? sP.bag_type
                                                            : filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                            ? filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                                .packaging_details
                                                                .bag_type &&
                                                              filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                                .packaging_details
                                                                .bag_type
                                                            : "";

                                                        sP.used_bags = fromPO
                                                          ? sP.used_bags_po
                                                          : sP.used_bags
                                                          ? sP.used_bags
                                                          : sP.used_bags_po
                                                          ? sP.used_bags_po
                                                          : 0;
                                                      }
                                                      return sP;
                                                    })
                                                  : []
                                                : dispatchTab &&
                                                  this.state.editingDispatch
                                                ? singleDate[
                                                    `packagageArrUsed`
                                                  ] &&
                                                  singleDate[`packagageArrUsed`]
                                                    .length > 0
                                                  ? singleDate[
                                                      `packagageArrUsed`
                                                    ].map((sP) => {
                                                      sP.new_remaining_bags =
                                                        Number(
                                                          sP.remaining_bags
                                                        ) -
                                                        Number(sP.used_bags) +
                                                        Number(
                                                          sP.used_bags_fordiapatch
                                                        );

                                                      // Number(
                                                      //   sP.remaining_bags
                                                      // ) - Number(sP.used_bags);

                                                      // this.state.editingDispatch !== false
                                                      //   ? sP.used_bags
                                                      //     ? sP.used_bags
                                                      //     : 0
                                                      //   0
                                                      // );

                                                      //                         this.state[`${singlePackaging.id}Remaining`]
                                                      // ? subtract(
                                                      //     this.state[`${singlePackaging.id}Remaining`],
                                                      //     singlePackaging.used_bags ? singlePackaging.used_bags : 0
                                                      //   )
                                                      // : singlePackaging.remaining_bags
                                                      // ? singlePackaging.remaining_bags
                                                      //   ? Number(singlePackaging.remaining_bags)
                                                      //   : "NA"
                                                      // : "NA"
                                                      if (edit === "checkBox") {
                                                      } else {
                                                        sP.bag_type =
                                                          sP.bag_type
                                                            ? sP.bag_type
                                                            : filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                            ? filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                                .packaging_details
                                                                .bag_type &&
                                                              filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                                .packaging_details
                                                                .bag_type
                                                            : "";

                                                        sP.used_bags = fromPO
                                                          ? sP.used_bags_po
                                                          : sP.used_bags
                                                          ? sP.used_bags
                                                          : sP.used_bags_po
                                                          ? sP.used_bags_po
                                                          : 0;
                                                      }
                                                      return sP;
                                                    })
                                                  : []
                                                : singleDate[
                                                    `packagageArrUsed`
                                                  ] &&
                                                  singleDate[`packagageArrUsed`]
                                                    .length > 0
                                                ? singleDate[
                                                    `packagageArrUsed`
                                                  ].map((sP) => {
                                                    sP.new_remaining_bags =
                                                      sP.remaining_bags;

                                                    // Number(
                                                    //   sP.remaining_bags
                                                    // ) - Number(sP.used_bags);

                                                    // this.state.editingDispatch !== false
                                                    //   ? sP.used_bags
                                                    //     ? sP.used_bags
                                                    //     : 0
                                                    //   0
                                                    // );

                                                    //                         this.state[`${singlePackaging.id}Remaining`]
                                                    // ? subtract(
                                                    //     this.state[`${singlePackaging.id}Remaining`],
                                                    //     singlePackaging.used_bags ? singlePackaging.used_bags : 0
                                                    //   )
                                                    // : singlePackaging.remaining_bags
                                                    // ? singlePackaging.remaining_bags
                                                    //   ? Number(singlePackaging.remaining_bags)
                                                    //   : "NA"
                                                    // : "NA"
                                                    if (edit === "checkBox") {
                                                    } else {
                                                      sP.bag_type = sP.bag_type
                                                        ? sP.bag_type
                                                        : filterData(
                                                            singleDate.packagingArr,
                                                            "packaging",
                                                            sP.packaging,
                                                            "onlyOne"
                                                          )
                                                        ? filterData(
                                                            singleDate.packagingArr,
                                                            "packaging",
                                                            sP.packaging,
                                                            "onlyOne"
                                                          ).packaging_details
                                                            .bag_type &&
                                                          filterData(
                                                            singleDate.packagingArr,
                                                            "packaging",
                                                            sP.packaging,
                                                            "onlyOne"
                                                          ).packaging_details
                                                            .bag_type
                                                        : "";

                                                      sP.used_bags = fromPO
                                                        ? sP.used_bags_po
                                                        : sP.used_bags
                                                        ? sP.used_bags
                                                        : sP.used_bags_po
                                                        ? sP.used_bags_po
                                                        : 0;
                                                    }
                                                    return sP;
                                                  })
                                                : []
                                            }
                                          />
                                        ) : name === "Specifications" &&
                                          singleDate[name] ? (
                                          <ShowSpecifications
                                            Unit={
                                              Unit
                                                ? Unit
                                                : singlelistView &&
                                                  singlelistView.unit
                                                ? singlelistView.unit
                                                : ""
                                            }
                                            details={
                                              singleDate[name] &&
                                              singleDate[name].length > 0
                                                ? singleDate[name].map((sP) => {
                                                    // sP.used_bags =0
                                                    return sP;
                                                  })
                                                : []
                                            }
                                          />
                                        ) : singleDate[name] ? (
                                          singleDate[`${name}Unit`] ? (
                                            singleDate[`${name}Unit`]
                                          ) : name === "Item Type" ? (
                                            `${singleDate[name]} `
                                          ) : (
                                            singleDate[name]
                                          )
                                        ) : (
                                          "-"
                                        )}

                                        {addBag === "showbagOnly" ? (
                                          ""
                                        ) : name === "Packaging" && addBag ? (
                                          <StyledButton
                                            onClick={() => {
                                              this.setState({
                                                addbagError: false,
                                              });
                                              const newData =
                                                submittedDataOfUsedBags;
                                              newData[singleDate.po_item_id] =
                                                singleDate["packagageArrUsed"]
                                                  ? singleDate[
                                                      "packagageArrUsed"
                                                    ]
                                                  : [];
                                              const totalBag = [];
                                              singleDate[
                                                "packagageArrUsed"
                                              ].map((sData) => {
                                                if (sData.remaining_bags) {
                                                  this.setState({
                                                    [`${sData.id}Remaining`]:
                                                      this.state
                                                        .editingDispatch !==
                                                      false
                                                        ? sum([
                                                            sData.remaining_bags,
                                                            sData.used_bags_fordiapatch,
                                                          ])
                                                        : sData.remaining_bags,
                                                  });
                                                  totalBag.push(sData);
                                                } else {
                                                }
                                                return sData;
                                              });
                                              if (totalBag.length > 0) {
                                                this.setState({
                                                  enteredDispatchdData:
                                                    submittedData,
                                                  openAddBagDialog: singleDate,
                                                  submittedDataOfUsedBags:
                                                    newData,
                                                });
                                              } else {
                                                this.setState({
                                                  error:
                                                    "Please allot respective rice bags to this miller!",
                                                });
                                              }
                                            }}
                                            disabled={
                                              selectedPVItemArray.indexOf(
                                                singleDate.po_item_id
                                              ) !== -1
                                                ? false
                                                : true
                                            }
                                            noColorWhenDisable={true}
                                            name={
                                              addBag === "showbagOnly" ? (
                                                "aaaaaaaaaaaaa"
                                              ) : (
                                                <span
                                                  style={{
                                                    color:
                                                      selectedPVItemArray.indexOf(
                                                        singleDate.po_item_id
                                                      ) !== -1
                                                        ? this.state
                                                            .addbagError ===
                                                          true
                                                          ? red
                                                          : primary
                                                        : "grey",
                                                  }}>
                                                  add bags
                                                </span>
                                              )
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    )
                                  )}
                                {edit && edit !== "checkBox" && (
                                  <div
                                    className="d-flex alignC justifyC minWidthFC"
                                    style={{
                                      margin: "12px 10px",
                                      width: "65px",
                                      minWidth: "50px",
                                      textTransform: "capitalize",
                                    }}>
                                    <StyledButton
                                      tooltip="Edit"
                                      name={<EditIcon />}
                                      width="60px"
                                      onClick={() => {
                                        if (editFunct) {
                                          editFunct(singleDate);
                                        } else {
                                          const newArr = [];
                                          poItemsArrayCopy.map((singleItem) => {
                                            if (
                                              singleItem.po_item_id ===
                                              singleDate.po_item_id
                                            ) {
                                            } else {
                                              newArr.push(singleItem);
                                            }
                                            return singleItem;
                                          });

                                          this.setState(
                                            {
                                              selectedPoid:
                                                singleDate.po_item_id,
                                              itemsId:
                                                singleDate["Item Typeid"],
                                              ItemType: singleDate["Item Type"],
                                              ItemCategoryId:
                                                singleDate["Item Categoryid"],
                                              ItemCategory:
                                                singleDate["Item Category"],
                                              packagingArr:
                                                singleDate.packagingArr,
                                              poItemsArray: newArr,
                                              poItemsArrayCopy:
                                                poItemsArrayCopy,
                                              BrandNameId:
                                                singleDate["Brandid"],
                                              BrandName: singleDate["Brand"],
                                              HSN_id: singleDate.hsn_code,
                                              HSN: singleDate["HSN Code"],
                                              total_quantity:
                                                singleDate[
                                                  "Total Item Quantity"
                                                ],
                                              Rate: singleDate["Contract Rate"],
                                              selectedBrandMultipleName: `${
                                                singleDate["Brand"]
                                              }${
                                                singleDate["Brand"] ? "-" : ""
                                              }${singleDate["Item Category"]}-${
                                                singleDate["Item Type"]
                                              } % `,
                                              // total_quantity:singleDate.total_quantity
                                            },
                                            () => {}
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              </div>

                              {/* <Divider className="width-100" /> */}
                            </div>

                            {/* <Divider /> */}
                          </div>
                        )
                    )}
                  </div>
                  {showTotal && (
                    <div className="d-flex alignC justifySE width-100">
                      {edit === "checkBox" ? (
                        <div
                          className="justifyC d-flex alignC"
                          style={{
                            margin: "0px 10px",
                            width: "65px",
                            minWidth: "65px",
                            display: "flex",
                            alignItems: "center",
                            color: "#100f0f",
                            textTransform: "capitalize",
                          }}>
                          {selectedPVItemArray.length === 0
                            ? ""
                            : selectedPVItemArray.length}
                        </div>
                      ) : (
                        ""
                      )}
                      {poItemheader &&
                        poItemheader.map((name, i) =>
                          noTotalQuantity && name === "Total Item Quantity" ? (
                            ""
                          ) : (
                            <div
                              key={name}
                              className="justifyC d-flex alignC"
                              style={{
                                margin: "12px 10px",
                                width:
                                  widthList && widthList[name]
                                    ? name === "Specifications"
                                      ? "180px"
                                      : name === "Packaging" && addBag
                                      ? `222.08px`
                                      : name === "Packaging"
                                      ? "135.49px"
                                      : widthList[name]
                                    : "fit-content",
                                minWidth:
                                  widthList && widthList[name]
                                    ? name === "Specifications"
                                      ? "180px"
                                      : name === "Packaging" && addBag
                                      ? `222.08px`
                                      : name === "Packaging"
                                      ? "135.49px"
                                      : widthList[name]
                                    : "fit-content",
                                display: "flex",
                                alignItems: "center",
                                color: "#100f0f",
                                textTransform: "capitalize",
                                flexDirection:
                                  name === "Packaging" && addBag && "column",
                              }}>
                              {name === "Packaging" && addBag ? (
                                <>
                                  {/* {//console.log(submittedData,"submittedData")} */}
                                  {`${
                                    submittedData["dispatch_weight"]
                                      ? Number(submittedData["dispatch_weight"])
                                      : submittedData["Dispatch Weight"]
                                      ? Number(submittedData["Dispatch Weight"])
                                      : selectedPVItemArray
                                          .reduce(
                                            (previousValue, currentValue) =>
                                              Number(previousValue) +
                                              Number(
                                                filterData(
                                                  array,
                                                  "po_item_id",
                                                  currentValue,
                                                  "onlyOne"
                                                )
                                                  ? filterData(
                                                      array,
                                                      "po_item_id",
                                                      currentValue,
                                                      "onlyOne"
                                                    )["packagageArrUsed"]
                                                    ? filterData(
                                                        array,
                                                        "po_item_id",
                                                        currentValue,
                                                        "onlyOne"
                                                      )[
                                                        "packagageArrUsed"
                                                      ].reduce(
                                                        (
                                                          previousValue1,
                                                          currentValue1
                                                        ) =>
                                                          Number(
                                                            previousValue1
                                                          ) +
                                                          (currentValue1.total_dispatch
                                                            ? Number(
                                                                currentValue1.total_dispatch
                                                              )
                                                            : 0),
                                                        0
                                                      )
                                                    : 0
                                                  : 0
                                              ),
                                            0
                                          )
                                          .toFixed(3)
                                  }${
                                    singlelistView && singlelistView.unit
                                      ? singlelistView.unit
                                      : ""
                                  }   `}
                                  <div
                                    style={{
                                      color: "#FF3D00",
                                      minWidth: "334px",
                                    }}>
                                    {Number(submittedData["Net Weight"]) ===
                                    (submittedData["dispatch_weight"]
                                      ? Number(submittedData["dispatch_weight"])
                                      : submittedData["Dispatch Weight"]
                                      ? Number(submittedData["Dispatch Weight"])
                                      : selectedPVItemArray.reduce(
                                          (previousValue, currentValue) =>
                                            Number(previousValue) +
                                            Number(
                                              filterData(
                                                array,
                                                "po_item_id",
                                                currentValue,
                                                "onlyOne"
                                              )
                                                ? filterData(
                                                    array,
                                                    "po_item_id",
                                                    currentValue,
                                                    "onlyOne"
                                                  )["packagageArrUsed"]
                                                  ? filterData(
                                                      array,
                                                      "po_item_id",
                                                      currentValue,
                                                      "onlyOne"
                                                    )[
                                                      "packagageArrUsed"
                                                    ].reduce(
                                                      (
                                                        previousValue1,
                                                        currentValue1
                                                      ) =>
                                                        Number(previousValue1) +
                                                        (currentValue1.total_dispatch
                                                          ? Number(
                                                              currentValue1.total_dispatch
                                                            )
                                                          : 0),
                                                      0
                                                    )
                                                  : 0
                                                : 0
                                            ),
                                          0
                                        ))
                                      ? ""
                                      : selectedPVItemArray.length > 0
                                      ? // singlelistView &&
                                        //   singlelistView.contract_type_details &&
                                        //   singlelistView.contract_type_details
                                        //     .contract_type === "EX FACTORY"
                                        //   ?
                                        "(Net Weight and Total Quantity is not same!)"
                                      : // : ""
                                        ""}
                                  </div>
                                  {/* { 
                                    selectedPVItemArray.reduce(
                                      (previousValue, currentValue) =>
                                        Number(previousValue) +
                                        Number(
                                          filterData(
                                            array,
                                            "po_item_id",
                                            currentValue,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                array,
                                                "po_item_id",
                                                currentValue,
                                                "onlyOne"
                                              )["packagageArrUsed"]
                                              ? filterData(
                                                  array,
                                                  "po_item_id",
                                                  currentValue,
                                                  "onlyOne"
                                                )["packagageArrUsed"].reduce(
                                                  (
                                                    previousValue1,
                                                    currentValue1
                                                  ) =>
                                                    Number(previousValue1) +
                                                    (currentValue1.total_dispatch
                                                      ? Number(
                                                          currentValue1.total_dispatch
                                                        )
                                                      : 0),
                                                  0
                                                )
                                              : 0
                                            : 0
                                        ),
                                      0
                                    )
                               ,"fffffffffffffffffffff")} */}
                                </>
                              ) : i === 0 ? (
                                <span style={{ fontWeight: "500" }}>
                                  {"Total"}
                                </span>
                              ) : i === 5 ? (
                                `${array.reduce(
                                  (previousValue1, currentValue1) =>
                                    Number(previousValue1) +
                                    (currentValue1["Total Item Quantity"]
                                      ? Number(
                                          currentValue1["Total Item Quantity"]
                                        )
                                      : 0),
                                  0
                                )} ${Unit} `
                              ) : (
                                ""
                              )}
                            </div>
                          )
                        )}
                      {edit === "checkBox"
                        ? ""
                        : edit && (
                            <div
                              className="justifyC d-flex alignC"
                              style={{
                                margin: "12px 10px",
                                width: "65px",
                                display: "flex",
                                alignItems: "center",
                                color: "#100f0f",
                                textTransform: "capitalize",
                              }}></div>
                          )}
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                  <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
                    No PO Item added !
                  </span>
                </div>
              )}
            </Paper>
          </div>
        </>
      );
    };
    const SurvayITemTable = (
      edit,
      array,
      noTotalQuantity,
      showTotal,
      width,
      addBag,
      submittedData
    ) => {
      return (
        <div
          style={{
            margin: "20px 0 20px 0",
            height: "auto",
            width: width ? width : edit ? "100%" : "calc(100% - 30px)",
            minWidth: width && "100%",
            fontSize: "0.9rem",
          }}>
          <Paper
            className="d-flex d-flex-column  overflowXA positionR scrollBluePrimary"
            style={{
              height: "auto",
              width: "100%",
              borderRadius: "7px",
              marginBottom: "10px",
            }}>
            <div
              style={{
                backgroundColor: "rgba(251, 224, 191, 1)",
                color: primary,
                borderColor: primary,
                width: "fit-content",
                minWidth: "100%",
                tableLayout: "fixed",
                display: "flex",
                alignItems: "center",
                height: "30px",
                minHeight: "30px",
                overflow: "hidden",
                borderRadius: "7px 7px 0 0",
              }}>
              <div
                className="d-flex alignC justifySE width-100"
                style={{ background: "rgb(242, 242, 242)" }}>
                {survayItemheader &&
                  survayItemheader.map((name, i) =>
                    noTotalQuantity && name === "Total Item Quantity" ? (
                      ""
                    ) : (
                      <div
                        key={name}
                        className="justifyC d-flex alignC"
                        style={{
                          margin: "12px 10px",
                          width:
                            widthList && widthList[survayItemheader[i]]
                              ? widthList[survayItemheader[i]]
                              : "fit-content",
                          minWidth: "fit-content",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}>
                        {name}
                      </div>
                    )
                  )}
                {edit && (
                  <div
                    className="justifyC d-flex alignC"
                    style={{
                      margin: "12px 10px",
                      width: "65px",
                      display: "flex",
                      alignItems: "center",
                      color: "#100f0f",
                      textTransform: "capitalize",
                    }}>
                    Edit
                  </div>
                )}
              </div>
            </div>
            {array && array.length > 0 ? (
              <>
                <div
                  className="scrollBluePrimary"
                  style={{
                    height: "fit-content",
                    overflowY: "auto",
                    width: "fit-content",
                    minWidth: "100%",
                    position: "relative",
                    background: "rgba(251, 224, 191, 1)",
                  }}>
                  {array.map(
                    (singleDate, i) =>
                      singleDate && (
                        <>
                          <div className="d-flex width-100 d-flex-column justifySE">
                            <div
                              className="d-flex width-100 justifySE"
                              style={{
                                // margin: "1px 0 0 0",
                                backgroundColor:
                                  colorArr && colorArr[i % colorArr.length],
                                height: edit === "checkBox" ? "44px" : "35px",
                              }}>
                              {survayItemheader &&
                                survayItemheader.map((name) =>
                                  noTotalQuantity &&
                                  name === "Total Item Quantity" ? (
                                    ""
                                  ) : (
                                    <div
                                      key={name}
                                      className="d-flex alignC justifyC minWidthFC"
                                      style={{
                                        margin: "12px 10px",
                                        width:
                                          widthList && widthList[name]
                                            ? widthList[name]
                                            : "fit-content",
                                        minWidth:
                                          widthList && widthList[name]
                                            ? widthList[name]
                                            : "fit-content",
                                        textTransform: "capitalize",
                                      }}>
                                      {singleDate[name]
                                        ? name === "Item Type"
                                          ? `${singleDate[name]} %`
                                          : singleDate[`${name}Unit`]
                                          ? singleDate[`${name}Unit`]
                                          : singleDate[name]
                                        : "-"}
                                    </div>
                                  )
                                )}
                              {edit && (
                                <div
                                  className="d-flex alignC justifyC minWidthFC"
                                  style={{
                                    margin: "12px 10px",
                                    width: "65px",
                                    minWidth: "50px",
                                    textTransform: "capitalize",
                                  }}>
                                  <StyledButton
                                    tooltip="Edit"
                                    name={<EditIcon />}
                                    width="60px"
                                    onClick={() => {
                                      const newArr = [];

                                      surveyorItemArrayCopy.map(
                                        (singleItem) => {
                                          if (singleItem.id === singleDate.id) {
                                          } else {
                                            newArr.push(singleItem);
                                          }
                                          return singleItem;
                                        }
                                      );
                                      const selectedSurveyor = filterData(
                                        allsurveyorList,
                                        "id",
                                        singleDate["Surveyorid"],
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            allsurveyorList,
                                            "id",
                                            singleDate["Surveyorid"],
                                            "onlyOne"
                                          )
                                        : "";
                                      const selectedSurveyorCompany =
                                        filterData(
                                          SurveyCompanyList,
                                          "id",
                                          singleDate["Survey Companyid"],
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              SurveyCompanyList,
                                              "id",
                                              singleDate["Survey Companyid"],
                                              "onlyOne"
                                            )
                                          : "";

                                      if (edit === "editSurvey") {
                                        singleDate["survayorCompanyId"] =
                                          selectedSurveyorCompany
                                            ? selectedSurveyorCompany.id
                                            : "";

                                        singleDate["survayorCompany"] =
                                          selectedSurveyorCompany
                                            ? selectedSurveyorCompany.name
                                            : "";
                                      }
                                      this.props
                                        .PurchaseOrdersFunction(
                                          "get",
                                          "masters/surveyor/list",
                                          null,
                                          "",
                                          null,
                                          {
                                            is_active: "True",
                                            company_id:
                                              singleDate["survayorCompanyId"],
                                          }
                                        )
                                        .then((res) => {
                                          if (res) {
                                            this.setState({
                                              survayorListForSurvay: res,
                                            });
                                          }
                                        });
                                      var speci = filterData(
                                        PO_Items_List,
                                        "id",
                                        Number(singleDate.po_item),
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            PO_Items_List,
                                            "id",
                                            Number(singleDate.po_item),
                                            "onlyOne"
                                          ).specifications
                                        : "";

                                      // var brokenC,
                                      //   moistureC,
                                      //   chalkyC,
                                      //   damagedC = "";

                                      speci.map((item) => {
                                        if (item.condition === "equal_to") {
                                          item.condition = "Only";
                                        }
                                        if (item.condition === "not_equal_to") {
                                          item.condition = "Not";
                                        }
                                        if (item.condition === "greater_than") {
                                          item.condition = "Above";
                                        }
                                        if (
                                          item.condition ===
                                          "greater_than_or_equal_to"
                                        ) {
                                          item.condition = "Minimum";
                                        }
                                        if (item.condition === "less_than") {
                                          item.condition = "Below";
                                        }
                                        if (
                                          item.condition ===
                                          "less_than_or_equal_to"
                                        ) {
                                          item.condition = "Maximum";
                                        }
                                        item.name = item.name.toLowerCase();

                                        if (item.name === "moisture") {
                                          this.setState({
                                            moistureCondition: item.condition,
                                            moistureValue: item.value,
                                          });
                                          // moistureC =
                                          //   item.condition + " " + item.value;
                                        }
                                        if (item.name === "broken") {
                                          this.setState({
                                            brokenCondition: item.condition,
                                            brokenValue: item.value,
                                          });
                                        }
                                        if (item.name === "chalky") {
                                          this.setState({
                                            chalkyCondition: item.condition,
                                            chalkyValue: item.value,
                                          });
                                          // chalkyC =
                                          //   item.condition + " " + item.value;
                                        }
                                        if (item.name === "damaged/coloured") {
                                          this.setState({
                                            damagedCondition: item.condition,
                                            damagedValue: item.value,
                                          });

                                          // damagedC =
                                          //   item.condition + " " + item.value;
                                        }
                                        if (item.name === "double polish") {
                                          this.setState({
                                            doubleCondition: item.condition,
                                            doubleValue: item.value,
                                          });
                                        }
                                        if (item.name === "length") {
                                          this.setState({
                                            lengthCondition: item.condition,
                                            lengthValue: item.value,
                                          });
                                        }
                                        if (item.name === "paddy") {
                                          this.setState({
                                            paddyCondition: item.condition,
                                            paddyValue: item.value,
                                          });
                                        }
                                        if (item.name === "foreign matter") {
                                          this.setState({
                                            foreignCondition: item.condition,
                                            foreignValue: item.value,
                                          });
                                        }
                                      });
                                      const brandSelected = filterData(
                                        poItemsArrayTOPreview,
                                        "id",
                                        Number(singleDate.po_item),
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            poItemsArrayTOPreview,
                                            "id",
                                            Number(singleDate.po_item),
                                            "onlyOne"
                                          )
                                        : "";

                                      this.setState(
                                        {
                                          selectedSrvayorid: singleDate.id,
                                          selectedPoIdinSiurvayor:
                                            singleDate.po_item,
                                          itemsId: singleDate["Item Typeid"],
                                          ItemType: singleDate["Item Type"],
                                          ItemCategoryId:
                                            singleDate["Item Categoryid"],
                                          ItemCategory:
                                            singleDate["Item Category"],
                                          surveyorItemArray: newArr,
                                          surveyorItemArrayCopy:
                                            surveyorItemArrayCopy,
                                          BrandNameId: singleDate["Brandid"],
                                          BrandName: singleDate["Brand"],
                                          Broken: singleDate["Broken"],
                                          Moisture: singleDate["Moisture"],

                                          Length: singleDate["Length"],
                                          Damaged:
                                            singleDate["Damaged/Discoloured"],
                                          Chalky: singleDate["Chalky"],
                                          Paddy: singleDate["Paddy"],
                                          Foreign: singleDate["Foreign Matter"],
                                          Double: singleDate["Double Polish"],
                                          REMARK: singleDate["REMARK"],
                                          survayor: singleDate["Surveyor"],
                                          survayorId: singleDate["Surveyorid"],
                                          survayorCompany:
                                            singleDate["survayorCompany"],
                                          survayorCompanyId:
                                            singleDate["survayorCompanyId"],
                                          // SamplingDate:["Sampling Date"],

                                          selectedBrandMultipleName: `${
                                            singleDate["Brand"]
                                          }${singleDate["Brand"] ? "-" : ""}${
                                            singleDate["Item Category"]
                                          }-${singleDate["Item Type"]} % `, // -${brandSelected["Total QuantityUnit"]}`,
                                        },
                                        () => {}
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            <Divider className="width-100" />
                          </div>

                          {/* <Divider /> */}
                        </>
                      )
                  )}
                </div>
                {/* {showTotal && (
                  <div className="d-flex alignC width-100">
                    {survayItemheader &&
                      survayItemheader.map((name, i) => (
                        <div
                          key={name}
                          className=" d-flex justifyC alignC"
                          style={{
                            margin: "12px 10px",
                            width:
                              widthList && widthList[survayItemheader[i]]
                                ? widthList[survayItemheader[i]]
                                : "fit-content",
                            minWidth:
                              widthList && widthList[survayItemheader[i]]
                                ? widthList[survayItemheader[i]]
                                : "fit-content",
                            color: "#100f0f",
                            textTransform: "capitalize",
                          }}
                        >
                          {i === 0 ? (
                            <div style={{ fontWeight: "500" }}>Total</div>
                          ) : i === 1 ||
                            i === 2 ||
                            i === 9 ||
                            i === 10 ||
                            i === survayItemheader.length - 1 ||
                            i === survayItemheader.length - 2 ? (
                            " "
                          ) : (
                            `${array
                              .reduce(
                                (previousValue, currentValue) =>
                                  Number(previousValue) +
                                  Number(
                                    currentValue[name] ? currentValue[name] : 0
                                  ),
                                0
                              )
                              .toFixed(2)}${
                              name === "Paddy"
                                ? `/${
                                    singlelistView && singlelistView.unit
                                      ? singlelistView.unit
                                      : ""
                                  }`
                                : ""
                            }`
                          )}
                        </div>
                      ))}
                    {edit && edit !== "checkBox" && (
                      <div
                        className="d-flex alignC justifyC minWidthFC"
                        style={{
                          margin: "12px 10px",
                          width: "65px",
                          minWidth: "50px",
                          textTransform: "capitalize",
                        }}
                      ></div>
                    )}
                  </div>
                )} */}
              </>
            ) : (
              <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
                  No Survey Item added !
                </span>
              </div>
            )}
          </Paper>

          {(edit === "editSurvey" &&
            surveyorItemArrayCopy.length > surveyorItemArray.length) ||
          edit !== "editSurvey" ? (
            <>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {/* <div style={{ marginTop: "25px" }}> */}
                <div
                  style={{
                    width: "calc(25% - 20px)",
                    margin: "10px 10px 0px 10px",
                  }}>
                  {labelText("Brand/Category/Type")}
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    value={
                      selectedBrandMultipleName !== false
                        ? selectedBrandMultipleName
                        : ""
                    }
                    error={errorMsg.BrandName ? true : false}
                    helperText={errorMsg.BrandName ? errorMsg.BrandName : ""}
                    onChange={(e) => {
                      this.setState({
                        moistureCondition: "",
                        brokenCondition: "",
                        foreignCondition: "",
                        doubleCondition: "",
                        lengthCondition: "",
                        paddyCondition: "",
                        chalkyCondition: "",
                        damagedCondition: "",

                        moistureValue: "",
                        brokenValue: "",
                        foreignValue: "",
                        doubleValue: "",
                        lengthValue: "",
                        paddyValue: "",
                        chalkyValue: "",
                        damagedValue: "",
                      });
                      const errorMsg = this.state.errorMsg;
                      errorMsg.BrandName = false;
                      errorMsg.ItemCategory = false;
                      errorMsg.ItemType = false;
                      const brandSelected =
                        poItemsArrayTOPreview &&
                        poItemsArrayTOPreview.length > 0 &&
                        poItemsArrayTOPreview.filter(
                          (option) =>
                            option.id.toString() ===
                            e.currentTarget.id.toString()
                        );

                      var specif = filterData(
                        PO_Items_List,
                        "id",
                        Number(e.currentTarget.id),
                        "onlyOne"
                      )
                        ? filterData(
                            PO_Items_List,
                            "id",
                            Number(e.currentTarget.id),
                            "onlyOne"
                          ).specifications
                        : "";

                      // var brokenC,
                      //   moistureC,
                      //   chalkyC,
                      //   damagedC = "";

                      specif.map((item) => {
                        if (item.condition === "equal_to") {
                          item.condition = "Only";
                        }
                        if (item.condition === "not_equal_to") {
                          item.condition = "Not";
                        }
                        if (item.condition === "greater_than") {
                          item.condition = "Above";
                        }
                        if (item.condition === "greater_than_or_equal_to") {
                          item.condition = "Minimum";
                        }
                        if (item.condition === "less_than") {
                          item.condition = "Below";
                        }
                        if (item.condition === "less_than_or_equal_to") {
                          item.condition = "Maximum";
                        }
                        item.name = item.name.toLowerCase();

                        if (item.name === "moisture") {
                          this.setState({
                            moistureCondition: item.condition,
                            moistureValue: item.value,
                          });
                          // moistureC =
                          //   item.condition + " " + item.value;
                        }
                        if (item.name === "broken") {
                          this.setState({
                            brokenCondition: item.condition,
                            brokenValue: item.value,
                          });
                        }
                        if (item.name === "chalky") {
                          this.setState({
                            chalkyCondition: item.condition,
                            chalkyValue: item.value,
                          });
                          // chalkyC =
                          //   item.condition + " " + item.value;
                        }
                        if (item.name === "damaged/coloured") {
                          this.setState({
                            damagedCondition: item.condition,
                            damagedValue: item.value,
                          });

                          // damagedC =
                          //   item.condition + " " + item.value;
                        }
                        if (item.name === "double polish") {
                          this.setState({
                            doubleCondition: item.condition,
                            doubleValue: item.value,
                          });
                        }
                        if (item.name === "length") {
                          this.setState({
                            lengthCondition: item.condition,
                            lengthValue: item.value,
                          });
                        }
                        if (item.name === "paddy") {
                          this.setState({
                            paddyCondition: item.condition,
                            paddyValue: item.value,
                          });
                        }
                        if (item.name === "foreign matter") {
                          this.setState({
                            foreignCondition: item.condition,
                            foreignValue: item.value,
                          });
                        }
                      });

                      const iCName =
                        brandSelected && brandSelected[0]["Item Category"];

                      const iTname =
                        brandSelected && brandSelected[0]["Item Type"];
                      this.setState({
                        errorMsg,
                        selectedPoIdinSiurvayor: e.currentTarget.id,
                        BrandNameId:
                          brandSelected && brandSelected[0]["Brandid"],
                        BrandName: brandSelected && brandSelected[0]["Brand"],
                        ItemCategoryId:
                          brandSelected && brandSelected[0]["Item Categoryid"],
                        ItemCategory: iCName,
                        itemsId:
                          brandSelected && brandSelected[0]["Item Typeid"],
                        ItemType: iTname,
                        selectedBrandMultipleName: e.target.value,
                      });
                    }}
                    // style={
                    //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    // }
                    style={{ width: "100%" }}
                    id="outlined-select-currency-native"
                    select
                    // label="Brand/Category/Type"
                    variant="outlined"
                    InputLabelProps={{}}>
                    {
                      poItemsArrayTOPreview &&
                        poItemsArrayTOPreview.length > 0 &&
                        poItemsArrayTOPreview
                          .filter(
                            (ss) =>
                              !array?.find((s) => Number(s.po_item) === ss.id)
                          )
                          .map((poItem) => (
                            <MenuItem
                              key={poItem.Brand}
                              value={`${
                                poItem.Brand ? `${poItem.Brand}-` : ""
                              }${poItem["Item Category"]}-${
                                poItem["Item Type"]
                              } % - ${poItem["total_quantity"]} `}
                              id={poItem.id}>
                              {`${poItem.Brand ? `${poItem.Brand}-` : ""}${
                                poItem["Item Category"]
                              }-${poItem["Item Type"]} % - ${
                                poItem["total_quantity"]
                              }`}
                            </MenuItem>
                          ))
                      //  <MenuItem
                      //  key={"no list"}
                      //  value={"no list"}
                      //  id={"no list"}>{"no list"}
                      //  </MenuItem>
                    }
                  </CssTextField>
                </div>
                <div
                  style={{
                    width: "calc(25% - 20px)",
                    margin: "10px 10px 0px 10px",
                  }}>
                  {labelText("Item Category")}
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    value={
                      ItemCategory ? ItemCategory : "" //
                    }
                    error={errorMsg.ItemCategory ? true : false}
                    helperText={
                      errorMsg.ItemCategory ? errorMsg.ItemCategory : ""
                    }
                    onChange={(e) => {
                      const errorMsg = this.state.errorMsg;
                      errorMsg.ItemCategory = false;
                      this.setState({
                        errorMsg,
                        ItemCategoryId: e.currentTarget.id,
                        ItemCategory: e.target.value,
                      });
                    }}
                    // style={
                    //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    // }
                    style={{ width: "100%" }}
                    id="outlined-select-currency-native"
                    select
                    // label="Item Category"
                    variant="outlined"
                    disabled={true}
                    InputLabelProps={{}}>
                    {ItemCateoryList &&
                      ItemCateoryList.length > 0 &&
                      ItemCateoryList.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.name}
                          id={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </CssTextField>
                </div>{" "}
                <div
                  style={{
                    width: "calc(25% - 20px)",
                    margin: "10px 10px 0px 10px",
                  }}>
                  {labelText("Item Type")}
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    value={
                      ItemType ? ItemType : "" //
                    }
                    error={errorMsg.ItemType ? true : false}
                    helperText={errorMsg.ItemType ? errorMsg.ItemType : ""}
                    onChange={(e) => {
                      const errorMsg = this.state.errorMsg;
                      errorMsg.ItemType = false;
                      this.setState({
                        errorMsg,
                        itemsId: e.currentTarget.id,
                        ItemType: e.target.value,
                      });
                    }}
                    // style={
                    //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    // }
                    style={{ width: "100%" }}
                    id="outlined-select-currency-native"
                    select
                    // label="Item Type"
                    variant="outlined"
                    disabled={true}
                    InputLabelProps={{}}>
                    {ItemList &&
                      ItemList.length > 0 &&
                      ItemList.map((option) => (
                        <MenuItem
                          key={option.broken_percentage}
                          value={option.broken_percentage}
                          id={option.id}>
                          {`${option.broken_percentage} %`}
                        </MenuItem>
                      ))}
                  </CssTextField>
                </div>
                {/* <CssTextField
                  required={true}
                  type="number"
                  onFocus={StopScroll}
                  autoComplete="off"
                  style={
                    { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                  }
                  onKeyDown={(evt) => {
                    evt.key === "e" && evt.preventDefault();

                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    // evt.key === "." && evt.preventDefault();
                  }}
                  error={errorMsg.Broken ? true : false}
                  helperText={errorMsg.Broken ? errorMsg.Broken : `${ItemType}`}
                  id="outlined-basic"
                  label="Broken"
                  InputProps={{
                    endAdornment: "%",
                  }}
                  variant="outlined"
                  name="Broken"
                  value={Broken}
                  onChange={this.handleChange}
                /> */}
                {BrandNameId && brokenCondition ? (
                  <div
                    style={{
                      width: "calc(25% - 20px)",
                      margin: "10px 10px 0px 10px",
                    }}>
                    {labelText("Broken")}
                    <CssTextField
                      required={true}
                      type="number"
                      onFocus={StopScroll}
                      autoComplete="off"
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      onKeyDown={(evt) => {
                        evt.key === "e" && evt.preventDefault();

                        evt.key === "E" && evt.preventDefault();
                        evt.key === "-" && evt.preventDefault();
                        // evt.key === "." && evt.preventDefault();
                      }}
                      error={errorMsg.Broken ? true : false}
                      helperText={
                        <div
                          style={{
                            fontStyle: "italic",
                          }}>
                          {errorMsg.Broken
                            ? errorMsg.Broken
                            : brokenCondition
                            ? brokenCondition + " " + brokenValue + " %"
                            : ""}
                        </div>
                      }
                      id="outlined-basic"
                      //  label="Broken"
                      InputProps={{
                        endAdornment: "%",
                      }}
                      variant="outlined"
                      name="Broken"
                      value={Broken}
                      onChange={this.handleChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                {/* </div> */}
                {BrandNameId && moistureCondition ? (
                  <div
                    style={{
                      width: "calc(25% - 20px)",
                      margin: "10px 10px 0px 10px",
                    }}>
                    {labelText("Moisture")}
                    <CssTextField
                      required={true}
                      type="number"
                      onFocus={StopScroll}
                      autoComplete="off"
                      style={{ width: "100%" }}
                      // style={
                      //   {
                      //     width: "calc(25% - 20px)",
                      //     margin: "10px 10px 0 10px",
                      //     // fontStyle: "italic",
                      //   } //disabled={tp}
                      // }
                      onKeyDown={(evt) => {
                        evt.key === "e" && evt.preventDefault();

                        evt.key === "E" && evt.preventDefault();
                        evt.key === "-" && evt.preventDefault();
                        // evt.key === "." && evt.preventDefault();
                      }}
                      error={errorMsg.Moisture ? true : false}
                      helperText={
                        <div
                          style={{
                            fontStyle: "italic",
                          }}>
                          {errorMsg.Moisture
                            ? errorMsg.Moisture
                            : moistureCondition
                            ? moistureCondition + " " + moistureValue + " %"
                            : ""}
                        </div>
                      }
                      id="outlined-basic"
                      // label="Moisture"
                      InputProps={{
                        endAdornment: "%",
                      }}
                      variant="outlined"
                      name="Moisture"
                      value={Moisture}
                      onChange={(event) => {
                        // if (moistureCondition.length > 0) {
                        //   if (moistureCondition === "Minimum") {
                        //     if (event.target.value >= moistureValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = moistureCondition + " " + moistureValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //       // errorMsg[event.target.name] = moistureCondition + "%";
                        //     }
                        //   }
                        //   if (moistureCondition === "Maximum") {
                        //     if (event.target.value <= moistureValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = moistureCondition + " " + moistureValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //       // errorMsg[event.target.name] = moistureCondition + "%";
                        //     }
                        //   }
                        //   if (moistureCondition === "Below") {
                        //     if (event.target.value < moistureValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = moistureCondition + " " + moistureValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //       // errorMsg[event.target.name] = moistureCondition + "%";
                        //     }
                        //   }
                        //   if (moistureCondition === "Above") {
                        //     if (event.target.value > moistureValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = moistureCondition + " " + moistureValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //       // errorMsg[event.target.name] = moistureCondition + "%";
                        //     }
                        //   }
                        //   if (moistureCondition === "Only") {
                        //     if (event.target.value === moistureValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = moistureCondition + " " + moistureValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //       // errorMsg[event.target.name] = moistureCondition + "%";
                        //     }
                        //   }
                        //   if (moistureCondition === "Not") {
                        //     if (event.target.value !== moistureValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = moistureCondition + " " + moistureValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //       // errorMsg[event.target.name] = moistureCondition + "%";
                        //     }
                        //   }
                        // } else {
                        //   const errorMsg = this.state.errorMsg;
                        //   errorMsg[event.target.name] = false;
                        //   this.setState({
                        //     [event.target.name]: event.target.value,
                        //     errorMsg,
                        //   });
                        // }
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                {BrandNameId && damagedCondition ? (
                  <div
                    style={{
                      width: "calc(25% - 20px)",
                      margin: "10px 10px 0px 10px",
                    }}>
                    {labelText("Damaged/Discoloured")}
                    <CssTextField
                      required={true}
                      type="number"
                      onFocus={StopScroll}
                      autoComplete="off"
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      onKeyDown={(evt) => {
                        evt.key === "e" && evt.preventDefault();

                        evt.key === "E" && evt.preventDefault();
                        evt.key === "-" && evt.preventDefault();
                        // evt.key === "." && evt.preventDefault();
                      }}
                      error={errorMsg.Damaged ? true : false}
                      helperText={
                        <div
                          style={{
                            fontStyle: "italic",
                          }}>
                          {errorMsg.Damaged
                            ? errorMsg.Damaged
                            : damagedCondition
                            ? damagedCondition + " " + damagedValue + " %"
                            : ""}
                        </div>
                      }
                      id="outlined-basic"
                      // label="Damaged/Discoloured"
                      InputProps={{
                        endAdornment: "%",
                      }}
                      variant="outlined"
                      name="Damaged"
                      value={Damaged}
                      onChange={(event) => {}}
                      onChange={this.handleChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                {BrandNameId && chalkyCondition ? (
                  <div
                    style={{
                      width: "calc(25% - 20px)",
                      margin: "10px 10px 0px 10px",
                    }}>
                    {labelText("Chalky")}
                    <CssTextField
                      required={true}
                      type="number"
                      onFocus={StopScroll}
                      autoComplete="off"
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      onKeyDown={(evt) => {
                        evt.key === "e" && evt.preventDefault();

                        evt.key === "E" && evt.preventDefault();
                        evt.key === "-" && evt.preventDefault();
                        // evt.key === "." && evt.preventDefault();
                      }}
                      error={errorMsg.Chalky ? true : false}
                      helperText={
                        <div
                          style={{
                            fontStyle: "italic",
                          }}>
                          {errorMsg.Chalky
                            ? errorMsg.Chalky
                            : chalkyCondition
                            ? chalkyCondition + " " + chalkyValue + " %"
                            : ""}
                        </div>
                      }
                      id="outlined-basic"
                      label="Chalky"
                      InputProps={{
                        endAdornment: "%",
                      }}
                      variant="outlined"
                      name="Chalky"
                      value={Chalky}
                      onChange={(event) => {
                        // if (chalkyCondition.length > 0) {
                        //   if (chalkyCondition === "Minimum") {
                        //     if (event.target.value >= chalkyValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] =
                        //         chalkyCondition + " " + chalkyValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //     }
                        //   }
                        //   if (chalkyCondition === "Maximum") {
                        //     if (event.target.value <= chalkyValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] =
                        //         chalkyCondition + " " + chalkyValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //     }
                        //   }
                        //   if (chalkyCondition === "Below") {
                        //     if (event.target.value < chalkyValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] =
                        //         chalkyCondition + " " + chalkyValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //     }
                        //   }
                        //   if (chalkyCondition === "Above") {
                        //     if (event.target.value > chalkyValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] =
                        //         chalkyCondition + " " + chalkyValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //     }
                        //   }
                        //   if (chalkyCondition === "Only") {
                        //     if (event.target.value === chalkyValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] =
                        //         chalkyCondition + " " + chalkyValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //     }
                        //   }
                        //   if (chalkyCondition === "Not") {
                        //     if (event.target.value !== chalkyValue) {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] = false;
                        //       this.setState({
                        //         [event.target.name]: event.target.value,
                        //         errorMsg,
                        //       });
                        //     } else {
                        //       const errorMsg = this.state.errorMsg;
                        //       errorMsg[event.target.name] =
                        //         chalkyCondition + " " + chalkyValue + " %";
                        //       this.setState({
                        //         errorMsg,
                        //       });
                        //     }
                        //   }
                        // } else {
                        //   const errorMsg = this.state.errorMsg;
                        //   errorMsg[event.target.name] = false;
                        //   this.setState({
                        //     [event.target.name]: event.target.value,
                        //     errorMsg,
                        //   });
                        // }
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                {BrandNameId && lengthCondition ? (
                  <div
                    style={{
                      width: "calc(25% - 20px)",
                      margin: "10px 10px 0px 10px",
                    }}>
                    {labelText("Length")}
                    <CssTextField
                      required={true}
                      type="number"
                      onFocus={StopScroll}
                      autoComplete="off"
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      onKeyDown={(evt) => {
                        evt.key === "e" && evt.preventDefault();

                        evt.key === "E" && evt.preventDefault();
                        evt.key === "-" && evt.preventDefault();
                        // evt.key === "." && evt.preventDefault();
                      }}
                      error={errorMsg.Length ? true : false}
                      helperText={
                        <div
                          style={{
                            fontStyle: "italic",
                          }}>
                          {errorMsg.Length
                            ? errorMsg.Length
                            : lengthCondition
                            ? lengthCondition + " " + lengthValue + " mm"
                            : ""}
                        </div>
                      }
                      InputProps={{
                        endAdornment: "MM",
                      }}
                      id="outlined-basic"
                      // label="Length"
                      variant="outlined"
                      name="Length"
                      value={Length}
                      onChange={this.handleChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                {BrandNameId && paddyCondition ? (
                  <CssTextField
                    required={true}
                    type="number"
                    onFocus={StopScroll}
                    autoComplete="off"
                    style={
                      { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    }
                    error={errorMsg.Paddy ? true : false}
                    // helperText={errorMsg.Paddy ? errorMsg.Paddy : ""}

                    helperText={
                      <div
                        style={{
                          fontStyle: "italic",
                        }}>
                        {errorMsg.Paddy
                          ? errorMsg.Paddy
                          : paddyCondition
                          ? paddyCondition + " " + paddyValue
                          : // + " %"
                            ""}
                      </div>
                    }
                    id="outlined-basic"
                    label="Paddy"
                    variant="outlined"
                    name="Paddy"
                    onKeyDown={(evt) => {
                      evt.key === "-" && evt.preventDefault();
                      // evt.key === "." && evt.preventDefault();
                    }}
                    InputProps={{
                      endAdornment:
                        singlelistView && singlelistView.unit
                          ? `/${singlelistView.unit}`
                          : "",
                    }}
                    value={Paddy}
                    onChange={this.handleChange}
                  />
                ) : (
                  ""
                )}
                {BrandNameId && foreignCondition ? (
                  <CssTextField
                    required={true}
                    type="text"
                    autoComplete="off"
                    style={
                      { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    }
                    error={errorMsg.Foreign ? true : false}
                    helperText={
                      <div
                        style={{
                          fontStyle: "italic",
                        }}>
                        {errorMsg.Foreign
                          ? errorMsg.Foreign
                          : foreignCondition
                          ? foreignCondition + " " + foreignValue
                          : ""}
                      </div>
                    }
                    id="outlined-basic"
                    label="Foreign Matter"
                    variant="outlined"
                    name="Foreign"
                    value={Foreign}
                    onChange={this.handleChange}
                  />
                ) : (
                  ""
                )}
                {BrandNameId && doubleCondition ? (
                  <CssTextField
                    required={true}
                    type="text"
                    autoComplete="off"
                    style={
                      { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    }
                    inputProps={{ min: 0 }}
                    error={errorMsg.Double ? true : false}
                    helperText={
                      <div
                        style={{
                          fontStyle: "italic",
                        }}>
                        {errorMsg.Double
                          ? errorMsg.Double
                          : doubleCondition
                          ? doubleCondition + " " + doubleValue
                          : ""}
                      </div>
                    }
                    id="outlined-basic"
                    label="Double Polish"
                    variant="outlined"
                    name="Double"
                    value={Double}
                    onChange={this.handleChange}
                  />
                ) : (
                  ""
                )}
                <div
                  style={{
                    width: "calc(25% - 20px)",
                    margin: "10px 10px 0px 10px",
                  }}>
                  {labelText("Remark", true)}
                  <CssTextField
                    // required={true}
                    type="text"
                    autoComplete="off"
                    // style={
                    //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    // }
                    // error={errorMsg.REMARK ? true : false}
                    // helperText={errorMsg.REMARK ? errorMsg.REMARK : ""}
                    id="outlined-basic"
                    // label="Remark"
                    style={{ width: "100%" }}
                    variant="outlined"
                    name="REMARK"
                    value={REMARK}
                    onChange={this.handleChange}
                  />
                </div>{" "}
                <div
                  style={{
                    width: "calc(25% - 20px)",
                    margin: "10px 10px 0px 10px",
                  }}>
                  {labelText("Survey Company")}
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    value={
                      survayorCompany ? survayorCompany : "" //
                    }
                    error={errorMsg.survayorCompany ? true : false}
                    helperText={
                      errorMsg.survayorCompany ? errorMsg.survayorCompany : ""
                    }
                    onChange={(e) => {
                      this.setState({ survayorId: "", survayor: "" });
                      const errorMsg = this.state.errorMsg;
                      errorMsg.survayorCompany = false;
                      if (SurveyCompanyList && SurveyCompanyList.length > 0) {
                        this.props
                          .PurchaseOrdersFunction(
                            "get",
                            "masters/surveyor/list",
                            null,
                            "",
                            null,
                            {
                              is_active: "True",
                              company_id: e.currentTarget.id,
                            }
                          )
                          .then((res) => {
                            if (res) {
                              this.setState({ survayorListForSurvay: res });
                            }
                          });
                      }
                      this.setState(
                        {
                          errorMsg,

                          survayorCompany: e.target.value,
                          survayorCompanyId: e.currentTarget.id,
                        },
                        () => {}
                      );
                    }}
                    style={
                      { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    }
                    id="outlined-select-currency-native"
                    select
                    // label="Survey Company"
                    style={{ width: "100%" }}
                    variant="outlined"
                    InputLabelProps={{}}>
                    {SurveyCompanyList &&
                      SurveyCompanyList.length > 0 &&
                      SurveyCompanyList.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.name}
                          id={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </CssTextField>
                </div>
              </div>
              {survayorCompanyId && (
                <div
                  style={{
                    width: "calc(25% - 20px)",
                    margin: "10px 10px 0px 10px",
                  }}>
                  {labelText("Surveyor")}
                  <CssTextField
                    // required={true}
                    autoComplete="off"
                    value={
                      survayor ? survayor : "" //
                    }
                    // error={errorMsg.survayor ? true : false}
                    // helperText={errorMsg.survayor ? errorMsg.survayor : ""}
                    onChange={(e) => {
                      // const errorMsg = this.state.errorMsg;
                      // errorMsg.survayor = false;
                      this.setState({
                        // errorMsg,
                        survayor: e.target.value,
                        survayorId: e.currentTarget.id,
                      });
                    }}
                    // style={
                    //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    // }
                    style={{ width: "100%" }}
                    id="outlined-select-currency-native"
                    select
                    //label="Surveyor"
                    variant="outlined"
                    InputLabelProps={{}}>
                    {survayorListForSurvay &&
                      survayorListForSurvay.length > 0 &&
                      survayorListForSurvay.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.name}
                          id={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </CssTextField>
                </div>
              )}
              {/* <CssTextField
                required={true}
                type="number" onFocus={StopScroll}
                autoComplete="off"
                style={
                  { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                }
                onKeyDown={(evt) => {
                  evt.key === "e" && evt.preventDefault();

                  evt.key === "E" && evt.preventDefault();
                  evt.key === "-" && evt.preventDefault();
                  evt.key === "." && evt.preventDefault();
                }}
                error={errorMsg.SamplingDate ? true : false}
                helperText={errorMsg.SamplingDate ? errorMsg.SamplingDate : `${ItemType} %`}
                id="outlined-basic"
                label="Sampling Date"
            
                variant="outlined"
                name="SamplingDate"
                value={SamplingDate}
                onChange={this.handleChange}
              /> */}

              {/* <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
              <CssKeyboardDatePicker
                label={"Sampling Date"}
                variant="inline"
                onOpen={() => {
                  this.setState({ calenderOpen: true });
                }}
                onClose={() => {
                  this.setState({ calenderOpen: false });
                }}
                maxDate={new Date()}
                disableFuture
                // minDate={"2022-01-22"}
                error={errorMsg.SamplingDate ? true : false}
                helperText={errorMsg.SamplingDate ? errorMsg.SamplingDate : ""}
                invalidLabel=""
                onKeyPress={(ev) => {
                  ev.preventDefault();
                }}
                allowKeyboardControl={false}
                keyboardIcon={calenderOpen ? <TopArrow /> : <BottomArrow />}
                autoOk
                inputVariant="outlined"
                style={
                  { width: "100%", maxWidth: "100%", marginRight: "20px" } //disabled={tp}
                }
                format="do MMM, yyyy"
                value={SamplingDate ? SamplingDate : new Date()}
                //views={['year', 'month']}
                InputAdornmentProps={{ position: "end" }}
                onChange={(newValue) => {
                  this.setState({
                    SamplingDate: newValue,
                    errorMsg,
                  });
                }}
              />
            </MuiPickersUtilsProvider> */}

              <div
                //className="d-flex alignC justifyFE"
                style={{
                  margin: "10px",
                  //width: "calc(25% - 20px)",
                  minWidth: "calc(25% - 20px)",
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <StyledButton
                  name="clear"
                  onClick={() => {
                    this.setState({
                      Broken: "",
                      Moisture: "",

                      ItemCategoryId: "",
                      ItemCategory: "",
                      ItemType: "",
                      itemsId: "",
                      //SamplingDate:"",
                      BrandNameId: "",
                      BrandName: false,
                      Length: "",
                      Damaged: "",
                      Chalky: "",
                      Paddy: "",
                      Foreign: "",
                      Double: "",
                      REMARK: "",
                      survayor: "",
                      survayorId: "",
                      survayorCompany: "",
                      survayorCompanyId: "",
                      selectedBrandMultipleName: false,
                      selectedPoIdinSiurvayor: "",
                    });
                  }}
                />
                <StyledButton
                  onClick={() => {
                    let e = 0;
                    let f = 0;
                    let g = 0;
                    let h = 0;
                    let i = 0;
                    let j = 0;
                    let k = 0;
                    let l = 0;

                    if (BrandName !== false) {
                      if (moistureCondition !== false) {
                        if (Moisture) {
                          if (moistureCondition === "Minimum") {
                            if (Number(Moisture) >= Number(moistureValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture = false;

                              this.setState({
                                [Moisture]: Moisture,
                                errorMsg,
                              });
                            } else {
                              e++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture =
                                moistureCondition + " " + moistureValue + " %";
                              this.setState({
                                errorMsg,
                              });

                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (moistureCondition === "Maximum") {
                            if (Number(Moisture) <= Number(moistureValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture = false;

                              this.setState({
                                [Moisture]: Moisture,
                                errorMsg,
                              });
                            } else {
                              e++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture =
                                moistureCondition + " " + moistureValue + " %";
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (moistureCondition === "Below") {
                            if (Number(Moisture) < Number(moistureValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture = false;

                              this.setState({
                                [Moisture]: Moisture,
                                errorMsg,
                              });
                            } else {
                              e++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture =
                                moistureCondition + " " + moistureValue + " %";
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (moistureCondition === "Above") {
                            if (Number(Moisture) > Number(moistureValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture = false;

                              this.setState({
                                [Moisture]: Moisture,
                                errorMsg,
                              });
                            } else {
                              e++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture =
                                moistureCondition + " " + moistureValue + " %";
                              this.setState({
                                errorMsg,
                              });
                              //  const errorMsg = this.state.errorMsg;
                              //  errorMsg.Moisture = "Please give an Moisture!";
                              //  this.setState({ errorMsg });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (moistureCondition === "Only") {
                            if (Number(Moisture) === Number(moistureValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture = false;

                              this.setState({
                                [Moisture]: Moisture,
                                errorMsg,
                              });
                            } else {
                              e++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture =
                                moistureCondition + " " + moistureValue + " %";
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (moistureCondition === "Not") {
                            if (Number(Moisture) !== Number(moistureValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture = false;

                              this.setState({
                                [Moisture]: Moisture,
                                errorMsg,
                              });
                            } else {
                              e++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Moisture =
                                moistureCondition + " " + moistureValue + " %";
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.Moisture = "Please give Moisture!";
                          this.setState({ errorMsg });
                        }
                      }
                      if (brokenCondition !== false) {
                        if (Broken) {
                          if (brokenCondition === "Minimum") {
                            if (Number(Broken) >= Number(brokenValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken = false;

                              this.setState({
                                [Broken]: Broken,
                                errorMsg,
                              });
                            } else {
                              l++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken =
                                brokenCondition + " " + brokenValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (brokenCondition === "Maximum") {
                            if (Number(Broken) <= Number(brokenValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Brokrn = false;

                              this.setState({
                                [Broken]: Broken,
                                errorMsg,
                              });
                            } else {
                              l++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken =
                                brokenCondition + " " + brokenValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (brokenCondition === "Below") {
                            if (Number(Broken) < Number(brokenValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken = false;

                              this.setState({
                                [Broken]: Broken,
                                errorMsg,
                              });
                            } else {
                              l++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken =
                                brokenCondition + " " + brokenValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (brokenCondition === "Above") {
                            if (Number(Broken) > Number(brokenValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken = false;

                              this.setState({
                                [Broken]: Broken,
                                errorMsg,
                              });
                            } else {
                              l++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken =
                                brokenCondition + " " + brokenValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }

                          if (brokenCondition === "Only") {
                            if (Number(Broken) === Number(brokenValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken = false;

                              this.setState({
                                [Broken]: Broken,
                                errorMsg,
                              });
                            } else {
                              l++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken =
                                brokenCondition + " " + brokenValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (brokenCondition === "Not") {
                            if (Number(Broken) !== Number(brokenValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken = false;

                              this.setState({
                                [Broken]: Broken,
                                errorMsg,
                              });
                            } else {
                              l++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Broken =
                                brokenCondition + " " + brokenValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.Broken = "Please give Broken!";
                          this.setState({ errorMsg });
                        }
                      }

                      if (damagedCondition !== false) {
                        if (Damaged) {
                          if (damagedCondition === "Minimum") {
                            if (Number(Damaged) >= Number(damagedValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged = false;

                              this.setState({
                                [Damaged]: Damaged,
                                errorMsg,
                              });
                            } else {
                              f++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged =
                                damagedCondition + " " + damagedValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (damagedCondition === "Maximum") {
                            if (Number(Damaged) <= Number(damagedValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged = false;

                              this.setState({
                                [Damaged]: Damaged,
                                errorMsg,
                              });
                            } else {
                              f++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged =
                                damagedCondition + " " + damagedValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (damagedCondition === "Below") {
                            if (Number(Damaged) < Number(damagedValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged = false;

                              this.setState({
                                [Damaged]: Damaged,
                                errorMsg,
                              });
                            } else {
                              f++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged =
                                damagedCondition + " " + damagedValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (damagedCondition === "Above") {
                            if (Number(Damaged) > Number(damagedValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged = false;

                              this.setState({
                                [Damaged]: Damaged,
                                errorMsg,
                              });
                            } else {
                              f++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged =
                                damagedCondition + " " + damagedValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (damagedCondition === "Only") {
                            if (Number(Damaged) === Number(damagedValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged = false;

                              this.setState({
                                [Damaged]: Damaged,
                                errorMsg,
                              });
                            } else {
                              f++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged =
                                damagedCondition + " " + damagedValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (damagedCondition === "Not") {
                            if (Number(Damaged) !== Number(damagedValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged = false;

                              this.setState({
                                [Damaged]: Damaged,
                                errorMsg,
                              });
                            } else {
                              f++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Damaged =
                                damagedCondition + " " + damagedValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.Damaged =
                            "Please give an Damaged/Discoloured!";
                          this.setState({ errorMsg });
                        }
                      }
                      if (chalkyCondition !== false) {
                        if (Chalky) {
                          if (chalkyCondition === "Minimum") {
                            if (Number(Chalky) >= Number(chalkyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky = false;

                              this.setState({
                                [Chalky]: Chalky,
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky =
                                chalkyCondition + " " + chalkyValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (chalkyCondition === "Maximum") {
                            if (Number(Chalky) <= Number(chalkyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky = false;

                              this.setState({
                                [Chalky]: Chalky,
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky =
                                chalkyCondition + " " + chalkyValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (chalkyCondition === "Below") {
                            if (Number(Chalky) < Number(chalkyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky = false;

                              this.setState({
                                [Chalky]: Chalky,
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky =
                                chalkyCondition + " " + chalkyValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (chalkyCondition === "Above") {
                            if (Number(Chalky) > Number(chalkyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky = false;

                              this.setState({
                                [Chalky]: Chalky,
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky =
                                chalkyCondition + " " + chalkyValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (chalkyCondition === "Only") {
                            if (Number(Chalky) === Number(chalkyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky = false;

                              this.setState({
                                [Chalky]: Chalky,
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky =
                                chalkyCondition + " " + chalkyValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (chalkyCondition === "Not") {
                            if (Number(Chalky) !== Number(chalkyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky = false;

                              this.setState({
                                [Chalky]: Chalky,
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Chalky =
                                chalkyCondition + " " + chalkyValue + " %";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.Chalky = "Please give Chalky!";

                          this.setState({ errorMsg });
                        }
                      }
                      if (lengthCondition !== false) {
                        if (Length) {
                          if (lengthCondition === "Minimum") {
                            if (Number(Length) >= Number(lengthValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length = false;

                              this.setState({
                                [Length]: Length,
                                errorMsg,
                              });
                            } else {
                              h++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length =
                                lengthCondition + " " + lengthValue + "mm";
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (lengthCondition === "Maximum") {
                            if (Number(Length) <= Number(lengthValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length = false;

                              this.setState({
                                [Length]: Length,
                                errorMsg,
                              });
                            } else {
                              h++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length =
                                lengthCondition + " " + lengthValue + " %";
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (lengthCondition === "Below") {
                            if (Number(Length) < Number(lengthValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length = false;

                              this.setState({
                                [Length]: Length,
                                errorMsg,
                              });
                            } else {
                              h++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length =
                                lengthCondition + " " + lengthValue + " %";
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (lengthCondition === "Above") {
                            if (Number(Length) > Number(lengthValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length = false;

                              this.setState({
                                [Length]: Length,
                                errorMsg,
                              });
                            } else {
                              h++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length =
                                lengthCondition + " " + lengthValue + " mm";
                              this.setState({
                                errorMsg,
                              });
                              //  const errorMsg = this.state.errorMsg;
                              //  errorMsg.Moisture = "Please give an Moisture!";
                              //  this.setState({ errorMsg });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (lengthCondition === "Only") {
                            if (Number(Length) === Number(lengthValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length = false;

                              this.setState({
                                [Length]: Length,
                                errorMsg,
                              });
                            } else {
                              h++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length =
                                lengthCondition + " " + lengthValue + " mm";
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (lengthCondition === "Not") {
                            if (Number(Length) !== Number(lengthValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length = false;

                              this.setState({
                                [Length]: Length,
                                errorMsg,
                              });
                            } else {
                              h++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Length =
                                lengthCondition + " " + lengthValue + " mm";
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.Length = "Please give Length!";
                          this.setState({ errorMsg });
                        }
                      }
                      if (paddyCondition !== false) {
                        if (Paddy) {
                          if (paddyCondition === "Minimum") {
                            if (Number(Paddy) >= Number(paddyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy = false;

                              this.setState({
                                [Paddy]: Paddy,
                                errorMsg,
                              });
                            } else {
                              i++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy =
                                paddyCondition + " " + paddyValue;
                              this.setState({
                                errorMsg,
                              });

                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (paddyCondition === "Maximum") {
                            if (Number(Paddy) <= Number(paddyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy = false;

                              this.setState({
                                [Paddy]: Paddy,
                                errorMsg,
                              });
                            } else {
                              i++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy =
                                paddyCondition + " " + paddyValue + " %";
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (paddyCondition === "Below") {
                            if (Number(Paddy) < Number(paddyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy = false;

                              this.setState({
                                [Paddy]: Paddy,
                                errorMsg,
                              });
                            } else {
                              i++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy =
                                paddyCondition + " " + paddyValue;
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (paddyCondition === "Above") {
                            if (Number(Paddy) > Number(paddyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy = false;

                              this.setState({
                                [Paddy]: Paddy,
                                errorMsg,
                              });
                            } else {
                              i++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy =
                                paddyCondition + " " + paddyValue;
                              this.setState({
                                errorMsg,
                              });
                              //  const errorMsg = this.state.errorMsg;
                              //  errorMsg.Moisture = "Please give an Moisture!";
                              //  this.setState({ errorMsg });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (paddyCondition === "Only") {
                            if (Number(Paddy) === Number(paddyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy = false;

                              this.setState({
                                [Paddy]: Paddy,
                                errorMsg,
                              });
                            } else {
                              i++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy =
                                paddyCondition + " " + paddyValue;
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (paddyCondition === "Not") {
                            if (Number(Paddy) !== Number(paddyValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy = false;

                              this.setState({
                                [Paddy]: Paddy,
                                errorMsg,
                              });
                            } else {
                              i++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Paddy =
                                paddyCondition + " " + paddyValue;
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.Paddy = "Please give Paddy!";
                          this.setState({
                            errorMsg,
                          });
                        }
                      }
                      if (foreignCondition !== false) {
                        if (Foreign) {
                          if (foreignCondition === "Minimum") {
                            if (Number(Foreign) >= Number(foreignValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign = false;

                              this.setState({
                                [Foreign]: Foreign,
                                errorMsg,
                              });
                            } else {
                              j++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign =
                                foreignCondition + " " + foreignValue;
                              this.setState({
                                errorMsg,
                              });

                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (foreignCondition === "Maximum") {
                            if (Number(Foreign) <= Number(foreignValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign = false;

                              this.setState({
                                [Foreign]: Foreign,
                                errorMsg,
                              });
                            } else {
                              j++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign =
                                foreignCondition + " " + foreignValue;
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (foreignCondition === "Below") {
                            if (Number(Foreign) < Number(foreignValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign = false;

                              this.setState({
                                [Foreign]: Foreign,
                                errorMsg,
                              });
                            } else {
                              j++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign =
                                foreignCondition + " " + foreignValue;
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (foreignCondition === "Above") {
                            if (Number(Foreign) > Number(foreignValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign = false;

                              this.setState({
                                [Foreign]: Foreign,
                                errorMsg,
                              });
                            } else {
                              j++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign =
                                foreignCondition + " " + foreignValue;
                              this.setState({
                                errorMsg,
                              });
                              //  const errorMsg = this.state.errorMsg;
                              //  errorMsg.Moisture = "Please give an Moisture!";
                              //  this.setState({ errorMsg });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (foreignCondition === "Only") {
                            if (Number(Foreign) === Number(foreignValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign = false;

                              this.setState({
                                [Foreign]: Foreign,
                                errorMsg,
                              });
                            } else {
                              j++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign =
                                foreignCondition + " " + foreignValue;
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (foreignCondition === "Not") {
                            if (Number(Foreign) !== Number(foreignValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign = false;

                              this.setState({
                                [Foreign]: Foreign,
                                errorMsg,
                              });
                            } else {
                              j++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Foreign =
                                foreignCondition + " " + foreignValue;
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.Foreign = "Please give Foreign Matter!";

                          this.setState({
                            errorMsg,
                          });
                        }
                      }
                      if (doubleCondition !== false) {
                        if (Double) {
                          if (doubleCondition === "Minimum") {
                            if (Number(Double) >= Number(doubleValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double = false;

                              this.setState({
                                [Double]: Double,
                                errorMsg,
                              });
                            } else {
                              k++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double =
                                doubleCondition + " " + doubleValue;
                              this.setState({
                                errorMsg,
                              });

                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (doubleCondition === "Maximum") {
                            if (Number(Double) <= Number(doubleValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double = false;

                              this.setState({
                                [Double]: Double,
                                errorMsg,
                              });
                            } else {
                              k++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double =
                                doubleCondition + " " + doubleValue;
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (doubleCondition === "Below") {
                            if (Number(Double) < Number(doubleValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double = false;

                              this.setState({
                                [Double]: Double,
                                errorMsg,
                              });
                            } else {
                              k++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double =
                                doubleCondition + " " + doubleValue;
                              this.setState({
                                errorMsg,
                              });

                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (doubleCondition === "Above") {
                            if (Number(Double) > Number(doubleValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double = false;

                              this.setState({
                                [Double]: Double,
                                errorMsg,
                              });
                            } else {
                              k++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double =
                                doubleCondition + " " + doubleValue;
                              this.setState({
                                errorMsg,
                              });
                            }
                          }
                          if (doubleCondition === "Only") {
                            if (Number(Double) === Number(doubleValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double = false;

                              this.setState({
                                [Double]: Double,
                                errorMsg,
                              });
                            } else {
                              k++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double =
                                doubleCondition + " " + doubleValue;
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                          if (doubleCondition === "Not") {
                            if (Number(Double) !== Number(doubleValue)) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double = false;

                              this.setState({
                                [Double]: Double,
                                errorMsg,
                              });
                            } else {
                              k++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Double =
                                doubleCondition + " " + doubleValue;
                              this.setState({
                                errorMsg,
                              });
                              // errorMsg[event.target.name] = moistureCondition + "%";
                            }
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.Double = "Please give Double Polish!";
                          this.setState({
                            errorMsg,
                          });
                        }
                      }
                      // if (Broken) {
                      // if (REMARK) {
                      if (survayorCompany) {
                        // if (survayor) {
                        if (
                          e === 0 &&
                          f === 0 &&
                          g === 0 &&
                          h === 0 &&
                          i === 0 &&
                          j === 0 &&
                          k === 0 &&
                          l == 0
                        ) {
                          const newsurveyorItemArray = surveyorItemArrayCopy;
                          const addData = {};
                          addData.id = selectedSrvayorid
                            ? selectedSrvayorid
                            : newsurveyorItemArray.length + 1;
                          addData.po_item_id = selectedSrvayorid
                            ? selectedSrvayorid
                            : newsurveyorItemArray.length + 1;
                          addData.po_item = selectedPoIdinSiurvayor;
                          addData[survayItemheader[0]] = BrandName;
                          addData[`${survayItemheader[0]}id`] = BrandNameId;
                          addData[survayItemheader[1]] = ItemCategory;
                          addData[`${survayItemheader[1]}id`] = ItemCategoryId;
                          addData[survayItemheader[2]] = ItemType;
                          addData[`${survayItemheader[2]}id`] = itemsId;
                          addData[survayItemheader[3]] = Moisture;
                          addData[survayItemheader[4]] = Broken;
                          addData[survayItemheader[5]] = Damaged;
                          addData[survayItemheader[6]] = Chalky;
                          addData[survayItemheader[7]] = Length;
                          addData[survayItemheader[8]] = Paddy;
                          addData[survayItemheader[9]] = Foreign;
                          addData[survayItemheader[10]] = Double;
                          addData[survayItemheader[11]] = REMARK;
                          // addData["survayorCompany"] = survayorCompany;
                          // addData["survayorCompanyId"] = survayorCompanyId;
                          addData[survayItemheader[12]] = survayorCompany;
                          addData[`${survayItemheader[12]}id`] =
                            survayorCompanyId;
                          addData[survayItemheader[13]] = survayor;
                          addData[`${survayItemheader[13]}id`] = survayorId;

                          const newArr = [];

                          if (newsurveyorItemArray.length > 0) {
                            newsurveyorItemArray.map((s) => {
                              if (s.id === addData.id) {
                                newArr.push(addData);
                              } else {
                                newArr.push(s);
                              }
                              return s;
                            });
                            if (selectedSrvayorid === false) {
                              newArr.push(addData);
                            }
                          } else {
                            newArr.push(addData); //first time
                          }
                          this.setState(
                            {
                              surveyorItemArray: newArr,
                              surveyorItemArrayCopy: newArr,
                            },
                            () => {
                              this.setWidth(
                                this.state.surveyorItemArray,
                                survayItemheader
                              );
                              this.setState({
                                Broken: "",
                                Moisture: "",
                                ItemCategoryId: "",
                                ItemCategory: "",
                                ItemType: "",
                                itemsId: "",
                                BrandNameId: "",
                                BrandName: false,
                                Length: "",
                                Damaged: "",
                                Chalky: "",
                                Paddy: "",
                                Foreign: "",
                                Double: "",
                                REMARK: "",
                                survayor: "",
                                survayorId: "",
                                survayorCompany: "",
                                survayorCompanyId: "",
                                selectedBrandMultipleName: false,
                                selectedPoIdinSiurvayor: "",
                                //SamplingDate:""
                              });
                            }
                          );
                        }
                        // } else {
                        //   const errorMsg = this.state.errorMsg;
                        //   errorMsg.survayor = "Please give Surveyor!";
                        //   this.setState({
                        //     errorMsg,
                        //   });
                        // }
                      } else {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.survayorCompany =
                          "Please give SurveyorCompany!";
                        this.setState({
                          errorMsg,
                        });
                      }
                      // } else {
                      //   const errorMsg = this.state.errorMsg;
                      //   errorMsg.REMARK = "Please give REMARK!";

                      //   this.setState({ errorMsg });
                      // }
                      // } else {
                      // const errorMsg = this.state.errorMsg;
                      // errorMsg.Broken = "Please give Broken!";
                      // this.setState({ errorMsg });
                      // }
                    } else {
                      const errorMsg = this.state.errorMsg;
                      errorMsg.BrandName = "Please select Brand Name!";
                      this.setState({ errorMsg });
                    }
                  }}
                  name={`${
                    editDialog !== false ? "Update" : "Add"
                  } Item Survey`}
                  minWidth="30px"
                  width="150px"
                  margin="0 10px"
                  withBg={true}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      );
    };
    const detailDialog = (onlyDialog) => {
      return (
        <Details
          onlyDialog={onlyDialog ? onlyDialog : true}
          {...this.props}
          {...this.state}
          POITemTable={POITemTable}
          closeDialog={() => {
            if (this.props.onClose) {
              this.props.onClose();
            }
            this.setState(
              {
                openDialog: false,
              },
              () => {}
            );
          }}
          openDialogFunct={async () => {
            return this.setState({
              openDialog: true,
            });
          }}
          clearPoItems={() => {
            this.setState({
              poItemsArrayCopy: [],
              poItemsArray: [],
            });
          }}
          setPoItems={(poItemsArrayCopy, poItemsArray) => {
            this.setState(
              poItemsArrayCopy && poItemsArray
                ? {
                    poItemsArrayCopy,
                    poItemsArray,
                  }
                : poItemsArrayCopy
                ? { poItemsArrayCopy }
                : poItemsArray
                ? { poItemsArray }
                : {},
              () => {
                this.setWidth(this.state.poItemsArray);
              }
            );
          }}
          setPoItemsPrev={(
            poItemsArrayTOPreview,
            poItemsArrayTOPreviewCopy,
            poItemsArrayTOPreviewCopy2
          ) => {
            this.fetchpoItems();
            this.setState({
              poItemsArrayTOPreview,
              poItemsArrayTOPreviewCopy,
              poItemsArrayTOPreviewCopy2,
            });
          }}
        />
      );
    };
    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.purchase_order,
      accessText.purchase_order_detail
    );
    return this.props.onlyCreate ? (
      detailDialog(true)
    ) : (
      <Layout
        RaisedMonthBar={
          selctedStatus === "CLOSED"
            ? { hintText: "Search Months", labelField: "month" }
            : null
        }
        tabs={tabs}
        noTab={true}
        loading={
          loading[EndPoints["PurchaseOrders"]]
            ? loading[EndPoints["PurchaseOrders"]] === true
              ? true
              : loader === true
              ? true
              : false
            : loader === true
            ? true
            : false
        }
        RaisedSelectBar={{
          data: status ? status : [],
          hintText: "Select status",
          labelField: "name",
          selctedData: "OPEN",
        }}
        fabClickHandler={(value) => {
          this.setState(
            {
              poItemsArray: [],
              poItemsArrayCopy: [],
              openDialog: true,
              loadingFun: false,
              added: false,
              editDialog: false,
              BrandName: false,
              selectedBrandMultipleName: false,
            },
            () => {
              this.reset("no snac");
              this.handleClearFields();
            }
          );
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        selectHandler={this.selectHandler}
        centerWidth={"230px"}
        openWidth={"465px"}
        closeWidth={"287px"}>
        {openAddBagDialog !== false ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "0px";
                target.style.maxHeight = "calc(100% - 40px)";
                target.style.minHeight = "300px";

                target.style.height = "fit-content";
                target.style.minWidth = "350px";
                target.style.maxWidth = "350px";
                target.style.margin = "0";
              } catch (e) {}
            }}
            open={true}
            onClose={() => {
              if (
                openAddBagDialog !== false &&
                openAddBagDialog["packagageArrUsed"] &&
                openAddBagDialog["packagageArrUsed"].length > 0
              ) {
                openAddBagDialog["packagageArrUsed"].map((sData) => {
                  if (sData.remaining_bags) {
                    this.setState({
                      [`${sData.id}Remaining`]: false,
                    });
                  } else {
                  }
                  return sData;
                });
              }
              this.setState({
                openAddBagDialog: false,
              });
            }}
            TransitionComponent={Transition}>
            <DialogActions
              className="d-flex justifySB"
              style={{
                background: primary,
                color: "white",
                padding: "10px 24px",
              }}
              onClick={() => {
                if (
                  openAddBagDialog !== false &&
                  openAddBagDialog["packagageArrUsed"] &&
                  openAddBagDialog["packagageArrUsed"].length > 0
                ) {
                  openAddBagDialog["packagageArrUsed"].map((sData) => {
                    if (sData.remaining_bags) {
                      this.setState({
                        [`${sData.id}Remaining`]: false,
                      });
                    } else {
                    }
                    return sData;
                  });
                }
                this.setState({
                  openAddBagDialog: false,
                });
              }}>
              <h3 style={styles.heading}>Add Bags</h3>
              <Tooltip title="close" arrow placement="top">
                <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                  <CrossIcon strokeWidth="2" />
                </IconButton>
              </Tooltip>
            </DialogActions>
            <DialogContent
              className="dashedDividerBottom  width-100 d-flex alignC justifyC d-flex-column"
              style={{ height: "100%" }}>
              {this.state.loadingBags && this.state.loadingBags === true ? (
                <Backdrop
                  style={{ zIndex: 1, color: "#fff" }}
                  open={
                    this.state.loadingBags ? this.state.loadingBags : false
                  }>
                  <StyledCircularProgress color="rgb(241, 154, 48)" />
                </Backdrop>
              ) : (
                ""
              )}
              {this.state.bagerror || this.state.bagsuccess ? (
                <SnackbarComponent
                  autoHideDuration={this.state.bagerror ? 3000 : 2000}
                  error={this.state.bagerror}
                  success={this.state.bagsuccess}
                  snackbarClose={() => {
                    if (
                      openAddBagDialog !== false &&
                      openAddBagDialog["packagageArrUsed"] &&
                      openAddBagDialog["packagageArrUsed"].length > 0
                    ) {
                      openAddBagDialog["packagageArrUsed"].map((sData) => {
                        if (sData.remaining_bags) {
                          this.setState({
                            [`${sData.id}Remaining`]: false,
                          });
                        } else {
                        }
                        return sData;
                      });
                    }
                    this.setState({
                      openAddBagDialog: false,
                      bagerror: "",
                      bagsuccess: "",
                    });
                  }}
                />
              ) : (
                ""
              )}
              {this.state.poBagAdded && this.state.poBagAdded !== false ? (
                <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
                  <CheckCircle
                    style={{
                      width: "100px",
                      height: "100px",
                      color: "rgb(241 154 48)",
                    }}
                  />
                  <span
                    className="heading"
                    style={{ color: "rgb(241 154 48)", margin: "10px 0 0 0" }}>
                    {this.state.poBagAdded}
                  </span>
                </div>
              ) : (
                <>
                  {openAddBagDialog &&
                    openAddBagDialog["packagageArrUsed"] &&
                    openAddBagDialog["packagageArrUsed"].map(
                      (singlePackaging) => (
                        <div
                          key={singlePackaging.sizeUnit}
                          className="d-flex-column"
                          style={{
                            display: "flex",
                            width: "100%",
                            fontSize: "1rem",
                            color: "rgba(0, 0, 0, 0.87)",
                          }}>
                          {`${singlePackaging.sizeUnit} Total ${
                            this.state[`${singlePackaging.id}Remaining`]
                              ? subtract(
                                  this.state[`${singlePackaging.id}Remaining`],
                                  singlePackaging.used_bags
                                    ? singlePackaging.used_bags
                                    : 0
                                )
                              : singlePackaging.remaining_bags
                              ? singlePackaging.remaining_bags
                                ? Number(singlePackaging.remaining_bags)
                                : "NA"
                              : "NA"
                          }  ${
                            singlePackaging.bag_type
                              ? `${singlePackaging.bag_type}`
                              : filterData(
                                  openAddBagDialog.packagingArr,
                                  "packaging",
                                  singlePackaging.packaging,
                                  "onlyOne"
                                )
                              ? filterData(
                                  openAddBagDialog.packagingArr,
                                  "packaging",
                                  singlePackaging.packaging,
                                  "onlyOne"
                                ).packaging_details.bag_type &&
                                filterData(
                                  openAddBagDialog.packagingArr,
                                  "packaging",
                                  singlePackaging.packaging,
                                  "onlyOne"
                                ).packaging_details.bag_type
                              : ""
                          } bags `}{" "}
                          {error || success ? (
                            <SnackbarComponent
                              error={error}
                              autoHideDuration={error ? 3000 : 2000}
                              success={success}
                              snackbarClose={() => {
                                this.setState({
                                  error: "",
                                  success: "",
                                });
                              }}
                            />
                          ) : (
                            ""
                          )}
                          <CssTextField95
                            required={true}
                            onInput={(e) => {
                              if (
                                e.target.value >
                                (this.state[`${singlePackaging.id}Remaining`]
                                  ? this.state[`${singlePackaging.id}Remaining`]
                                  : singlePackaging.remaining_bags
                                  ? singlePackaging.remaining_bags
                                    ? Number(singlePackaging.remaining_bags)
                                    : 0
                                  : 0)
                              ) {
                                this.setState({
                                  error: "Can't be greater than total bags",
                                });
                              }
                              e.target.value = e.target.value
                                ? e.target.value >
                                  (this.state[`${singlePackaging.id}Remaining`]
                                    ? this.state[
                                        `${singlePackaging.id}Remaining`
                                      ]
                                    : singlePackaging.remaining_bags
                                    ? singlePackaging.remaining_bags
                                      ? Number(singlePackaging.remaining_bags)
                                      : 0
                                    : 0)
                                  ? this.state[`${singlePackaging.id}Remaining`]
                                    ? this.state[
                                        `${singlePackaging.id}Remaining`
                                      ]
                                    : singlePackaging.remaining_bags
                                    ? singlePackaging.remaining_bags
                                      ? Number(singlePackaging.remaining_bags)
                                      : 0
                                    : 0
                                  : e.target.value
                                : "";
                            }}
                            InputProps={{
                              inputProps: {
                                min: 0,
                                max: this.state[
                                  `${singlePackaging.id}Remaining`
                                ]
                                  ? this.state[`${singlePackaging.id}Remaining`]
                                  : singlePackaging.remaining_bags
                                  ? singlePackaging.remaining_bags
                                    ? Number(singlePackaging.remaining_bags)
                                    : 0
                                  : 0,
                              },
                            }}
                            type="number"
                            onFocus={StopScroll}
                            autoComplete="off"
                            id="outlined-basic"
                            error={
                              singlePackaging.remaining_bags ? false : true
                            }
                            label="Used Bags"
                            style={{ width: "100%", margin: "10px 0" }}
                            variant="outlined"
                            disabled={
                              singlePackaging.remaining_bags ? false : true
                            }
                            value={
                              submittedDataOfUsedBags[
                                openAddBagDialog.po_item_id
                              ]
                                ? filterData(
                                    submittedDataOfUsedBags[
                                      openAddBagDialog.po_item_id
                                    ],
                                    "id",
                                    singlePackaging.id,
                                    "onlyOne"
                                  )
                                  ? filterData(
                                      submittedDataOfUsedBags[
                                        openAddBagDialog.po_item_id
                                      ],
                                      "id",
                                      singlePackaging.id,
                                      "onlyOne"
                                    )["used_bags"]
                                    ? filterData(
                                        submittedDataOfUsedBags[
                                          openAddBagDialog.po_item_id
                                        ],
                                        "id",
                                        singlePackaging.id,
                                        "onlyOne"
                                      )["used_bags"]
                                    : ""
                                  : ""
                                : singlePackaging["used_bags"]
                                ? singlePackaging["used_bags"]
                                : ""
                            }
                            onChange={(e) => {
                              const singlePo = singlePackaging;
                              const newPacks =
                                openAddBagDialog["packagageArrUsed"];
                              const newA = [];
                              newPacks.map((sM) => {
                                if (sM.id === singlePo.id) {
                                  const SelectedUnit =
                                    this.state.enteredDispatchdData &&
                                    this.state.enteredDispatchdData["Unit"];
                                  sM["used_bags"] =
                                    e.target.value && e.target.value !== 0
                                      ? e.target.value
                                      : undefined;

                                  sM["total_dispatch"] =
                                    e.target.value && e.target.value !== 0
                                      ? multiply(
                                          Number(
                                            sM["used_bags"]
                                              ? sM["used_bags"]
                                              : 0
                                          ),
                                          this.changeUnit(
                                            singlePo.unit,
                                            SelectedUnit,
                                            singlePo.size
                                          )
                                        )
                                      : 0;

                                  sM["total_amount"] = singlePo[
                                    "total_dispatch"
                                  ]
                                    ? multiply(
                                        singlePo["total_dispatch"]
                                          ? Number(singlePo["total_dispatch"])
                                          : 0,
                                        openAddBagDialog["Contract Rate"]
                                          ? this.changeUnit(
                                              singlelistView &&
                                                singlelistView["unit"],
                                              SelectedUnit,
                                              Number(
                                                openAddBagDialog[
                                                  "Contract Rate"
                                                ]
                                              ),
                                              "opposite"
                                            )
                                          : 0
                                      )
                                    : 0;
                                  // singlePackaging.remaining_bags=

                                  newA.push(sM);
                                } else {
                                  newA.push(sM);
                                }
                                return sM;
                              });
                              const newData = submittedDataOfUsedBags;
                              newData[openAddBagDialog.po_item_id] = newPacks;

                              this.setState({
                                submittedDataOfUsedBags: newData,
                                // [``]
                                // [`${openAddBagDialog.po_item_id}${singlePackaging.size}usedBags`]:e.target.value
                              });
                            }}
                          />
                        </div>
                      )
                    )}
                </>
              )}
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                minHeight: "52.5px",
              }}>
              {added ? (
                ""
              ) : (
                <StyledButton
                  onClick={() => {
                    const newArr = poItemsArrayTOPreview;

                    newArr.map((sObj) => {
                      if (sObj.po_item_id === openAddBagDialog.po_item_id) {
                        sObj["packagageArrUsed"] =
                          submittedDataOfUsedBags[openAddBagDialog.po_item_id];
                      } else {
                      }
                      return sObj;
                    });
                    if (
                      openAddBagDialog !== false &&
                      openAddBagDialog["packagageArrUsed"] &&
                      openAddBagDialog["packagageArrUsed"].length > 0
                    ) {
                      openAddBagDialog["packagageArrUsed"].map((sData) => {
                        if (sData.remaining_bags) {
                          this.setState({
                            [`${sData.id}Remaining`]: false,
                          });
                        } else {
                        }
                        return sData;
                      });
                    }
                    this.setState({
                      poItemsArrayTOPreviewCopy2: newArr,
                      openAddBagDialog: false,
                    });
                  }}
                  name={"Submit"}
                  withBg={true}
                  background={primary}
                  width="120px"
                  margin="0 10px 0 0"
                />
              )}
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}

        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {detailDialog(true)}
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.PurchaseOrders === true || loader === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="rgb(241, 154, 48)" />
            </div>
          ) : (
            <TabComponent
              onChange={(newVal) => {
                const selctedTab = this.setState(
                  { selectedValue: newVal },
                  () => {
                    this.setState({
                      dispatchTab: newVal === Number(0) ? true : "",
                    });
                    this.setState({
                      dispatchTab: newVal === Number(2) ? false : true,
                    });
                  }
                  // () => {
                  //   if (newVal === Number(2)) {

                  //   }

                  // }
                );
              }}
              list={[
                {
                  name: "Dispatches",
                  // onClick: { dispatchTab: true },
                  component:
                    loadingRight === true ? (
                      <div className="alignC justiyC d-flex width-100 height-100">
                        <StyledCircularProgress color="rgb(241, 154, 48)" />
                      </div>
                    ) : (
                      <>
                        <TableComponent
                          pagination={this.state.list_pagination}
                          loader={loadingRight}
                          errorMsgs={errorMsgForDispatch}
                          seterrorMsgs={(s, clear) => {
                            this.setState({
                              errorMsgForDispatch: clear
                                ? {}
                                : {
                                    ...errorMsgForDispatch,
                                    [s]: false,
                                    "Net Weight":
                                      (s === "Tare Weight" ||
                                        s === "Gross Weight") &&
                                      false,
                                  },
                            });
                          }}
                          remaining_quantity={
                            singlelistView &&
                            singlelistView.total_dispatched_quantity
                              ? this.state.editingDispatch !== false
                                ? sum([
                                    singlelistView.total_dispatched_quantity &&
                                      subtract(
                                        Number(
                                          singlelistView.total_po_quantity
                                        ),
                                        Number(
                                          singlelistView.total_dispatched_quantity
                                        )
                                      ),
                                    Number(
                                      this.state.editingDispatch &&
                                        this.state.editingDispatch
                                          .dispatch_weight
                                        ? this.state.editingDispatch
                                            .dispatch_weight
                                        : 0
                                    ),
                                  ])
                                : singlelistView.total_dispatched_quantity &&
                                  subtract(
                                    Number(singlelistView.total_po_quantity),
                                    Number(
                                      singlelistView.total_dispatched_quantity
                                    )
                                  )
                              : singlelistView &&
                                Number(singlelistView.total_po_quantity)
                          }
                          subMenu={true}
                          customCretae={true}
                          tableErrorValue={selectedPVItemArray}
                          tableValueDetailArray={poItemsArrayTOPreviewCopy2}
                          onAdd={() => {
                            this.setState({
                              survayorList: [],
                              addbagError: false,
                              errorMsgForDispatch: {},
                              poItemsArrayTOPreview: poItemsArrayTOPreviewCopy,
                              poItemsArrayTOPreviewCopy2:
                                poItemsArrayTOPreviewCopy.map((ss) => {
                                  ss["packagageArrUsed"].map((s) => {
                                    s.used_bags = 0;
                                    s.used_bags_fordiapatch = 0;
                                    return s;
                                  });
                                  return ss;
                                }),
                              selectedPVItemArray: [],
                            });
                          }}
                          tableData={(
                            editList,
                            submittedData,
                            selectedList
                          ) => {
                            if (selectedList) {
                              submittedData.dispatch_weight =
                                selectedList.dispatch_weight;
                            }
                            return editList
                              ? POITemTable(
                                  "",
                                  poItemsArrayTOPreviewCopy2,
                                  "noTotalQuantity",
                                  "showTotal",
                                  null,
                                  "showbagOnly",
                                  submittedData
                                )
                              : POITemTable(
                                  "checkBox",
                                  poItemsArrayTOPreviewCopy2,
                                  "noTotalQuantity",
                                  "showTotal",
                                  null,
                                  "addBag",
                                  submittedData
                                );
                          }}
                          settableErrorValue={() => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.poItemsArrayTOPreview =
                              "Please add an PO Item!";
                            this.setState({ errorMsg });
                          }}
                          module={"Dispatch"}
                          postApi="dispatch/create"
                          editApi="dispatch"
                          noAddAnother={true}
                          closeOnSave={true}
                          editOnlyParam={true}
                          {...this.state}
                          subMenuheader={[
                            "Sr.No.",
                            "Brand",
                            "Item Category",
                            "Item Type",
                            "Packaging",
                            "HSN Code",
                            "Contract Rate",
                          ]}
                          header={
                            singlelistView &&
                            singlelistView.contract_type_details
                              .contract_type === "FOR"
                              ? [
                                  "Sr.No.",
                                  "Dispatch Date",
                                  "Dispatched From",
                                  "Dispatched To",
                                  "Dispatch Weight",
                                  "Contract Type",
                                  // "Miller Bill Weight",
                                  "Miller Bill Number",
                                  // "Miller Bill Amount",
                                  // "Miller Side Brokerage Rate",
                                  // "Miller Side Brokerage Amount",
                                  // "BRI brokerage rate",
                                  // "BRI Brokerage Amount",
                                  // "Miller TDS",
                                  // "Miller GST Charge",
                                  // "Miller Advance",
                                  // "Miller Total Amount",
                                  "Purchase Order",
                                  "Sales Order",
                                  "Transporter",
                                  "Truck Number",
                                  "Bilty Number",
                                  "Bilty Date",
                                  "Gross Weight",
                                  "Tare Weight",
                                  "Net Weight",
                                  "Freight Rate",
                                  "Total Freight",
                                  "Transporter Advance",
                                  "Transporter Balance",
                                  "Survey Company",
                                  "Surveyor",
                                  "Survey Date",
                                  "Dispatch Status",
                                  // "Miller Payment",
                                  // "Transporter Payment",
                                  // "Broker Payment",
                                ]
                              : [
                                  "Sr.No.",
                                  "Dispatch Date",
                                  "Dispatched From",
                                  "Dispatched To",
                                  "Dispatch Weight",
                                  "Contract Type",
                                  // "Miller Bill Weight",
                                  // "Miller Bill Number",
                                  // "Miller Bill Amount",
                                  // "Miller Side Brokerage Rate",
                                  // "Miller Side Brokerage Amount",
                                  // "BRI brokerage rate",
                                  // "BRI Brokerage Amount",
                                  "Freight Deduction",
                                  // "Miller TDS",
                                  // "Miller GST Charge",
                                  // "Miller Advance",
                                  // "Miller Total Amount",
                                  "Purchase Order",
                                  "Sales Order",
                                  "Transporter",
                                  "Truck Number",
                                  "Bilty Number",
                                  "Bilty Date",
                                  "Gross Weight",
                                  "Tare Weight",
                                  "Net Weight",
                                  "Freight Rate",
                                  "Total Freight",
                                  "Transporter Advance",
                                  "Transporter Balance",
                                  "Survey Company",
                                  "Surveyor",
                                  "Survey Date",
                                  "Dispatch Status",
                                  // "Miller Payment",
                                  // "Transporter Payment",
                                  // "Broker Payment",
                                ]
                          }
                          {...this.props}
                          clearError={() => {
                            this.setState({ error: "" });
                          }}
                          tableList={
                            PODispatchedList && PODispatchedList.length
                              ? PODispatchedList.map((singleDispatch) => {
                                  // //console.log(
                                  //   singleDispatch.miller_bill_weight,
                                  //   "vmiller_bill_weight"
                                  // );
                                  singleDispatch["Dispatch Date"] = moment(
                                    singleDispatch.dispatch_date
                                  ).format("ddd, MMM Do YYYY");
                                  singleDispatch["Dispatch DateMonth"] =
                                    singleDispatch.dispatch_date;

                                  singleDispatch["Dispatched From"] =
                                    singleDispatch.dispatched_from_details
                                      ? singleDispatch.dispatched_from_details
                                          .name
                                      : "-";
                                  singleDispatch["Dispatched FromId"] =
                                    singleDispatch.dispatched_from_details
                                      ? singleDispatch.dispatched_from_details
                                          .id
                                      : "-";
                                  singleDispatch["Dispatched To"] =
                                    singleDispatch.dispatched_to_details
                                      ? `${singleDispatch.dispatched_to_details.name} - ${singleDispatch.dispatched_to_details.city}`
                                      : "-";
                                  singleDispatch["Dispatched ToId"] =
                                    singleDispatch.dispatched_to_details
                                      ? singleDispatch.dispatched_to_details.id
                                      : "-";
                                  singleDispatch["Total Bags"] =
                                    singleDispatch.total_bags;

                                  singleDispatch["Dispatch Weight"] =
                                    singleDispatch.dispatch_weight
                                      ? singleDispatch.dispatch_weight
                                      : "";
                                  singleDispatch["Dispatch WeightUnit"] =
                                    singleDispatch.dispatch_weight
                                      ? Number(
                                          singleDispatch.dispatch_weight
                                        ).toFixed(3)
                                      : "" + " " + singleDispatch.unit;
                                      singleDispatch["Miller Advance"]=singleDispatch.miller_accounts_details
                                      ? singleDispatch.miller_accounts_details.miller_advance
                                      : "";
                                      singleDispatch["Miller TDS"]=singleDispatch && singleDispatch.miller_accounts_details
                                      ? singleDispatch.miller_accounts_details.miller_tds
                                      : "";
                                  singleDispatch["Contract Type"] =
                                    singleDispatch.purchase_order_details
                                      .contract_type_details
                                      ? singleDispatch.purchase_order_details
                                          .contract_type_details.contract_type
                                      : "-";
                                  singleDispatch["Miller Bill Weight"] =
                                  singleDispatch.miller_accounts_details
                                  ? singleDispatch.miller_accounts_details.miller_bill_weight
                                  : "";
                                  singleDispatch["Miller Bill Amount"] =
                                  singleDispatch.miller_accounts_details
                                  ? singleDispatch.miller_accounts_details.miller_bill_amount
                                  : "";
                                  console.log(singleDispatch.miller_accounts_details,"singleDispatch.miller_accounts_details")
                                  singleDispatch["TDS Amount"] =multiply(
                                    divide(
                                      singleDispatch["Miller TDS"]
                                        ? Number(singleDispatch["Miller TDS"])
                                        : 0,
                                      100
                                    ),
                                    singleDispatch["Miller Bill Amount"]
                                      ? Number(singleDispatch["Miller Bill Amount"])
                                      : 0
                                  ).toFixed(2)
                                  singleDispatch["Miller GST Charge"] =
                                  singleDispatch.miller_accounts_details
                                  ? singleDispatch.miller_accounts_details.miller_gst_charge
                                  : "";


                                  singleDispatch["Miller Side Brokerage Rate"] =
                                  singleDispatch.miller_accounts_details
                                  ? singleDispatch.miller_accounts_details.brokerage_rate_for_miller
                                  : "";
                                  singleDispatch["Miller Side Brokerage Amount"] =
                                  singleDispatch.miller_accounts_details
                                  ? singleDispatch.miller_accounts_details.brokerage_amount_for_miller
                                  : "";
                                  singleDispatch["BRI brokerage rate"] =
                                  singleDispatch.miller_accounts_details
                                  ? singleDispatch.miller_accounts_details.brokerage_rate_for_bri
                                  : "";         
                                  singleDispatch["BRI Brokerage Amount"] =
                                  singleDispatch.miller_accounts_details
                                  ? singleDispatch.miller_accounts_details.brokerage_amount_for_bri
                                  : "";             
                                   
                                  singleDispatch["Miller Bill Number"] =
                                    singleDispatch.miller_bill_number
                                      ? singleDispatch.miller_bill_number
                                      : 0;

                                  // singleDispatch["Miller Bill Amount"] =
                                  //   singleDispatch.miller_bill_amount
                                  //     ? Number(
                                  //         singleDispatch.miller_bill_amount
                                  //       ).toFixed(2)
                                  //     : 0;
                                  // singleDispatch["Miller Side Brokerage Rate"] =
                                  //   singleDispatch.brokerage_rate_for_miller
                                  //     ? Number(
                                  //         singleDispatch.brokerage_rate_for_miller
                                  //       ).toFixed(2)
                                  //     : 0;

                                  singleDispatch["Net Weight"] =
                                    singleDispatch.net_weight * 1;
                                  // singleDispatch[
                                  //   "Miller Side Brokerage Amount"
                                  // ] = multiply(
                                  //   singleDispatch["Miller Side Brokerage Rate"]
                                  //     ? Number(
                                  //         singleDispatch[
                                  //           "Miller Side Brokerage Rate"
                                  //         ]
                                  //       )
                                  //     : 0,
                                  //   getMinValue(
                                  //     singleDispatch["dispatch_weight"]
                                  //       ? Number(
                                  //           singleDispatch["dispatch_weight"]
                                  //         )
                                  //       : 0,
                                  //     singleDispatch["Net Weight"]
                                  //       ? Number(singleDispatch["Net Weight"])
                                  //       : 0,
                                  //     singleDispatch["Miller Bill Weight"]
                                  //       ? Number(
                                  //           singleDispatch["Miller Bill Weight"]
                                  //         )
                                  //       : 0,
                                  //     singleDispatch["Dispatch Weight"]
                                  //   )
                                  // );
                                  // singleDispatch["BRI brokerage rate"] =
                                  //   singleDispatch.brokerage_rate_for_bri
                                  //     ? Number(
                                  //         singleDispatch.brokerage_rate_for_bri
                                  //       ).toFixed(2)
                                  //     : 0;
                                  // singleDispatch["BRI Brokerage Amount"] =
                                  //   multiply(
                                  //     Number(
                                  //       singleDispatch["BRI brokerage rate"]
                                  //     ) *
                                  //       getMinValue(
                                  //         Number(singleDispatch["Net Weight"]),
                                  //         singleDispatch["dispatch_weight"]
                                  //           ? Number(
                                  //               singleDispatch[
                                  //                 "dispatch_weight"
                                  //               ]
                                  //             )
                                  //           : 0,
                                  //         singleDispatch["Dispatch Weight"],
                                  //         singleDispatch["Miller Bill Weight"]
                                  //       )
                                  //   );
                                  singleDispatch["Freight Deduction"] =
                                    singleDispatch.freight_deduction
                                      ? Number(
                                          singleDispatch.freight_deduction
                                        ).toFixed(2)
                                      : 0;

                                  // singleDispatch["Miller Advance"] = Number(
                                  //   singleDispatch.miller_advance
                                  // ).toFixed(2);
                                 
                                  // singleDispatch["Miller Total AmountUnit"] =
                                  //   singleDispatch.miller_total_amount
                                  //     ? `₹ ${Number(
                                  //         singleDispatch.miller_total_amount
                                  //       ).toFixed(2)}`
                                  //     : "";

                                  singleDispatch["Purchase Order"] =
                                    singleDispatch.purchase_order_details
                                      ? singleDispatch.purchase_order_details
                                          .po_number
                                      : "-";
                                  singleDispatch["Purchase OrderId"] =
                                    singleDispatch.purchase_order_details
                                      ? singleDispatch.purchase_order_details.id
                                      : "-";

                                  if (
                                    singleDispatch &&
                                    singleDispatch.purchase_order_details &&
                                    singleDispatch.purchase_order_details
                                      .sales_order_details
                                  ) {
                                    singleDispatch["Sales Order"] =
                                      singleDispatch.purchase_order_details
                                        .sales_order_details.so_number &&
                                      singleDispatch.purchase_order_details
                                        .sales_order_details.so_number
                                        ? singleDispatch.purchase_order_details
                                            .sales_order_details.so_number
                                        : "-";
                                    singleDispatch["Sales OrderId"] =
                                      singleDispatch &&
                                      singleDispatch.purchase_order_details &&
                                      singleDispatch.purchase_order_details
                                        .sales_order_details &&
                                      singleDispatch.purchase_order_details
                                        .sales_order_details.so_number
                                        ? singleDispatch.purchase_order_details
                                            .sales_order_details.id
                                        : "";
                                  }

                                  singleDispatch["Transporter"] =
                                    singleDispatch.transporter_details
                                      ? singleDispatch.transporter_details.name
                                      : "-";

                                  singleDispatch["Transpoter Name"] =
                                    TransporterList &&
                                    TransporterList.length > 0 &&
                                    TransporterList.filter(
                                      (sT) =>
                                        sT.id === singleDispatch.transporter
                                    ) &&
                                    TransporterList.filter(
                                      (sT) =>
                                        sT.id === singleDispatch.transporter
                                    )[0] &&
                                    TransporterList.filter(
                                      (sT) =>
                                        sT.id === singleDispatch.transporter
                                    )[0].name
                                      ? TransporterList.filter(
                                          (sT) =>
                                            sT.id === singleDispatch.transporter
                                        )[0].name
                                      : "-";
                                  singleDispatch["Transpoter NameId"] =
                                    singleDispatch.transporter
                                      ? singleDispatch.transporter
                                      : "-";

                                  singleDispatch["Truck"] =
                                    singleDispatch.truck_number
                                      ? singleDispatch.truck_number
                                      : "";
                                  singleDispatch["Truck Number"] =
                                    singleDispatch.truck_number
                                      ? singleDispatch.truck_number
                                      : "";
                                  singleDispatch["Bilty Number"] =
                                    singleDispatch.bilty_number;
                                  singleDispatch["Bilty Date"] =
                                    singleDispatch.bilty_date
                                      ? moment(
                                          singleDispatch.bilty_date
                                        ).format("ddd, MMM Do YYYY")
                                      : "";
                                  singleDispatch["Bilty DateMonth"] =
                                    singleDispatch.bilty_date
                                      ? singleDispatch.bilty_date
                                      : "";

                                  // singleDispatch["Truck"] =
                                  //   singleDispatch.truck_number
                                  //     ? singleDispatch.truck_number
                                  //     : "";
                                  singleDispatch["Truck Number"] =
                                    singleDispatch.truck_number
                                      ? singleDispatch.truck_number
                                      : "";
                                  singleDispatch["Bilty Number"] =
                                    singleDispatch.bilty_number;
                                  singleDispatch["Bilty Date"] =
                                    singleDispatch.bilty_date
                                      ? moment(
                                          singleDispatch.bilty_date
                                        ).format("ddd, MMM Do YYYY")
                                      : "";
                                  singleDispatch["Bilty DateMonth"] =
                                    singleDispatch.bilty_date
                                      ? singleDispatch.bilty_date
                                      : "";

                                  singleDispatch["Gross Weight"] =
                                    singleDispatch.gross_weight * 1;
                                  singleDispatch["Gross WeightUnit"] =
                                    (singleDispatch.gross_weight
                                      ? Number(
                                          singleDispatch.gross_weight
                                        ).toFixed(2)
                                      : "") +
                                    " " +
                                    singleDispatch.unit;
                                  singleDispatch["Tare Weight"] =
                                    singleDispatch.tare_weight * 1;
                                  singleDispatch["Tare WeightUnit"] =
                                    (singleDispatch.tare_weight
                                      ? Number(
                                          singleDispatch.tare_weight
                                        ).toFixed(2)
                                      : "") +
                                    " " +
                                    singleDispatch.unit;
                                  singleDispatch["Net WeightUnit"] =
                                    (singleDispatch.net_weight
                                      ? Number(
                                          singleDispatch.net_weight
                                        ).toFixed(2)
                                      : "") +
                                    " " +
                                    singleDispatch.unit;
                                  singleDispatch["Freight Rate"] = Number(
                                    singleDispatch.freight_rate
                                  ).toFixed(2);
                                  singleDispatch[
                                    "Freight RateUnit"
                                  ] = `₹ ${Number(
                                    singleDispatch.freight_rate
                                  ).toFixed(2)} /${
                                    singlelistView && singlelistView.unit
                                      ? singlelistView.unit
                                      : ""
                                  }`;
                                  singleDispatch["Total Freight"] =
                                    singleDispatch.total_freight
                                      ? Number(
                                          singleDispatch.total_freight
                                        ).toFixed(2)
                                      : "";
                                  singleDispatch[
                                    "Total FreightUnit"
                                  ] = `₹ ${Number(
                                    singleDispatch.total_freight
                                  ).toFixed(2)}`;
                                  singleDispatch["Transporter Advance"] =
                                    singleDispatch.transporter_advance
                                      ? Number(
                                          singleDispatch.transporter_advance
                                        ).toFixed(2)
                                      : "";
                                  singleDispatch["Transporter AdvanceUnit"] =
                                    singleDispatch.transporter_advance
                                      ? `₹ ${Number(
                                          singleDispatch.transporter_advance
                                        ).toFixed(2)}`
                                      : "";

                                  singleDispatch["Transporter Balance"] =
                                    singleDispatch.transporter_balance
                                      ? Number(
                                          singleDispatch.transporter_balance
                                        ).toFixed(2)
                                      : "";
                                  singleDispatch[
                                    "Transporter BalanceUnit"
                                  ] = `₹ ${Number(
                                    singleDispatch.transporter_balance
                                  ).toFixed(2)}`;

                                  singleDispatch["Survey Company"] =
                                    singleDispatch &&
                                    filterData(
                                      SurveyCompanyList,
                                      "id",
                                      singleDispatch.survey_company,
                                      "onlyOne"
                                    )
                                      ? filterData(
                                          SurveyCompanyList,
                                          "id",
                                          singleDispatch.survey_company,
                                          "onlyOne"
                                        ).name
                                      : ""; singleDispatch["Miller Total Amount"] =sum([sum([
                                        Number(
                                          subtract(
                                            multiply(
                                              getMinValue(
                                                singleDispatch["Dispatch Weight"],
                                                singleDispatch["Net Weight"],
                                                singleDispatch["Miller Bill Weight"]
                                              ),
                                              poItemsArrayTOPreviewCopy2.length > 0
                                                ? Number(
                                                    poItemsArrayTOPreviewCopy2[0][
                                                      "Contract Rate"
                                                    ]
                                                  )
                                                : 1
                                            ),
    
                                            sum([
                                              Number(
                                                singleDispatch["Miller Advance"] ?? 0
                                              ),
                                              Number(
                                                singleDispatch["TDS Amount"] ?? 0
                                              ),
                                            ])
                                          )
                                        ),
    
                                        Number(
                                          singleDispatch["Transporter Advance"] ?? 0
                                        ),
                                      ]),Number(singleDispatch["Miller GST Charge"]??0)]);
                                  singleDispatch["Survey CompanyId"] =
                                    singleDispatch &&
                                    singleDispatch.survey_company
                                      ? singleDispatch.survey_company
                                      : "-";

                                  singleDispatch["Surveyor"] =
                                    singleDispatch &&
                                    singleDispatch.surveyor_details &&
                                    singleDispatch.surveyor_details.name
                                      ? singleDispatch.surveyor_details.name
                                      : "-";
                                  singleDispatch["SurveyorId"] =
                                    singleDispatch &&
                                    singleDispatch.surveyor_details &&
                                    singleDispatch.surveyor_details.id
                                      ? singleDispatch.surveyor_details.id
                                      : "-";
                                  // singleDispatch["surveyor"] =
                                  //   singleDispatch &&
                                  //   singleDispatch.surveyor_details &&
                                  //   singleDispatch.surveyor_details.name
                                  //     ? singleDispatch.surveyor_details.name
                                  //     : "-";

                                  // singleDispatch["surveyorId"] =
                                  //   singleDispatch &&
                                  //   singleDispatch.surveyor_details &&
                                  //   singleDispatch.surveyor_details.id
                                  //     ? singleDispatch.surveyor_details.id
                                  //     : "-";
                                  singleDispatch["subList"] =
                                    singleDispatch.subList
                                      ? singleDispatch.subList.map((sData) => {
                                          const brandData =
                                            this.state.poItemsArrayTOPreview
                                              ?.length > 0
                                              ? this.state.poItemsArrayTOPreview.find(
                                                  (dis) =>
                                                    dis.brand_details?.id ===
                                                    sData.brand
                                                )
                                              : {};

                                          //console.log(sData,"poItemsArray",poItemsArrayTOPreview,"gggggggggggggggggggg",brandData)
                                          sData["Brand"] = brandData
                                            ? brandData.brand_details.brand_name
                                            : sData.brand;
                                          sData["BrandId"] = brandData
                                            ? brandData.brand_details.id
                                            : sData.brand;

                                          // dikkat hoga
                                          sData["Item Category"] = filterData(
                                            ItemCateoryList,
                                            "id",
                                            sData.item_category,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                ItemCateoryList,
                                                "id",
                                                sData.item_category,
                                                "onlyOne"
                                              )["name"]
                                            : "";
                                          sData["Item Type"] = filterData(
                                            ItemList,
                                            "id",
                                            sData.item_type,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                ItemList,
                                                "id",
                                                sData.item_type,
                                                "onlyOne"
                                              )["broken_percentage"]
                                            : "";
                                          sData["Item TypeUI"] = filterData(
                                            ItemList,
                                            "id",
                                            sData.item_type,
                                            "onlyOne"
                                          )
                                            ? `${Number(
                                                filterData(
                                                  ItemList,
                                                  "id",
                                                  sData.item_type,
                                                  "onlyOne"
                                                )["broken_percentage"]
                                              ).toFixed(2)} %`
                                            : "";
                                          sData["Item Typeid"] = sData.item_type
                                            ? sData.item_type
                                            : "";

                                          sData["Packaging"] =
                                            sData.dispatch_packaging &&
                                            sData.dispatch_packaging.map(
                                              (sP) => {
                                                const newAP =
                                                  sP.packaging_details;
                                                return `${newAP.size}${newAP.unit}`;
                                              }
                                            ) &&
                                            sData.dispatch_packaging.map(
                                              (sP) => {
                                                const newAP =
                                                  sP.packaging_details;
                                                return `${newAP.size}${newAP.unit}`;
                                              }
                                            ).length > 0 &&
                                            `tota( )...${
                                              sData.dispatch_packaging.map(
                                                (sP) => {
                                                  const newAP =
                                                    sP.packaging_details;
                                                  return `${newAP.size}${newAP.unit}`;
                                                }
                                              )[0]
                                            }`;

                                          sData["PackagingUI"] = (
                                            <ShowPackaging
                                              noTotalbags={true}
                                              details={sData.dispatch_packaging.map(
                                                (sP) => {
                                                  const newAP =
                                                    sP.packaging_details;
                                                  newAP.used_bags =
                                                    sP.total_bags_dispatched;

                                                  return newAP;
                                                }
                                              )}
                                            />
                                          );

                                          sData["HSN Code"] = filterData(
                                            HSNlist,
                                            "id",
                                            sData.hsn_code,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                HSNlist,
                                                "id",
                                                sData.hsn_code,
                                                "onlyOne"
                                              )["hsn_code"]
                                            : "";
                                          sData["Contract RateUI"] = filterData(
                                            poItemsArrayTOPreview,
                                            "id",
                                            sData.po_item,
                                            "onlyOne"
                                          )
                                            ? `₹ ${Number(
                                                filterData(
                                                  poItemsArrayTOPreview,
                                                  "id",
                                                  sData.po_item,
                                                  "onlyOne"
                                                )["Contract Rate"]
                                              ).toFixed(2)} /${
                                                singlelistView &&
                                                singlelistView.unit
                                                  ? singlelistView.unit
                                                  : ""
                                              }`
                                            : sData.contract_rate
                                            ? `₹ ${Number(
                                                sData.contract_rate
                                              ).toFixed(2)} /${
                                                singlelistView &&
                                                singlelistView.unit
                                                  ? singlelistView.unit
                                                  : ""
                                              }`
                                            : "";

                                          sData["Specifications"] = filterData(
                                            poItemsArrayTOPreviewCopy,
                                            "id",
                                            sData.po_item,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                poItemsArrayTOPreviewCopy,
                                                "id",
                                                sData.po_item,
                                                "onlyOne"
                                              )["Specifications"]
                                            : sData.Specifications
                                            ? sData.Specifications
                                            : "";

                                          sData["SpecificationUI"] = (
                                            <ShowSpecifications
                                              Unit={
                                                singlelistView &&
                                                singlelistView.unit
                                                  ? singlelistView.unit
                                                  : ""
                                              }
                                              details={
                                                sData.Specifications &&
                                                sData.Specifications > 0
                                                  ? sData.Specifications.map(
                                                      (sP) => {
                                                        // sP.used_bags =0
                                                        return sP;
                                                      }
                                                    )
                                                  : []
                                              }
                                            />
                                          );
                                          sData["Contract Rate"] = filterData(
                                            poItemsArrayTOPreviewCopy,
                                            "id",
                                            sData.po_item,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                poItemsArrayTOPreviewCopy,
                                                "id",
                                                sData.po_item,
                                                "onlyOne"
                                              )["Contract Rate"]
                                            : sData.contract_rate
                                            ? sData.contract_rate
                                            : "";

                                          return sData;
                                        })
                                      : [];

                                  singleDispatch["Survey Date"] =
                                    singleDispatch.last_survey
                                      ? moment(
                                          singleDispatch.last_survey
                                        ).format("ddd, MMM Do YYYY")
                                      : "";
                                  singleDispatch["Survey DateMonth"] =
                                    singleDispatch.last_survey
                                      ? singleDispatch.last_survey
                                      : "";
                                  singleDispatch["Dispatch Status"] =
                                    singleDispatch.dispatch_status
                                      ? singleDispatch.dispatch_status
                                      : "";
                                  // singleDispatch["Miller Payment"] =
                                  //   singleDispatch.miller_payment
                                  //     ? singleDispatch.miller_payment
                                  //     : "-";
                                  // singleDispatch["Transporter Payment"] =
                                  //   singleDispatch.transporter_payment
                                  //     ? singleDispatch.transporter_payment
                                  //     : "-";
                                  // singleDispatch["Broker Payment"] =
                                  //   singleDispatch.user_type === "Admin"
                                  //     ? singleDispatch.broker_payment_by_bri
                                  //     : singleDispatch.broker_payment_by_miller;
                                  // singleDispatch.broker_payment_by_bri
                                  //   ? singleDispatch.broker_payment_by_bri
                                  //   : "-";
                                  // broker_payment_by_miller

                                  // singleDispatch["Miller TDS"] =
                                  //   singleDispatch.miller_tds
                                  //     ? Number(
                                  //         singleDispatch.miller_tds
                                  //       ).toFixed(2)
                                  //     : 0;

                                  // singleDispatch["TDS Amount"] = singleDispatch
                                  //   ? multiply(
                                  //       divide(
                                  //         singleDispatch.miller_tds
                                  //           ? Number(singleDispatch.miller_tds)
                                  //           : 0,
                                  //         100
                                  //       ),
                                  //       singleDispatch.miller_bill_amount
                                  //         ? Number(
                                  //             singleDispatch.miller_bill_amount
                                  //           ).toFixed(2)
                                  //         : 0
                                  //     )
                                  //   : "-";
                                  // singleDispatch["TDS AmountUnit"] =
                                  //   singleDispatch
                                  //     ? `₹ ${multiply(
                                  //         divide(
                                  //           singleDispatch.miller_tds
                                  //             ? Number(
                                  //                 singleDispatch.miller_tds
                                  //               )
                                  //             : 0,
                                  //           100
                                  //         ),
                                  //         singleDispatch.miller_bill_amount
                                  //           ? Number(
                                  //               singleDispatch.miller_bill_amount
                                  //             ).toFixed(2)
                                  //           : 0
                                  //       )}`
                                  //     : "-";
                                  // singleDispatch["Miller GST Charge"] =
                                  //   singleDispatch.miller_gst_charge
                                  //     ? Number(
                                  //         singleDispatch.miller_gst_charge
                                  //       ).toFixed(2)
                                  //     : 0;
                                  singleDispatch["Bilty"] =
                                    singleDispatch &&
                                    singleDispatch.bilty_number
                                      ? singleDispatch.bilty_number
                                      : "-";
                                  return singleDispatch;
                                }).sort((a, b) => {
                                  var dateA = moment(b.created_at).format(
                                    "MMM D YYYY h:mm:ss A"
                                  );
                                  var dateB = moment(a.created_at).format(
                                    "MMM D YYYY h:mm:ss A"
                                  );
                                  return new Date(dateA) - new Date(dateB);
                                })
                              : []
                          }
                          whenAddedBeforeValue={() => {
                            const pvArr1 = [];
                            const totalBag = [];

                            poItemsArrayTOPreviewCopy2.map((s) => {
                              pvArr1.push(s.po_item_id);

                              s["packagageArrUsed"].map((sData) => {
                                if (sData.remaining_bags && sData.used_bags) {
                                  totalBag.push(sData);
                                } else {
                                }
                              });
                            });
                            if (totalBag.length > 0) {
                              return true;
                            } else {
                              this.setState({
                                error:
                                  selectedPVItemArray.length === 0
                                    ? "Please select items!"
                                    : "Please add bags!",
                                addbagError: true,
                              });
                              return false;
                            }
                          }}
                          inputArray={[
                            {
                              name: "Dispatch Date",
                              type: "Month",
                              dataType: "text",
                              api: "dispatch_date",
                            },
                            {
                              name: "Dispatched From",
                              type: "select",
                              dataType: "text",
                              array:
                                MillerList && MillerList.length > 0
                                  ? MillerList
                                  : [],
                              value: "name",
                              disabled: true,
                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.miller_details &&
                                  singlelistView.miller_details
                                    ? singlelistView.miller_details.name
                                    : "",
                                id:
                                  singlelistView &&
                                  singlelistView.miller_details &&
                                  singlelistView.miller_details
                                    ? singlelistView.miller_details.id
                                    : "",
                              },
                              api: "dispatched_from",
                            },
                            {
                              name: "Dispatched To",
                              type: "select",
                              dataType: "text",
                              array:
                                singlelistView &&
                                singlelistView.ports_details &&
                                singlelistView.ports_details.length > 0
                                  ? singlelistView.ports_details.map((data) => {
                                      data[
                                        "namecity"
                                      ] = `${data.name} - ${data.city}`;
                                      return data;
                                    })
                                  : [],
                              value: "namecity",
                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.ports_details &&
                                  singlelistView.ports_details.length > 0
                                    ? `${singlelistView.ports_details[0].name} - ${singlelistView.ports_details[0].city}`
                                    : "",
                                id:
                                  singlelistView &&
                                  singlelistView.ports_details &&
                                  singlelistView.ports_details.length > 0
                                    ? singlelistView.ports_details[0].id
                                    : "",
                              },

                              api: "dispatched_to",
                              // disabled: true,
                            },
                            {
                              name: "Purchase Order",
                              type: "textField",
                              dataType: "text",
                              disabled: true,
                              defaultValue: {
                                value:
                                  singlelistView && singlelistView.po_number
                                    ? singlelistView.po_number
                                    : "",
                                id:
                                  singlelistView && singlelistView.id
                                    ? singlelistView.id
                                    : "",
                              },
                              api: "purchase_order",
                            },
                            singlelistView &&
                              singlelistView.sales_order_details && {
                                name: "Sales Order",
                                type: "textField",
                                dataType: "text",
                                not_required: true,
                                disabled: true,
                                defaultValue: {
                                  value:
                                    singlelistView &&
                                    singlelistView.sales_order_details
                                      ? singlelistView.sales_order_details
                                          .so_number
                                      : "",
                                  id:
                                    singlelistView &&
                                    singlelistView.sales_order_details
                                      ? singlelistView.sales_order_details.id
                                      : "",
                                },
                                api: "sales_order",
                                // required: "false",
                              },

                            {
                              name: "Contract Type",
                              type: "select",
                              dataType: "text",
                              array:
                                ContractTypeList && ContractTypeList.length > 0
                                  ? ContractTypeList
                                  : [],
                              value: "contract_type",
                              disabled: true,
                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.contract_type_details
                                    ? singlelistView.contract_type_details
                                        .contract_type
                                    : "",
                                id:
                                  singlelistView &&
                                  singlelistView.item_type_details
                                    ? singlelistView.item_type_details.id
                                    : "",
                              },
                            },

                            {
                              name: "Unit",
                              type: "select",
                              dataType: "text",
                              array: unit && unit.length > 0 ? unit : [],
                              value: "name",
                              disabled: true,
                              defaultValue: {
                                value:
                                  singlelistView && singlelistView.unit
                                    ? singlelistView.unit
                                    : "",
                              },
                              api: "unit",
                              onChange: async (submittedData) => {
                                this.setState({
                                  selectedUnit: submittedData["Unit"],
                                });
                                return submittedData;
                              },
                            },

                            singlelistView &&
                              singlelistView.contract_type_details && {
                                //   .contract_type === "EX FACTORY" && // singlelistView.contract_type_details
                                name: "Gross Weight",
                                type: "textField",
                                dataType: "number",
                                endAdornment: this.state.selectedUnit
                                  ? this.state.selectedUnit
                                  : singlelistView && singlelistView.unit
                                  ? singlelistView.unit
                                  : "",
                                onChange: async (submittedData) => {
                                  if (
                                    (submittedData["Gross Weight"]
                                      ? Number(submittedData["Gross Weight"])
                                      : 0) <=
                                    (submittedData["Tare Weight"]
                                      ? submittedData["Tare Weight"]
                                      : 0)
                                  ) {
                                    submittedData["Tare Weight"] = 0;
                                    this.setState({
                                      error:
                                        "Gross weight can't be less than or equal to Tare Weight!",
                                    });
                                  } else {
                                    submittedData["Net Weight"] = Math.floor([
                                      (submittedData["Gross Weight"]
                                        ? Number(submittedData["Gross Weight"])
                                        : 0) -
                                        (submittedData["Tare Weight"]
                                          ? submittedData["Tare Weight"]
                                          : 0),
                                    ]).toFixed(2);
                                  }
                                  // submittedData[
                                  //   "Miller Side Brokerage Amount"
                                  // ] = multiply(
                                  //   submittedData["Miller Side Brokerage Rate"]
                                  //     ? submittedData[
                                  //         "Miller Side Brokerage Rate"
                                  //       ]
                                  //     : 0,
                                  //   getMinValue(
                                  //     submittedData["Net Weight"]
                                  //       ? Number(submittedData["Net Weight"])
                                  //       : 0,
                                  //     submittedData["Miller Bill Weight"]
                                  //       ? Number(
                                  //           submittedData["Miller Bill Weight"]
                                  //         )
                                  //       : 0,
                                  //     submittedData["dispatch_weight"]
                                  //       ? Number(
                                  //           submittedData["dispatch_weight"]
                                  //         )
                                  //       : 0,
                                  //     submittedData["Dispatch Weight"]
                                  //   )
                                  // );
                                  // submittedData["BRI Brokerage Amount"] =
                                  //   multiply(
                                  //     submittedData["BRI brokerage rate"] *
                                  //       getMinValue(
                                  //         submittedData["Net Weight"]
                                  //           ? Number(
                                  //               submittedData["Net Weight"]
                                  //             )
                                  //           : 0,
                                  //         submittedData["Miller Bill Weight"]
                                  //           ? Number(
                                  //               submittedData[
                                  //                 "Miller Bill Weight"
                                  //               ]
                                  //             )
                                  //           : 0,
                                  //         submittedData["dispatch_weight"]
                                  //           ? Number(
                                  //               submittedData["dispatch_weight"]
                                  //             )
                                  //           : 0,
                                  //         submittedData["Dispatch Weight"]
                                  //       )
                                  //   );
                                  return submittedData;
                                },
                                api: "gross_weight",
                              },
                            singlelistView &&
                              singlelistView.contract_type_details && {
                                //   .contract_type === "EX FACTORY" && // singlelistView.contract_type_details
                                name: "Tare Weight",
                                type: "textField",
                                dataType: "number",
                                onChange: async (submittedData) => {
                                  if (
                                    (submittedData["Gross Weight"]
                                      ? Number(submittedData["Gross Weight"])
                                      : 0) <=
                                    (submittedData["Tare Weight"]
                                      ? submittedData["Tare Weight"]
                                      : 0)
                                  ) {
                                    submittedData["Tare Weight"] = 0;
                                    this.setState({
                                      error:
                                        "Tare weight can't be equal or greater to Gross Weight!",
                                    });
                                  } else {
                                    submittedData["Net Weight"] = Math.floor([
                                      (submittedData["Gross Weight"]
                                        ? Number(submittedData["Gross Weight"])
                                        : 0) -
                                        (submittedData["Tare Weight"]
                                          ? submittedData["Tare Weight"]
                                          : 0),
                                    ]).toFixed(3);
                                  }
                                  // submittedData[
                                  //   "Miller Side Brokerage Amount"
                                  // ] = multiply(
                                  //   submittedData["Miller Side Brokerage Rate"]
                                  //     ? Number(
                                  //         submittedData[
                                  //           "Miller Side Brokerage Rate"
                                  //         ]
                                  //       )
                                  //     : 0,
                                  //   getMinValue(
                                  //     submittedData["Net Weight"]
                                  //       ? Number(submittedData["Net Weight"])
                                  //       : 0,
                                  //     submittedData["Miller Bill Weight"]
                                  //       ? Number(
                                  //           submittedData["Miller Bill Weight"]
                                  //         )
                                  //       : 0,
                                  //     submittedData["dispatch_weight"]
                                  //       ? Number(
                                  //           submittedData["dispatch_weight"]
                                  //         )
                                  //       : 0,
                                  //     submittedData["Dispatch Weight"]
                                  //   )
                                  // );
                                  // submittedData["BRI Brokerage Amount"] =
                                  //   multiply(
                                  //     submittedData["BRI brokerage rate"] *
                                  //       getMinValue(
                                  //         submittedData["Net Weight"]
                                  //           ? Number(
                                  //               submittedData["Net Weight"]
                                  //             )
                                  //           : 0,
                                  //         submittedData["Miller Bill Weight"]
                                  //           ? Number(
                                  //               submittedData[
                                  //                 "Miller Bill Weight"
                                  //               ]
                                  //             )
                                  //           : 0,
                                  //         submittedData["dispatch_weight"]
                                  //           ? Number(
                                  //               submittedData["dispatch_weight"]
                                  //             )
                                  //           : 0,
                                  //         submittedData["Dispatch Weight"]
                                  //       )
                                  //   );
                                  // submittedData["Total Freight"] = Math.floor(
                                  //   (submittedData["Net Weight"]
                                  //     ? submittedData["Net Weight"]
                                  //     : 0) *
                                  //     (submittedData["Freight Rate"]
                                  //       ? submittedData["Freight Rate"]
                                  //       : 0)
                                  // );

                                  return submittedData;
                                },

                                api: "tare_weight",
                                endAdornment: this.state.selectedUnit
                                  ? this.state.selectedUnit
                                  : singlelistView && singlelistView.unit
                                  ? singlelistView.unit
                                  : "",
                              },
                            singlelistView &&
                              singlelistView.contract_type_details && {
                                //   .contract_type === "EX FACTORY" && // singlelistView.contract_type_details
                                // disabled: true,
                                name: "Net Weight",
                                type: "textField",
                                dataType: "number",
                                api: "net_weight",

                                endAdornment: this.state.selectedUnit
                                  ? this.state.selectedUnit
                                  : singlelistView && singlelistView.unit
                                  ? singlelistView.unit
                                  : "",
                              },

                            {
                              name: "Miller Bill Number",
                              type: "textField",
                              dataType: "text",
                              api: "miller_bill_number",
                              capitalize: true,
                              defaultValue: {
                                value:
                                  singlelistView && singlelistView.miller_bill_number
                                    ? singlelistView.miller_bill_number
                                    : "",
                              },
                              
                            },
                            {
                              name: "Miller Bill Weight",
                              type: "textField",
                              dataType: "number",
                              endAdornment: this.state.selectedUnit
                                ? this.state.selectedUnit
                                : singlelistView && singlelistView.unit
                                ? singlelistView.unit
                                : "",
                                defaultValue: {
                                  value:
                                    singlelistView && singlelistView.miller_accounts_details
                                      ? singlelistView.miller_accounts_details.miller_bill_weight
                                      : "",
                                },
                              //disabled: true,
                              // startAdornment: "₹",
                              api: "miller_bill_weight",
                              onChange: async (submittedData) => {
                                // submittedData["Miller Bill Amount"] =getMinValue(submittedData["Dispatch Weight"] ,submittedData["Net Weight",submittedData["Miller Bill Weight"]] );

                                submittedData["Miller Side Brokerage Amount"] =
                                  multiply(
                                    submittedData["Miller Side Brokerage Rate"]
                                      ? Number(
                                          submittedData[
                                            "Miller Side Brokerage Rate"
                                          ]
                                        )
                                      : 0,
                                    getMinValue(
                                      submittedData["Dispatch Weight"],
                                      submittedData["Net Weight"],
                                      submittedData["Miller Bill Weight"]
                                    )
                                  );
                                submittedData["BRI Brokerage Amount"] =
                                  multiply(
                                    Number(
                                      submittedData["BRI brokerage rate"] ?? 0
                                    ),
                                    getMinValue(
                                      submittedData["Dispatch Weight"],
                                      submittedData["Net Weight"],
                                      submittedData["Miller Bill Weight"]
                                    )
                                  );
                                submittedData["Total Freight"] =
                                  this.changeUnit(
                                    "MT",
                                    singlelistView && singlelistView.unit
                                      ? singlelistView.unit
                                      : "",
                                    multiply(
                                      divide(
                                        getMinValue(
                                          submittedData["Dispatch Weight"],
                                          submittedData["Net Weight"],
                                          submittedData["Miller Bill Weight"]
                                        ),
                                        1000
                                      ),
                                      submittedData["Freight Rate"]
                                        ? submittedData["Freight Rate"]
                                        : 0
                                    ).toFixed(2)
                                  );
                                submittedData["Transporter Balance"] = subtract(
                                  submittedData["Total Freight"]
                                    ? submittedData["Total Freight"]
                                    : 0,
                                  submittedData["Transporter Advance"]
                                    ? Number(
                                        submittedData["Transporter Advance"]
                                      )
                                    : 0
                                ).toFixed(2);
                                submittedData["TDS Amount"] = submittedData
                                  ? multiply(
                                      divide(
                                        submittedData["Miller TDS"]
                                          ? Number(submittedData["Miller TDS"])
                                          : 0,
                                        100
                                      ),
                                      submittedData["Miller Bill Amount"]
                                        ? submittedData["Miller Bill Amount"]
                                        : 0
                                    ).toFixed(2)
                                  : "-";
                                console.log(
                                  poItemsArrayTOPreviewCopy2,
                                  "selectedPVItemArray"
                              );
                                submittedData["Miller Total Amount"] =sum([ sum([
                                  Number(
                                    subtract(
                                      multiply(
                                        getMinValue(
                                          submittedData["Dispatch Weight"],
                                          submittedData["Net Weight"],
                                          submittedData["Miller Bill Weight"]
                                        ),
                                        poItemsArrayTOPreviewCopy2.length > 0
                                          ? Number(
                                              poItemsArrayTOPreviewCopy2[0][
                                                "Contract Rate"
                                              ]
                                            )
                                          : 1
                                      ),

                                      sum([
                                        Number(
                                          submittedData["Miller Advance"] ?? 0
                                        ),
                                        Number(
                                          submittedData["TDS Amount"] ?? 0
                                        ),
                                      ])
                                    )
                                  ),

                                  Number(
                                    submittedData["Transporter Advance"] ?? 0
                                  ),
                                ]),Number(submittedData["Miller GST Charge"]??0)])
                                submittedData["Miller Side Brokerage Amount"] =
                                  multiply(
                                    submittedData["Miller Side Brokerage Rate"]
                                      ? Number(
                                          submittedData[
                                            "Miller Side Brokerage Rate"
                                          ]
                                        )
                                      : 0,
                                    getMinValue(
                                      submittedData["Net Weight"]
                                        ? Number(submittedData["Net Weight"])
                                        : 0,
                                      submittedData["Miller Bill Weight"]
                                        ? Number(
                                            submittedData["Miller Bill Weight"]
                                          )
                                        : 0,
                                      submittedData["Dispatch Weight"],
                                      submittedData["dispatch_weight"]
                                        ? Number(
                                            submittedData["dispatch_weight"]
                                          )
                                        : 0
                                    )
                                  );
                                submittedData["BRI Brokerage Amount"] =
                                  multiply(
                                    submittedData["BRI brokerage rate"] *
                                      getMinValue(
                                        submittedData["Net Weight"]
                                          ? Number(submittedData["Net Weight"])
                                          : 0,
                                        submittedData["Miller Bill Weight"]
                                          ? Number(
                                              submittedData[
                                                "Miller Bill Weight"
                                              ]
                                            )
                                          : 0,
                                        submittedData["Dispatch Weight"],
                                        submittedData["dispatch_weight"]
                                          ? Number(
                                              submittedData["dispatch_weight"]
                                            )
                                          : 0
                                      )
                                  );

                                return submittedData;
                              },
                            },
                          
                            {
                              name: "Miller Bill Amount",
                              type: "textField",
                              dataType: "number",
                              disabled: false,
                              startAdornment: "₹",
                              api: "miller_bill_amount",
                              defaultValue: {
                                value:
                                  singlelistView && singlelistView.miller_accounts_details
                                    ? singlelistView.miller_accounts_details.miller_bill_amount
                                    : "",
                              },
                              onChange: async (submittedData) => {
                                console.log(submittedData,"dddddddddddddddddddddddddddd")
                                submittedData["TDS Amount"] = multiply(
                                  divide(
                                    submittedData["Miller TDS"]
                                      ? Number(submittedData["Miller TDS"])
                                      : 0,
                                    100
                                  ),
                                  submittedData["Miller Bill Amount"]
                                    ? Number(
                                        submittedData["Miller Bill Amount"]
                                      )
                                    : 0
                                ).toFixed(2);
                                return submittedData;
                              },
                            
                            },
                            {
                              name: "Miller Advance",
                              type: "textField",
                              dataType: "number",

                              onChange: async (submittedData) => {
                                submittedData["Miller Total Amount"] = sum([sum([
                                  Number(
                                    subtract(
                                      multiply(
                                        getMinValue(
                                          submittedData["Dispatch Weight"],
                                          submittedData["Net Weight"],
                                          submittedData["Miller Bill Weight"]
                                        ),
                                        poItemsArrayTOPreviewCopy2.length > 0
                                          ? Number(
                                              poItemsArrayTOPreviewCopy2[0][
                                                "Contract Rate"
                                              ]
                                            )
                                          : 1
                                      ),

                                      sum([
                                        Number(
                                          submittedData["Miller Advance"] ?? 0
                                        ),
                                        Number(
                                          submittedData["TDS Amount"] ?? 0
                                        ),
                                      ])
                                    )
                                  ),

                                  Number(
                                    submittedData["Transporter Advance"] ?? 0
                                  ),
                                ]),Number(submittedData["Miller GST Charge"]??0)])
                                return submittedData;
                              },
                              api: "miller_advance",
                              // defaultValue: {
                              //   value:
                              //     singlelistView && singlelistView.miller_accounts_details
                              //       ? singlelistView.miller_accounts_details.miller_advance
                              //       : "",
                              // },
                            },
                            {
                              name: "Miller TDS",
                              type: "textField",
                              dataType: "number",
                              endAdornment: "%",
                              onChange: async (submittedData) => {
                                submittedData["TDS Amount"] = multiply(
                                  divide(
                                    submittedData["Miller TDS"]
                                      ? Number(submittedData["Miller TDS"])
                                      : 0,
                                    100
                                  ),
                                  submittedData["Miller Bill Amount"]
                                    ? Number(
                                        submittedData["Miller Bill Amount"]
                                      )
                                    : 0
                                ).toFixed(2);

                                // submittedData["Miller Total Amount"] = sum([
                                //   submittedData["Miller Advance"]
                                //     ? -Number(submittedData["Miller Advance"])
                                //     : 0,
                                //   submittedData["Transporter Advance"]
                                //     ? Number(
                                //         submittedData["Transporter Advance"]
                                //       )
                                //     : 0,
                                //   submittedData["TDS Amount"]
                                //     ? Number(submittedData["TDS Amount"])
                                //     : 0,
                                //   submittedData["Miller Bill Amount"]
                                //     ? Number(
                                //         submittedData["Miller Bill Amount"]
                                //       )
                                //     : 0,
                                // ]).toFixed(2);
                                submittedData["Miller Total Amount"] = sum([sum([
                                  Number(
                                    subtract(
                                      multiply(
                                        getMinValue(
                                          submittedData["Dispatch Weight"],
                                          submittedData["Net Weight"],
                                          submittedData["Miller Bill Weight"]
                                        ),
                                        poItemsArrayTOPreviewCopy2.length > 0
                                          ? Number(
                                              poItemsArrayTOPreviewCopy2[0][
                                                "Contract Rate"
                                              ]
                                            )
                                          : 1
                                      ),

                                      sum([
                                        Number(
                                          submittedData["Miller Advance"] ?? 0
                                        ),
                                        Number(
                                          submittedData["TDS Amount"] ?? 0
                                        ),
                                      ])
                                    )
                                  ),

                                  Number(
                                    submittedData["Transporter Advance"] ?? 0
                                  ),
                                ]),Number(submittedData["Miller GST Charge"]??0)])
                                return submittedData;
                              },
                              api: "miller_tds",
                              defaultValue: {
                                value:
                                  singlelistView && singlelistView.miller_accounts_details
                                    ? singlelistView.miller_accounts_details.miller_tds
                                    : "",
                              },
                            },
                            {
                              name: "TDS Amount",
                              type: "textField",
                              dataType: "number",
                              disabled: true,
                              startAdornment: "₹",
                            },
                            {
                              name: "Miller GST Charge",
                              type: "textField",
                              dataType: "number",
                              // disabled: true,
                              endAdornment: "%",
                              api: "miller_gst_charge",
                              defaultValue: {
                                value:
                                  singlelistView && singlelistView.miller_accounts_details
                                    ? singlelistView.miller_accounts_details.miller_gst_charge
                                    : "",
                              },
                              onChange: async (submittedData) => {
                                submittedData["Miller Total Amount"] = sum([sum([
                                  Number(
                                    subtract(
                                      multiply(
                                        getMinValue(
                                          submittedData["Dispatch Weight"],
                                          submittedData["Net Weight"],
                                          submittedData["Miller Bill Weight"]
                                        ),
                                        poItemsArrayTOPreviewCopy2.length > 0
                                          ? Number(
                                              poItemsArrayTOPreviewCopy2[0][
                                                "Contract Rate"
                                              ]
                                            )
                                          : 1
                                      ),

                                      sum([
                                        Number(
                                          submittedData["Miller Advance"] ?? 0
                                        ),
                                        Number(
                                          submittedData["TDS Amount"] ?? 0
                                        ),
                                      ])
                                    )
                                  ),

                                  Number(
                                    submittedData["Transporter Advance"] ?? 0
                                  ),
                                ]),Number(submittedData["Miller GST Charge"]??0)])
                                return submittedData;
                              },

                            },
                            {
                              name: "Miller Side Brokerage Rate",
                              type: "textField",
                              dataType: "number",
                              // disabled: true,
                              // startAdornment: "₹",
                              // endAdornment: "%",
                              zeroAccepted: true,
                              api: "brokerage_rate_for_miller",
                              onChange: async (submittedData) => {
                                submittedData["Miller Side Brokerage Amount"] =
                                  multiply(
                                    submittedData["Miller Side Brokerage Rate"]
                                      ? Number(
                                          submittedData[
                                            "Miller Side Brokerage Rate"
                                          ]
                                        )
                                      : 0,
                                    getMinValue(
                                      submittedData["Net Weight"]
                                        ? Number(submittedData["Net Weight"])
                                        : 0,
                                      submittedData["Miller Bill Weight"]
                                        ? Number(
                                            submittedData["Miller Bill Weight"]
                                          )
                                        : 0,
                                      submittedData["Dispatch Weight"],
                                      submittedData["dispatch_weight"]
                                        ? Number(
                                            submittedData["dispatch_weight"]
                                          )
                                        : 0
                                    )
                                  );
                                return submittedData;
                              },
                            },
                            {
                              name: "Miller Side Brokerage Amount",
                              type: "textField",
                              dataType: "number",
                              disabled: true,
                              zeroAccepted: true,
                              startAdornment: "₹",
                            },
                            {
                              name: "BRI brokerage rate",
                              type: "textField",
                              dataType: "number",
                              // disabled: true,
                              // startAdornment: "₹",
                              // endAdornment: "%",

                              zeroAccepted: true,
                              api: "brokerage_rate_for_bri",
                              onChange: async (submittedData) => {
                                //console.log(submittedData, "submittedData");
                                submittedData["BRI Brokerage Amount"] =
                                  multiply(
                                    submittedData["BRI brokerage rate"],

                                    getMinValue(
                                      submittedData["Net Weight"]
                                        ? Number(submittedData["Net Weight"])
                                        : 0,
                                      submittedData["Dispatch Weight"]
                                        ? Number(
                                            submittedData["Dispatch Weight"]
                                          )
                                        : 0,
                                      submittedData["Miller Bill Weight"]
                                        ? Number(
                                            submittedData["Miller Bill Weight"]
                                          )
                                        : 0,
                                      submittedData["dispatch_weight"]
                                        ? Number(
                                            submittedData["dispatch_weight"]
                                          )
                                        : 0
                                    )
                                  );
                                return submittedData;
                              },
                            },

                            {
                              name: "BRI Brokerage Amount",
                              type: "textField",
                              dataType: "number",
                              disabled: true,
                              zeroAccepted: true,
                              startAdornment: "₹",
                              // api: "brokerage_rate_for_bri",
                              onChange: async (submittedData) => {
                                submittedData["BRI Brokerage Amount"] =
                                  multiply(
                                    submittedData["BRI brokerage rate"],
                                    getMinValue(
                                      submittedData["Net Weight"]
                                        ? Number(submittedData["Net Weight"])
                                        : 0,
                                      submittedData["Dispatch Weight"]
                                        ? Number(
                                            submittedData["Dispatch Weight"]
                                          )
                                        : 0,
                                      submittedData["Miller Bill Weight"]
                                        ? Number(
                                            submittedData["Miller Bill Weight"]
                                          )
                                        : 0,
                                      submittedData["dispatch_weight"]
                                        ? Number(
                                            submittedData["dispatch_weight"]
                                          )
                                        : 0
                                    )
                                  );
                                return submittedData;
                              },
                            },

                            // {
                            //   name: "Miller Bill Amount",
                            //   type: "textField",
                            //   dataType: "number",
                            //   disabled: true,
                            //   startAdornment: "₹",
                            //   api: "miller_bill_amount",
                            // },
                            // singlelistView &&
                            // singlelistView.contract_type_details &&
                            // singlelistView.contract_type_details
                            //   .contract_type === "EX FACTORY" &&

                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "EX FACTORY" && {
                                name: "Freight Deduction",
                                type: "textField",
                                //disabled: true,
                                dataType: "number",
                                api: "freight_deduction",
                                onChange: async (submittedData) => {
                                  submittedData["Transporter Balance"] =
                                    subtract(
                                      Number(
                                        submittedData["Total Freight"]
                                          ? submittedData["Total Freight"]
                                          : 0
                                      ),
                                      sum([
                                        Number(
                                          submittedData["Transporter Advance"]
                                            ? submittedData[
                                                "Transporter Advance"
                                              ]
                                            : 0
                                        ),
                                        submittedData["Freight Deduction"]
                                          ? Number(
                                              submittedData["Freight Deduction"]
                                            )
                                          : 0,
                                      ])
                                    ).toFixed(2);

                                  return submittedData;
                                },
                              },
                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "EX FACTORY" && {
                                name: "Freight Rate",
                                type: "textField",
                                dataType: "number",

                                api: "freight_rate",
                                startAdornment: "₹",
                                endAdornment: this.state.selectedUnit
                                  ? `/${this.state.selectedUnit}`
                                  : singlelistView && singlelistView.unit
                                  ? `/${singlelistView.unit}`
                                  : "",
                                onChange: async (submittedData) => {
                                  submittedData["Total Freight"] =
                                    this.changeUnit(
                                      "MT",
                                      singlelistView && singlelistView.unit
                                        ? singlelistView.unit
                                        : "",
                                      Math.floor(
                                        divide(
                                          selectedPVItemArray.reduce(
                                            (previousValue, currentValue) =>
                                              Number(previousValue) +
                                              Number(
                                                filterData(
                                                  poItemsArrayTOPreviewCopy2,
                                                  "po_item_id",
                                                  currentValue,
                                                  "onlyOne"
                                                )
                                                  ? filterData(
                                                      poItemsArrayTOPreviewCopy2,
                                                      "po_item_id",
                                                      currentValue,
                                                      "onlyOne"
                                                    )["packagageArrUsed"]
                                                    ? filterData(
                                                        poItemsArrayTOPreviewCopy2,
                                                        "po_item_id",
                                                        currentValue,
                                                        "onlyOne"
                                                      )[
                                                        "packagageArrUsed"
                                                      ].reduce(
                                                        (
                                                          previousValue1,
                                                          currentValue1
                                                        ) =>
                                                          Number(
                                                            previousValue1
                                                          ) +
                                                          (currentValue1.total_dispatch
                                                            ? Number(
                                                                currentValue1.total_dispatch
                                                              )
                                                            : 0),
                                                        0
                                                      )
                                                    : 0
                                                  : 0
                                              ),
                                            0
                                          ),
                                          1000
                                        ) *
                                          Number(
                                            submittedData["Freight Rate"]
                                              ? submittedData["Freight Rate"]
                                              : 0
                                          )
                                      ).toFixed(2)
                                    );

                                  submittedData["Transporter Balance"] =
                                    subtract(
                                      Number(
                                        submittedData["Total Freight"]
                                          ? submittedData["Total Freight"]
                                          : 0
                                      ),
                                      sum([
                                        Number(
                                          submittedData["Transporter Advance"]
                                            ? submittedData[
                                                "Transporter Advance"
                                              ]
                                            : 0
                                        ),
                                        submittedData["Freight Deduction"]
                                          ? Number(
                                              submittedData["Freight Deduction"]
                                            )
                                          : 0,
                                      ])
                                    ).toFixed(2);
                                  return submittedData;
                                },
                              },
                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "EX FACTORY" && {
                                name: "Transporter Advance",
                                type: "textField",
                                onChange: async (submittedData) => {
                                 submittedData["Miller Total Amount"] = sum([sum([
                                    Number(
                                      subtract(
                                        multiply(
                                          getMinValue(
                                            submittedData["Dispatch Weight"],
                                            submittedData["Net Weight"],
                                            submittedData["Miller Bill Weight"]
                                          ),
                                          poItemsArrayTOPreviewCopy2.length > 0
                                            ? Number(
                                                poItemsArrayTOPreviewCopy2[0][
                                                  "Contract Rate"
                                                ]
                                              )
                                            : 1
                                        ),

                                        sum([
                                          Number(
                                            submittedData["Miller Advance"] ?? 0
                                          ),
                                          Number(
                                            submittedData["TDS Amount"] ?? 0
                                          ),
                                        ])
                                      )
                                    ),

                                    Number(
                                      submittedData["Transporter Advance"] ?? 0
                                    ),
                                  ]),Number(submittedData["Miller GST Charge"]??0)])
                                  submittedData["Transporter Balance"] =
                                    subtract(
                                      Number(
                                        submittedData["Total Freight"]
                                          ? submittedData["Total Freight"]
                                          : 0
                                      ),
                                      sum([
                                        Number(
                                          submittedData["Transporter Advance"]
                                            ? submittedData[
                                                "Transporter Advance"
                                              ]
                                            : 0
                                        ),
                                        submittedData["Freight Deduction"]
                                          ? Number(
                                              submittedData["Freight Deduction"]
                                            )
                                          : 0,
                                      ])
                                    ).toFixed(2);

                                  return submittedData;
                                },
                                dataType: "number",
                                api: "transporter_advance",
                                startAdornment: "₹",
                              },
                            {
                              name: "Miller Total Amount",
                              type: "textField",
                              dataType: "number",
                              disabled: true,
                              startAdornment: "₹",

                              api: "miller_total_amount",
                            },

                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "EX FACTORY" && {
                                name: "Transpoter Name",
                                type: "select",
                                numeric: true,

                                dataType: "text",
                                array:
                                  TransporterList && TransporterList.length > 0
                                    ? TransporterList
                                    : [],
                                value: "name",

                                defaultValue: {
                                  value:
                                    singlelistView &&
                                    singlelistView.transporter_details
                                      ? singlelistView.transporter_details.name
                                      : "",
                                  id:
                                    singlelistView &&
                                    singlelistView.transporter_details
                                      ? singlelistView.transporter_details.id
                                      : "",
                                },
                                api: "transporter",
                              },

                            {
                              name: "Truck Number",
                              type: "textField",
                              dataType: "text",
                              api: "truck_number",
                              upperCase: true,
                            },
                            // singlelistView &&
                            //   singlelistView.contract_type_details &&
                            //   singlelistView.contract_type_details
                            //     .contract_type === "EX FACTORY" && {
                            //     name: "Freight Rate",
                            //     type: "textField",
                            //     dataType: "number",

                            //     api: "freight_rate",
                            //     startAdornment: "₹",
                            //     endAdornment: this.state.selectedUnit
                            //       ? `/${this.state.selectedUnit}`
                            //       : singlelistView && singlelistView.unit
                            //       ? `/${singlelistView.unit}`
                            //       : "",
                            //     onChange: async (submittedData) => {
                            //       submittedData["Total Freight"] = Math.floor(
                            //         selectedPVItemArray.reduce(
                            //           (previousValue, currentValue) =>
                            //             Number(previousValue) +
                            //             Number(
                            //               filterData(
                            //                 poItemsArrayTOPreviewCopy2,
                            //                 "po_item_id",
                            //                 currentValue,
                            //                 "onlyOne"
                            //               )
                            //                 ? filterData(
                            //                     poItemsArrayTOPreviewCopy2,
                            //                     "po_item_id",
                            //                     currentValue,
                            //                     "onlyOne"
                            //                   )["packagageArrUsed"]
                            //                   ? filterData(
                            //                       poItemsArrayTOPreviewCopy2,
                            //                       "po_item_id",
                            //                       currentValue,
                            //                       "onlyOne"
                            //                     )["packagageArrUsed"].reduce(
                            //                       (
                            //                         previousValue1,
                            //                         currentValue1
                            //                       ) =>
                            //                         Number(previousValue1) +
                            //                         (currentValue1.total_dispatch
                            //                           ? Number(
                            //                               currentValue1.total_dispatch
                            //                             )
                            //                           : 0),
                            //                       0
                            //                     )
                            //                   : 0
                            //                 : 0
                            //             ),
                            //           0
                            //         ) *
                            //           (submittedData["Freight Rate"]
                            //             ? submittedData["Freight Rate"]
                            //             : 0)
                            //       ).toFixed(2);

                            //       submittedData["Transporter Balance"] =
                            //         subtract(
                            //           submittedData["Total Freight"]
                            //             ? submittedData["Total Freight"]
                            //             : 0,
                            //           submittedData["Transporter Advance"]
                            //             ? Number(
                            //                 submittedData["Transporter Advance"]
                            //               )
                            //             : 0
                            //         ).toFixed(2);
                            //       return submittedData;
                            //     },
                            //   },
                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "EX FACTORY" && {
                                name: "Transporter Balance",
                                type: "textField",
                                disabled: true,
                                dataType: "number",
                                api: "transporter_balance",
                                startAdornment: "₹",
                              },

                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "EX FACTORY" && {
                                name: "Total Freight",
                                type: "textField",
                                dataType: "number",
                                api: "total_freight",
                                disabled: true,
                                startAdornment: "₹",
                              },
                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "EX FACTORY" && {
                                name: "Bilty",
                                type: "textField",
                                dataType: "text",
                                api: "bilty_number",
                                capitalize: true,
                              },
                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "EX FACTORY" && {
                                name: "Bility Date",
                                type: "Month",
                                dataType: "text",
                                api: "bilty_date",
                              },

                            {
                              name: "Survey Company",
                              type: "select",
                              dataType: "text",
                              api: "survey_company",
                              array:
                                SurveyCompanyList &&
                                SurveyCompanyList.length > 0
                                  ? SurveyCompanyList
                                  : [],
                              value: "name",
                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.survey_company &&
                                  singlelistView.survey_company
                                    ? filterData(
                                        SurveyCompanyList,
                                        "id",
                                        singlelistView.survey_company,
                                        "onlyOne"
                                      ).name
                                    : "",
                                id:
                                  singlelistView &&
                                  singlelistView.survey_company &&
                                  singlelistView.survey_company
                                    ? singlelistView.survey_company
                                    : "",
                              },
                              numeric: true,

                              onChange: async (
                                submittedData,
                                setLoad,
                                loadClose
                              ) => {
                                if (
                                  SurveyCompanyList &&
                                  SurveyCompanyList.length > 0
                                ) {
                                  if (setLoad) {
                                    setLoad();
                                  }
                                  this.props
                                    .PurchaseOrdersFunction(
                                      "get",
                                      "masters/surveyor/list",
                                      null,
                                      "",
                                      null,
                                      {
                                        is_active: "True",
                                        company_id:
                                          submittedData["Survey CompanyId"],
                                      }
                                    )
                                    .then((res) => {
                                      if (res) {
                                        this.setState(
                                          { survayorList: res },
                                          () => {
                                            if (loadClose) {
                                              loadClose();
                                            }
                                          }
                                        );
                                      }
                                    });
                                  submittedData["Surveyor"] = "";
                                  submittedData["SurveyorId"] = "";

                                  return submittedData;
                                }
                              },
                            },
                            survayorList &&
                              survayorList.length > 0 && {
                                name: "Surveyor",
                                type: "select",
                                not_required: true,

                                dataType: "text",
                                array:
                                  survayorList && survayorList.length > 0
                                    ? survayorList
                                    : [],
                                value: "name" ? "name" : "",

                                api: "surveyor",
                                numeric: true,
                                defaultValue: {
                                  value: singlelistView.surveyor_details
                                    ? singlelistView.surveyor_details.name
                                    : "",
                                  id: singlelistView.surveyor_details
                                    ? singlelistView.surveyor_details.id
                                    : "",
                                },
                              },

                            singlelistView &&
                              singlelistView.last_survey && {
                                name: "Survey Date",
                                type: "Month",
                                dataType: "text",
                                disabled: true,
                                api:
                                  singlelistView && singlelistView.last_survey
                                    ? "last_survey"
                                    : false,
                                defaultValue: {
                                  value:
                                    singlelistView && singlelistView.last_survey
                                      ? singlelistView.last_survey
                                      : "",
                                },
                              },

                            {
                              name: "Broker",
                              type: "select",
                              dataType: "text",
                              array:
                                BrokerList && BrokerList.length > 0
                                  ? BrokerList
                                  : [],
                              value: "name",

                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.broker_details
                                    ? singlelistView.broker_details.name //default_brokerage
                                    : "",
                                id:
                                  singlelistView &&
                                  singlelistView.broker_details
                                    ? singlelistView.broker_details.id
                                    : "",
                              },
                              disabled: true,
                            },

                            {
                              name: "Dispatch Status",
                              type: "select",
                              dataType: "text",
                              array:
                                statusDispatch && statusDispatch.length > 0
                                  ? statusDispatch
                                  : [],
                              value: "name",

                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.statusDispatch
                                    ? singlelistView.statusDispatch
                                    : "dispatched",
                              },
                              api: "dispatch_status",
                            },
                            {
                              name: "Miller payment",
                              datatype: "text",
                              defaultValue: {
                                value: "UNPAID",
                              },
                              api: "miller_payment",
                            },

                            // {
                            //   name: "Miller Payment",
                            //   type: "checkBox",
                            //   defaultValue: {
                            //     value: false,
                            //   },
                            //   api: "miller_payment",
                            // },
                            // singlelistView &&
                            //   singlelistView.contract_type_details &&
                            //   singlelistView.contract_type_details.contract_type ===
                            //     "EX FACTORY" && {
                            //     name: "Transporter Payment",
                            //     type: "checkBox",
                            //     defaultValue: {
                            //       value: false,
                            //     },
                            //     api: "transporter_payment",
                            //   },
                            // {
                            //   name: "Broker Payment",
                            //   type: "checkBox",
                            //   defaultValue: {
                            //     value: false,
                            //   },
                            //   api: "broker_payment",
                            // },
                          ]}
                          setSelectedList={(selected) => {
                            this.props
                              .PurchaseOrdersFunction(
                                "get",
                                "masters/surveyor/list",
                                null,
                                "",
                                null,
                                {
                                  is_active: "True",
                                  company_id: selected["Survey CompanyId"],
                                }
                              )
                              .then((res) => {
                                if (res) {
                                  this.setState({ survayorList: res });
                                } else {
                                }
                              });

                            const poItemArr = [];
                            selected.subList.map((sSub) => {
                              if (!poItemArr.includes(sSub.po_item)) {
                                poItemArr.push(sSub.po_item);
                              }
                              return sSub;
                            });

                            this.setState(
                              {
                                editingDispatch: selected,
                                poItemsArrayTOPreviewCopy2:
                                  poItemsArrayTOPreviewCopy
                                    .map((sList) => {
                                      if (poItemArr.includes(sList.id)) {
                                        const sListPackaging = filterData(
                                          selected.subList,
                                          "po_item",
                                          sList.id,
                                          "onlyOne"
                                        );

                                        const packaging_details =
                                          sListPackaging.dispatch_packaging &&
                                          sListPackaging.dispatch_packaging
                                            .length > 0
                                            ? sListPackaging.dispatch_packaging.map(
                                                (s) => {
                                                  s.size =
                                                    s.packaging_details.size;
                                                  s.unit =
                                                    s.packaging_details.unit;
                                                  s.remaining_bags = filterData(
                                                    sList.packagageArrUsed,
                                                    "id",
                                                    s.packaging_details.id,
                                                    "onlyOne"
                                                  )
                                                    ? filterData(
                                                        sList.packagageArrUsed,
                                                        "id",
                                                        s.packaging_details.id,
                                                        "onlyOne"
                                                      ).remaining_bags
                                                    : s.remaining_bags;
                                                  s.used_bags_po = filterData(
                                                    sList.packagageArrUsed,
                                                    "id",
                                                    s.packaging_details.id,
                                                    "onlyOne"
                                                  )
                                                    ? filterData(
                                                        sList.packagageArrUsed,
                                                        "id",
                                                        s.packaging_details.id,
                                                        "onlyOne"
                                                      ).used_bags_po
                                                    : s.used_bags_po;
                                                  return s;
                                                }
                                              )
                                            : [];
                                        // sData["Specifications"] = (
                                        //   <ShowSpecifications
                                        //     details={
                                        //       sData.specifications && sData.specifications.length > 0
                                        //         ? sData.specifications.map((sS) => {
                                        //             return sS;
                                        //           })
                                        //         : []
                                        //     }
                                        //   />
                                        // )
                                        //console.log(sListPackaging,"sListPackaging")
                                        const addData = {
                                          brand: sListPackaging.brand,
                                          po_item: sListPackaging.po_item,
                                          packagingArr: packaging_details.map(
                                            (sP) => {
                                              sP.sizeUnit = `${sP.size} ${sP.unit}`;
                                              return sP;
                                            }
                                          ),
                                          total_quantity:
                                            sListPackaging.total_quantity,

                                          packaging: sListPackaging.packaging,
                                        };

                                        addData["id"] = sListPackaging.id;

                                        addData.po_item_id =
                                          sListPackaging.po_item;

                                        // addData["used_bags"] = sListPackaging.used_bags ? sListPackaging.used_bags : null;
                                        addData["dispatched_quantity"] =
                                          sListPackaging.dispatched_quantity;
                                        addData[poItemheader[1]] = filterData(
                                          ItemCateoryList,
                                          "id",
                                          sListPackaging.item_category,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              ItemCateoryList,
                                              "id",
                                              sListPackaging.item_category,
                                              "onlyOne"
                                            ).name
                                          : "";

                                        addData[`${poItemheader[1]}id`] =
                                          sListPackaging.item_category;

                                        addData[poItemheader[2]] = filterData(
                                          ItemList,
                                          "id",
                                          sListPackaging.item_type,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              ItemList,
                                              "id",
                                              sListPackaging.item_type,
                                              "onlyOne"
                                            ).broken_percentage
                                          : "";
                                        addData[`${poItemheader[2]}id`] =
                                          sListPackaging.item_type &&
                                          sListPackaging.item_type
                                            ? sListPackaging.item_type
                                            : sListPackaging[
                                                `${poItemheader[2]}id`
                                              ]
                                            ? sListPackaging[
                                                `${poItemheader[2]}id`
                                              ]
                                            : "";

                                        // //console.log(
                                        //   sListPackaging,
                                        //   "sListPackaging"
                                        // );
                                        addData[poItemheader[0]] =
                                          sListPackaging.brand_details &&
                                          sListPackaging.brand_details
                                            .brand_name;
                                        addData[`${poItemheader[0]}id`] =
                                          sListPackaging.brand_details &&
                                          sListPackaging.brand_details.id;

                                        addData.selectedBrandMultipleName = `${
                                          sListPackaging.brand
                                        }${sListPackaging.brand ? "-" : ""}${
                                          filterData(
                                            ItemCateoryList,
                                            "id",
                                            sListPackaging.item_category,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                ItemCateoryList,
                                                "id",
                                                sListPackaging.item_category,
                                                "onlyOne"
                                              ).name
                                            : ""
                                        }-${
                                          filterData(
                                            ItemList,
                                            "id",
                                            sListPackaging.item_type,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                ItemList,
                                                "id",
                                                sListPackaging.item_type,
                                                "onlyOne"
                                              ).broken_percentage
                                            : ""
                                        } %`;

                                        addData[poItemheader[3]] =
                                          packaging_details.map((sP) => {
                                            return `${sP.size} ${sP.unit}`;
                                          }) &&
                                          packaging_details.map((sP) => {
                                            return `${sP.size} ${sP.unit}`;
                                          }).length > 0
                                            ? packaging_details.map((sP) => {
                                                return `${sP.size} ${sP.unit}`;
                                              })[0]
                                            : "-";
                                        addData["packagageArrUsed"] =
                                          packaging_details.map((sP) => {
                                            const newObj = {};
                                            newObj.id = sP.id;

                                            newObj.bag_type =
                                              sP.packaging_details.bag_type;
                                            newObj.packaging = sP.packaging;

                                            newObj.dispatch_items =
                                              sP.dispatch_items
                                                ? sP.dispatch_items
                                                : "";

                                            newObj.net_weight = sP.net_weight
                                              ? sP.net_weight
                                              : "0.000";

                                            newObj.size = sP.size;
                                            newObj.unit = sP.unit;
                                            newObj.sizeUnit = `${sP.size} ${sP.unit}`;
                                            newObj.total_bags =
                                              sP.remaining_bags;
                                            newObj.remaining_bags =
                                              sP.remaining_bags;

                                            newObj.total_dispatch = multiply(
                                              Number(
                                                sP.total_bags_dispatched
                                                  ? sP.total_bags_dispatched
                                                  : 0
                                              ),
                                              this.changeUnit(
                                                sP.unit,
                                                singlelistView &&
                                                  singlelistView.unit
                                                  ? singlelistView.unit
                                                  : "",
                                                sP.size
                                              )
                                            );

                                            newObj.total_amount = Math.floor(
                                              (sListPackaging["Contract Rate"]
                                                ? sListPackaging[
                                                    "Contract Rate"
                                                  ]
                                                : 0) *
                                                this.changeUnit(
                                                  sP.unit,
                                                  singlelistView &&
                                                    singlelistView.unit
                                                    ? singlelistView.unit
                                                    : "",
                                                  sP.size
                                                ) *
                                                (sP.total_bags_dispatched
                                                  ? sP.total_bags_dispatched
                                                  : 0)
                                            );

                                            newObj.used_bags =
                                              sP.total_bags_dispatched
                                                ? sP.total_bags_dispatched
                                                : 0;
                                            newObj.used_bags_fordiapatch =
                                              sP.total_bags_dispatched
                                                ? sP.total_bags_dispatched
                                                : 0;
                                            newObj.used_bags_po =
                                              sP.used_bags_po;

                                            return newObj;
                                          });
                                        addData["PackagingId"] =
                                          packaging_details;
                                        addData[poItemheader[4]] =
                                          HSNlist &&
                                          HSNlist.length > 0 &&
                                          HSNlist.filter(
                                            (sM) =>
                                              sM.id ===
                                              (sListPackaging.hsn_code
                                                ? sListPackaging.hsn_code
                                                : 0)
                                          )[0]
                                            ? HSNlist.filter(
                                                (sM) =>
                                                  sM.id ===
                                                  (sListPackaging.hsn_code
                                                    ? sListPackaging.hsn_code
                                                    : 0)
                                              )[0].hsn_code
                                            : "";

                                        addData[`${poItemheader[4]}id`] =
                                          sListPackaging.hsn_code
                                            ? sListPackaging.hsn_code
                                            : "";
                                        addData[poItemheader[5]] =
                                          sListPackaging.total_quantity;
                                        addData[`${poItemheader[5]}Unit`] = `${
                                          sListPackaging.total_quantity
                                        } ${
                                          singlelistView && singlelistView.unit
                                            ? singlelistView.unit
                                            : ""
                                        }`;

                                        addData[poItemheader[6]] = addData[
                                          poItemheader[6]
                                        ] = sList["Contract Rate"]
                                          ? sList["Contract Rate"]
                                          : sList.contract_rate;
                                        addData["specificationArr"] =
                                          sList["Specifications"];
                                        addData["Specifications"] =
                                          sList["Specifications"];

                                        addData[`${poItemheader[6]}Unit`] = `${
                                          sList["Contract Rate"]
                                            ? sList["Contract Rate"]
                                            : sList.contract_rate
                                        }/${
                                          singlelistView && singlelistView.unit
                                            ? singlelistView.unit
                                            : ""
                                        }`;
                                        addData.id = sListPackaging.id;

                                        return addData;
                                      } else {
                                        return false;
                                      }
                                    })
                                    .filter((sD) => sD !== false),
                                // poItemsArrayTOPreviewCopy:
                                //   poItemsArrayTOPreviewCopy
                                poItemsArrayTOPreview: poItemsArrayTOPreviewCopy
                                  .map((sList) => {
                                    if (poItemArr.includes(sList.id)) {
                                      const sListPackaging = filterData(
                                        selected.subList,
                                        "po_item",
                                        sList.id,
                                        "onlyOne"
                                      );

                                      const packaging_details =
                                        sListPackaging.dispatch_packaging &&
                                        sListPackaging.dispatch_packaging
                                          .length > 0
                                          ? sListPackaging.dispatch_packaging.map(
                                              (s) => {
                                                s.size =
                                                  s.packaging_details.size;
                                                s.unit =
                                                  s.packaging_details.unit;
                                                s.remaining_bags = filterData(
                                                  sList.packagageArrUsed,
                                                  "id",
                                                  s.packaging_details.id,
                                                  "onlyOne"
                                                )
                                                  ? filterData(
                                                      sList.packagageArrUsed,
                                                      "id",
                                                      s.packaging_details.id,
                                                      "onlyOne"
                                                    ).remaining_bags
                                                  : s.remaining_bags;
                                                s.used_bags_po = filterData(
                                                  sList.packagageArrUsed,
                                                  "id",
                                                  s.packaging_details.id,
                                                  "onlyOne"
                                                )
                                                  ? filterData(
                                                      sList.packagageArrUsed,
                                                      "id",
                                                      s.packaging_details.id,
                                                      "onlyOne"
                                                    ).used_bags_po
                                                  : s.used_bags_po;
                                                return s;
                                              }
                                            )
                                          : [];

                                      const addData = {
                                        brand: sListPackaging.brand,
                                        po_item: sListPackaging.po_item,
                                        // hsn_code: sListPackaging.hsn_code ? sListPackaging.hsn_code : '',
                                        packagingArr: packaging_details.map(
                                          (sP) => {
                                            sP.sizeUnit = `${sP.size} ${sP.unit}`;
                                            return sP;
                                          }
                                        ),
                                        total_quantity:
                                          sListPackaging.total_quantity,

                                        packaging: sListPackaging.packaging,
                                      };
                                      addData["id"] = sListPackaging.id;

                                      addData.po_item_id =
                                        sListPackaging.po_item;
                                      addData["dispatched_quantity"] =
                                        sListPackaging.dispatched_quantity;
                                      addData[poItemheader[1]] = filterData(
                                        ItemCateoryList,
                                        "id",
                                        sListPackaging.item_category,
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            ItemCateoryList,
                                            "id",
                                            sListPackaging.item_category,
                                            "onlyOne"
                                          ).name
                                        : "";
                                      addData[`${poItemheader[1]}id`] =
                                        sListPackaging.item_category;

                                      addData[poItemheader[2]] = filterData(
                                        ItemList,
                                        "id",
                                        sListPackaging.item_type,
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            ItemList,
                                            "id",
                                            sListPackaging.item_type,
                                            "onlyOne"
                                          ).broken_percentage
                                        : "";
                                      addData[`${poItemheader[2]}id`] =
                                        sListPackaging.item_type
                                          ? sListPackaging.item_type
                                          : sListPackaging[
                                              `${poItemheader[2]}id`
                                            ]
                                          ? sListPackaging[
                                              `${poItemheader[2]}id`
                                            ]
                                          : "";

                                      addData[poItemheader[0]] =
                                        sListPackaging.brand_details &&
                                        sListPackaging.brand_details.brand_name;
                                      addData[`${poItemheader[0]}id`] =
                                        sListPackaging.brand_details &&
                                        sListPackaging.brand_details.id;

                                      addData.selectedBrandMultipleName = `${
                                        sListPackaging.brand
                                      }${sListPackaging.brand ? "-" : ""}${
                                        filterData(
                                          ItemCateoryList,
                                          "id",
                                          sListPackaging.item_category,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              ItemCateoryList,
                                              "id",
                                              sListPackaging.item_category,
                                              "onlyOne"
                                            ).name
                                          : ""
                                      }-${
                                        filterData(
                                          ItemList,
                                          "id",
                                          sListPackaging.item_type,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              ItemList,
                                              "id",
                                              sListPackaging.item_type,
                                              "onlyOne"
                                            ).broken_percentage
                                          : ""
                                      } %`;

                                      addData[poItemheader[3]] =
                                        packaging_details.map((sP) => {
                                          return `${sP.size} ${sP.unit}`;
                                        }) &&
                                        packaging_details.map((sP) => {
                                          return `${sP.size} ${sP.unit}`;
                                        }).length > 0
                                          ? packaging_details.map((sP) => {
                                              return `${sP.size} ${sP.unit}`;
                                            })[0]
                                          : "-";

                                      addData["packagageArrUsed"] =
                                        packaging_details.map((sP) => {
                                          const newObj = {};
                                          newObj.id = sP.id;
                                          newObj.bag_type =
                                            sP.packaging_details.bag_type;
                                          newObj.packaging = sP.packaging;

                                          newObj.dispatch_items =
                                            sP.dispatch_items;

                                          newObj.net_weight = sP.net_weight
                                            ? sP.net_weight
                                            : "0.000";

                                          newObj.size = sP.size;
                                          newObj.unit = sP.unit;
                                          newObj.sizeUnit = `${sP.size} ${sP.unit}`;
                                          newObj.total_bags = sP.remaining_bags;
                                          newObj.remaining_bags =
                                            sP.remaining_bags;

                                          newObj.total_dispatch = multiply(
                                            Number(
                                              sP.total_bags_dispatched
                                                ? sP.total_bags_dispatched
                                                : 0
                                            ),
                                            this.changeUnit(
                                              sP.unit,
                                              singlelistView &&
                                                singlelistView.unit
                                                ? singlelistView.unit
                                                : "",
                                              sP.size
                                            )
                                          );

                                          newObj.total_amount = Math.floor(
                                            (sListPackaging["Contract Rate"]
                                              ? sListPackaging["Contract Rate"]
                                              : 0) *
                                              this.changeUnit(
                                                sP.unit,
                                                singlelistView &&
                                                  singlelistView.unit
                                                  ? singlelistView.unit
                                                  : "",
                                                sP.size
                                              ) *
                                              (sP.total_bags_dispatched
                                                ? sP.total_bags_dispatched
                                                : 0)
                                          );
                                          newObj.used_bags =
                                            sP.total_bags_dispatched
                                              ? sP.total_bags_dispatched
                                              : 0;

                                          newObj.used_bags_fordiapatch =
                                            sP.total_bags_dispatched
                                              ? sP.total_bags_dispatched
                                              : 0;
                                          newObj.used_bags_po = sP.used_bags_po;
                                          return newObj;
                                        });
                                      addData["PackagingId"] =
                                        packaging_details;
                                      addData[poItemheader[4]] =
                                        HSNlist &&
                                        HSNlist.length > 0 &&
                                        HSNlist.filter(
                                          (sM) =>
                                            sM.id ===
                                            (sListPackaging.hsn_code
                                              ? sListPackaging.hsn_code
                                              : 0)
                                        )[0]
                                          ? HSNlist.filter(
                                              (sM) =>
                                                sM.id ===
                                                (sListPackaging.hsn_code
                                                  ? sListPackaging.hsn_code
                                                  : 0)
                                            )[0].hsn_code
                                          : "";

                                      addData[`${poItemheader[4]}id`] =
                                        sListPackaging.hsn_code
                                          ? sListPackaging.hsn_code
                                          : "";
                                      addData[poItemheader[5]] =
                                        sListPackaging.total_quantity;
                                      addData[`${poItemheader[5]}Unit`] = `${
                                        sListPackaging.total_quantity
                                      } ${
                                        singlelistView && singlelistView.unit
                                          ? singlelistView.unit
                                          : ""
                                      }`;

                                      addData[`${poItemheader[6]}Unit`] = `${
                                        sListPackaging["Contract Rate"]
                                          ? sListPackaging["Contract Rate"]
                                          : sListPackaging.contract_rate
                                      }/${
                                        singlelistView && singlelistView.unit
                                          ? singlelistView.unit
                                          : ""
                                      }`;
                                      addData[poItemheader[7]] =
                                        sListPackaging.Specifications;
                                      addData[poItemheader[6]] = addData[
                                        poItemheader[6]
                                      ] = sList["Contract Rate"]
                                        ? sList["Contract Rate"]
                                        : sList.contract_rate;
                                      addData["specificationArr"] =
                                        sList["Specifications"];
                                      addData["Specifications"] =
                                        sList["Specifications"];

                                      addData[`${poItemheader[6]}Unit`] = `${
                                        sList["Contract Rate"]
                                          ? sList["Contract Rate"]
                                          : sList.contract_rate
                                      }/${
                                        singlelistView && singlelistView.unit
                                          ? singlelistView.unit
                                          : ""
                                      }`;
                                      addData.id = sListPackaging.id;

                                      // addData[poItemheader[7]] = sListPackaging[
                                      //   "Specifications"
                                      // ]
                                      //   ? sListPackaging["Specifications"]
                                      //   : sListPackaging.specifications;
                                      return addData;
                                    } else {
                                      return false;
                                    }
                                  })
                                  .filter((sD) => sD !== false),
                                selectedPVItemArray: poItemsArrayTOPreviewCopy
                                  .map((sList) => {
                                    if (poItemArr.includes(sList.id)) {
                                      return sList;
                                    } else {
                                      return false;
                                    }
                                  })
                                  .filter((sD) => sD !== false)
                                  .map((s) => {
                                    return s.id;
                                  }),
                              },
                              () => {}
                            );
                          }}
                          postData={this.props.PurchaseOrdersFunction}
                          fetchAgain={() => {
                            this.setState({ editingDispatch: false });
                            this.props
                              .PurchaseOrdersFunction(
                                "get",
                                EndPoints["PurchaseOrders"],
                                null,
                                null,
                                null,
                                null,
                                null,
                                true
                              )
                              .then((res) => {
                                if (
                                  (this.state.singlelistView &&
                                    this.state.singlelistView.id) ||
                                  (this.props.params.id &&
                                    res &&
                                    res.length > 0 &&
                                    res
                                      .sort((a, b) => b.id - a.id)
                                      .filter((sD) => {
                                        if (sD.status) {
                                          return (
                                            sD.status ===
                                            this.state.selctedStatus
                                          );
                                        } else {
                                          return null;
                                        }
                                      }) &&
                                    res
                                      .sort((a, b) => b.id - a.id)
                                      .filter((sD) => {
                                        if (sD.status) {
                                          return (
                                            sD.status ===
                                            this.state.selctedStatus
                                          );
                                        } else {
                                          return null;
                                        }
                                      })[this.props.params.id])
                                ) {
                                  this.listClickHandler(
                                    res
                                      .sort((a, b) => b.id - a.id)
                                      .filter((sD) => {
                                        if (sD.status) {
                                          return (
                                            sD.status ===
                                            this.state.selctedStatus
                                          );
                                        } else {
                                          return null;
                                        }
                                      })[this.props.params.id]
                                  );
                                }
                              });
                          }}
                          noAddButton={
                            (singlelistView &&
                              singlelistView.status &&
                              singlelistView.status === "CLOSED") ||
                            (singlelistView &&
                              singlelistView.status &&
                              singlelistView.status === "CANCELLED" &&
                              true)
                          }
                          actions={
                            (singlelistView &&
                              singlelistView.status &&
                              singlelistView.status === "CLOSED") ||
                            (singlelistView &&
                              singlelistView.status &&
                              singlelistView.status === "CANCELLED" &&
                              [])
                          }
                        />
                        {/* {detailDialog(true)} */}
                      </>
                    ),
                },

                {
                  name: "Survey Report",
                  component: (
                    <>
                      <div
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                          minWidth: "100%",
                          display: "flex",
                        }}>
                        <Paper
                          style={{
                            width: "calc(100% - 33.33px)",
                            // marginRight: "25px",
                            boxShadow: "1px 2px 6px 1px #d3d3d38f",
                            padding: "10px",
                            background: "rgb(0 0 0 / 1%)",
                            pointerEvents: "none",
                            margin: "5px 15px 5px 5px",
                          }}>
                          <div style={styles.heading1}>PO Number</div>
                          <div style={styles.subHeading}>
                            {singlelistView && singlelistView.po_number
                              ? singlelistView && singlelistView.po_number
                              : ""}
                          </div>

                          <div style={styles.heading1}>
                            Miller Name & Address
                          </div>
                          <div style={styles.subHeading}>{`${
                            singlelistView &&
                            singlelistView.miller_details &&
                            singlelistView.miller_details.name
                              ? singlelistView.miller_details.name
                              : ""
                          }, ${
                            singlelistView &&
                            singlelistView.miller_details.address
                              ? singlelistView.miller_details.address
                              : ""
                          }`}</div>
                        </Paper>

                        <Paper
                          style={{
                            width: "calc(100% - 33.33px)",
                            // marginRight: "25px",
                            margin: "5px 15px 5px 5px",
                            boxShadow: "1px 2px 6px 1px #d3d3d38f",
                            padding: "10px",
                            background: "rgb(0 0 0 / 1%)",
                            pointerEvents: "none",
                          }}>
                          <div style={styles.heading1}>Miller GST No.</div>
                          <div style={styles.subHeading}>
                            {singlelistView &&
                            singlelistView.miller_details &&
                            singlelistView.miller_details
                              ? singlelistView.miller_details.gstin
                              : ""}
                          </div>

                          <div style={styles.heading1}>Ports</div>
                          <div style={styles.subHeading}>
                            {singlelistView &&
                            singlelistView.ports_details &&
                            singlelistView.ports_details.length > 0
                              ? singlelistView.ports_details.map((s) => {
                                  return s.city;
                                })
                              : []}
                          </div>
                        </Paper>
                        <Paper
                          style={{
                            width: "calc(100% - 33.33px)",
                            // marginRight: "25px",
                            margin: "5px 5px 5px 5px",
                            padding: "10px",
                            boxShadow: "1px 2px 6px 1px #d3d3d38f",
                            background: "rgb(0 0 0 / 1%)",
                            pointerEvents: "none",
                          }}>
                          <div style={styles.heading1}>Contact Person</div>
                          <div style={styles.subHeading}>
                            {singlelistView &&
                              singlelistView.miller_details.contact_person}
                          </div>

                          <div style={styles.heading1}>Contract Type</div>
                          <div style={styles.subHeading}>
                            {singlelistView &&
                            singlelistView.contract_type_details
                              ? singlelistView.contract_type_details
                                  .contract_type
                              : ""}
                          </div>
                        </Paper>
                      </div>
                      {/* <CssTextField
                        required={true}
                        //disabled
                        type="text"
                        autoComplete="off"
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "0px 10px 0 10px",
                            pointerEvents: "none",
                          } //disabled={tp}
                        }
                        id="outlined-basic"
                        label="PO Number"
                        name="PO Number"
                        value={
                          singlelistView && singlelistView.po_number
                            ? singlelistView && singlelistView.po_number
                            : ""
                        }
                        onChange={this.handleChange}
                      />
                      <CssTextField
                        required={true}
                        // disabled
                        type="text"
                        autoComplete="off"
                        style={{
                          width: "calc(25% - 20px)",
                          margin: "0px 10px 0 10px",
                          pointerEvents: "none",
                        }}
                        id="outlined-basic"
                        label="MillerName & Address"
                        // variant="outlined"
                        name="MillerName & Address"
                        value={`${
                          singlelistView &&
                          singlelistView.miller_details &&
                          singlelistView.miller_details.name
                            ? singlelistView.miller_details.name
                            : ""
                        }, ${
                          singlelistView &&
                          singlelistView.miller_details.address
                            ? singlelistView.miller_details.address
                            : ""
                        }`}
                        onChange={this.handleChange}
                      />
                      <CssTextField
                        required={true}
                        //disabled
                        type="text"
                        autoComplete="off"
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "0px 10px 0 10px",
                            pointerEvents: "none",
                          } //disabled={tp}
                        }
                        id="outlined-basic"
                        label="Miller GST NO."
                        // variant="outlined"
                        name="Miller GST NO."
                        value={
                          singlelistView &&
                          singlelistView.miller_details &&
                          singlelistView.miller_details
                            ? singlelistView.miller_details.gstin
                            : ""
                        }
                        onChange={this.handleChange}
                      />
                      <CssTextField
                        required={true}
                        //disabled
                        type="text"
                        autoComplete="off"
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "0px 10px 0 10px",
                            pointerEvents: "none",
                          } //disabled={tp}
                        }
                        id="outlined-basic"
                        label="Contact Person"
                        // variant="outlined"
                        name="Contact Person"
                        value={
                          singlelistView &&
                          singlelistView.miller_details.contact_person
                        }
                        onChange={this.handleChange}
                      />
                      <CssTextField
                        required={true}
                        // disabled
                        type="text"
                        autoComplete="off"
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "10px 10px 0 10px",
                            pointerEvents: "none",
                          } //disabled={tp}
                        }
                        id="outlined-basic"
                        label="Contract Type"
                        // variant="outlined"
                        name="Contract Type"
                        value={
                          singlelistView && singlelistView.contract_type_details
                            ? singlelistView.contract_type_details.contract_type
                            : ""
                        }
                        onChange={this.handleChange}
                      />
                      <CssTextField
                        required={true}
                        //disabled
                        type="text"
                        autoComplete="off"
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "10px 10px 0 10px",
                            pointerEvents: "none",
                          } //disabled={tp}
                        }
                        id="outlined-basic"
                        label="Ports"
                        // variant="outlined"
                        name="Ports"
                        value={
                          singlelistView &&
                          singlelistView.ports_details &&
                          singlelistView.ports_details.length > 0
                            ? singlelistView.ports_details.map((s) => {
                                return s.city;
                              })
                            : []
                        }
                        onChange={this.handleChange}
                      /> */}
                      <TableComponent
                        noAddButton={
                          (singlelistView &&
                            singlelistView.status &&
                            singlelistView.status === "CLOSED") ||
                          (singlelistView &&
                            singlelistView.status &&
                            singlelistView.status === "CANCELLED" &&
                            true)
                        }
                        actions={
                          (singlelistView &&
                            singlelistView.status &&
                            singlelistView.status === "CLOSED") ||
                          (singlelistView &&
                            singlelistView.status &&
                            singlelistView.status === "CANCELLED" &&
                            [])
                        }
                        loader={loadingRight}
                        subMenu={true}
                        noAddAnother={true}
                        customCretae={"survay report"}
                        tableValueDetailArray={surveyorItemArrayCopy}
                        module={"Survey Report"}
                        postApi="dispatch/purchase-order/survey-report/create"
                        editApi="dispatch/purchase-order/survey-report/item/bulk-update"
                        editOnlyParam={true}
                        subMenuheader={survayItemheader}
                        {...this.state}
                        header={["Survey Number", "Survey Date"]}
                        reset={() => {
                          const errorMsg = this.state.errorMsg;
                          //   errorMsg.SamplingDate=false;
                          errorMsg.survayor = false;
                          errorMsg.survayorCompany = false;
                          errorMsg.REMARK = false;
                          errorMsg.Double = false;
                          errorMsg.Foreign = false;
                          errorMsg.Paddy = false;
                          errorMsg.Length = false;
                          errorMsg.Chalky = false;
                          errorMsg.Damaged = false;
                          errorMsg.Broken = false;
                          errorMsg.Moisture = false;
                          errorMsg.BrandName = false;
                          this.setState({
                            errorMsg,
                            Broken: "",
                            Moisture: "",
                            ItemCategoryId: "",
                            ItemCategory: "",
                            ItemType: "",
                            itemsId: "",
                            BrandNameId: "",
                            BrandName: false,
                            Length: "",
                            Damaged: "",
                            Chalky: "",
                            Paddy: "",
                            Foreign: "",
                            Double: "",
                            REMARK: "",
                            survayor: "",
                            survayorId: "",
                            survayorCompany: "",
                            survayorCompanyId: "",
                            selectedBrandMultipleName: false,
                            selectedPoIdinSiurvayor: "",
                            surveyorItemArray: [],
                            surveyorItemArrayCopy: [],
                            // SamplingDate:""
                          });
                        }}
                        settError={() => {
                          const errorMsg = this.state.errorMsg;
                          //  errorMsg.SamplingDate="Please give an Sampling Date";
                          errorMsg.survayor = "Please give an Surveyor!";
                          errorMsg.survayorCompany =
                            "Please give an SurveyorCompany!";
                          errorMsg.REMARK = "Please give an Contract REMARK!";
                          errorMsg.Double = "Please select an Double Polish!";
                          errorMsg.Foreign = "Please give an Foreign Matter!";
                          errorMsg.Paddy = "Please give an Paddy!";
                          errorMsg.Length = "Please give an Length!";
                          errorMsg.Chalky = "Please give an Chalky!";
                          errorMsg.Damaged =
                            "Please give an Damaged/Discoloured!";
                          errorMsg.Broken = "Please give an Broken!";
                          errorMsg.Moisture = "Please select an Moisture!";
                          errorMsg.BrandName = "Please select an BrandName!";
                          this.setState({ errorMsg });
                        }}
                        tableData={(editList, submittedData) => {
                          return editList
                            ? SurvayITemTable(
                                "editSurvey",
                                surveyorItemArray,
                                "noTotalQuantity",
                                "showTotal",
                                null,
                                "showbagOnly",
                                submittedData
                              )
                            : SurvayITemTable(
                                "edit",
                                surveyorItemArray,
                                "noTotalQuantity",
                                "showTotal",
                                null,
                                null,
                                submittedData
                              );
                        }}
                        {...this.props}
                        setSelectedList={(data) => {
                          this.setState(
                            {
                              surveyorItemArray: data.subList,
                              surveyorItemArrayCopy: data.subList,
                            },
                            () => {
                              this.setWidth(
                                this.state.surveyorItemArray,
                                survayItemheader
                              );
                            }
                          );
                        }}
                        tableList={
                          surveyReport && surveyReport.length > 0
                            ? surveyReport.map((singleSReport, i) => {
                                const newObj = singleSReport;
                                newObj["Survey Number"] =
                                  i + 1 > 9 ? i + 1 : `0${i + 1}`;
                                newObj["Survey Date"] =
                                  singleSReport.subList &&
                                  singleSReport.subList.length > 0 &&
                                  singleSReport.subList[0].sampling_date
                                    ? moment(
                                        singleSReport.subList[0].sampling_date
                                      ).format("ddd, MMM Do YYYY")
                                    : "";
                                newObj["Survey DateMonth"] =
                                  singleSReport.subList &&
                                  singleSReport.subList.length > 0 &&
                                  singleSReport.subList[0].sampling_date
                                    ? singleSReport.subList[0].sampling_date
                                    : "";

                                newObj["subList"] = singleSReport.subList.map(
                                  (singleItem) => {
                                    //console.log(singleItem, "singledddItem");

                                    const brandData =
                                      this.state.poItemsArrayTOPreview?.length >
                                      0
                                        ? this.state.poItemsArrayTOPreview.find(
                                            (dis) =>
                                              dis.id === singleItem.po_item
                                          )
                                        : {};

                                    singleItem["Brand"] =
                                      brandData && brandData.brand_details
                                        ? brandData.brand_details.brand_name
                                        : singleItem.brand_details
                                        ? singleItem.brand_details.brand_name ??
                                          ""
                                        : "";
                                    singleItem["BrandId"] =
                                      brandData && brandData.brand_details
                                        ? brandData.brand_details.id
                                        : singleItem.brand_details
                                        ? singleItem.brand_details.id
                                        : "";
                                    singleItem["brand_details"] =
                                      brandData && brandData.brand_details
                                        ? brandData.brand_details
                                        : singleItem.brand_details;
                                    const brandSelected = [
                                      singleItem.brand_details,
                                    ];
                                    //console.log(singleItem, "singleItem");
                                    const CategorySelected = filterData(
                                      ItemCateoryList,
                                      "id",
                                      brandSelected &&
                                        brandSelected[0].item_category &&
                                        brandSelected[0].item_category,
                                      "onlyOne"
                                    )
                                      ? filterData(
                                          ItemCateoryList,
                                          "id",
                                          brandSelected &&
                                            brandSelected[0].item_category &&
                                            brandSelected[0].item_category,
                                          "onlyOne"
                                        ).name
                                      : "";
                                    let typeSelected = filterData(
                                      ItemList,
                                      "id",
                                      brandSelected &&
                                        brandSelected[0].item_type &&
                                        brandSelected[0].item_type,
                                      "onlyOne"
                                    )
                                      ? filterData(
                                          ItemList,
                                          "id",
                                          brandSelected &&
                                            brandSelected[0].item_type &&
                                            brandSelected[0].item_type,
                                          "onlyOne"
                                        ).broken_percentage
                                      : "";
                                    typeSelected = typeSelected;

                                    singleItem[survayItemheader[1]] =
                                      CategorySelected;
                                    singleItem[`${survayItemheader[1]}id`] =
                                      brandSelected &&
                                      brandSelected[0].item_category &&
                                      brandSelected[0].item_category;
                                    singleItem[survayItemheader[2]] =
                                      typeSelected;
                                    singleItem[`${survayItemheader[2]}id`] =
                                      brandSelected &&
                                      brandSelected[0].item_type &&
                                      brandSelected[0].item_type;
                                    singleItem[
                                      `${survayItemheader[2]}Unit`
                                    ] = `${typeSelected} %`;
                                    singleItem[survayItemheader[3]] =
                                      singleItem.moisture;
                                    singleItem[survayItemheader[4]] =
                                      singleItem.broken;
                                    singleItem[survayItemheader[4]] =
                                      singleItem.broken;
                                    singleItem[survayItemheader[5]] =
                                      singleItem.damaged;
                                    singleItem[survayItemheader[6]] =
                                      singleItem.chalky;
                                    singleItem[survayItemheader[7]] =
                                      singleItem.length;
                                    singleItem[survayItemheader[8]] =
                                      singleItem.paddy;
                                    singleItem[survayItemheader[9]] =
                                      singleItem.foreign_material;
                                    singleItem[survayItemheader[10]] =
                                      singleItem.double_polish;
                                    singleItem[survayItemheader[11]] =
                                      singleItem.remark;

                                    singleItem[survayItemheader[12]] =
                                      filterData(
                                        SurveyCompanyList,
                                        "id",
                                        singleItem.survey_company,
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            SurveyCompanyList,
                                            "id",
                                            singleItem.survey_company,
                                            "onlyOne"
                                          ).name
                                        : "";

                                    singleItem[`${survayItemheader[12]}id`] =
                                      singleItem.survey_company;
                                    singleItem[survayItemheader[13]] =
                                      filterData(
                                        allsurveyorList,
                                        "id",
                                        singleItem.surveyor,
                                        "onlyOne"
                                      ) && singleItem.Surveyorid
                                        ? filterData(
                                            allsurveyorList,
                                            "id",
                                            singleItem.surveyor,
                                            "onlyOne"
                                          ).name
                                        : "";

                                    singleItem[`${survayItemheader[13]}id`] =
                                      singleItem.surveyor;
                                    singleItem[survayItemheader[14]] =
                                      singleItem.sampling_date;
                                    return singleItem;
                                  }
                                );
                                return newObj;
                              })
                            : []
                        }
                        inputArray={[
                          {
                            name: "PO Number",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView && singlelistView.po_number
                                  ? singlelistView && singlelistView.po_number
                                  : "",
                            },
                            api: "purchase_order",
                          },
                          {
                            name: "MillerName & Address",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value: `${
                                singlelistView &&
                                singlelistView.miller_details &&
                                singlelistView.miller_details.name
                                  ? singlelistView.miller_details.name
                                  : ""
                              }, ${
                                singlelistView &&
                                singlelistView.miller_details &&
                                singlelistView.miller_details.address
                                  ? singlelistView.miller_details.address
                                  : ""
                              }`,
                              id:
                                singlelistView &&
                                singlelistView.miller_details &&
                                singlelistView.miller_details
                                  ? singlelistView.miller_details.id
                                  : "",
                            },
                            api: "miller",
                          },
                          {
                            name: "Miller GST NO.",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView &&
                                singlelistView.miller_details &&
                                singlelistView.miller_details
                                  ? singlelistView.miller_details.gstin
                                  : "",
                            },
                          },
                          {
                            name: "Contract Type",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView &&
                                singlelistView.contract_type_details
                                  ? singlelistView.contract_type_details
                                      .contract_type
                                  : "",
                            },
                          },
                          {
                            name: "Contact Person",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView &&
                                singlelistView.miller_details.contact_person,
                            },
                          },
                          {
                            name: "Ports",
                            type: "multiInput",
                            dataType: "text",
                            array:
                              singlelistView &&
                              singlelistView.ports_details &&
                              singlelistView.ports_details.length > 0
                                ? singlelistView.ports_details
                                : [],
                            value: "city",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView &&
                                singlelistView.ports_details &&
                                singlelistView.ports_details.length > 0
                                  ? singlelistView.ports_details
                                  : [],

                              id:
                                singlelistView &&
                                singlelistView.ports_details &&
                                singlelistView.ports_details.length > 0
                                  ? singlelistView.ports_details.map((s) => {
                                      return s.id;
                                    })
                                  : [],
                            },
                          },
                          {
                            name: "Broker",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView && singlelistView.broker_details
                                  ? singlelistView.broker_details.name //default_brokerage
                                  : "",
                            },
                          },
                          {
                            name: "Survey Date",
                            type: "Month",
                            // disabled: true,
                            // defaultValue: {
                            //   value:singlelistView && singlelistView.sampling_date,
                            // },
                            api: "sampling_date",
                          },
                        ]}
                        postData={this.props.PurchaseOrdersFunction}
                        fetchAgain={() => {
                          this.setState({ editingDispatch: false });
                          this.props
                            .PurchaseOrdersFunction(
                              "get",
                              EndPoints["PurchaseOrders"]
                            )
                            .then((res) => {
                              if (
                                (this.state.singlelistView &&
                                  this.state.singlelistView.id) ||
                                (this.props.params.id &&
                                  res &&
                                  res.length > 0 &&
                                  res
                                    .sort((a, b) => b.id - a.id)
                                    .filter((sD) => {
                                      if (sD.status) {
                                        return (
                                          sD.status === this.state.selctedStatus
                                        );
                                      } else {
                                        return null;
                                      }
                                    }) &&
                                  res
                                    .sort((a, b) => b.id - a.id)
                                    .filter((sD) => {
                                      if (sD.status) {
                                        return (
                                          sD.status === this.state.selctedStatus
                                        );
                                      } else {
                                        return null;
                                      }
                                    })[this.props.params.id])
                              ) {
                                this.listClickHandler(
                                  res
                                    .sort((a, b) => b.id - a.id)
                                    .filter((sD) => {
                                      if (sD.status) {
                                        return (
                                          sD.status === this.state.selctedStatus
                                        );
                                      } else {
                                        return null;
                                      }
                                    })[this.props.params.id]
                                );
                              }
                            });
                        }}
                      />
                      {/* {detailDialog(true)} */}
                    </>
                  ),
                },
                {
                  name: "Order Details",
                  // onClick: { dispatchTab: false },

                  component:
                    // singlelistView === null ? (
                    //   <div className="d-flex width-100 height-100 justifyC alignC">
                    //     <NoData />
                    //   </div>):
                    loadingRight === true ? (
                      <div className="alignC justiyC d-flex width-100 height-100">
                        <StyledCircularProgress color="rgb(241, 154, 48)" />
                      </div>
                    ) : (
                      <Details
                        onlyUi={true}
                        {...this.props}
                        {...this.state}
                        POITemTable={POITemTable}
                        closeDialog={() => {
                          this.setState(
                            {
                              openDialog: false,
                            },
                            () => {}
                          );
                        }}
                        clearPoItems={() => {
                          this.setState({
                            poItemsArrayCopy: [],
                            poItemsArray: [],
                          });
                        }}
                        setPoItems={(poItemsArrayCopy, poItemsArray) => {
                          this.setState(
                            poItemsArrayCopy && poItemsArray
                              ? {
                                  poItemsArrayCopy,
                                  poItemsArray,
                                }
                              : poItemsArrayCopy
                              ? { poItemsArrayCopy }
                              : poItemsArray
                              ? { poItemsArray }
                              : {},
                            () => {
                              this.setWidth(this.state.poItemsArray);
                            }
                          );
                        }}
                        setPoItemsPrev={(
                          poItemsArrayTOPreview,
                          poItemsArrayTOPreviewCopy,
                          poItemsArrayTOPreviewCopy2
                        ) => {
                          this.fetchpoItems();
                          this.setState(
                            {
                              poItemsArrayTOPreview,
                              poItemsArrayTOPreviewCopy,
                              poItemsArrayTOPreviewCopy2,
                            },
                            () => {
                              // if (this.state.poItemsArray.lenght > 0) {
                              this.setWidth(this.state.poItemsArrayTOPreview);
                              // }
                            }
                          );
                        }}
                      />
                    ),
                },
              ]}
              selectedValue={selectedValue ? selectedValue : 0}
            />
          )}
        </Paper>
      </Layout>
    );
  }
}

export default PurchaseOrdersComponent;
