import {
  Tabs,
  Tab,
  AppBar,
  withStyles,
  Box,
  Typography,
} from "@material-ui/core";

import PropTypes from "prop-types";
import { StyledCircularProgress } from "../styles/StyledComponents";

import CommingSoon from "./CommingSoon";
import MainViewTable from "./MainViewTable";
import React, { useState, useEffect } from "react";
import {
  lightGrey,
  primary,
  white,
  green,
  red,
  yellow,
} from "../styles/colors";
import {
  CssDialog,
  StyledButton,
  StyledSearchBar,
} from "../styles/StyledComponents";
import {
  Divider,
  Popover,
  List,
  ListItem,
  ClickAwayListener,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import { BottomArrow, PlusIcon, TopArrow } from "../styles/Icons";
import AddIcon from "@mui/icons-material/Add";
import { MoreHorizOutlined } from "@material-ui/icons";
import CreateDialog from "./CreateDialog";
import CreateDialogPO from "./CreateDialogPO";
import CreateDialogForSurvayReport from "./CreateDialogForSurvayReport";
import { filterData } from "../Regex";
import SnackbarComponent from "./SnackbarComponent";
function TabPanel(props) {
  const { module, children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div
          style={{
            // margin: "0 -24px -24px -24px",
            height: "100%",
            // padding: "24px 2px",
            overflowY: "auto",
            // margin: "0 -41px -24px -41px",
            // height: "calc(100% + 24px)",
            // padding: "2px 17px",
            // overflowY: "auto",
            // width: "calc(100% + 80px)",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function TabPanelnoPadding(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div
          className="scrollBluePrimary"
          style={{
            // margin: "0 -24px -24px -24px",
            height: "calc(100% + 15px)",
            padding: "0",
            margin: "0",
            overflowY: "auto",
            overflowX: "auto",
            width: "100%",
            minWidth: "100%",
            // margin: "0 -41px -24px -41px",
            // height: "calc(100% + 24px)",
            // padding: "2px 17px",
            // overflowY: "auto",Nav
            // width: "calc(100% + 80px)",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

TabPanelnoPadding.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const CssTab = withStyles({
  root: {
    "&.PrivateTabIndicator-colorSecondary-1003": {
      backgroundColor: "#1E32FA",
    },
  },
})(Tab);
const CssTabs = withStyles({
  root: {
    "& .PrivateTabIndicator-colorSecondary-5": {
      backgroundColor: "#00867E",
    },
    "& .MuiTabs-flexContainer": {
      display: " flex",
      height: "0",
      marginBottom: "0",
    },
    "& .MuiTab-root": {
      padding: "6px 40px",
    },
  },
})(Tabs);

export default function MainView(props) {
  const {
    module,
    customCretae,
    filterChild,
    noAddButton,
    checkbox,
    bottomBar,
    singleOnclick,
    checkboxConfirmation,
    addModuleToRole,
    customButton,
    removeallOption,
    noSearchBar,
    Title,
    minHeight,
    nobottomBarAction,
    bottomBarheader,
    changeUnit,
    singleUnit,
    weight,
    setSelectedListArrayError,
    setSelectedListArrayErrorSet,
    padding,
    confirmToSelect,
    confirmToSelectDenied,
    noPadding,
    tableListRate,
    tabelLoading,
    noHeader,
    sidePadding,pagination
  } = props;
  console.log(noAddButton,"panoAddButtongination")
  const reUse = props.list
    ? props.list
    : [{ name: "dummy", component: <CommingSoon /> }];
  const [value, setValue] = React.useState(0);
  const [valueToMap, setValueToMap] = React.useState(reUse);
  const handleChange = (event, newValue, singleTab) => {
    if (props.onChange) {
      props.onChange(singleTab,newValue);
    }
    setValue(newValue);
  };
  useEffect(() => {
    if (props.selectedValue) {
      setValue(props.selectedValue);
    } else {
    }
  }, [props.selectedValue]);
  useEffect(() => {
    if (props.list) {
      setValueToMap(props.list);
    } else {
    }
  }, [props.list]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedList, setSelectedList] = useState(null);

  const [selectedListArray, setSelectedListArray] = useState([]);
  const [loader, setLoad] = useState(false);
  useEffect(() => {
    setLoad(tabelLoading);
  }, [tabelLoading]);
  const [editList, setEditList] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [inputArr, setInputArray] = useState([
    { name: "title", type: "textField", dataType: "text" },
    // { name: "ssipluser", type: "select", dataType: "text" },
  ]);
  const [tableListCopy, setTableListCopy] = useState(tableListRate ?? []);
  const [menuOpen, setmenuOpen] = useState(false);
  const [openCheckConfirmDialog, setOpenCheckConfirmDialog] = useState(false);
  useEffect(() => {
    setTableList(tableListRate);
    setTableListCopy(tableListRate);
 
  }, [tableListRate]);
  const [tableList, setTableList] = useState(tableListRate ?? []);
  const [widthList, setWidthList] = useState({});
  const [header, setHeader] = useState(["Sr.No."]);
  const [error, setError] = useState(false);

  return (
    <>
      <div
        style={{
          height: "100%",
          weight: "100%",
          background: "#8080800f",
          padding: sidePadding ? "" : "20px",
        }}
      >
        {noHeader ? (
          ""
        ) : (
          <div style={{ marginBottom: "10px" }}>
            <span style={{ fontWeight: "600", color: "#787878cf" }}>
              Rates & Offers
            </span>
          </div>
        )}
        <div
          className="width-100 d-flex d-flex-column alignC"
          style={{
            backgroundColor: white,
            minHeight: noHeader ? "100%" : "calc(100% - 20.02px)",
            padding: sidePadding ? "" : "20px",
            height: noHeader ? "100%" : "calc(100% - 20.02px)",
          }}
        >
          {props.loading &&
          props.loading.boards &&
          props.loading.boards === true ? (
            <StyledCircularProgress />
          ) : (
            <>
              {noSearchBar ? (
                ""
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                  {Title ? (
                    <div
                      className="widthFC fontWeight400 alignC d-flex"
                      style={{ fontSize: "23px" }}
                    >
                      {Title}
                    </div>
                  ) : (
                    ""
                  )}
                  <StyledSearchBar
                    padding={"9px"}
                    rd={"4px"}
                    bd={"1px solid #80808036"}
                    // bgC={"#F2F2F2"}
                    fieldName={
                      props.searchFiled
                        ? props.searchFiled
                        : props.header
                        ? props.header
                        : tableList &&
                          tableList.length > 0 &&
                          Object.keys(tableList[0])
                        ? Object.keys(tableList[0])
                        : ["id"]
                    }
                    copyData={tableListCopy}
                    filteredData={(filterNotify) => {
                      setTableList(filterNotify);
                    }}
                    onClear={() => {
                      setTableList(tableListCopy);
                    }}
                    placeholder={`Search ${module}`}
                  />
                  {Title ? (
                    ""
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {filterChild ? filterChild() : ""}
                      {noAddButton ? (
                        ""
                      ) : (
                        <Button
                          onClick={() => {
                            setOpenDialog(true);
                            setSelectedList(null);
                            props.rateHandle();

                            // if (props.onAdd) {
                            //   props.onAdd();
                            // }
                            // if (props.reset) {
                            //   props.reset();
                            // }
                          }}
                          style={{
                            background: "rgb(241 154 48)",
                            color: "white",
                            textTransform: "capitalize",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "5px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AddIcon />
                          </div>
                          Add New {module}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              )}
              <AppBar
                style={{
                  boxShadow: "none",
                  borderBottom: "1px solid #0000000B",
                  width: "100%",
                  backgroundColor: "white",
                  // padding: "0 40px",
                  // background:"rgb(209 204 204 / 41%)",
                }}
                position="static"
              >
                <CssTabs
                  style={{
                    backgroundColor: "rgb(209 204 204 / 41%)",
                    color: primary,
                    padding: "0 40px",
                  }}
                  value={value}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {valueToMap &&
                    valueToMap.map(
                      (singleTab, index) =>
                        singleTab && (
                          <CssTab
                            onClick={(event) => {
                              handleChange(event, index, singleTab);
                            }}
                            key={singleTab.name}
                            style={{
                              fontSize: "0.9rem",
                              minWidth: "fit-content",
                              letterSpacing: "1px",
                              textTransform: "capitalize",
                              opacity: value === index && 1,
                            }}
                            value={index}
                            label={singleTab.name}
                            wrapped
                            {...a11yProps(index)}
                          />
                        )
                    )}
                </CssTabs>
              </AppBar>

              {valueToMap &&
                valueToMap.map((singleTab, index) =>
                  value === index ? (
                    noPadding ? (
                      <TabPanelnoPadding
                        key={index.toString()}
                        style={{
                          backgroundColor: white,
                          height:noAddButton?"calc(100% - 49px)":"calc(100% - 98px)"
                        }}
                        value={index}
                        index={index}
                      >
                        {singleTab.component ? (
                          singleTab.component
                        ) : (
                          <MainViewTable
                            tableList={tableList}
                            pagination={pagination}
                            header={props.header ?? ["id"]}
                            loader={loader}
                            actions={[]}
                          />
                        )}
                      </TabPanelnoPadding>
                    ) : (
                      <TabPanel
                        key={index.toString()}
                        className="list width-100  overflowya postionR"
                        style={{
                          backgroundColor: white,
                          height:noAddButton?"calc(100% - 49px)":"calc(100% - 98px)"
                        }}
                        value={index}
                        index={index}
                      >
                        {singleTab.component ? (
                          singleTab.component
                        ) : (
                          <MainViewTable
                            tableList={tableList}
                            pagination={pagination}
                            header={props.header ?? ["id"]}
                            loader={loader}
                            actions={[]}
                          />
                        )}
                      </TabPanel>
                    )
                  ) : (
                    ""
                  )
                )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
