import React from "react";

import { primary } from "../styles/colors";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import PurchaseIcon from "@mui/icons-material/AddShoppingCart";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import DispatchesIcon from "@mui/icons-material/LocalShipping";
import InventoryIcon from "@mui/icons-material/Inventory";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import SailingOutlinedIcon from "@mui/icons-material/SailingOutlined";
import SalesOrdersIcon from "@mui/icons-material/Sell";
import DirectionsBusOutlinedIcon from "@mui/icons-material/DirectionsBusOutlined";
import BrokersIcon from "@mui/icons-material/Group";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import BrandingWatermarkOutlinedIcon from "@mui/icons-material/BrandingWatermarkOutlined";
import { withStyles } from "@material-ui/styles";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import {
  POIcon,
  SOIcon,
  PORTIcon,
  BrandIcon,
  VendorsIcon,
  SettingIcon,
} from "../styles/Icons";
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import PaymentIcon from '@mui/icons-material/Payment';
import GavelIcon from '@mui/icons-material/Gavel';

export const accessText = {
  
  //purchase order module
  purchase_order: "Purchase Order",
  //  child
  purchase_order_detail: "PO Overview",
  rates: "Offer Rate",
  sales_intent:"Sales Intent",
  chat:"Chat",
  payment_terms: "Payment Term",
  hsn_codes: "HSN Code",
  cash_discounts: "Cash Discount",
  contract_type:"Contract Type",
 
//port module
  ports: "Port",
   //  child
  port_overview: "Ports Detail",
  shippers: "Shipper",
  
//sales order module
  sales_orders: "Sales Order",
  //  child
  sales_orders_overview: "SO Overview",
  clients: "Client",
  policys: "Policy",
  so_payment_terms: "SO Payment Term",
  incoterms: "Incoterm",
  shipping_terms: "Shipping Term",


 //vendors module
  vendors: "Vendor",
  //  child
  millers: "Miller",
  brokers: "Broker",
  transporters: "Transporter",



//brand module
  rice_bags: "Rice Bag",
  //  child
  brands:"Brand",
  rice_bags_details: "Rice Bags Detail",
  packagings: "Packaging",
  riceBagSellers: "Rice Bag Seller",


  //setting module
  settings: "Setting",
  //  child
  users: "User",
  survey_companys: "Survey Company",
  item_categories: "Item Category",
  item_types: "Item Type",
  benificiaries: "Beneficiary",
  notify: "Notify",
  currencys: "Currency",

};

const routes = [
  {
    path: null,
    title: "Setting",
    childRoutes: [
   
      {
        path: "/UsersAndPermissions",
        title: <span style={{ marginLeft: "-20px" }}>User</span>,

        access: accessText.users,
        icon: (path, chectSelected) => (
          <AccountBoxOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/SurveyCompanys",
        title: <span style={{ marginLeft: "-20px" }}>Survey Company</span>,
        access: accessText.survey_companys,
        icon: (path, chectSelected) => (
          <PollOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/ItemCategories",
        title: <span style={{ marginLeft: "-20px" }}>Item Category</span>,
        access: accessText.item_categories,
        icon: (path, chectSelected) => (
          <CategoryOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/ItemTypes",
        title: <span style={{ marginLeft: "-20px" }}>Item Type</span>,
        access: accessText.item_types,
        icon: (path, chectSelected) => (
          <LoyaltyOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
   
      {
        path: "/Beneficiarys",
        title: <span style={{ marginLeft: "-20px" }}>Beneficiary</span>,
        access: accessText.benificiaries,
        icon: (path, chectSelected) => (
          <CorporateFareOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },

    
      {
        path: "/Notifys",
        title: <span style={{ marginLeft: "-20px" }}>Notify</span>,

        access: accessText.notify,
        icon: (path, chectSelected) => (
          <NotificationsOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Currencys",
        title: <span style={{ marginLeft: "-20px" }}>Currency</span>,
        access: accessText.currencys,
        icon: (path, chectSelected) => (
          <CurrencyRupeeOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
     
    ],
    access: accessText.settings,
    icon: (path, chectSelected) => (
      <SettingIcon style={{ color: chectSelected(path) ? primary : "white" }} />
    ),
  },

  {
    path: null,
    title: "Rice Bag",
    childRoutes: [
      {
        path: "/BrandsManagements",
        title: <span style={{ marginLeft: "-20px" }}>Rice Bags Detail</span>,
        access: accessText.rice_bags_details,
        icon: (path, chectSelected) => (
          <BrandingWatermarkOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Brands",
        title: <span style={{ marginLeft: "-20px" }}>Brand</span>,
        access: accessText.brands,
        icon: (path, chectSelected) => (
          <PaymentIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Packagings",
        title: <span style={{ marginLeft: "-20px" }}>Packaging</span>,
        access: accessText.packagings,
        icon: (path, chectSelected) => (
          <ShoppingBagOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/RiceBagSellers",
        title: <span style={{ marginLeft: "-20px" }}>Rice Bag Seller</span>,
        access: accessText.riceBagSellers,
        icon: (path, chectSelected) => (
          <ProductionQuantityLimitsOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
    ],
    access: accessText.rice_bags,
    icon: (path, chectSelected) => (
      <BrandIcon style={{ color: chectSelected(path) ? primary : "white" }} />
    ),
  },
  {
    path: null,
    title: "Port",
    childRoutes: [
      {
        path: "/Ports",
        title: <span style={{ marginLeft: "-20px" }}>Ports Detail</span>,
        access: accessText.port_overview,
        icon: (path, chectSelected) => (
          <SailingOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },

      {
        path: "/Shippers",
        title: <span style={{ marginLeft: "-20px" }}>Shipper</span>,
        access: accessText.shippers,
        icon: (path, chectSelected) => (
          <LocalShippingOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
    ],
    access: accessText.ports,
    icon: (path, chectSelected) => (
      <PORTIcon style={{ color: chectSelected(path) ? primary : "white" }} />
    ),
  },
  {
    path: null,
    title: "Sales Order",
    childRoutes: [
      {
        path: "/SalesOrders",
        title: <span style={{ marginLeft: "-20px" }}>SO Overview</span>,
       
        access: accessText.sales_orders_overview,
        icon: (path, chectSelected) => (
          <DiscountOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },

      {
        path: "/Clients",

        title: <span style={{ marginLeft: "-20px" }}>Client</span>,
        access: accessText.clients,
        icon: (path, chectSelected) => (
          <GroupOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Policys",

        title: <span style={{ marginLeft: "-20px" }}>Policy</span>,
        access: accessText.policys,
        icon: (path, chectSelected) => (
          <PolicyOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/SOpaymentTerms",

        title: <span style={{ marginLeft: "-20px" }}>Payment Term</span>,
        access: accessText.so_payment_terms,
        icon: (path, chectSelected) => (
          <PaidOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Incoterms",

        title: <span style={{ marginLeft: "-20px" }}>Incoterm</span>,
        access: accessText.incoterms,
        icon: (path, chectSelected) => (
          <DashboardOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white"  }}
          />
        ),
      },
      {
        path: "/ShippingTerms",

        title: <span style={{ marginLeft: "-20px" }}>Shipping Term</span>,
        access: accessText.shipping_terms,
        icon: (path, chectSelected) => (
          <GavelOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
    ],
    access: accessText.sales_orders,

    icon: (path, chectSelected) => ( 
      <SOIcon style={{ color: chectSelected(path) ? primary : "white" }} />
    ),
  },
  {
    path: null,
    title: "Vendor",
    childRoutes: [
      {
        path: "/Millers",
        title: <span style={{ marginLeft: "-20px" }}>Miller</span>,
        access: accessText.millers,
        icon: (path, chectSelected) => (
          <FactoryOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },

      {
        path: "/Brokers",
        title: <span style={{ marginLeft: "-20px" }}>Broker</span>,
        access: accessText.brokers,
        icon: (path, chectSelected) => (
          <GroupOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },

      {
        path: "/Transporters",
        title: <span style={{ marginLeft: "-20px" }}>Transporter</span>,
        access: accessText.transporters,
        icon: (path, chectSelected) => (
          <DirectionsBusOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
    ],

    access: accessText.vendors,
    icon: (path, chectSelected) => (
      <VendorsIcon style={{ color: chectSelected(path) ? primary : "white" }} />
    ),
  },


  {
    path: null,
    title: "Purchase Order",
    childRoutes: [
      {
        path: "/PurchaseOrders",
        title: <span style={{ marginLeft: "-20px" }}>PO Overview</span>,
        access: accessText.purchase_order_detail,
        icon: (path, chectSelected) => (
          <PurchaseIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Rates",
        title: <span style={{ marginLeft: "-20px" }}>Offer Rate</span>,
        access: accessText.rates,
        icon: (path, chectSelected) => (
          <CurrencyRupeeIcon
            size="large"
            style={{
              color: chectSelected(path) ? primary : "white",
              minWidth: "0px",
            }}
          />
        ),
      },
      {
        path: "/SalesIntents",
        title: <span style={{ marginLeft: "-20px" }}>Sales Intent</span>,
        access: accessText.sales_intent,
        icon: (path, chectSelected) => (
          <LoyaltyIcon
            size="large"
            style={{
              color: chectSelected(path) ? primary : "white",
              minWidth: "0px",
            }}
          />
        ),
      },
      {
        path: "/Chats",
        title: <span style={{ marginLeft: "-20px" }}>Chat</span>,
        access: accessText.chat,
        icon: (path, chectSelected) => (
          <ChatBubbleOutlineRoundedIcon
            size="large"
            style={{
              color: chectSelected(path) ? primary : "white",
              minWidth: "0px",
            }}
          />
        ),
      },
      {
        path: "/PaymentTerms",
        title: <span style={{ marginLeft: "-20px" }}>Payment Term</span>,
        access: accessText.payment_terms,
        icon: (path, chectSelected) => (
          <PaymentsOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/contractType",
        title: <span style={{ marginLeft: "-20px" }}>Contract Type</span>,
        access: accessText.contract_type,
        icon: (path, chectSelected) => (
          <GavelIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },

      {
        path: "/HsNs",
        title: <span style={{ marginLeft: "-20px" }}>HSN Code</span>,
        access: accessText.hsn_codes,
        icon: (path, chectSelected) => (
          <SourceOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/CashDiscount",
        title: <span style={{ marginLeft: "-20px" }}>Cash Discount</span>,
        access: accessText.cash_discounts,
        icon: (path, chectSelected) => (
          <CreditCardIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
    ],

    access: accessText.purchase_order,
    icon: (path, chectSelected) => (
      <POIcon style={{ color: chectSelected(path) ? primary : "white" }} />
    ),
  },
  // {
  //   path: "/Rates",
  //   title: <span style={{ marginLeft: "-20px" }}>Rates</span>,
  //   access: "Rates",
  //   icon: (path, chectSelected) => (
  //     <CurrencyRupeeIcon
  //       size="large"
  //       style={{
  //         color: chectSelected(path) ? primary : "white",
  //         minWidth: "0px",
  //       }}
  //     />
  //   ),
  // },
];

export default routes;
// style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"}
