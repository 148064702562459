import React, { useRef, useEffect } from "react";

import { savePDF } from "@progress/kendo-react-pdf";

import PdfLayout from "./PdfLayout";

function DownloadUi(props) {
  const { download, onClose, landscape, title, children, file, fileName, noLayout } = props;
  const contentArea = useRef(null);

  const handleExportWithFunction = (event) => {
    savePDF(contentArea.current, {
      paperSize: "A4",
      landscape: landscape ? true : false,
      fileName: `${fileName ? fileName : ""}.pdf`,
      forcePageBreak: ".page-break",
      // allPages: true,
      // margin: {
      //   bottom: 50,
      //   left: 20,
      //   right: 20,
      //   top: 50,
      // },
      // template: $("#page-template").html(),
    });
    onClose();
  };
  useEffect(() => {
    if (contentArea) {
      handleExportWithFunction();
    }
  }, [download]);

  return (
    <div style={{ height: "0", width: "0", overflow: "hidden" }}>
      <div
        ref={contentArea}
        style={{
          height: "100%",

          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {noLayout && noLayout === true ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              padding: "0",
              fontSize: "0.5rem",
            }}
          >
            {children}
          </div>
        ) : (
          <PdfLayout
            {...props}
            title={title ? title : ""}
            file={
              file
                ? file
                : {
                    name: "",
                    value: "",
                  }
            }
          >
            <div
              style={{
                width: "calc(100% + 1px)",
                height: "calc(100% + 2px)",
                padding: "0",
                fontSize: "0.6rem",
              }}
            >
              {children}
            </div>
          </PdfLayout>
        )}
      </div>
    </div>
  );
}

export default DownloadUi;
