import React, { useEffect } from "react";
import { connect } from "react-redux";
import CashDiscountComponent from "./CashDiscountComponent";
import { CashDiscountFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";

function CashDiscountContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.CashDiscountFunction("get", EndPoints["CashDiscount"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <CashDiscountComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  CashDiscount: state.modules.CashDiscount,
  [EndPoints["CashDiscount"]]: state.modules[EndPoints["CashDiscount"]],
  loading: state.modules.loading,
});
const mapDispatchToProps = {
    CashDiscountFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashDiscountContainer);
