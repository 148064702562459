import React, { useRef, useEffect } from "react";

import { savePDF } from "@progress/kendo-react-pdf";
import { Divider } from "@material-ui/core";

import PdfLayout from "../../layoutComponents/PdfLayout";
import moment from "moment";
import { filterData } from "../../Regex";

function BagPurchasePdf(props) {
  const { downloadPdf, singlelistView } = props;
  const contentArea = useRef(null);
  console.log(downloadPdf, singlelistView, "aaaaaaaaaaaa");
  const handleExportWithFunction = (event) => {
    savePDF(contentArea.current, {
      paperSize: "A4",
      // landscape: true,
      fileName: `${downloadPdf && downloadPdf.bag_invoice_number}.pdf`,
    });
    props.onClose();
  };
  useEffect(() => {
    if (contentArea) {
      handleExportWithFunction();
    }
  }, [downloadPdf]);

  const HDivider = <Divider style={{ minWidth: "100%", width: "100%" }} />;
  const VDivider = (
    <Divider
      variant="vertical"
      style={{ height: "100%", minHeight: "29.19px" }}
    />
  );

  return (
    <div style={{ height: "0", width: "0", overflow: "hidden" }}>
      <div
        ref={contentArea}
        style={{
          height: "100%",

          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}>
        <PdfLayout
          title="Bag Purchase Order"
          file={{
            name: "Bag PO Number.",
            value: downloadPdf && downloadPdf.bag_invoice_number,
            date: downloadPdf.date,
          }}>
          <div
            style={{
              width: "calc(100% + 1px)",
              height: "calc(100% + 2px)",
              padding: "0",
            }}>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                Bag PO Number :
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}>
                {downloadPdf && downloadPdf.bag_invoice_number
                  ? downloadPdf.bag_invoice_number
                  : ""}
              </div>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                Date :
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}>
                {downloadPdf && downloadPdf.date
                  ? moment(downloadPdf.date).format("ddd, MMM Do YYYY")
                  : ""}
              </div>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                Buyer:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}>
                {downloadPdf && downloadPdf.buyer ? downloadPdf.buyer : ""}
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                Buyer Address:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}>
                {downloadPdf && downloadPdf.buyer_address
                  ? downloadPdf.buyer_address
                  : ""}
              </div>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}>
                Buyer GST No.
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "uppercase",
                }}>
                {downloadPdf && downloadPdf.buyer_gst
                  ? downloadPdf.buyer_gst
                  : ""}
              </div>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                Seller :
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                {downloadPdf && downloadPdf.seller ? downloadPdf.Seller : ""}
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                Seller Address:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}>
                {downloadPdf && downloadPdf.seller_address
                  ? downloadPdf.seller_address
                  : ""}
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}>
                Seller GST No.
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "uppercase",
                }}>
                {downloadPdf && downloadPdf.seller_gst
                  ? downloadPdf.seller_gst
                  : ""}
              </div>
            </div>

            {/* <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Name of Commodity:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {downloadPdf && downloadPdf.commodity_name
                  ? downloadPdf.commodity_name
                  : ""}
              </div>
            </div> */}

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}>
                Quantity:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                {downloadPdf && downloadPdf.quantity
                  ? downloadPdf.quantity + " bags"
                  : ""}
              </div>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}>
                Price:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                {downloadPdf && downloadPdf.price
                  ? downloadPdf.price + " /Bag"
                  : ""}
              </div>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}>
                Bags Weight :
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                {downloadPdf && downloadPdf.bags_weight
                  ? downloadPdf.bags_weight + " grams"
                  : ""}
              </div>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}>
                Brand:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                {singlelistView && singlelistView.brand_name
                  ? singlelistView.brand_name
                  : "-"}
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}>
                Item Category
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                {singlelistView && singlelistView.item_category_name
                  ? singlelistView.item_category_name
                  : ""}
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}>
                Item Type
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                {singlelistView && singlelistView.item_type_name
                  ? singlelistView.item_type_name + "" + "%"
                  : ""}
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                Delivery Place:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                {`${
                  downloadPdf && downloadPdf.delivery_place
                    ? downloadPdf.delivery_place
                    : ""
                }`}
              </div>
            </div>

            {/* <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Taxes:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {downloadPdf && downloadPdf.taxes ? downloadPdf.taxes : ""}
              </div>
            </div>
            */}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}>
                Payment:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}>
                {downloadPdf && downloadPdf.payment ? downloadPdf.payment : ""}
              </div>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}>
                {`In witness where of this purchase order is made on ${
                  downloadPdf && downloadPdf.po_date ? downloadPdf.po_date : ""
                } and duly signed by the authorized representative of buyer.`}
              </div>
            </div>
          </div>
        </PdfLayout>
      </div>
    </div>
  );
}

export default BagPurchasePdf;
