import React, { useEffect } from "react";
import { connect } from "react-redux";
import ShippingTermsComponent from "./ShippingTermsComponent";
import { BrandsManagementsFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";
function ShippingTermsContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.BrandsManagementsFunction("get", EndPoints["ShippingTerms"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <ShippingTermsComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  [EndPoints["ShippingTerms"]]: state.modules[EndPoints["ShippingTerms"]],
  loading: state.modules.loading,
});
const mapDispatchToProps = {
  BrandsManagementsFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingTermsContainer);
