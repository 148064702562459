import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BrandsManagementsComponent from './BrandsManagementsComponent'
import { BrandsManagementsFunction } from '../../redux/index'
import { useParams } from 'react-router'

import { EndPoints } from '../../utils/EndPoints'
function BrandsManagementsContainer(props) {
  const [BrandList, setBrandList] = useState([])
  useEffect(() => {
    fetchUsers()
  }, [])

  const fetchUsers = () => {
    if (props.user.uid) {
      props.BrandsManagementsFunction('get', EndPoints['BrandsManagements'],null,null,null,{ is_active: "True",is_group:"True" })
      // props
      //   .BrandsManagementsFunction(
      //     'get',
      //     'masters/brand/list',
      //     null,
      //     'BrandList',
      //     null,
      //     { is_active: "True",is_group:"True" }
      //   )
      //   .then((BrandList) => {
      //     if (BrandList) {
      //       setBrandList(BrandList)
      //     }
      //   })
    }
  }

  const { tab, id } = useParams()
  const params = { tab: tab, id: id }
  return (
    <BrandsManagementsComponent
      {...props}
      params={params}
      // BrandList={BrandList}
    />
  )
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  BrandsManagements: state.modules.BrandsManagements,
  loading: state.modules.loading,
 
  [EndPoints['BrandsManagements']]:
    state.modules[EndPoints['BrandsManagements']],
})
const mapDispatchToProps = {
  BrandsManagementsFunction,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrandsManagementsContainer)
